import React from 'react';

import { Typography } from '@material-ui/core';

import { TreeItem } from './duck/types';
import { useStyles } from './label.jss';

interface LabelProps {
  item: TreeItem;
}

export const Label = ({ item }: LabelProps) => {
  const classes = useStyles();

  const formatLabel = (value: string, [start, end]: [number, number]) => (
    <span>
      {start > 0 && value.slice(0, start)}
      <span className={classes.matchedText}>
        {value.slice(start, end + 1)}
      </span>
      {end < value.length && value.slice(end + 1)}
    </span>
  );

  return (
    <Typography variant="body1" noWrap className={""}>
      {item.match ? formatLabel(item.label, item.match) : item.label}
    </Typography>
  )
};
