import React from 'react';

import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { ColumnSettings } from 'app/components/dataGrid/agDataGrid';
import { User } from 'app/dataStore/types';
import i18n from '../../../../localization/i18n';

export const columnSettings: ColumnSettings[] = [
  { name: 'id', width: 140 },
  {
    name: 'login',
    width: 140,
    sort: {
      direction: 'asc',
      order: 0,
    },
    lockVisibility: true,
  },
  { name: 'email', width: 170 },
  { name: 'firstName', width: 150 },
  { name: 'lastName', width: 150 },
  { name: 'passwordExpDate', width: 170 },
  {
    name: 'lockLevel',
    width: 140,
    filter: 'agSetColumnFilter',
    formatter: (item: ICellRendererParams) => {
      const data = item.data as User;
      let cellValue = '';
      switch (data.lockLevel) {
        case 0:
          cellValue = i18n.t('workgroup.users.isLocked.unlocked');
          break;
        case 1:
          cellValue = i18n.t('workgroup.users.isLocked.locked');
          break;
        case 2:
          cellValue = i18n.t('workgroup.users.isLocked.masterlocked');
          break;
        case 3:
          cellValue = i18n.t('workgroup.users.isLocked.inactivity');
          break;
      }
      return <div>{cellValue}</div>;
    },
    keyCreator: (params: any) => {
      switch (params.value) {
        case 0:
          return i18n.t('workgroup.users.isLocked.unlocked');
        case 1:
          return i18n.t('workgroup.users.isLocked.locked');
        case 2:
          return i18n.t('workgroup.users.isLocked.masterlocked');
        case 3:
          return i18n.t('workgroup.users.isLocked.inactivity');
      }
      return 'Not Set';
    },
  },
  {
    name: 'lockReason',
    width: 150,
  },
];
