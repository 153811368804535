import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Uploader, UploaderProps } from './uploader';

export interface UploadDialogProps extends Pick<UploaderProps, Exclude<keyof UploaderProps, 'showButtons' | 'startUpload'>> {
  open: boolean;
  title: string;
  onCancel: () => void;
}

export const UploadDialog = React.memo(
  (props: UploadDialogProps) => {
    const [t] = useTranslation();
    const [startUpload, setStartUpload] = useState(false);
    const onUpload = useCallback(() => {
      setStartUpload(true);
    }, []);
    return (
      <Dialog open={props.open} maxWidth="md">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Uploader
            filesAreContent={props.filesAreContent}
            multiple={props.multiple}
            onUploadComplete={props.onUploadComplete}
            showButtons={false}
            startUpload={startUpload}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={props.onCancel} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button color="primary" onClick={onUpload}>
            {t('upload')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => prevProps.open === nextProps.open
);
