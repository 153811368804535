import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  const tabGrey = theme.palette.grey[400];
  const borderStyle = `1px solid ${tabGrey}`;

  return createStyles({
    tabsIndicator: {
      height: '0px',
    },
    tabsRoot: {
      minHeight: '60px',
      minWidth: '540px',
    },
    tabRoot: {
      minHeight: '60px',
      borderBottom: borderStyle,
      borderRadius: '4px 4px 0 0',
      flexGrow: 1,
    },
    tabSelected: {
      border: borderStyle,
      borderBottomWidth: 0,
    },
    tabFirstSelected: {
      border: borderStyle,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
    },
    tabLastSelected: {
      border: borderStyle,
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    tabContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(1),
      overflowY: 'auto',
    },
    tabWrapper: {
      flexDirection: 'row',
    },
    selectMargin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1) / 2,
    },
    badge: {
      position: 'absolute',
      right: (-theme.spacing(1) * 3) / 2,
    },
  });
};

export const useStyles = makeStyles(styles);
