import { CountryInfo } from "app/dataStore";

export const isStateFieldMandatory = (countryCode: string) => {
  const countriesWithStates = ['US', ''];
  return countriesWithStates.includes(countryCode);
};

export const countryNameToCode = (countries: CountryInfo[], countryName: string | undefined) => {
  const country = countries.find((country) => country.name === countryName);
  return country ? country.code : '';
};
