import i18n from 'i18next';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { BladeActions, BladeConfig, bladeConnect, BladeProps, openBlade } from '../../../../blade';
import { DataStoreSelectors, NStation } from '../../../../dataStore';
import {
    changeStationSlotPosition, cleanupStationEdit, stationAddSilence, stationFinishEdit,
    stationRemoveSlot, stationSetEdittingSlot
} from '../../../duck/actions';
import {
    selectCompanyId, selectCurrentStationBlockRemoteScrolling, selectSavingStationData,
    selectStationEdittingSlotId, selectStationFetchingData, selectStationSlotsAndAssets
} from '../../../duck/selectors';
import { fetchStation, saveStationData } from '../../../duck/thunks';
import { ProgramsListBladeType } from '../../programs/programListContainer';
import { ProgramScheduleBladeType } from '../../programSchedule/programScheduleContainer';
import { StationEdit, StationEditActions, StationEditProps } from './stationEdit';

export interface StationEditContainerProps extends BladeProps {
  stationId: number;
  stationName: string;
}

const getEmptyStation = (): NStation => {
  return {
  id: 0,
  name: '',
  isDefault: false,
  players: [],
};
}

const getStation = (state: any, id: number | undefined) => {
  if (!id) {
    return getEmptyStation();
  }

  let station = DataStoreSelectors.NStation.selectStationById(state, id);

  if (!station) {
    station = getEmptyStation();
    station.id = id;
  }

  return station;
};

const mapStateToProps: MapStateToProps<StationEditProps, StationEditContainerProps, any> = (
  state,
  ownProps
) => {
  const slotsAndAssets = selectStationSlotsAndAssets(state, ownProps.stationId);
  const blockRemoteScrolling = selectCurrentStationBlockRemoteScrolling(state);
  const station = getStation(state, ownProps.stationId); 
  const noOtherStations = Object.keys(DataStoreSelectors.NStation.selectStations(state).items).length === 0;
  if(noOtherStations) {
    station.isDefault = true;
  }
  return {
    station,
    canChangeDefault: !noOtherStations,
    companyId: selectCompanyId(state),
    savingData: selectSavingStationData(state, ownProps.stationId),
    fetchingData: selectStationFetchingData(state),
    scheduleEditingSlotId: selectStationEdittingSlotId(state),
    blockRemoteScrolling,
    ...slotsAndAssets,
  };
};

const mapDispatchToProps: MapDispatchToProps<StationEditActions, StationEditContainerProps> = (
  dispatch,
  ownProps
) => {
  return {
    configureWiFiSettings: () => openBlade(StationEditBladeType, '', {}, dispatch),
    createStation: (station: NStation, blockRemoteScrolling: boolean) => {
      dispatch<any>(saveStationData(station, blockRemoteScrolling, bladeId(ownProps)));
    },
    fetchStation: (stationId: number) => {
      dispatch<any>(fetchStation(stationId));
    },
    onBladeClose: (stationId: number) => {
      dispatch(cleanupStationEdit(stationId))
      dispatch<any>(stationFinishEdit())
    },
    closeBlade: () => {
      dispatch(BladeActions.closeBlade(bladeId(ownProps)))
      dispatch<any>(stationFinishEdit())
    },
    scheduleSlot: (stationId: number, slotProgramName: string, slotLocalId: number) => {
      dispatch(stationSetEdittingSlot(stationId, slotLocalId));
      openBlade(
        bladeId(ownProps),
        ProgramScheduleBladeType,
        { stationId, localId: slotLocalId, slotProgramName },
        dispatch
      );},
    addSilence: (stationId: number) => dispatch(stationAddSilence(stationId)),
    addSlots: (stationId: number, defaultSlot: boolean) =>
      openBlade(bladeId(ownProps), ProgramsListBladeType, { stationId, defaultSlot }, dispatch),
    deleteSlot: (stationId: number, slotLocalId: number) =>
      dispatch(stationRemoveSlot(stationId, slotLocalId)),
    changeSlotPosition: (stationId: number, oldIndex: number, newIndex: number) =>
      dispatch(changeStationSlotPosition(stationId, oldIndex, newIndex)),
  };
};

export const StationEditBladeType = 'NEWNITY_STATION_EDIT';

const bladeId = (props: StationEditContainerProps) =>
  props.stationId ? StationEditBladeType : 'NEWNITY_STATION_CREATE';

const bladeConfig: BladeConfig<StationEditContainerProps> = {
  size: { defaultWidth: 800, minWidth: 600 },
  bladeType: StationEditBladeType,
  id: bladeId,
  title: ownProps =>
    ownProps.stationName ? ownProps.stationName : i18n.t('newnity.edit.station.blade.create.title'),
  allowMultipleInstances: false,
};

export const StationEditContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  StationEdit
);
