import { WorkgroupProfileType } from 'app/newnity/duck/types';
import { Brand, TemplateLibrary, User, Workgroup } from '../../dataStore';
import { apiFetch, currentWorkgroupKey, fetchHelper, getCookieValue } from '../../v6/ajax';
import { getMockedTreeItems } from './mockWorkgroups';
import { WorkgroupCreateObject } from './types';

export const WorkgroupApi = {
  getWorkgroups: async (
    workgroupId: number = Number(getCookieValue(currentWorkgroupKey))
  ): Promise<Workgroup[]> => {
    if ((window as any)['mockWorkgroups']) {
      const mockWorkgroups = (window as any)['mockWorkgroups'];
      const workgroups = getMockedTreeItems(mockWorkgroups[0], mockWorkgroups[1]);
      return new Promise(resolve =>
        resolve(
          workgroups.map<Workgroup>(ti => ({
            id: ti.modelId,
            parentId: ti.modelParentId,
            name: ti.label,
          }))
        )
      );
    }

    let response;

    if ((process.env.REACT_APP_HEADER_01 as string) === 'MVision-Current-WorkgroupId') {
      // this will run when the app is called in mvision-html scope
      response = await apiFetch(
        process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/tree`
      );
    } else {
      // this will run when called in standalone scope (for dev only), change 4 to whatever you want
      response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + 'v6/admin/workgroup/1/tree');
    }

    return response.json();
  },

  createWorkgroup: async (wkCreateObj: WorkgroupCreateObject): Promise<number> => {
    let response;
    response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup`,
      'POST',
      wkCreateObj
    );
    return response.json();
  },

  setWorkgroupAsFavorite: async (workgroupId: number): Promise<void> => {
    return new Promise(resolve => setTimeout(resolve, 3000));
  },
  removeWorkgroupAsFavorite: async (workgroupId: number): Promise<void> => {
    return new Promise(resolve => setTimeout(resolve, 3000));
  },

  getBrands: async (): Promise<Brand[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/brands`
    );
    return response.json();
  },

  getTemplateLibraries: async (workgroupId?: number): Promise<TemplateLibrary[]> => {
    let response: Response;
    if (process.env.REACT_APP_HEADER_01 === 'MVision-Current-WorkgroupId' && workgroupId) {
      // this will run when the app is called in mvision-html scope
      response = await apiFetch(
        process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/templateLibraries/${workgroupId}`
      );
    } else {
      // this will run when called in standalone scope (for dev only), change 4 to whatever you want
      response = await apiFetch(
        process.env.REACT_APP_ROOT_DOMAIN + 'v6/admin/workgroup/templateLibraries'
      );
    }
    return response.json();
  },

  getUsersForWorkgroup: async (
    workgroupId: number = Number(getCookieValue(currentWorkgroupKey))
  ): Promise<User[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/users`
    );

    return response.json();
  },

  removeUsersFromWorkgroup: async (workgroupId: number, userIds: number[]): Promise<void> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/users`,
      'DELETE',
      { userIds }
    );
    return response.json();
  },

  addUsersToWorkgroup: async (workgroupId: number, userIds: number[], profileType?: WorkgroupProfileType): Promise<void> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/users`,
      'PUT',
      { userIds, profileType: profileType ? profileType : WorkgroupProfileType.NewnityDefault }
    );
    return response.json();
  },
};
