import i18next from 'i18next';
import { Dispatch } from 'redux';

import i18n from '../../../localization/i18n';
import { openBlade } from '../../blade';
import { closeBlade, setTitle, closeChildrenBlades } from '../../blade/duck/actions';
import { pushNotification } from '../../components/notifier';
import {
  DataStoreActions,
  DataStoreSelectors,
  EntityType,
  NCompany,
  NDevice,
  NLocation,
  NStation,
} from '../../dataStore';
import { CompanyBladeType } from '../blades/company';
import {
  companyDataFetchError,
  companyDataFetchRequest,
  companyDataFetchSuccess,
  companySaveError,
  companySaveRequest,
  companySaveSuccess,
  defaultParentWorkgroupError,
  defaultParentWorkgroupRequest,
  defaultParentWorkgroupSuccess,
  deviceDeleteError,
  deviceDeleteRequest,
  deviceDeleteSuccess,
  deviceFinishEdit,
  deviceSaveError,
  deviceSaveRequest,
  deviceSaveSuccess,
  importFileError,
  importFileRequest,
  importFileSuccess,
  locationDeleteError,
  locationDeleteRequest,
  locationDeleteSuccess,
  locationFinishEdit,
  locationSaveError,
  locationSaveRequest,
  locationSaveSuccessful,
  parseImportFileError,
  parseImportFileRequest,
  parseImportFileSuccess,
  searchSetSearched,
  setCompanyError,
  setCompanyRequest,
  setCompanySuccess,
  startupRequest,
  startupSuccess,
  stationDeleteError,
  stationDeleteRequest,
  stationDeleteSuccess,
  stationFetchError,
  stationFetchRequest,
  stationFetchSuccess,
  stationFinishEdit,
  stationSaveError,
  stationSaveRequest,
  stationSaveSuccessful,
  updateCurrentParentWorkgroup,
} from './actions';
import { CompanyDto, LocationDto, NewnityApi } from './api';
import { emptySearchFields } from './reducer';
import { NewnityState, ProgramAsset, SearchEntity, SearchFields } from './types';

export function newnityStartup() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startupRequest);
    dispatch(fetchCompanies());
    try {
      await NewnityApi.anthemAuthentication();
      dispatch(fetchPrograms());
      dispatch(startupSuccess());
    } catch (err) {
      dispatch(
        pushNotification(
          `${i18next.t('newnity.startup.anthemAuthenticationFailure')}: ${err}`,
          'error'
        )
      );
    }
  };
}

export function fetchCompanies() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NCompany));
    try {
      const companies = await NewnityApi.search(emptySearchFields, SearchEntity.Company);
      dispatch(DataStoreActions.entityListSuccess(EntityType.NCompany, companies));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NCompany, err));
      dispatch(pushNotification(`Could not fetch companies: ${err}`, 'error'));
    }
  };
}

export function fetchCompanyInfo(companyId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(companyDataFetchRequest());
    try {
      const companyInfo = await NewnityApi.getCompanyInfo(companyId);
      const updatedCompany = NewnityApi.dtoToCompany(companyInfo);
      dispatch(DataStoreActions.entityUpsert(EntityType.NCompany, updatedCompany));
      dispatch(companyDataFetchSuccess());
    } catch (err) {
      dispatch(companyDataFetchError(err));
      dispatch(pushNotification(`Could not fetch company info: ${err}`, 'error'));
    }
  };
}
export function setCurrentCompany(companyId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setCompanyRequest(companyId));
    try {
      await NewnityApi.anthemChangeWorkgroup(companyId);
      dispatch(setCompanySuccess(companyId));
    } catch (err) {
      dispatch(setCompanyError(companyId, err));
      dispatch(pushNotification(`Could not switch workgroup in Anthem: ${err}`, 'error'));
    }
  };
}
export function fetchDefaultParentWorkgroup(companyWorkgroupId?: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(defaultParentWorkgroupRequest());
    try {
      if (!companyWorkgroupId) {
        const companyInfo = await NewnityApi.getDefaultParentWorkgroup();
        const company = NewnityApi.dtoToCompany(companyInfo);
        dispatch(DataStoreActions.entityUpsert(EntityType.NParentCompanyWorkgroup, company));
        dispatch(defaultParentWorkgroupSuccess(company.id));
      } else {
        const companyInfo = await NewnityApi.getDefaultParentWorkgroup(companyWorkgroupId);
        const company = NewnityApi.dtoToCompany(companyInfo);
        dispatch(DataStoreActions.entityUpsert(EntityType.NParentCompanyWorkgroup, company));
        dispatch(updateCurrentParentWorkgroup(company.id));
      }
    } catch (err) {
      dispatch(defaultParentWorkgroupError(err));
      dispatch(pushNotification(`Could not fetch company info: ${err}`, 'error'));
    }
  };
}

export function saveCompanyInfo(companyData: NCompany) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(companySaveRequest(companyData.id));
    try {
      const createMode = companyData.id === 0;
      const companyDto = await NewnityApi.saveCompanyInfo(companyData, createMode);
      const updatedCompany = NewnityApi.dtoToCompany(companyDto);
      dispatch(DataStoreActions.entityUpsert(EntityType.NCompany, updatedCompany));

      dispatch(setTitle(CompanyBladeType, updatedCompany.name));
      await NewnityApi.anthemChangeWorkgroup(updatedCompany.id);
      dispatch(companySaveSuccess(updatedCompany.id));
      const notificationMessageKey = createMode
        ? 'newnity.edit.company.toast.create.success'
        : 'newnity.edit.company.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
    } catch (err) {
      dispatch(companySaveError(err));
      dispatch(pushNotification(`Could not save company info: ${err}`, 'error'));
    }
  };
}

export function openCompanyEditBlade(
  companyId: number = 0,
  companyName: string = i18n.t('newnity.edit.company.blade.create.title')
) {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState() as { newnity: NewnityState };
    const currentCompanyFetchingData = state.newnity.currentCompany.fetchingData;
    const parentWorkgroup = state.newnity.parentWorkgroup;

    openBlade('', CompanyBladeType, { title: companyName }, dispatch);

    if (companyId > 0) {
      if (!currentCompanyFetchingData) {
        dispatch(fetchCompanyInfo(companyId));
        dispatch(fetchDefaultParentWorkgroup(companyId));
      }
    } else {
      if (parentWorkgroup.defaultWorkgroupParent < 1 && !parentWorkgroup.fetchingData) {
        dispatch(fetchDefaultParentWorkgroup());
      }
    }
  };
}

export const fetchLocations = (companyId: number) => {
  return async (dispatch: Dispatch<any>) => {
    if (!companyId) {
      return;
    }
    dispatch(DataStoreActions.entityListRequest(EntityType.NLocationList));
    try {
      const locations = await NewnityApi.getLocations(companyId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.NLocationList, locations));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NLocationList, err));
      dispatch(pushNotification(`Could not fetch locations: ${err}`, 'error'));
    }
  };
};

export const fetchLocation = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const locationInfo = await NewnityApi.getLocation(id);
      const updatedLocation = NewnityApi.dtoToLocation(locationInfo);

      dispatch(DataStoreActions.entityUpsert(EntityType.NLocation, updatedLocation));
      dispatch(DataStoreActions.entityUpsert(EntityType.NLocationList, updatedLocation));
    } catch (err) {
      dispatch(pushNotification(`Could not fetch location: ${err}`, 'error'));
    }
  };
};

export const search = (fields: SearchFields, entity: EntityType) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(entity));
    dispatch(searchSetSearched(entity, fields));
    try {
      const results = await NewnityApi.search(fields, entityToSearchEntity(entity));
      dispatch(DataStoreActions.entityListSuccess(entity, results));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(entity, err));
      dispatch(pushNotification(`Could not fetch search results: ${err}`, 'error'));
    }
  };
};

const entityToSearchEntity = (entity: EntityType) => {
  switch (entity) {
    case EntityType.NCompanySearchResult:
      return SearchEntity.Company;
    case EntityType.NLocationSearchResult:
      return SearchEntity.Location;
    case EntityType.NDeviceSearchResult:
      return SearchEntity.Device;
    default:
      return SearchEntity.Company;
  }
};

export const saveLocationData = (companyId: number, location: NLocation, bladeId?: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(locationSaveRequest(companyId, location));
      const createMode = location.workgroupId === 0;
      const savedLocationDto = await NewnityApi.saveLocation(companyId, location, createMode);
      const savedLocation = NewnityApi.dtoToLocation(savedLocationDto);
      dispatch(DataStoreActions.entityUpsert(EntityType.NLocation, savedLocation));
      dispatch(DataStoreActions.entityUpsert(EntityType.NLocationList, savedLocation));
      dispatch(locationSaveSuccessful(companyId, savedLocation));
      dispatch(locationFinishEdit());

      const notificationMessageKey = createMode
        ? 'newnity.edit.location.toast.create.success'
        : 'newnity.edit.location.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
      if (bladeId) {
        dispatch(closeBlade(bladeId));
      }
    } catch (err) {
      dispatch(locationSaveError(companyId, location, err));
      dispatch(pushNotification(`Could not fetch search results: ${err}`, 'error'));
    }
  };
};

export const deleteLocation = (locationId: number, locationName: string, bladeId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(locationDeleteRequest(locationId, locationName));
      const status = await NewnityApi.deleteLocations([locationId]);
      if (status === 200) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.NLocation, {
            id: locationId,
            name: locationName,
          })
        );
        dispatch(
          DataStoreActions.entityDelete(EntityType.NLocationList, {
            id: locationId,
            name: locationName,
          })
        );
        dispatch(locationDeleteSuccess(locationId, locationName));
        dispatch(locationFinishEdit());
        dispatch(closeChildrenBlades(bladeId));

      } else {
        dispatch(locationDeleteError(locationId, locationName, ''));
        dispatch(pushNotification(`Could not delete location ${locationName}`, 'error'));
      }
    } catch (err) {
      dispatch(locationDeleteError(locationId, locationName, err));
      dispatch(pushNotification(`Could not delete location ${locationName}: ${err}`, 'error'));
    }
  };
};

export function saveDevice(device: NDevice) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(deviceSaveRequest());
    try {
      const createMode = device.id === 0;
      const saveResult = await NewnityApi.saveDevice(device, createMode);

      if (saveResult.error) {
        throw new Error(saveResult.error);
      }

      const updatedDevice = saveResult.device;
      try {
        if (device.stationId !== 0) {
          await NewnityApi.assignStationToDevice(
            updatedDevice.id,
            device.stationId,
            updatedDevice.serialNumber
          );
          updatedDevice.stationId = device.stationId;
          updatedDevice.stationName = device.stationName;

        }
      } catch (err) {
        dispatch(pushNotification(err.message, 'error'));
      }

      dispatch(DataStoreActions.entityUpsert(EntityType.NDevice, updatedDevice as NDevice));
      dispatch(deviceSaveSuccess());
      dispatch(deviceFinishEdit());
      
      const notificationMessageKey = createMode
        ? 'newnity.device.edit.toast.create.success'
        : 'newnity.device.edit.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
    } catch (err) {
      dispatch(deviceSaveError(err.message));
      dispatch(pushNotification(`Could not save device: ${err.message}`, 'error'));
    }
  };
}

export function fetchDevice(deviceId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NDevice));
    try {
      const device = await NewnityApi.getDevice(deviceId);
      const dataStoreDevice = NewnityApi.dtoToDevice(device);
      dispatch(DataStoreActions.entityUpsert(EntityType.NDevice, dataStoreDevice));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch device: ${err}`, 'error'));
    }
  };
}

export function fetchEntity(entityType: EntityType, entityId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(entityType));
    try {
      let entity;
      let newEntity;

      switch (entityType) {
        case EntityType.NCompany:
        case EntityType.NParentCompanyWorkgroup:
          entity = await NewnityApi.getCompanyInfo(entityId);
          newEntity = NewnityApi.dtoToCompany(entity as CompanyDto);
          break;

        case EntityType.NLocation:
          entity = await NewnityApi.getLocation(entityId);
          newEntity = NewnityApi.dtoToLocation(entity as LocationDto);
          break;

        // case EntityType.NDevice:
        //   entity = await NewnityApi.getDevice(entityId);
        //   break;

        default:
          throw new Error(`Unknown entity type ${entityType}`);
      }

      dispatch(DataStoreActions.entityUpsert(entityType, newEntity));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(
        pushNotification(
          `Could not fetch entity type ${entityType} with id ${entityId}: ${err}`,
          'error'
        )
      );
    }
  };
}

export const fetchPrograms = () => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    if (
      DataStoreSelectors.getDataStoreItemsFetching(getState(), EntityType.NProgram).fetchComplete
    ) {
      return;
    }

    dispatch(DataStoreActions.entityListRequest(EntityType.NProgram));
    try {
      const programs = await NewnityApi.anthemGetAllPrograms();
      dispatch(DataStoreActions.entityListSuccess(EntityType.NProgram, programs));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch Anthem programs: ${err}`, 'error'));
    }
  };
};

export const fetchDevices = (companyId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NDevice));
    try {
      const devices = await NewnityApi.getDevices(companyId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.NDevice, devices));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch devices: ${err}`, 'error'));
    }
  };
};

export const fetchStations = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NStation));
    try {
      const stations = await NewnityApi.getStations();
      dispatch(DataStoreActions.entityListSuccess(EntityType.NStation, stations));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NStation, err));
      dispatch(pushNotification(`Could not fetch playlists: ${err}`, 'error'));
    }
  };
};

export const fetchStation = (stationId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => { newnity: NewnityState }) => {
    try {
      dispatch(stationFetchRequest(stationId));
      const programAssetsItems = await NewnityApi.anthemGetMediaLibrary();
      const programAssets = programAssetsItems.reduce<ProgramAsset>((obj, item) => {
        obj[item.programId] = item.assetId;
        return obj;
      }, {});
      const station = await NewnityApi.getStation(stationId);
      const state = getState();
      if (state.newnity.currentStation.fetchingData.id === stationId) {
        dispatch(stationFetchSuccess(station, programAssets));
      }
    } catch (err) {
      const state = getState();
      if (state.newnity.currentStation.fetchingData.id === stationId) {
        dispatch(pushNotification(`Could not fetch playlist: ${err}`, 'error'));
        dispatch(stationFetchError(stationId, err));
      }
    }
  };
};

export const deleteStation = (companyId: number, stationId: number, stationName: string, bladeId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(stationDeleteRequest(stationId, stationName));
      const status = await NewnityApi.deleteStation(companyId, stationId);
      if (status.ok) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.NStation, {
            id: stationId,
            name: stationName,
          })
        );
        dispatch(stationDeleteSuccess(stationId, stationName));
        dispatch(stationFinishEdit());
        dispatch(closeChildrenBlades(bladeId));
      } else {
        dispatch(stationDeleteError(stationId, stationName, ''));
        dispatch(pushNotification(`Could not delete playlist ${stationName}`, 'error'));
      }
    } catch (err) {
      dispatch(stationDeleteError(stationId, stationName, err));
      dispatch(pushNotification(`Could not delete playlist ${stationName}: ${err}`, 'error'));
    }
  };
};

export function deleteDevice(deviceId: number, bladeId: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(deviceDeleteRequest(deviceId));
      const result = await NewnityApi.deleteDevice(deviceId);

      if (result.error) {
        throw result.error;
      }

      dispatch(DataStoreActions.entityDelete(EntityType.NDevice, { id: deviceId, name: '' }));
      dispatch(deviceDeleteSuccess());
      dispatch(deviceFinishEdit())
      dispatch(closeChildrenBlades(bladeId));
    } catch (err) {
      dispatch(deviceDeleteError(err));
      dispatch(pushNotification(`Could not delete device: ${err}`, 'error'));
    }
  };
}

export const saveStationData = (
  station: NStation,
  blockRemoteScrolling: boolean,
  bladeId?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch(stationSaveRequest(station));
      const createMode = station.id === 0;
      const state = getState() as { newnity: NewnityState };
      const stationState = createMode
        ? state.newnity.currentStation.createSchedule
        : state.newnity.currentStation.editSchedule;
      const savedStation = await NewnityApi.saveStation(
        station,
        stationState,
        blockRemoteScrolling,
        createMode
      );

      // unset the previous default station if the current one is set as default
      if (station.isDefault) {
        const defaultStation = DataStoreSelectors.NStation.selectDefaultStation(state);
        if (defaultStation && defaultStation.id !== station.id) {
          const oldDefaultStation: NStation = { ...defaultStation, isDefault: false };
          dispatch(DataStoreActions.entityUpsert(EntityType.NStation, oldDefaultStation));
        }
      }

      dispatch(stationSaveSuccessful(station));
      dispatch(stationFinishEdit());
      dispatch(DataStoreActions.entityUpsert(EntityType.NStation, savedStation));
      const notificationMessageKey = createMode
        ? 'newnity.edit.station.toast.create.success'
        : 'newnity.edit.station.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
      if (bladeId) {
        dispatch(closeBlade(bladeId));
      }
    } catch (err) {
      dispatch(stationSaveError(station, err));
      dispatch(pushNotification(`Could not save playlist: ${err}`, 'error'));
    }
  };
};

export const parseImportFile = (remotePath: string) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch(parseImportFileRequest(remotePath));
      const response = await NewnityApi.checkImportFile(remotePath);
      const state = getState() as { newnity: NewnityState };

      if (state.newnity.import.parseFileFetchState.id !== remotePath) {
        return;
      }

      dispatch(parseImportFileSuccess(remotePath, response));
    } catch (err) {
      dispatch(parseImportFileError(remotePath, err));
      dispatch(pushNotification(`Could not parse import file: ${err}`, 'error'));
    }
  };
};

export const importFile = (remotePath: string, companyId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch(importFileRequest(remotePath));
      const response = await NewnityApi.importFile(remotePath, companyId);
      const state = getState() as { newnity: NewnityState };

      if (state.newnity.import.importFetchState.id !== remotePath) {
        return;
      }

      dispatch(importFileSuccess(remotePath, response));
      dispatch(pushNotification(`File import completed`, 'success'));
    } catch (err) {
      dispatch(importFileError(remotePath, err));
      dispatch(pushNotification(`Could not import file: ${err}`, 'error'));
    }
  };
};
