import { addHours, addYears, endOfDay, format, startOfDay } from 'date-fns';

import { NStation } from '../../../dataStore/types';
import {
    AnthemMediaType, Schedule, StationDefaultSlot, StationDefaultSlotDto, StationDto,
    StationScheduleSlot, StationScheduleSlotDto, StationState
} from '../types';
import { anthemFetchOptions } from './anthemApi';

export const getDefaultSchedule = (): Schedule => {
  const now = new Date();
    return {
      id: 0,
      name: '',
      diffusionDays: 127,
      startDate: now,
      endDate: addYears(now, 1),
      startTime: startOfDay(now),
      endTime: endOfDay(now),
    };
};

const stationScheduleSlotToDto = (slot: StationScheduleSlot): StationScheduleSlotDto => {
  let schedule: Schedule;
  if (!slot.schedule) {
    schedule = getDefaultSchedule();
  } else {
    schedule = slot.schedule;
  }

  if (schedule.startDate.getTimezoneOffset) {
    schedule.startDate = addHours(schedule.startDate, schedule.startDate.getTimezoneOffset() / -60);
  }

  if (schedule.endDate.getTimezoneOffset) {
    schedule.endDate = addHours(schedule.endDate, schedule.endDate.getTimezoneOffset() / -60);
  }
  
  const dtoSlot: StationScheduleSlotDto = {
    id: slot.id,
    name: slot.name,
    description: '',
    type: slot.type,
    contentIdMedia: slot.programId,
    isSilence: slot.isSilence,
    ...schedule,
    startTime: format(schedule.startTime, 'HH:mm:ss'),
    endTime: format(schedule.endTime, 'HH:mm:ss'),
    startDate: format(schedule.startDate, 'MM/dd/yyyy'),
    endDate: format(schedule.endDate, 'MM/dd/yyyy')
  };
  return dtoSlot;
};

const addNewSlotsToMediaLibrary = async (
  scheduleSlots: StationScheduleSlot[],
  defaultSlot: StationDefaultSlot
) => {
  const newProgramSlotAssetIds = scheduleSlots
    .filter(slot => !slot.isSilence && slot.assetId && !slot.programId)
    .map(slot => slot.assetId);

  if (defaultSlot.assetId && !defaultSlot.programId) {
    newProgramSlotAssetIds.push(defaultSlot.assetId);
  }
  // add the new programs to the media library
  if (newProgramSlotAssetIds.length > 0) {
    const programInstancesResult = await fetch(
      process.env.REACT_APP_ROOT_DOMAIN + 'music/api/program',
      anthemFetchOptions('PUT', newProgramSlotAssetIds)
    );
    const newProgramInstanceIds: Array<{
      id: number;
      assetId: number;
    }> = await programInstancesResult.json();

    // map the new program instance ids from the media libray to the new slots in the station
    const assetsWithNoInstances: number[] = [];
    const slots = scheduleSlots.map(s => {
      if (s.isSilence) {
        return s;
      }
      if (s.assetId && !s.programId) {
        const newProgramInstance = newProgramInstanceIds.find(p => p.assetId === s.assetId);
        if (!newProgramInstance) {
          // the assetId has no instance added to the media library(something went wrong on the Anthem server)
          // we add the assetId to a collection in order to provide an error to the user
          assetsWithNoInstances.push(s.assetId);
        } else {
          // set the program instance id to the slot program id
          return { ...s, programId: newProgramInstance.id };
        }
      }
      return s;
    });
    if (assetsWithNoInstances.length > 0) {
      throw new Error(
        `${assetsWithNoInstances.length} programs could not be added to the library!`
      );
    }

    const newDefaultSlot = {...defaultSlot};
    // do the same but for the default slot
    if (defaultSlot.assetId && !defaultSlot.programId) {
      const defaultSlotNewProgramInstance = newProgramInstanceIds.find(
        p => p.assetId === defaultSlot.assetId
      );
      if (!defaultSlotNewProgramInstance) {
        throw new Error('Default slot program could not be added to the library!');
      }
      newDefaultSlot.programId = defaultSlotNewProgramInstance.id;
    }
    return { scheduleSlots: slots, defaultSlot: newDefaultSlot };
  } else {
    return { scheduleSlots, defaultSlot };
  }
};

const stationSlotsToDto = (
  stationSlots: StationScheduleSlot[],
  stationDefaultSlot: StationDefaultSlot
) => {
  if (stationSlots.length === 0) {
    throw new Error('Playlist has no programs');
  }
  const slots = stationSlots.map((slot, i) => stationScheduleSlotToDto(slot));
  const defaultSlot: StationDefaultSlotDto = {
    contentIdMedia: stationDefaultSlot.programId,
    description: '',
    type: AnthemMediaType.MusicProgram,
    isSilence: false,
    id: stationDefaultSlot.id,
    name: stationDefaultSlot.name,
  };
  return { slots, defaultSlot };
};

export const stationApi = {
  getStations: async (): Promise<NStation[]> => {
    const result = await fetch(
      process.env.REACT_APP_ROOT_DOMAIN + 'music/api/station',
      anthemFetchOptions('GET')
    );
    return result.json();
  },
  getStation: async (stationId: number): Promise<StationDto> => {
    const result = await fetch(
      process.env.REACT_APP_ROOT_DOMAIN + `music/api/station/${stationId}`,
      anthemFetchOptions('GET')
    );
    return result.json();
  },
  saveStation: async (
    station: NStation,
    stationState: StationState,
    blockRemoteScrolling: boolean,
    createMode: boolean
  ): Promise<NStation> => {
    if (!stationState.defaultSlot) {
      throw new Error('Default slot is empty');
    }

    const mediaLibrarySlots = await addNewSlotsToMediaLibrary(
      stationState.scheduleSlots,
      stationState.defaultSlot
    );

    const stationSlots = stationSlotsToDto(
      mediaLibrarySlots.scheduleSlots,
      mediaLibrarySlots.defaultSlot
    );

    const stationDto: StationDto = {
      id: station.id,
      isDefault: station.isDefault,
      name: station.name,
      players: station.players ? station.players : [],
      schedule: {
        blockRemoteScrolling,
        id: stationState.id,
        name: stationState.name,
        ...stationSlots,
      },
    };

    if (createMode) {
      delete stationDto.id;
    }

    const saveResult = await fetch(
      `${process.env.REACT_APP_ROOT_DOMAIN}music/api/station`,
      anthemFetchOptions('POST', stationDto)
    );

    if (!saveResult.ok) {
      throw new Error('Station data could not be saved.');
    }
    return saveResult.json();
  },
  deleteStation: async (companyId: number, stationId: number) => {
    return fetch(
      process.env.REACT_APP_ROOT_DOMAIN + 'music/api/station/' + stationId,
      anthemFetchOptions('DELETE')
    );
  },
};
