import classNames from 'classnames';
import { FastField, Field, FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@material-ui/core';

import {
    Autocomplete, FieldButton, GroupBox, MaxLengthTextField as TextField
} from '../../../../components';
import { CountryInfo, NCompany } from '../../../../dataStore';
import { countries } from '../../../../dataStore/countries';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { CompanyInfoActions, CompanyInfoProps } from './company';
import { useStyles } from './company.jss';

export const CompanyDetailsFields: React.FunctionComponent<
  CompanyInfoProps & CompanyInfoActions
> = props => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.fieldsContainer}>
      <GroupBox title="Company">
        <div className={classes.horizontalContainer}>
          <div className={classNames([classes.vertical, classes.marginRight])}>
            <FastField
              name="name"
              render={({ field, form }: FieldProps<NCompany>) => {
                return (
                  <TextField
                    autoFocus={true}
                    label={t('newnity.company.name')}
                    helperText={helperText(form, field)}
                    error={validateEmptyField(form, field)}
                    {...field}
                    value={field.value || ''}
                    className={classes.formField}
                    inputProps={{ maxLength: 150 }}
                  />
                );
              }}
            />
            <FastField
              name="number"
              render={({ field, form }: FieldProps<NCompany>) => {
                return (
                  <TextField
                    label={t('newnity.company.number')}
                    helperText={helperText(form, field)}
                    {...field}
                    error={validateEmptyField(form, field)}
                    value={field.value || ''}
                    className={classes.formField}
                    inputProps={{ maxLength: 50 }}
                  />
                );
              }}
            />
          </div>
          <div className={classNames([classes.vertical, classes.marginLeft])}>
            <Field
              name="parentId"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.parentWorkgroup')}
                  {...field}
                  error={validateEmptyField(form, field)}
                  value={field.value || 1}
                  className={classes.formField}
                  helperText={helperText(form, field)}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <FieldButton
                          title={t('create.workgroup.select_btn_label')}
                          onClick={() => {}}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
      </GroupBox>
      <GroupBox title="Address">
        <div className={classes.horizontalContainer}>
          <div className={classNames([classes.vertical, classes.marginRight])}>
            <FastField
              name="country"
              render={({ field, form }: FieldProps<NCompany>) => (
                <Autocomplete
                  items={countries}
                  selectedItem={form.values.country}
                  minCharInput={2}
                  textFieldProps={{
                    name: 'country',
                    label: t('newnity.company.address.country'),
                  }}
                  onChange={(selectedItem: CountryInfo | undefined) => {
                    form.setFieldValue(field.name, selectedItem ? selectedItem.name : '');
                  }}
                />
              )}
            />
            <FastField
              name="city"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.address.city')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
            <FastField
              name="state"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.address.state')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
          </div>
          <div className={classNames([classes.vertical, classes.marginLeft])}>
            <FastField
              name="zipCode"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.address.zipCode')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <FastField
              name="streetAddress"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  multiline
                  label={t('newnity.company.address.streetAddress')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
          </div>
        </div>
      </GroupBox>
      <GroupBox title="Contact">
        <div className={classes.horizontalContainer}>
          <div className={classNames([classes.vertical, classes.marginRight])}>
            <FastField
              name="firstName"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.contact.firstName')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <FastField
              name="lastName"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.contact.lastName')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
          </div>
          <div className={classNames([classes.vertical, classes.marginLeft])}>
            <FastField
              name="phone"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.contact.phone')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <FastField
              name="email"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  label={t('newnity.company.contact.email')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
          </div>
        </div>
      </GroupBox>
    </div>
  );
};
