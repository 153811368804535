import { createSelector } from 'reselect';

import { EntityType, NLocation } from '../types';
import { getDataStore } from './genericSelectors';

export const selectLocations = (state: any) =>
  getDataStore(state)[EntityType.NLocation];

export const selectLocationItems = createSelector(
  [selectLocations],
  locations => {
    return Object.keys(locations.items).filter((key: string) => key !== '0').map<NLocation>(
      key => locations.items[Number(key)]
    );
  }
);

export const selectLocationById = (state: any, locationId: number) => selectLocations(state).items[locationId];
