import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const drawerWidth = 260;

const styles = (theme: Theme) =>
  createStyles({
    app: {
      display: "flex",
      height: "100%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    drawerPaper: {
      background: theme.palette.primary.main,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      display: 'flex',
      flexDirection: 'column'
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    expandCollapseButton: {
      marginLeft: theme.spacing(1),
    },
    listItemText: {
      paddingLeft: theme.spacing(4),
    },
    content: {
      flexGrow: 1,
      width: "100%",
      height: "100%",
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
      // padding: theme.spacing(3)
    },
    version: {
      fontSize: 10,
      textAlign: 'center'
    }
  });

export const useStyles = makeStyles(styles);
