import React from 'react';

import { LocationSearchFields } from 'app/newnity/duck/types';
import { AddressFields } from '../addressFields';
import { TabFieldsWithContentWrapper } from '../tabFields';
import { getLocationInfoFields } from '../utils';

interface LocationFieldsProps {
  tabIndex: number;
  location: LocationSearchFields;
  handleChange(e: React.ChangeEvent): void;
}

export const LocationFields = (props: LocationFieldsProps) => {
  return (
    <>
      {props.tabIndex === 0 && (
        <TabFieldsWithContentWrapper
          handleChange={props.handleChange}
          fields={getLocationInfoFields(props.location)}
          key="location"
          parentName="location"
        />
      )}
      {props.tabIndex === 1 && (
        <AddressFields
          parentName="location"
          {...props.location}
          handleChange={props.handleChange}
          key="location.address"
        />
      )}
    </>
  );
};
