import React from 'react';

import { EntityType } from 'app/dataStore';
import { SearchFields } from 'app/newnity/duck/types';
import { CompanyTabLabels } from './company';
import { DeviceTabLabels } from './device';
import { LocationTabLabels } from './location';

interface TabLabelsProps {
  currentEntityType: EntityType;
  fields: SearchFields;
  currentTabIndex: number;
  onTabChanged: (event: React.ChangeEvent<any>, newTabIndex: number) => void;
}

export const TabLabels = (props: TabLabelsProps) => {
  return (
    <>
      {props.currentEntityType === EntityType.NCompanySearchResult && (
        <CompanyTabLabels
          company={props.fields.company}
          currentTabIndex={props.currentTabIndex}
          onTabChanged={props.onTabChanged}
        />
      )}
      {props.currentEntityType === EntityType.NLocationSearchResult && (
        <LocationTabLabels
          location={props.fields.location}
          currentTabIndex={props.currentTabIndex}
          onTabChanged={props.onTabChanged}
        />
      )}
      {props.currentEntityType === EntityType.NDeviceSearchResult && (
        <DeviceTabLabels
          device={props.fields.device}
          location={props.fields.location}
          currentTabIndex={props.currentTabIndex}
          onTabChanged={props.onTabChanged}
        />
      )}
    </>
  );
};
