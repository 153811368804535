import { Form, Formik, FormikErrors } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../../localization/i18n';
import { BladeProps, useBladeClosing } from '../../../../blade';
import { FooterSubmit } from '../../../../components/bladeLayout/footerSubmit';
import { TabsHeaderAndContent } from '../../../../components/bladeLayout/tabsHeaderAndContent';
import { validateDeviceSettings } from '../../../../components/deviceSettings/deviceSettings';
import { DeviceSettingsContainer } from '../../../../components/deviceSettings/deviceSettingsContainer';
import { EntityType, NDevice, NLocationList, NStation } from '../../../../dataStore';
import { Loading } from '../../search/results/loading';
import { useStyles } from './device.jss';
import { DeviceDetails } from './deviceDetails';

export interface DeviceProps {
  companyId: number;
  device: NDevice;
  locations: NLocationList[];
  stations: NStation[];
  isSaving: boolean;
  isLoading: boolean;
  saved: boolean;
  openedFromLocation?: number;
}

export interface DeviceActions {
  saveDevice: (device: NDevice) => void;
  setDeviceSaved: (value: boolean) => void;
  fetchDevice: (deviceId: number) => void;
  fetchLocations: (companyId: number) => void;
  fetchStations: () => void;
  deviceSetLocation: (locationId: number) => void;
  forceCloseBlade: () => void;
  onCloseBlade: () => void;
}

type Props = DeviceActions & DeviceProps & BladeProps;

const tabLabels = [
  { label: i18n.t('newnity.device.properties.tab') },
  { label: i18n.t('device.settings.title') },
];

const getLocationId = (props: Props, formRef: React.MutableRefObject<null>) => {
  let locationId = 0;

  if (props.openedFromLocation) {
    locationId = props.openedFromLocation;
  }

  if (!locationId) {
    locationId = ((formRef.current as any) as Formik).getFormikBag().values.locationId;
  }

  return locationId;
};

export const Device = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formRef = useRef(null);

  const {
    fetchLocations,
    fetchStations,
    fetchDevice,
    setDirty,
    forceCloseBlade,
    setDeviceSaved,
  } = props;

  useEffect(() => {
    fetchLocations(props.companyId);
    fetchStations();

    if (props.device.id) {
      fetchDevice(props.device.id);
    }
  }, [props.companyId, props.device.id]);

  useEffect(() => {
    if (props.saved) {
      setDirty(false);
      forceCloseBlade();
      setDeviceSaved(false);
    }
  }, [props.saved, setDirty, forceCloseBlade, setDeviceSaved]);

  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => props.onCloseBlade()
  );

  const content = (tabIndex: number) => {
    if (tabIndex === 0) {
      return props.isLoading ? (
        <Loading />
      ) : (
        <DeviceDetails
          locations={props.locations}
          stations={props.stations}
          openedFromLocation={props.openedFromLocation}
          deviceSetLocation={props.deviceSetLocation}
        />
      );
    } else {
      const locationId = getLocationId(props, formRef);

      if (!locationId) {
        return <div>{t('nDevice.columnTitle.deviceSettings.select.location')}</div>;
      }

      return (
        <DeviceSettingsContainer
          entityId={props.device.id}
          entityType={EntityType.NDevice}
          parentEntityId={locationId}
          isSaving={props.isSaving}
        />
      );
    }
  };

  const footer = () => {
    return (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={props.forceCloseBlade}
        submitButtonLabel={t('footer.save')}
        submitInProgress={props.isSaving}
      />
    );
  };

  const validateForm = useCallback(
    (values: NDevice) => {
      const dirty = ((formRef.current as any) as Formik).getFormikComputedProps().dirty;

      setDirty(dirty);

      const errors: FormikErrors<NDevice> = {};
      if (!props.openedFromLocation && !values.locationName) {
        errors.locationId = t('newnity.device.required.location');
      }

      if (values.serialNumber._containsAny(['\\', '/', ':', '*', '?', '"', '<', '>', '|', '.'])) {
        errors.serialNumber = t('newnity.device.serial.characters');
      }

      if (!values.name.trim()) {
        errors.name = t('newnity.device.required.name');
      }
      validateDeviceSettings(values, errors);

      return errors;
    },
    [t, setDirty, props.openedFromLocation]
  );

  return (
    <Formik
      initialValues={props.device}
      onSubmit={props.saveDevice}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      validate={validateForm}
      ref={formRef}
    >
      {() => (
        <Form className={classes.form}>
          <TabsHeaderAndContent content={content} tabs={tabLabels} footer={footer} />
        </Form>
      )}
    </Formik>
  );
};
