import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonsHeaderAndContent,
  createEditDelete,
} from '../../../../components/bladeLayout/buttonsHeaderAndContent';
import { DataGridContainer, DataGridSelectionType } from '../../../../components/dataGrid';
import { ColumnSettings } from '../../../../components/dataGrid/agDataGrid';
import { DeleteConfirmDialog, useDeleteConfirm } from '../../../../components/deleteConfirmDialog';
import { EntityType, IdName, NDevice } from '../../../../dataStore';
import { useStyles } from './deviceList.jss';

export interface DeviceListProps {
  companyId: number;
  openedFromLocation?: number;
  items: NDevice[];
  selected: IdName[];
  deletingDevice: boolean;
  isEditing: boolean;
}

export interface DeviceListActions {
  fetchDevices: (companyId: number) => void;
  createDevice: () => void;
  editDevice: (deviceId: number) => void;
  setSelected: (items: IdName[]) => void;
  deleteDevice: (deviceId: number) => void;
}

type Props = DeviceListActions & DeviceListProps;

const allColumns: ColumnSettings[] = [
  { name: 'id', width: 160 },
  { name: 'deviceTypeName', width: 140 },
  { name: 'name', width: 190, sort: { order: 0, direction: 'asc' } },
  { name: 'serialNumber', width: 150, lockVisibility: true },
  { name: 'salesOrderNumber', width: 200 },
  { name: 'description', width: 140 },
  { name: 'locationName', width: 200 },
  { name: 'locationNumber', width: 200 },
  { name: 'stationName', width: 200},
];

const withoutLocationColumns: ColumnSettings[] = [
  { name: 'id', width: 160 },
  { name: 'deviceTypeName', width: 140 },
  { name: 'name', width: 190, sort: { order: 0, direction: 'asc' } },
  { name: 'serialNumber', width: 150, lockVisibility: true },
  { name: 'salesOrderNumber', width: 200 },
  { name: 'description', width: 130 },
  { name: 'stationName', width: 200},
];

export const DeviceList: React.FunctionComponent<Props> = props => {
  const [deleteConfirmDevice, setDeleteConfirmDevice] = useDeleteConfirm();
  const { fetchDevices } = props;

  useEffect(() => {
    fetchDevices(props.companyId);
  }, [fetchDevices, props.companyId]);
  const classes = useStyles();
  const [t] = useTranslation();

  const onDeviceDelete = () => {
    props.deleteDevice(deleteConfirmDevice.id as number);
    setDeleteConfirmDevice({
      id: 0,
      name: '',
      confirmNeeded: false,
    });
  };

  const editDevice = () => {
    props.selected.length && props.editDevice(props.selected[0].id);
  };

  const deleteDevice = () => {
    if (props.selected.length > 0) {
      const device = props.selected[0];
      setDeleteConfirmDevice({
        id: device.id,
        name: device.name,
        confirmNeeded: true,
      });
    }
  };

  const headerButtons = createEditDelete(props.createDevice, editDevice, deleteDevice);

  return (
    <ButtonsHeaderAndContent
      isBusy={props.deletingDevice}
      buttons={headerButtons}
      removePadding
      removeVerticalScroll
    >
      <div className={classes.container}>
        <DataGridContainer
          entityType={EntityType.NDevice}
          onSelect={props.setSelected}
          defaultSelection={props.selected}
          items={props.items}
          selectionType={DataGridSelectionType.Single}
          columnSettings={props.openedFromLocation ? withoutLocationColumns : allColumns}
        />
        <DeleteConfirmDialog
          important={true}
          title={t('newnity.device.delete.confirm.title')}
          message={
            (props.isEditing ? t('newnity.device.delete.currently.editing') : '') +
            t('newnity.device.delete.confirm.message', {
              deviceSerial:
                props.selected.length > 0
                  ? (props.selected[0] as NDevice).serialNumber ||
                    t('newnity.device.delete.confirm.serial')
                  : '',
            })
          }
          open={deleteConfirmDevice.confirmNeeded}
          onDelete={onDeviceDelete}
          onCancel={() =>
            setDeleteConfirmDevice({
              id: 0,
              name: '',
              confirmNeeded: false,
            })
          }
        />
      </div>
    </ButtonsHeaderAndContent>
  );
};
