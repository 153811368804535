import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    contentContainer: {
      display: 'flex',
      height: '100%',
      backgroundColor: 'white',
    },
    searchContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '540px',
      background: 'white',
    },
    entitySwitch: {
      alignSelf: 'center',
      marginBottom: theme.spacing(2),
    },
    searchButton: {
      borderRadius: 0,
    },
    resizableSearch: {
      flexShrink: 0,
      height: '100%',
      overflowX: 'auto',
    },
    resizableResults: {
      flexGrow: 1,
      height: '100%',
      overflowX: 'auto',
    },
    dragHandle: {
      position: 'relative',
      flexShrink: 0,
      height: '100%',
      width: '4px',
      zIndex: 1000,
      cursor: 'col-resize',
      boxShadow: '-1px 0px 2px 0px rgba(0,0,0,0.2)',
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
      },
    },
    dragHandleActive: {
      zIndex: 1000,
      backgroundColor: theme.palette.grey[500],
    },
    noSelect: {
      userSelect: 'none',
    },
  });
};

export const useStyles = makeStyles(styles);
