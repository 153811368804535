import { VariantType } from 'notistack';

export const areaName = "notifications";

export interface NotificationsState {
  items: Notification[]
}

export enum NotificationType {
  success,
  error,
  warning,
  info
}

export interface Notification {
  key: number,
  message: string,
  type: VariantType,
  action?: NotificationAction
}

export interface NotificationAction {
  label: string;
  actionName: string;
  actionParams: any;
}

export enum ActionTypes {
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
}