import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    unselectableRow: {
      pointerEvents: 'none',
      '& .ag-icon-checkbox-checked': {
        color: `${theme.palette.grey[500]} !important`,
      },
    },
    gridContainer: {
      '& .ag-cell-value': {
        width: '100%',
      },
      '& .ag-filter-filter': {
        borderBottomColor: `${theme.palette.secondary.main} !important`,
        borderRadius: '0 !important',
      },
      '& .ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected': {
        borderBottomColor: `${theme.palette.secondary.main} !important`,
      },
      '& .ag-primary-cols-header-panel': {
        display: 'none',
        borderRadius: '0 !important',
      },
      '& .ag-icon-checkbox-checked': {
        color: theme.palette.secondary.main,
        cursor: 'pointer !important',
      },
      '& .ag-icon-checkbox-unchecked': {
        cursor: 'pointer !important',
      },
      '& .ag-floating-filter-input': {
        fontSize: '1rem !important',
        height: '32px !important',
        paddingBottom: '0 !important',
        borderRadius: '0px !important',
        fontWeight: 'normal !important',
      },
      '& .ag-header-row:first-child .ag-header-cell:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
      '& .ag-floating-filter-input:focus': {
        borderColor: `${theme.palette.secondary.main} !important`,
        fontWeight: 'normal !important',
      },
      '& .ag-filter-select': {
        font: 'inherit',
        border: '0 !important',
        height: '1.1875em !important',
        margin: 0,
        display: 'block',
        padding: '6px 0 7px !important',
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.secondary.main} !important`,
      },
      '& .selection-cell': {
        textAlign: 'center',
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    rowPointer: {
      cursor: 'pointer',
    },
  });
};

export const useStyles = makeStyles(styles);
