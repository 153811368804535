import classNames from 'classnames';
import React from 'react';

import { Paper, Typography } from '@material-ui/core';

import { useStyles } from './groupBox.jss';

export const GroupBox: React.FunctionComponent<{
  title: string;
  grow?: boolean;
  noMargin?: boolean;
  direction?: 'row' | 'column';
  headerContent?: React.ReactNode;
}> = props => {
  const classes = useStyles();
  const title = (
    <Typography
      variant="h5"
      className={classNames(classes.title, { [classes.titleMarginBottom]: !props.headerContent })}
    >
      {props.title}
    </Typography>
  );

  return (
    <Paper
      className={classNames(classes.paper, {
        [classes.grow]: props.grow,
        [classes.marginBottom]: !props.noMargin,
      })}
    >
      {props.headerContent ? (
        <div className={classes.titleBox}>
          {title}
          {props.headerContent}
        </div>
      ) : (
        title
      )}
      {props.direction ? (
        <div
          className={classNames(classes.parent, {
            [classes.row]: props.direction === 'row',
            [classes.column]: props.direction === 'column',
          })}
        >
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </Paper>
  );
};
