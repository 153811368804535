import React, { useCallback } from 'react';

import { Tabs } from '@material-ui/core';
import { SearchFieldsSwitch } from './results';

import { EntityType, IdName } from '../../../../dataStore';
import { SearchFields } from '../../../duck/types';
import { TabLabels } from './labels';
import { useStyles } from './tabs.jss';

export interface TabsProps {
  currentEntityType: EntityType;
  fields: SearchFields;
  handleChange(e: React.ChangeEvent): void;
  onTabChange(index: number): void;
  tabIndex: number;
  companies: IdName[];
}

const SearchTabs = React.memo((props: TabsProps) => {
  const classes = useStyles();

  const onTabChangedHandler = useCallback(
    (event: React.ChangeEvent<any>, newTabIndex: number) => props.onTabChange(newTabIndex),
    []
  );

  return (
    <>
      <Tabs
        value={false}
        textColor="primary"
        variant="fullWidth"
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        <TabLabels
          fields={props.fields}
          currentEntityType={props.currentEntityType}
          currentTabIndex={props.tabIndex}
          onTabChanged={onTabChangedHandler}
        />
      </Tabs>

      <SearchFieldsSwitch
        currentEntityType={props.currentEntityType}
        tabIndex={props.tabIndex}
        handleChange={props.handleChange}
        fields={props.fields}
      />
    </>
  );
});

export { SearchTabs };
