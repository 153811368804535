import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { normalize } from '../utils/extensions';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { DataStoreState, IdName } from './types';

type DataStoreActions = ActionType<typeof Actions>;

export const initializeEntityState = () => ({
  items: {},
  itemsFetching: {
    error: '',
    fetchComplete: false,
    isFetching: false,
  },
});

export const initialState: DataStoreState = {
  player: initializeEntityState(),
  site: initializeEntityState(),
  workgroup: initializeEntityState(),
  brand: initializeEntityState(),
  templateLibrary: initializeEntityState(),
  user: initializeEntityState(),
  nCompany: initializeEntityState(),
  nLocation: initializeEntityState(),
  nLocationList: initializeEntityState(),
  nDevice: initializeEntityState(),
  nCompanySearchResult: initializeEntityState(),
  nLocationSearchResult: initializeEntityState(),
  nDeviceSearchResult: initializeEntityState(),
  nParentCompanyWorkgroup: initializeEntityState(),
  nStation: initializeEntityState(),
  hasDeviceSettings: initializeEntityState(),
  nProgram: initializeEntityState()
};

export const dataStoreReducer: Reducer<DataStoreState, DataStoreActions> = (
  state: DataStoreState = initialState,
  action: DataStoreActions
) => {
  switch (action.type) {
    case ActionTypes.ENTITY_LIST_REQUEST: {
      const key = action.payload.entityType as keyof DataStoreState;
      return {
        ...state,
        [action.payload.entityType]: {
          ...state[key],
          itemsFetching: {
            error: '',
            fetchComplete: false,
            isFetching: true,
          },
        },
      };
    }
    case ActionTypes.ENTITY_LIST_SUCCESS: {
      return {
        ...state,
        [action.payload.entityType]: {
          items: normalize<IdName>(action.payload.items, 'id'),
          itemsFetching: {
            error: '',
            fetchComplete: true,
            isFetching: false,
          },
        },
      };
    }
    case ActionTypes.ENTITY_LIST_ERROR: {
      const key = action.payload.entityType as keyof DataStoreState;
      return {
        ...state,
        [key]: {
          ...state[key],
          itemsFetching: {
            error: action.payload.error,
            fetchComplete: false,
            isFetching: false,
          },
        },
      };
    }
    case ActionTypes.ENTITY_UPSERT: {
      const key = action.payload.entityType as keyof DataStoreState;
      return {
        ...state,
        [key]: {
          ...state[key],
          items: {
            ...state[key].items,
            [action.payload.item.id]: action.payload.item,
          },
          itemsFetching: {
            error: '',
            fetchComplete: true,
            isFetching: false,
          },
        },
      };
    }
    case ActionTypes.ENTITY_DELETE: {
      const key = action.payload.entityType as keyof DataStoreState;
      const newEntityState = { ...state[key] };
      delete newEntityState.items[action.payload.item.id];

      return {
        ...state,
        [key]: newEntityState,
      };
    }
    default:
      return state;
  }
};