import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';

export interface DeleteConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  important?: boolean;
  onCancel: () => void;
  onDelete: () => void;
  deleteLabel?: string;
}

export const useDeleteConfirm = (id = 0, name = '', confirmNeeded = false) =>
  useState<{
    id: number | number[];
    name: string;
    confirmNeeded: boolean;
  }>({ id, name, confirmNeeded });

export const DeleteConfirmDialog = React.memo(
  (props: DeleteConfirmDialogProps) => {
    const [t] = useTranslation();
    const style = props.important ? {color: 'red'} : {};


    return (
      <Dialog open={props.open}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={style}>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button
            onClick={props.onDelete}
            color="primary"
          >
            {props.deleteLabel || t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => prevProps.open === nextProps.open
);
