import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  ButtonsHeaderAndContent,
  createEditDelete,
} from 'app/components/bladeLayout/buttonsHeaderAndContent';
import { DataGridContainer, DataGridSelectionType } from 'app/components/dataGrid';
import { ColumnSettings } from 'app/components/dataGrid/agDataGrid';
import { EntityType } from 'app/dataStore';
import { IdName, NLocationList } from 'app/dataStore/types';
import { NewnityApi } from 'app/newnity/duck/api';
import { useStyles } from './locationList.jss';
import { pushNotification } from 'app/components/notifier';

export interface LocationListProps {
  companyId: number;
  items: NLocationList[];
  selected: IdName[];
  locationsFetching: boolean;
  locationDeleting: boolean;
  isEditing: boolean;
}

export interface LocationListActions {
  fetchLocations: (companyId: number) => void;
  createLocation: () => void;
  editLocation: (id: number, name: string) => void;
  deleteLocation: (id: number, name: string) => void;
  setSelected: (items: IdName[]) => void;
}

type Props = LocationListActions & LocationListProps;

interface DeleteConfirmDialogProps {
  open: boolean;
  locationId: number;
  locationName: string;
  isEditing: boolean;
  onCancel: () => void;
  onDelete: (id: number, name: string) => void;
}

const DeleteConfirmDialog = React.memo(
  (props: DeleteConfirmDialogProps) => {
    const [t] = useTranslation();
    const classes = useStyles();
    const [confirmDelete, handleInputChange] = useState('');
    const [linkedEntities, fetchLinkedEntities] = useState([{ workgroups: 0, hardwares: 0 }]);
    const [isLoading, setIsLoading] = useState(true);

    const { onDelete } = props;

    const handleDelete = useCallback(
      (locationId: number, locationName: string) => {
        onDelete(locationId, locationName);
        handleInputChange('');
      },
      [onDelete]
    );

    const handleCancel = () => {
      props.onCancel();
      handleInputChange('');
    };
    useEffect(() => {
      if (props.locationId !== 0) {
        NewnityApi.getDeleteLocations([props.locationId])
          .then(result => {
            fetchLinkedEntities(result);
            setIsLoading(false);
          })
          .catch(err =>
            pushNotification(`Error occured trying to fetch location for delete: ${err}`)
          );
      }
    }, [props.open, props.locationId]);

    return (
      <Dialog open={props.open}>
        <DialogTitle>{t('newnity.list.location.delete.confirm.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.warningMessage}>
            {props.isEditing ? t('newnity.list.location.delete.currently.editing') : ''}
            {t('newnity.list.location.delete.confirm.content', {
              locationName: props.locationName,
            })}
            <br />
            <b>{t('newnity.list.location.delete.confirm.warning')}</b>
          </DialogContentText>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('newnity.list.location.delete.table.location')}</TableCell>
                <TableCell>{t('newnity.list.location.delete.table.device')}</TableCell>
                <TableCell>{t('newnity.list.location.delete.table.workgroup')}</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && (
              <TableBody>
                {linkedEntities.map(x => (
                  <TableRow>
                    <TableCell>{props.locationName}</TableCell>
                    <TableCell>{x.hardwares}</TableCell>
                    <TableCell>{x.workgroups}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <TextField
            autoFocus
            margin="dense"
            id="confirmDelete"
            label="Type Yes to Delete"
            fullWidth
            value={confirmDelete}
            onChange={e => {
              handleInputChange(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button
            onClick={() => {
              handleDelete(props.locationId, props.locationName);
            }}
            color="primary"
            disabled={confirmDelete.toUpperCase() !== 'YES'}
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => prevProps.open === nextProps.open
);

const columnSettings: ColumnSettings[] = [
  {
    name: 'oracleNumber',
    width: 240,
  },
  {
    name: 'name',
    width: 170,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true,
  },
  {
    name: 'clientSiteId',
    width: 140,
  },
  {
    name: 'country',
    width: 120,
  },
  {
    name: 'city',
    width: 120,
  },
  {
    name: 'timezone',
    width: 140,
  },
];

export const LocationList: React.FunctionComponent<Props> = props => {
  const { fetchLocations } = props;

  useEffect(() => {
    fetchLocations(props.companyId);
  }, [props.companyId, fetchLocations]);

  const [deleteConfirmLocation, setDeleteConfirmLocation] = useState<{
    id: number;
    name: string;
    delete: boolean;
  }>({ id: 0, name: '', delete: false });

  const classes = useStyles();

  const edit = () => {
    if (props.selected.length > 0) {
      props.editLocation(props.selected[0].id, props.selected[0].name);
    }
  };

  const deleteLocation = () => {
    if (props.selected.length > 0) {
      const location = props.selected[0];
      setDeleteConfirmLocation({
        id: location.id,
        name: location.name,
        delete: true,
      });
    }
  };

  const headerButtons = [...createEditDelete(props.createLocation, edit, deleteLocation)];

  return (
    <ButtonsHeaderAndContent
      buttons={headerButtons}
      removePadding={true}
      isBusy={props.locationsFetching || props.locationDeleting}
    >
      <div className={classes.container}>
        <DataGridContainer
          entityType={EntityType.NLocationList}
          onSelect={props.setSelected}
          items={props.items}
          defaultSelection={props.selected}
          selectionType={DataGridSelectionType.SingleOrNone}
          showSelectionColumn
          columnSettings={columnSettings}
        />
        <DeleteConfirmDialog
          open={deleteConfirmLocation.delete}
          locationName={deleteConfirmLocation.name}
          locationId={deleteConfirmLocation.id}
          isEditing={props.isEditing}
          onDelete={(id, name) => {
            props.deleteLocation(id, name);
            setDeleteConfirmLocation({
              id: 0,
              name: '',
              delete: false,
            });
          }}
          onCancel={() =>
            setDeleteConfirmLocation({
              id: 0,
              name: '',
              delete: false,
            })
          }
        />
      </div>
    </ButtonsHeaderAndContent>
  );
};
