import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      overflow: 'auto',
    },
    headerButton: {
      marginRight: theme.spacing(1),
    },
    headerButtonIconSmall: {
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    form: {
      paddingBottom: '0px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'auto'
    },
    previewButton: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      backgroundColor: '#e0e0e0',
    },
    lockButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '-7px',
    },
  });

export const useStyles = makeStyles(styles);
