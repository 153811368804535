import { withSnackbar } from 'notistack';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { removeNotification } from './duck/actions';
import { Notifier, NotifierActions, NotifierProps } from './notifier';

const mapStateToProps: MapStateToProps<NotifierProps, any, any> = state => ({
  notifications: state.notifications.items
});

const mapDispatchToProps: MapDispatchToProps<
  NotifierActions,
  any
> = dispatch => ({
  removeNotification: (notificationKey: number) =>
    dispatch(removeNotification(notificationKey))
});

export const NotifierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Notifier));
