import { DeviceSearchFields, LocationSearchFields } from 'app/newnity/duck/types';
import React from 'react';
import { AddressFields } from '../addressFields';
import { TabFieldsWithContentWrapper } from '../tabFields';
import { getDeviceInfoFields } from '../utils';

interface DeviceFieldsProps {
  tabIndex: number;
  device: DeviceSearchFields;
  location: LocationSearchFields;
  handleChange(e: React.ChangeEvent): void;
}

export const DeviceFields = (props: DeviceFieldsProps) => {
  return (
    <>
      {props.tabIndex === 0 && (
        <TabFieldsWithContentWrapper
          handleChange={props.handleChange}
          fields={getDeviceInfoFields(props.device)}
          key="device"
          parentName="device"
        />
      )}
      {props.tabIndex === 1 && (
        <AddressFields
          parentName="device"
          {...props.location}
          handleChange={props.handleChange}
          key="device.address"
        />
      )}
    </>
  );
};
