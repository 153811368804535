interface Normalized<T> {
  [key: string]: T;
}

export function denormalize<T>(obj: Normalized<T>): T[] {
  const arr = Object.keys(obj).map(k => obj[k]);
  return arr;
}

export function normalize<T>(arr: T[], key: string): Normalized<T> {
  return arr.reduce<Normalized<T>>((obj, item) => {
    obj[(item as any)[key]] = item;
    return obj;
  }, {});
}

declare global {

  interface Array<T> {
    _findBy<T>(key: string, value: any): boolean;
    _findIndexBy<T>(key: string, value: any): number;
    _difference<T>(other: T[]): T[];
    _insert<T>(element: T, position: number): T[];
  }

  interface String {
    _contains(value: string, caseSensitive?: boolean): boolean;
    _containsAny(values: string[]): boolean;
  }

  interface Number {
    _padLeft2(): string;
  }
}

/* eslint-disable no-extend-native */

Array.prototype._findBy = function (key, value) {
  return this.findIndex(t => t[key] === value) !== -1;
}

Array.prototype._findIndexBy = function (key, value) {
  return this.findIndex(t => t[key] === value);
}

Array.prototype._difference = function (other) {
  return this.filter(f => other.indexOf(f) === -1);
}

Array.prototype._insert = function (element, position) {
  this.splice(position, 0, element);
  return this;
}

String.prototype._contains = function (value, caseSensitive = false) {
  if (caseSensitive) {
    return this.indexOf(value) !== -1;
  } else {
    return this.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
}

String.prototype._containsAny = function (values) {
  return values.some(v => {
    return this.indexOf(v) !== -1;
  });
}

Number.prototype._padLeft2 = function() {
  const s = '0' + this.toString();
  return s.substr(s.length - 2, 2);
};

/* eslint-enable no-extend-native */