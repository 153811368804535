import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import StarRounded from '@material-ui/icons/StarRounded';

import { ICellRendererParams } from '@ag-grid-community/core';
import { ColumnSettings, DataGridSelectionType } from 'app/components/dataGrid/agDataGrid';
import {
  ButtonsHeaderAndContent,
  createEditDelete,
} from '../../../../components/bladeLayout/buttonsHeaderAndContent';
import { DataGridContainer } from '../../../../components/dataGrid';
import { DeleteConfirmDialog, useDeleteConfirm } from '../../../../components/deleteConfirmDialog';
import { EntityType, IdName, NStation } from '../../../../dataStore';
import { useStyles } from './stationList.jss';

export interface StationListProps {
  companyId: number;
  items: NStation[];
  stationsFetching: boolean;
  stationDeleting: boolean;
  isEditing: boolean;
}

export interface StationListActions {
  fetchStations: () => void;
  createStation: () => void;
  editStation: (id: number, name: string) => void;
  deleteStation: (stationId: number, stationName: string, companyId: number) => void;
}

type Props = StationListActions & StationListProps;

const DefaultStationFormatter = (props: ICellRendererParams) => {
  return (props.data as NStation).isDefault ? <StarRounded color="secondary" /> : <div />;
};

const columnSettings: ColumnSettings[] = [
  { name: 'name', width: 170, lockVisibility: true, sort: { order: 0, direction: 'asc' } },
  { name: 'id', width: 170 },
  { name: 'isDefault', width: 170, formatter: DefaultStationFormatter },
];

export const StationList: React.FunctionComponent<Props> = props => {
  const { fetchStations } = props;

  useEffect(() => {
    fetchStations();
  }, [fetchStations]);
  const [selected, setSelected] = useState<IdName[]>([]);
  const [deleteConfirmStation, setDeleteConfirmStation] = useDeleteConfirm();

  const classes = useStyles();
  const [t] = useTranslation();

  const edit = () => {
    if (selected.length > 0) {
      props.editStation(selected[0].id, selected[0].name);
    }
  };

  const deleteStation = () => {
    if (selected.length > 0) {
      const station = selected[0];
      setDeleteConfirmStation({
        id: station.id,
        name: station.name,
        confirmNeeded: true,
      });
    }
  };

  const onStationDelete = () => {
    props.deleteStation(
      deleteConfirmStation.id as number,
      deleteConfirmStation.name,
      props.companyId
    );
    setDeleteConfirmStation({
      id: 0,
      name: '',
      confirmNeeded: false,
    });
  };

  const headerButtons = createEditDelete(props.createStation, edit, deleteStation);

  return (
    <ButtonsHeaderAndContent
      buttons={headerButtons}
      removePadding={true}
      isBusy={props.stationsFetching || props.stationDeleting}
    >
      <div className={classes.container}>
        <DataGridContainer
          entityType={EntityType.NStation}
          onSelect={items => {
            setSelected(items);
          }}
          items={props.items}
          defaultSelection={selected}
          selectionType={DataGridSelectionType.SingleOrNone}
          columnSettings={columnSettings}
        />
        <DeleteConfirmDialog
          title={t('newnity.list.station.delete.confirm.title')}
          message={
            (props.isEditing ? t('newnity.list.station.delete.currently.editing') : '') +
            t('newnity.list.station.delete.confirm.content', {
              stationName: selected.length > 0 ? selected[0].name : '',
            })
          }
          open={deleteConfirmStation.confirmNeeded}
          onDelete={onStationDelete}
          onCancel={() =>
            setDeleteConfirmStation({
              id: 0,
              name: '',
              confirmNeeded: false,
            })
          }
        />
      </div>
    </ButtonsHeaderAndContent>
  );
};
