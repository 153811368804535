import { TreeContainingState, TreeState } from './types';

const getTreeState = (
  state: any,
  areaName: string,
  treeName: string
): TreeState => {
  if (!state[areaName]) {
    throw new Error(`The state does not contain an area named ${areaName}`);
  }
  if (!state[areaName][treeName]) {
    throw new Error(`The state area ${areaName} does not contain a tree named ${treeName}`);
  }
  return state[areaName][treeName];
};

export const selectTreeItems = (
  state: TreeContainingState,
  areaName: string,
  treeName: string
) => {
  const treeState = getTreeState(state, areaName, treeName);

  return treeState.items;
};

export const selectVisibleTree = (
  state: TreeContainingState,
  areaName: string,
  treeName: string
) => {
  const treeState = getTreeState(state, areaName, treeName);

  if (treeState.filterValue === '') {
    return treeState.unfilteredTree;
  } else {
    return treeState.filteredTree;
  }
};

export const selectSelectionMode = (
  state: TreeContainingState,
  areaName: string,
  treeName: string
) => {
  const treeState = getTreeState(state, areaName, treeName);
  return treeState.selectionMode;
};

export const selectSelectedItems = (
  state: TreeContainingState,
  areaName: string,
  treeName: string
) => {
  const treeState = getTreeState(state, areaName, treeName);
  return treeState.selectedItems;
};

export const selectActionState = (
  state: TreeContainingState,
  areaName: string,
  treeName: string
) => {
  const treeState = getTreeState(state, areaName, treeName);
  return treeState.itemActions;
};
