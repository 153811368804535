import { openBlade } from 'app/blade';
import { ColumnSettings, DataGridSelectionType } from 'app/components/dataGrid/agDataGrid';
import {
    SelectionBladeContainerProps, SelectionListBladeType
} from 'app/components/selectionBlade';
import { EntityType, ImportResult } from 'app/dataStore';
import { connect, MapDispatchToProps } from 'react-redux';

import { ImportSummary, ImportSummaryActions, ImportSummaryProps } from './importSummary';

export interface ImportSummaryContainerProps extends ImportSummaryProps {
  parentBladeId: string;
  excludeColumnNameFromDetails?: boolean;
}
const mapDispatchToProps: MapDispatchToProps<ImportSummaryActions, ImportSummaryContainerProps> = (
  dispatch,
  ownProps
) => {
  const columnSettings: ColumnSettings[] = [
    {
      name: 'row',
      width: 100,
      sort: {
        direction: 'asc',
        order: 0
      }
    },
  ];
  if (!ownProps.excludeColumnNameFromDetails) {
    columnSettings.push({ name: 'columnName', width: 100 });
  }
  columnSettings.push({ name: 'name', width: 300 });
  return {
    showDetails: (title: string, data: ImportResult) =>
      openBlade<SelectionBladeContainerProps>(
        ownProps.parentBladeId,
        SelectionListBladeType,
        {
          title,
          columnSettings,
          selectionKey: '',
          entityType: EntityType.ImportSkippedRow,
          items: data.skipped,
          selectionType: DataGridSelectionType.None,
        },
        dispatch
      ),
  };
};

export const ImportSummaryContainer = connect(
  null,
  mapDispatchToProps
)(ImportSummary);
