import {
  FilterChangedEvent,
  IDoesFilterPassParams,
  IFilterParams,
  IFloatingFilter,
  IFloatingFilterParams,
} from '@ag-grid-community/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './customFilters.jss';

type BooleanFloatingFilterProps = IFloatingFilterParams & BooleanFloatingFilterParams;

interface BooleanFloatingFilterParams {
  filterTitle: string;
}

export const BooleanFloatingFilter = forwardRef((props: BooleanFloatingFilterProps, ref: any) => {
  const [value, setValue] = useState(-1);
  const [t] = useTranslation();

  const classes = useStyles();

  const { parentFilterInstance } = props;

  useImperativeHandle(
    ref,
    (): IFloatingFilter => ({
      onParentModelChanged(parentModel: any, event: FilterChangedEvent) {
        setValue(parentModel);
      },
    })
  );

  const handleValueChanged = useCallback(
    (event: any) => {
      const newVal = event.target.value;
      setValue(newVal);
      parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('equals', newVal);
      });
    },
    [parentFilterInstance]
  );

  return (
    <FormControl className={classes.booleanFilterSelect}>
      <InputLabel>{props.filterTitle}</InputLabel>
      <Select value={value} onChange={handleValueChanged}>
        <MenuItem value={-1}>{t('all')}</MenuItem>
        <MenuItem value={0}>{t('no')}</MenuItem>
        <MenuItem value={1}>{t('yes')}</MenuItem>
      </Select>
    </FormControl>
  );
});

type BooleanFilterParams = IFilterParams & BooleanFloatingFilterParams;

export const BooleanFilter = forwardRef((props: BooleanFilterParams, ref: any) => {
  const classes = useStyles();
  const inputRef = useRef<any>(null);
  const hiddenInputRef = useRef<any>(null);
  const [t] = useTranslation();

  const [value, setValue] = useState<number>(-1);

  const updateValue = useCallback(
    (newValue: number) => {
      setValue(newValue);
      hiddenInputRef.current.value = newValue;
      props.filterChangedCallback();
    },
    [props]
  );

  useImperativeHandle(ref, () => ({
    isFilterActive: () => true,
    doesFilterPass: (params: IDoesFilterPassParams) => {
      const newValue = parseInt(hiddenInputRef.current.value, 10);

      if (newValue === -1) {
        return true;
      }

      const boolValue = !!newValue;
      return params.data[props.colDef.field ? props.colDef.field : 'id'] === boolValue;
    },
    onFloatingFilterChanged(type: string, newValue: number) {
      updateValue(newValue);
    },
    getModel() {
      return parseInt(hiddenInputRef.current.value, 10);
    },
  }));

  const handleValueChanged = useCallback((event: any) => {
    const newValue = event.target.value;
    updateValue(newValue);
  }, [updateValue]);

  return (
    <>
      <FormControl className={classes.booleanFilterSelect} ref={inputRef}>
        <InputLabel>{props.filterTitle}</InputLabel>
        <Select value={value} onChange={handleValueChanged}>
          <MenuItem value={-1}>{t('all')}</MenuItem>
          <MenuItem value={0}>{t('no')}</MenuItem>
          <MenuItem value={1}>{t('yes')}</MenuItem>
        </Select>
      </FormControl>

      <input
        type="number"
        hidden
        ref={hiddenInputRef}
        onChange={() => props.filterChangedCallback()}
      />
    </>
  );
});
