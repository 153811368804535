import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { bladeConnect, BladeProps, openBlade } from '../../../../blade';
import { DataStoreSelectors, NDevice} from '../../../../dataStore';
import { setSelectedDevices, deviceStartEdit } from '../../../duck/actions';
import { selectCompanyId, selectIsEditingDevice } from '../../../duck/selectors';
import { deleteDevice, fetchDevices } from '../../../duck/thunks';
import { NewnityState } from '../../../duck/types';
import { DeviceBladeType } from '../edit/deviceContainer';
import { DeviceList, DeviceListActions, DeviceListProps } from './deviceList';

interface DevicesListContainerProps {
  openedFromLocation?: number;
}

const mapStateToProps: MapStateToProps<
  DeviceListProps,
  BladeProps & DevicesListContainerProps,
  any
> = (state, props) => {
  const temp = DataStoreSelectors.NDevice.selectDeviceItems(state) as NDevice[];
  const _ = {
    companyId: selectCompanyId(state),
    locationId: props.openedFromLocation,
    items: props.openedFromLocation
      ? temp.filter(a => a.locationId === props.openedFromLocation)
      : temp,
    selected: (state.newnity as NewnityState).selectedDevices,
    deletingDevice: (state.newnity as NewnityState).currentDevice.deletingDevice,
    isEditing: selectIsEditingDevice(state),
  };

  return _;
};

const mapDispatchToProps: MapDispatchToProps<
  DeviceListActions,
  BladeProps & DevicesListContainerProps
> = (dispatch, _) => {
  return {
    fetchDevices: companyId => dispatch<any>(fetchDevices(companyId)),
    createDevice: () => {
      openBlade(
        DeviceListBladeType,
        DeviceBladeType,
        { openedFromLocation: _.openedFromLocation },
        dispatch
      )
      dispatch<any>(deviceStartEdit())
    },
    editDevice: deviceId =>{
      openBlade(
        DeviceListBladeType,
        DeviceBladeType,
        { deviceId, openedFromLocation: _.openedFromLocation },
        dispatch
      )
      dispatch<any>(deviceStartEdit())
    },
    setSelected: items => dispatch(setSelectedDevices(items)),
    deleteDevice: (deviceId: number) => dispatch<any>(deleteDevice(deviceId, DeviceListBladeType)),
  };
};

export const DeviceListBladeType = 'NEWNITY_DEVICE_LIST';

const bladeConfig = {
  size: { defaultWidth: 900, minWidth: 900 },
  bladeType: DeviceListBladeType,
  id: (_: any) => DeviceListBladeType,
  title: (_: any) => 'Devices',
  allowMultipleInstances: false,
};

export const DeviceListContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  DeviceList
);


