import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';

import { cancelCloseBlade, forceCloseBlade, setBladeNewlyCreateState } from '../duck/actions';
import {
  selectActiveBladesDenormalized,
  selectClosingBlades,
  selectFrozenBlades,
  selectPostClosingAction,
} from '../duck/selectors';
import { BladeRow, BladeRowActions, BladeRowProps } from './bladeRow';

export const mapStateToProps: MapStateToProps<BladeRowProps, any, any> = (state, _) => {
  return {
    blades: selectActiveBladesDenormalized(state),
    closingBlades: selectClosingBlades(state),
    actionAfterClosing: selectPostClosingAction(state),
    frozenBlades: selectFrozenBlades(state),
  };
};

export const mapDispatchToProps: MapDispatchToProps<BladeRowActions, any> = (
  dispatch: Dispatch
) => {
  return {
    forceCloseBlades: (actionAfterClosing?: any) => {
      dispatch(forceCloseBlade());
      if (actionAfterClosing) {
        setTimeout(() => dispatch<any>(actionAfterClosing), 0);
      }
    },
    cancelBladeClose: () => dispatch(cancelCloseBlade()),
    setBladeNewlyCreateState: (bladeId: string, isNewlyCreated: boolean) =>
      dispatch(setBladeNewlyCreateState(bladeId, isNewlyCreated)),
  };
};

export const BladeRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BladeRow);
