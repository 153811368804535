import { fetchHelper } from '../../v6/ajax';
import { FileUploadRequest } from './types';

const getChunkStatus = (fileUploadRequest: FileUploadRequest): Promise<Response> => {
  const url =
    process.env.REACT_APP_ROOT_DOMAIN +
    `v6/upload/?flowChunkNumber=${fileUploadRequest.flowChunkNumber}&flowChunkSize=${fileUploadRequest.flowChunkSize}&flowFilename=${fileUploadRequest.flowFilename}&flowIdentifier=${fileUploadRequest.flowIdentifier}&flowRelativePath=${fileUploadRequest.flowRelativePath}&flowTotalChunks=${fileUploadRequest.flowTotalChunks}&flowTotalSize=${fileUploadRequest.flowTotalSize}&isContent=${fileUploadRequest.isContent}`;
  return fetch(url, { method: 'GET' });
};

const sendChunk = (fileUploadRequest: FileUploadRequest, file: File): Promise<Response> => {
  // read the chunk from file
  const offset = (fileUploadRequest.flowChunkNumber - 1) * fileUploadRequest.flowChunkSize;
  const blob = file.slice(offset, Math.min(offset + fileUploadRequest.flowChunkSize, file.size));

  // build multipart form data
  const formData = Object.keys(fileUploadRequest).reduce<FormData>((data, k) => {
    data.append(k, fileUploadRequest[k as keyof FileUploadRequest].toString());
    return data;
  }, new FormData());
  formData.append('file', blob);

  // send it to the server
  return fetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/upload`, { method: 'POST', body: formData });
};

export const uploadChunk = (
  fileUploadRequest: FileUploadRequest,
  file: File
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    getChunkStatus(fileUploadRequest).then(result => {
      if (result.status === 502) {
        sendChunk(fileUploadRequest, file)
          .then(chunkResult => resolve(chunkResult))
          .catch(reason => reject(reason));
      }
    });
  });
};

export const finishContentUpload = (fileUploadRequest: FileUploadRequest): Promise<Response> => {
  return fetchHelper(
    process.env.REACT_APP_ROOT_DOMAIN + `v6/contentFile`,
    'GET',
    fileUploadRequest
  );
};
