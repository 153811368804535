import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { openBlade } from '../../../../blade';
import { forceCloseBlade } from '../../../../blade/duck/actions';
import { DataStoreSelectors, EntityType, IdName } from '../../../../dataStore';
import { setInCompanyMode, setSelectedDevices, setSelectedLocations } from '../../../duck/actions';
import { openCompanyEditBlade, setCurrentCompany } from '../../../duck/thunks';
import { SearchState } from '../../../duck/types';
import { SearchBladeType } from '../searchContainer';
import { SearchResults, SearchResultsActions, SearchResultsProps } from './results';

const mapStateToProps: MapStateToProps<SearchResultsProps, any, any> = state => {
  const searchedEntity = (state.newnity.search as SearchState).searchedEntity;

  if (!searchedEntity) {
    return {
      isFetching: false,
      items: [],
    };
  }

  let items: IdName[] = [];

  switch (searchedEntity) {
    case EntityType.NCompanySearchResult:
      items = DataStoreSelectors.NSearchCompanyResult.selecSearchCompanyItems(state);
      break;
    case EntityType.NDeviceSearchResult:
      items = DataStoreSelectors.NDeviceSearchResult.selecSearchDeviceItems(state);
      break;
    case EntityType.NLocationSearchResult:
      items = DataStoreSelectors.NLocationSearchResult.selectSearchLocationItems(state);
      break;
  }

  return {
    searchedEntity,
    isFetching: DataStoreSelectors.getDataStoreItemsFetching(state, searchedEntity).isFetching,
    items,
  };
};

const mapDispatchToProps: MapDispatchToProps<SearchResultsActions, any> = dispatch => {
  return {
    enterCompanyMode: id => {
      dispatch<any>(setCurrentCompany(id));
      dispatch(setInCompanyMode(true));
      dispatch(forceCloseBlade(SearchBladeType));
    },
    openCompanyEditBlade: (id, name) => dispatch<any>(openCompanyEditBlade(id, name)),
    openBlade: (parent, type, props) => openBlade(parent, type, props, dispatch),
    setSelectedLocations: items => dispatch(setSelectedLocations(items)),
    setSelectedDevices: items => dispatch(setSelectedDevices(items)),
  };
};

export const SearchResultsContainer = connect(mapStateToProps, mapDispatchToProps)(SearchResults);
