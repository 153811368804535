import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    booleanFilterSelect: {
      width: '100%'
    }
  });
};

export const useStyles = makeStyles(styles);
