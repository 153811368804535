import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { bladeConnect, BladeProps } from '../../../blade';
import { DataStoreSelectors } from '../../../dataStore';
import { searchSetCurrentEntity, searchSetFields, searchSetTabIndex } from '../../duck/actions';
import { search } from '../../duck/thunks';
import { SearchState } from '../../duck/types';
import { Search, SearchActions, SearchProps } from './search';

const mapStateToProps: MapStateToProps<SearchProps, BladeProps,any
> = (state, ownPros) => {
  const searchState = (state.newnity.search as SearchState);
  const companies = DataStoreSelectors.NCompany.selectCompanyItems(state);
  
  return {
    ...searchState,
    companies,
    ...ownPros,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  SearchActions,
  BladeProps
> = dispatch => {
  return {
    setFields: fields => dispatch(searchSetFields(fields)),
    setCurrentEntity: entity => dispatch(searchSetCurrentEntity(entity)),
    setTabIndex: index => dispatch(searchSetTabIndex(index)),
    search: (fields, entity) => dispatch<any>(search(fields, entity)),
  };
};

export const SearchBladeType = "NEWNITY_SEARCH";

const bladeConfig = {
  size: { defaultWidth: 1100, minWidth: 600 },
  bladeType: SearchBladeType,
  id: (_: any) => SearchBladeType,
  title: (_: any) => "Search",
  allowMultipleInstances: false,
};

export const SearchContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(Search);
