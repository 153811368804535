import React from 'react';

import { Tab } from '@material-ui/core';
import { LocationSearchFields } from 'app/newnity/duck/types';
import { useTranslation } from 'react-i18next';
import { TabLabel } from '../tabLabel';
import { useStyles } from '../tabs.jss';
import { countNonEmptyAddressFields, countNonEmptyLocationInfoFields } from '../utils';

interface LocationTabLabelsProps {
  location: LocationSearchFields;
  currentTabIndex: number;
  onTabChanged: (event: React.ChangeEvent<any>, newTabIndex: number) => void;
}

export const LocationTabLabels = (props: LocationTabLabelsProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <Tab
        value={0}
        onChange={props.onTabChanged}
        selected={props.currentTabIndex === 0}
        key="location.info"
        classes={{
          root: classes.tabRoot,
          selected: classes.tabFirstSelected,
          wrapper: classes.tabWrapper,
        }}
        label={
          <TabLabel
            label={t('newnity.location')}
            numberOfNonEmptyFields={countNonEmptyLocationInfoFields(props.location)}
          />
        }
      />
      <Tab
        value={1}
        onChange={props.onTabChanged}
        selected={props.currentTabIndex === 1}
        key="location.address"
        classes={{
          root: classes.tabRoot,
          selected: classes.tabLastSelected,
          wrapper: classes.tabWrapper,
        }}
        label={
          <TabLabel
            label={t('newnity.search.locationAddress')}
            numberOfNonEmptyFields={countNonEmptyAddressFields(props.location)}
          />
        }
      />
    </>
  );
};
