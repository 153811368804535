import classNames from 'classnames';
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

import { errorBoundaryStyles, ErrorBoundaryStylesProps } from './blade.jss';

interface BladeErrorState {
  error: Error | null;
  snackOpen: boolean;
}

class BladeErrorBoundaryUnstyled extends React.Component<
  ErrorBoundaryStylesProps,
  BladeErrorState
> {
  constructor(props: ErrorBoundaryStylesProps) {
    super(props);
    this.state = { error: null, snackOpen: false };
  }

  public static getDerivedStateFromError(error: any) {
    return { error: error as Error, snackOpen: true };
  }

  public render() {
    const { classes, children } = this.props;
    const { error } = this.state;

    return error !== null ? (
      <div>
        <div className={classes.error}>
          <ErrorIcon className={classes.icon} />
          <Typography variant="h6" className={classes.message}>
            {error.message}
          </Typography>
        </div>
        <div className={classNames(classes.error, classes.errorStack)}>
          <Typography variant="body2" className={classes.stack}>
            {error.stack}
          </Typography>
        </div>
      </div>
    ) : (
      children
    );
  }
}

export const BladeErrorBoundary = withStyles(errorBoundaryStyles)(
  BladeErrorBoundaryUnstyled
);
