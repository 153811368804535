import classNames from 'classnames';
import React from 'react';

import Paper from '@material-ui/core/Paper';

import { useStyles } from './footer.jss';

export const Footer: React.FunctionComponent<{
  compensateForMargins?: boolean;
}> = React.memo(props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper
        className={classNames(classes.container, {
          [classes.containerExtendMargins]: props.compensateForMargins,
        })}
        square={true}
      >
        {props.children}
      </Paper>
    </React.Fragment>
  );
});
