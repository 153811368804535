import React, { useCallback, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

import { withTextFastField } from 'app/utils/formik';
import { useDebouncedCallback } from 'use-debounce';

const INPUT_DELAY = 200;

const TextFieldInnerWrapper = (props: TextFieldProps) => {
  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const [debouncedHandleOnChange] = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    },
    INPUT_DELAY
  );

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return (
    <TextField
      inputProps={{ maxLength: 200 }}
      autoComplete="new-password"
      {...props}
      value={innerValue}
      onChange={handleOnChange}
    />
  );
};

export const TextFastFieldWrapper = withTextFastField(TextFieldInnerWrapper);
