import { AgGridReact } from '@ag-grid-community/react';
import React, { useCallback, useEffect, useState } from 'react';

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css';

import { EntityType, IdName } from 'app/dataStore/types';
import { useTranslation } from 'react-i18next';

import {
  AllModules,
  ColDef,
  Column,
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridSizeChangedEvent,
  ICellRendererParams,
  IFilterComp,
  RowClickedEvent,
  RowNode,
  SelectionChangedEvent,
} from '@ag-grid-enterprise/all-modules';
import { useStyles } from './agDataGrid.jss';
import { BooleanFilter, BooleanFloatingFilter } from './customFilters';

export interface DataGridStateProps {
  items: IdName[];
}

export enum DataGridSelectionType {
  None,
  Single,
  SingleOrNone,
  Multiple,
  MultipleOrNone,
}

export interface ColumnSettings {
  name: string;
  width: number;
  lockVisibility?: boolean;
  sort?: { order: number; direction: 'asc' | 'desc' };
  formatter?: (item: ICellRendererParams) => JSX.Element;
  filter?: string | boolean | (new () => IFilterComp) | undefined;
  customFloatingFilter?: any | string;
  customFloatingFilterParams?: any;
  keyCreator?: (params: any) => string;
}

export interface DataGridProps {
  entityType: EntityType;
  selectionType: DataGridSelectionType;
  highlightNewlyAdded?: boolean;
  supressUnselectDefault?: boolean;
  onSelect?: (items: IdName[]) => void;
  onRowClicked?: (item: IdName) => void;
  defaultSelection?: IdName[];
  keepDefaultSelection?: boolean;
  columnSettings: ColumnSettings[];
  showSelectionColumn?: boolean;
}

export type Props = DataGridProps &
  DataGridStateProps & {
    children?: React.ReactNode;
  };

const NoRowsFound: React.FunctionComponent<any> = () => {
  const [t] = useTranslation();
  const message = t('newnity.search.noresults');

  return <div className="ag-overlay-loading-center">{message}</div>;
};

export const DataGrid: React.FunctionComponent<Props> = React.memo((props: Props) => {
  const [t] = useTranslation();
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [widthSum, setWidthSum] = useState<number>(0);
  const [frameworkComponents, setFrameworkComponents] = useState<any>(null);
  const [copiedItems, setCopiedItems] = useState<IdName[]>([]);

  const classes = useStyles();

  const { onRowClicked, onSelect } = props;

  const rowSelection =
    props.selectionType === DataGridSelectionType.Multiple ||
    props.selectionType === DataGridSelectionType.MultipleOrNone
      ? 'multiple'
      : 'single';

  const showSelectionColumn = props.selectionType !== DataGridSelectionType.None;

  const onGridReady = useCallback(
    ({ api }: { api: GridApi }) => {
      setGridApi(api);
      Array.from(document.getElementsByClassName('ag-floating-filter-input')).forEach(obj => {
        if (obj.attributes.getNamedItem('disabled')) {
          // skip columns with disabled filter
          return;
        }
        obj.setAttribute('placeholder', 'Filter...');
      });

      const colWithSort = columnDefs.find(e => !!e.sort);

      if (colWithSort) {
        api.setSortModel([
          {
            colId: colWithSort.field,
            sort: colWithSort.sort,
          },
        ]);
      }
    },
    [columnDefs]
  );

  const isFirstColumn = useCallback(
    ({ columnApi, column }: { columnApi: ColumnApi; column: Column }) => {
      const displayedColumns = columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === column;
      return thisIsFirstColumn;
    },
    []
  );

  const buildColumns = useCallback((): ColDef[] => {
    const columns: ColDef[] = [];
    let width = 0;

    const components: any = {};

    if (showSelectionColumn) {
      columns.push({
        field: '#selection',
        resizable: false,
        sortable: false,
        checkboxSelection: true,
        headerCheckboxSelection: rowSelection === 'single' ? false : true,
        headerName: '',
        suppressMenu: true,
        suppressSizeToFit: true,
        cellClass: 'selection-cell',
        headerClass: 'selection-cell',
        suppressMovable: true,
        pinned: 'left',
        width: 72,
        suppressColumnsToolPanel: true,
        lockPosition: true,
        headerCheckboxSelectionFilteredOnly: true,
      });
    }

    props.columnSettings.forEach((columnConfig: ColumnSettings, index: number) => {
      if (columnConfig.customFloatingFilter && columnConfig.customFloatingFilter === 'boolean') {
        components['booleanFloatingFilter'] = BooleanFloatingFilter;
        components['booleanFilter'] = BooleanFilter;
      }

      if (columnConfig.customFloatingFilter) {
        components[columnConfig.name] = columnConfig.customFloatingFilter;
      }

      if (columnConfig.formatter) {
        components[columnConfig.name] = columnConfig.formatter;
      }

      width += columnConfig.width;
      columns.push({
        sort: columnConfig.sort ? columnConfig.sort.direction : undefined,
        field: columnConfig.name,
        width: showSelectionColumn ? columnConfig.width + 25 : columnConfig.width,
        minWidth: columnConfig.width,
        sortable: true,
        headerName: t(`${props.entityType}.columnTitle.${columnConfig.name}`),
        lockVisible: columnConfig.lockVisibility,
        resizable: true,
        cellRenderer: columnConfig.formatter ? columnConfig.name : undefined,
        floatingFilterComponentParams: {
          suppressFilterButton: columnConfig.filter === 'agSetColumnFilter' ? false : true,
          ...columnConfig.customFloatingFilterParams,
        },
        floatingFilterComponent: columnConfig.customFloatingFilter ? columnConfig.name : undefined,
        filter: columnConfig.filter ? columnConfig.filter : 'agTextColumnFilter',
        filterParams: columnConfig.customFloatingFilterParams,
        menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab'],
        keyCreator: columnConfig.keyCreator,
        tooltipField: columnConfig.name,
        lockPinned: true,
      });
    });

    setWidthSum(width);
    setFrameworkComponents({
      ...components,
      [NoRowsFound.name]: NoRowsFound,
    });
    return columns;
  }, [props.columnSettings, props.entityType, showSelectionColumn, rowSelection, t]);

  useEffect(() => {
    setColumnDefs(buildColumns());
  }, [props.columnSettings, buildColumns]);

  // run when default selected rows change
  useEffect(() => {
    // select default rows
    if (gridApi) {
      const newlyAdded = props.items.find(
        item => copiedItems.find(e => e.id === item.id) === undefined
      );
      gridApi.forEachNode((rowNode: RowNode, index: number) => {
        if (props.defaultSelection) {
          const row = props.defaultSelection.find(e => e.id === rowNode.data.id);
          if (row) {
            rowNode.setSelected(true);
            rowNode.data.HIGHLIGHT_ROW = true;
            gridApi.redrawRows({
              rowNodes: [rowNode],
            });
          }
        }

        if (
          props.highlightNewlyAdded &&
          newlyAdded &&
          rowNode.data.id === newlyAdded.id &&
          newlyAdded.id !== 0
        ) {
          rowNode.setSelected(true);
          gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');
          gridApi.redrawRows({
            rowNodes: [rowNode],
          });
        }
      });
    }

    setCopiedItems(props.items);
  }, [props.defaultSelection, gridApi, props.items]);

  // row clicked
  const handleRowClicked = useCallback(
    (event: RowClickedEvent) => {
      if (onRowClicked) {
        onRowClicked(event.data as IdName);
      }
    },
    [onRowClicked]
  );

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      if (onSelect) {
        onSelect(event.api.getSelectedRows());
      }
    },
    [onSelect]
  );

  const defaultColDef = {
    headerCheckboxSelection: rowSelection === 'multiple' ? isFirstColumn : undefined,
    checkboxSelection: rowSelection === 'multiple' ? isFirstColumn : undefined,
  };

  const handleGridSizeChanged = useCallback(
    ({ api, columnApi, clientWidth }: GridSizeChangedEvent) => {
      if (clientWidth > widthSum) {
        api.sizeColumnsToFit();
      } else {
        columnApi.getAllDisplayedColumns().forEach((col: Column) => {
          const settings = props.columnSettings.find(e => e.name === col.getColDef().field);
          if (settings) {
            columnApi.setColumnWidth(col, settings.width);
          } else {
            const colDef = col.getColDef();
            columnApi.setColumnWidth(col, colDef && colDef.width ? colDef.width : 100);
          }
        });
      }
    },
    [widthSum, props.columnSettings]
  );

  const handleFilterChanged = (event: FilterChangedEvent) => {
    const rows = event.api.getDisplayedRowCount();
    if (rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  };

  const getRowClass = (event: any) => {
    const node = event.node;
    if (props.supressUnselectDefault && props.defaultSelection) {
      if (node.data.HIGHLIGHT_ROW === true) {
        return classes.unselectableRow;
      }
    }

    return '';
  };

  if (frameworkComponents) {
    return (
      <div style={{ height: '100%', width: '100%' }} className={classes.gridContainer}>
        <div id="grid" className="ag-theme-material" style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={props.items}
            modules={AllModules}
            onRowClicked={handleRowClicked}
            onSelectionChanged={handleSelectionChanged}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            noRowsOverlayComponent={NoRowsFound.name}
            rowSelection={rowSelection}
            onFilterChanged={handleFilterChanged}
            onGridSizeChanged={handleGridSizeChanged}
            rowClass={onRowClicked ? classes.rowPointer : ''}
            getRowClass={getRowClass}
            floatingFilter
            suppressContextMenu
            suppressCellSelection
            suppressRowClickSelection
            suppressDragLeaveHidesColumns
          ></AgGridReact>
        </div>
      </div>
    );
  }

  return <div></div>;
});
