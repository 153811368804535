import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    blade: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    },
    paper: {
      height: '100%',
      backgroundColor: theme.palette.grey[100],
    },
    content: {
      flexGrow: 1,
    },
    dragHandle: {
      width: '4px',
      height: '100%',
      zIndex: 100,
      cursor: 'col-resize',
      right: '-2px',
      position: 'absolute',
      top: 0,
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
      },
    },
    dragHandleActive: {
      zIndex: 100,
      backgroundColor: theme.palette.grey[500],
    },
    dragHandleActiveLessThanMin: {
      backgroudColor: 'rgba(255, 0, 0, 0.2)',
    },
    noSelect: {
      userSelect: 'none',
    },
  });

export const errorBoundaryStyles = createStyles({
  error: {
    background: 'red',
    display: 'flex',
    alignItems: 'center',
  },
  errorStack: {
    maxHeight: '300px',
    overflow: 'auto',
  },
  icon: {
    color: '#ffffff',
    marginLeft: 16,
  },
  message: {
    color: '#ffffff',
    marginLeft: 8,
  },
  stack: {
    color: '#ffffff',
    marginLeft: 48,
  },
});

export interface ErrorBoundaryStylesProps {
  classes: {
    error: string;
    errorStack: string;
    icon: string;
    message: string;
    stack: string;
  };
}

export const useStyles = makeStyles(styles);
