import React from 'react';

import { EntityType } from 'app/dataStore';
import { SearchFields } from 'app/newnity/duck/types';
import { CompanyFields } from './company';
import { DeviceFields } from './device';
import { LocationFields } from './location';

interface SearchResultsProps {
  tabIndex: number;
  currentEntityType: EntityType;
  handleChange: (event: React.ChangeEvent) => any;
  fields: SearchFields;
}

export const SearchFieldsSwitch = (props: SearchResultsProps) => (
  <>
    {props.currentEntityType === EntityType.NCompanySearchResult && (
      <CompanyFields
        company={props.fields.company}
        handleChange={props.handleChange}
        tabIndex={props.tabIndex}
        key="company"
      />
    )}

    {props.currentEntityType === EntityType.NLocationSearchResult && (
      <LocationFields
        location={props.fields.location}
        handleChange={props.handleChange}
        tabIndex={props.tabIndex}
        key="location"
      />
    )}

    {props.currentEntityType === EntityType.NDeviceSearchResult && (
      <DeviceFields
        device={props.fields.device}
        handleChange={props.handleChange}
        location={props.fields.location}
        tabIndex={props.tabIndex}
        key="device"
      />
    )}
  </>
);
