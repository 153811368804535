import React from 'react';

import { TextFastFieldWrapper } from 'app/components/forms';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from './contentWrapper';

export interface TabFieldsProps {
  key: string;
  fields: { [key: string]: string };
  parentName: string;
  dontNestTranslationKey?: boolean;
  handleChange(e: React.ChangeEvent): void;
}

export const withContentWrapper = (Component: (props: TabFieldsProps) => JSX.Element) => {
  return (props: TabFieldsProps) => (
    <ContentWrapper>
      <Component {...props} />
    </ContentWrapper>
  );
};

export const TabFields = (props: TabFieldsProps) => {
  const [t] = useTranslation();

  return (
    <>
      {Object.keys(props.fields).map((fieldName: string) => {
        const name = props.parentName ? `${props.parentName}.${fieldName}` : fieldName;
        const translationKey = props.dontNestTranslationKey ? fieldName : name;

        return (
          <TextFastFieldWrapper
            name={name}
            value={props.fields[fieldName]}
            onChange={props.handleChange}
            label={t(`newnity.search.${translationKey}`)}
            key={`${props.key}-${fieldName}`}
          />
        );
      })}
    </>
  );
};

export const TabFieldsWithContentWrapper = withContentWrapper(TabFields);
