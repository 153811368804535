import {
    CheckboxState, NormalizedSelection, SelectedItem, SelectionMode, TreeState
} from '../types';

export const selectItem = (state: TreeState, treeItemId: string) : TreeState => {
  const selectedItems = selectRow(state, treeItemId);
  return { ...state, selectedItems };
};

export const unselectItem = (state: TreeState, treeItemId: string
) : TreeState => {
  const selectedItems = unselectRow(state, treeItemId);
  return { ...state, selectedItems };
};

export const selectAll = (state: TreeState): TreeState => {
  const selectedItems = selectAllRows(state);
  return { ...state, selectedItems };
};

export const unselectAll = (state: TreeState): TreeState => {
  const selectedItems = {};
  return { ...state, selectedItems };
};

const selectAllRows = (
  treeState: TreeState
): NormalizedSelection => {
  let ids = Object.keys(treeState.items);
  let root = ids[0];
  let selection: NormalizedSelection = {};
  
  switch (treeState.selectionMode) {
    case SelectionMode.MultipleWithInheritanceSelection:
      selection = selectRow(treeState, root);
      break;
    case SelectionMode.MultipleSelection:
      ids.forEach(i => selection[i] = {
        state: CheckboxState.Selected,
        selected: true,
        isIndeterminate: false,
        selectedChildrenCount: 0
      });
      break;
    default:
      break;
  }

  return selection;
}

const selectRow = (
  treeState: TreeState,
  selectedTreeItemId: string
): NormalizedSelection => {
  const treeItemIds = Object.keys(treeState.items);
  const selectedTreeItem = treeState.items[selectedTreeItemId];
  let treeSelection = { ...treeState.selectedItems };
  const currentSelection: SelectedItem = treeSelection[selectedTreeItemId]
    ? treeSelection[selectedTreeItemId]
    : {
        state: CheckboxState.Selected,
        selected: true,
        isIndeterminate: false,
        selectedChildrenCount: 0
      };

  switch (treeState.selectionMode) {
    case SelectionMode.SingleSelection: {
      treeSelection = {};
      treeSelection[selectedTreeItemId] = currentSelection;
      break;
    }

    case SelectionMode.MultipleSelection: {
      treeSelection[selectedTreeItemId] = currentSelection;
      break;
    }
    case SelectionMode.MultipleWithInheritanceSelection: {
      let addedCount = 0;

      if (selectedTreeItem.hasChildren) {
        ({ treeSelection, addedCount } = inheritSelectionToChildren(
          selectedTreeItemId,
          treeState,
          treeItemIds,
          treeSelection
        ));
        if (!treeSelection[selectedTreeItemId]) {
          addedCount++;
        }
        treeSelection[selectedTreeItemId] = {
          isIndeterminate: false,
          selected: true,
          selectedChildrenCount: selectedTreeItem.totalChildCount,
          state: CheckboxState.SelectedWithChildren
        };
      } else {
        treeSelection[selectedTreeItemId] = {
          isIndeterminate: false,
          selected: true,
          selectedChildrenCount: 0,
          state: CheckboxState.Selected
        };
        addedCount = 1;
      }
      treeSelection = parentsSelectionAddCount(
        selectedTreeItem.treeParentId,
        treeState,
        treeSelection,
        addedCount
      );
      break;
    }
  }
  
  return treeSelection;
};

const unselectRow = (
  treeState: TreeState,
  selectedTreeItemId: string
): NormalizedSelection => {
  let treeSelection = { ...treeState.selectedItems };

  switch (treeState.selectionMode) {
    case SelectionMode.MultipleSelection: {
      delete treeSelection[selectedTreeItemId];
      break;
    }
    case SelectionMode.MultipleWithInheritanceSelection:
        switch (treeSelection[selectedTreeItemId].state) {
          case CheckboxState.SelectedWithChildren: {
            treeSelection[selectedTreeItemId].state = CheckboxState.Selected;
            let removedCount = 0;
            const treeItemIds = Object.keys(treeState.items);
            ({
              treeSelection,
              removedCount
            } = removeInheritedSelectionFromChildren(
              selectedTreeItemId,
              treeState,
              treeItemIds,
              treeSelection
            ));

            parentsSelectionAddCount(
              selectedTreeItemId,
              treeState,
              treeSelection,
              -removedCount
            );
            break;
          }
          case CheckboxState.Selected:
              delete treeSelection[selectedTreeItemId];
              parentsSelectionAddCount(
                treeState.items[selectedTreeItemId].treeParentId,
                treeState,
                treeSelection,
                -1
              )
            break;
        }
      break;
  }
  return treeSelection;
};

const inheritSelectionToChildren = (
  selectedTreeItemId: string,
  treeState: TreeState,
  treeItemIds: string[],
  treeSelection: NormalizedSelection
) => {
  let i = treeState.items[selectedTreeItemId].flatListIndex + 1;
  const parentTreeItem = treeState.items[selectedTreeItemId];
  let newlySelectedItemsCount = 0;

  while (i < treeItemIds.length) {
    const currentTreeItem = treeState.items[treeItemIds[i]];
    if (currentTreeItem.level <= parentTreeItem.level) {
      break;
    }

    if (!treeSelection[currentTreeItem.treeId]) {
      newlySelectedItemsCount++;
    }
    treeSelection[currentTreeItem.treeId] = {
      isIndeterminate: false,
      selected: true,
      selectedChildrenCount: currentTreeItem.totalChildCount,
      state: currentTreeItem.hasChildren
        ? CheckboxState.SelectedWithChildren
        : CheckboxState.Selected
    };
    i++;
  }
  return { treeSelection, addedCount: newlySelectedItemsCount };
};

const removeInheritedSelectionFromChildren = (
  parentTreeItemId: string,
  treeState: TreeState,
  treeItemIds: string[],
  treeSelection: NormalizedSelection
) => {
  let i = treeState.items[parentTreeItemId].flatListIndex + 1;

  const parentTreeItem = treeState.items[parentTreeItemId];
  let removedCount = 0;
  while (i < treeItemIds.length) {
    const currentTreeItem = treeState.items[treeItemIds[i]];
    if (currentTreeItem.level <= parentTreeItem.level) {
      break;
    }

    if (!treeSelection[currentTreeItem.treeId]) {
      i++;
      continue;
    }

    delete treeSelection[currentTreeItem.treeId];
    removedCount++;
    i++;
  }
  return { treeSelection, removedCount };
};

const parentsSelectionAddCount = (
  treeItemId: string,
  treeState: TreeState,
  treeSelection: NormalizedSelection,
  itemsToAdd: number
) => {
  let currentItem = treeState.items[treeItemId];
  while (currentItem) {
    let itemSelection = treeSelection[currentItem.treeId];
    if (!itemSelection) {
      itemSelection = {
        isIndeterminate: currentItem.totalChildCount !== itemsToAdd,
        selectedChildrenCount: itemsToAdd,
        state: CheckboxState.SelectedWithChildren,
        selected: false
      };
      treeSelection[currentItem.treeId] = itemSelection;
      itemsToAdd++;
    } else {
      itemSelection.selectedChildrenCount += itemsToAdd;
    }

    switch (itemSelection.selectedChildrenCount) {
      case 0: {
        itemSelection.isIndeterminate = false;
        break;
      }
      case currentItem.totalChildCount: {
        itemSelection.state = CheckboxState.SelectedWithChildren;
        itemSelection.isIndeterminate = false;
        break;
      }
      default: {
        itemSelection.isIndeterminate = true;
        itemSelection.state = CheckboxState.SelectedWithChildren;
      }
    }

    if (
      !itemSelection.isIndeterminate &&
      itemSelection.selectedChildrenCount === 0
    ) {
      if (!itemSelection.selected) {
        delete treeSelection[currentItem.treeId];
        itemsToAdd--;
      } else {
        treeSelection[currentItem.treeId].state = CheckboxState.Selected;
      }
    }

    currentItem = treeState.items[currentItem.treeParentId];
  }
  return treeSelection;
};