import { createSelector } from 'reselect';

import { DataStoreSelectors } from '../../dataStore';
import { areaName, WorkgroupState } from './types';

const selectWorkgroupState = (state: any): WorkgroupState =>
  state[areaName] as WorkgroupState;


export const selectFavoriteWorkgroups = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.favoriteItems
);


export const selectPickSelectionMode = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.pickSelection.on
);

export const selectPickSelectionWorkgroup = createSelector(
  [selectWorkgroupState, DataStoreSelectors.Workgroup.selectWorkgroups],
  (state: WorkgroupState, workgroups) =>
  workgroups[state.pickSelection.selectedWorkgroupId]
);

export const selectPickSelectionTreeItemId = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.pickSelection.selectedTreeItemId
);

export const selectPickSelectionPathWorkgroup = createSelector(
  [selectWorkgroupState, DataStoreSelectors.Workgroup.selectWorkgroups],
  (state: WorkgroupState, workgroups) => {
    let path = '';
    let id = state.pickSelection.selectedWorkgroupId;
    let i = 0;

    // TODO add 3 lines
    while (i < 3 && workgroups[id]) {
      path = workgroups[id].name + ' \\ ' + path;
      id = workgroups[id].parentId;
      i++;
    }

    if (i === 3 && workgroups[id]) { 
      path = '... \\ ' + path; 
    }

    return path;
  }
);

export const selectSavingState = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => ({
    isSaving: state.createWorkgroup.isSaving,
    savingError: state.createWorkgroup.savingError,
  })
);