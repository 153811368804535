import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import GroupWork from '@material-ui/icons/GroupWork';
import Home from '@material-ui/icons/Home';
import People from '@material-ui/icons/People';
import Router from '@material-ui/icons/Router';

import { WorkgroupListBladeType } from '../../workgroup/blades/workgroupList';
import { useStyles } from './toolbar.jss';

export interface AdminAppToolbarActions {
  openBlade: (bladeType: string, props: object) => void;
  exitApp: () => void;
}

export const AdminAppToolbar = (props: AdminAppToolbarActions) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <List>
        <ListItem
          className={classes.list}
          button
          key={'home'}
          onClick={() => props.exitApp()}
        >
          <ListItemIcon>
            <Home className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={t('home')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}>
        <ListItem
          button
          key={'workgroups'}
          onClick={() => props.openBlade(WorkgroupListBladeType, {})}
        >
          <ListItemIcon className={classes.icon}>
            <GroupWork />
          </ListItemIcon>
          <ListItemText primary={t('workgroups')} />
        </ListItem>
        <ListItem button key={'users'}>
          <ListItemIcon className={classes.icon}>
            <People />
          </ListItemIcon>
          <ListItemText primary={t('users')} />
        </ListItem>
        <ListItem button key={'players'}>
          <ListItemIcon className={classes.icon}>
            <Router />
          </ListItemIcon>
          <ListItemText primary={t('players')} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};