import classNames from 'classnames';
import { FastField, Field, FieldProps, FormikProps } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { MaxLengthTextField as TextField } from '../../components';
import { NCompany } from '../../dataStore';
import { EntityType, HasDeviceSettings } from '../../dataStore/types';
import { useStyles as useCompanyStyles } from '../../newnity/blades/company/edit/company.jss';
import { NetworkMode, WifiSecurity } from '../../newnity/duck/types';
import { enumItemsFn } from '../../utils/enumItems';
import {
  helperText,
  parseFieldToEntity,
  replaceFieldInitialValue,
  validateEmptyField,
} from '../../utils/formik';
import { useStyles } from './deviceSettings.jss';

const MIN_AUDIO_VOLUME = 0;
const MAX_AUDIO_VOLUME = 100;

export interface DeviceSettingsProps {
  entityId: number;
  entityType: EntityType;
  isSaving: boolean;
  entity: HasDeviceSettings;
  parentEntity: HasDeviceSettings;
}

export interface DeviceSettingsActions {
  fetchParentEntity: () => void;
  fetchEntity: () => void;
}

type Props = DeviceSettingsProps & DeviceSettingsActions;

const fieldLabel = (field: { name: string }, t: i18next.TFunction) =>
  t(`newnity.tab.defaultSettings.${field.name}`);

const audioFields = ['audioVolume'];

const networkFields = [
  'wifiSettings.networkEnabled',
  'wifiSettings.dhcpEnabled',
  'wifiSettings.ipAddress',
  'wifiSettings.ipMask',
  'wifiSettings.ipGateway',
  'wifiSettings.dnsIp1',
  'wifiSettings.dnsIp2',
  'wifiSettings.ssId',
  'wifiSettings.securityKeyType',
  'wifiSettings.securityKey',
  'wifiSettings.verifySecurityKey',
  'ethernetSettings.networkEnabled',
  'ethernetSettings.dhcpEnabled',
  'ethernetSettings.ipAddress',
  'ethernetSettings.ipMask',
  'ethernetSettings.ipGateway',
  'ethernetSettings.dnsIp1',
  'ethernetSettings.dnsIp2',
  'proxySettings.proxyEnabled',
  'proxySettings.proxyAddress',
  'proxySettings.proxyPort',
  'proxySettings.proxyUser',
  'proxySettings.proxyPassword',
];

const verifySecurityKey = (values: HasDeviceSettings, errors: any) => {
  if (
    'verifySecurityKey' in values.deviceSettings.wifiSettings &&
    values.deviceSettings.wifiSettings.securityKey !==
      (values.deviceSettings.wifiSettings as any).verifySecurityKey
  ) {
    const error = i18next.t(
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.securityKeyError'
    );
    errors.deviceSettings = {
      wifiSettings: {
        securityKey: error,
        verifySecurityKey: error,
      },
    };
  }
};

export const validateDeviceSettings = (values: HasDeviceSettings, errors: any) => {
  return verifySecurityKey(values, errors);
};

export const DeviceSettings: React.FunctionComponent<Props> = React.memo(
  props => {
    const [t] = useTranslation();
    const companyClasses = useCompanyStyles();
    const classes = useStyles();

    const { fetchParentEntity, fetchEntity } = props;

    const isReady = () => {
      if (!props.parentEntity || !props.parentEntity.deviceSettings) {
        return false;
      }

      if (props.entityId > 0 && (!props.entity || !props.entity.deviceSettings)) {
        return false;
      }

      return true;
    };

    const fieldValue = (
      field: { name: string; value: any },
      formik: FormikProps<HasDeviceSettings>
    ) => {
      if (!props.parentEntity) {
        return;
      }

      if (
        audioFields.map((fieldName: string) => `deviceSettings.${fieldName}`).includes(field.name)
      ) {
        const [setting] = field.name.split('.').slice(1);
        if (formik.values.deviceSettings.enableSoundVolumeOverride) {
          if (props.entityId < 1) {
            if (field.value === parseFieldToEntity(field, formik.initialValues)) {
              field.value = (props.parentEntity.deviceSettings as any)[setting];
              replaceFieldInitialValue(field, formik.initialValues);
            }
          } else {
            if (field.value === parseFieldToEntity(field, formik.initialValues)) {
              field.value = (props.entity.deviceSettings as any)[setting];
              replaceFieldInitialValue(field, formik.initialValues);
            }
          }
        } else {
          field.value = (props.parentEntity.deviceSettings as any)[setting];
        }
      }

      if (
        networkFields.map((fieldName: string) => `deviceSettings.${fieldName}`).includes(field.name)
      ) {
        const [networkType, setting] = field.name.split('.').slice(1);
        if (formik.values.deviceSettings.enableNetworkSettingsOverride) {
          if (props.entityId < 1) {
            if (field.value === parseFieldToEntity(field, formik.initialValues)) {
              field.value = (props.parentEntity.deviceSettings as any)[networkType][setting];
              replaceFieldInitialValue(field, formik.initialValues);
            }
          } else {
            if (field.value === parseFieldToEntity(field, formik.initialValues)) {
              field.value = (props.entity.deviceSettings as any)[networkType][setting];
              replaceFieldInitialValue(field, formik.initialValues);
            }
          }
        } else {
          field.value = (props.parentEntity.deviceSettings as any)[networkType][setting];
        }
      }
    };

    useEffect(() => {
      fetchParentEntity();
    }, [fetchParentEntity]);

    useEffect(() => {
      if (props.entityId > 0 && (!props.entity || !props.entity.deviceSettings)) {
        fetchEntity();
      }
    }, [props.entityId, props.isSaving, props.entity, fetchEntity]);

    const onChangeAudioOverride = (checked: boolean, formik: FormikProps<HasDeviceSettings>) => {
      formik.values.deviceSettings.audioVolume = props.parentEntity.deviceSettings.audioVolume;
    };

    const onChangeNetworkOverride = (checked: boolean, formik: FormikProps<HasDeviceSettings>) => {
      networkFields.forEach((field: string) => {
        const [networkType, setting] = field.split('.');
        formik.setFieldValue(
          `deviceSettings.${field}`,
          (props.parentEntity.deviceSettings as any)[networkType][setting]
        );
      });
    };

    const disableAudioFields = useCallback((form: FormikProps<HasDeviceSettings>) => {
      return !form.values.deviceSettings.enableSoundVolumeOverride;
    }, []);

    const disableNetworkFields = useCallback((form: FormikProps<HasDeviceSettings>) => {
      return !form.values.deviceSettings.enableNetworkSettingsOverride;
    }, []);

    if (!isReady()) {
      return <CircularProgress size={60} />;
    } else {
      return (
        <>
          <Field
            name="deviceSettings.enableSoundVolumeOverride"
            render={({ field, form }: FieldProps<HasDeviceSettings>) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{
                        name: 'deviceSettings.enableSoundVolumeOverride',
                        id: 'deviceSettings.enableSoundVolumeOverride',
                      }}
                      onChange={(_, checked) => {
                        onChangeAudioOverride(checked, form);
                        field.onChange(_);
                      }}
                      checked={field.value}
                    />
                  }
                  label={fieldLabel(field, t)}
                />
              );
            }}
          />
          <Paper className={classes.audioSettingsPaper}>
            {/* <Typography>{t('newnity.tab.defaultSettings.deviceSettings.audioVolume')}</Typography> */}
            <Field
              name="deviceSettings.audioVolume"
              render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                fieldValue(field, form);
                return (
                  <TextField
                    {...field}
                    fullWidth
                    disabled={disableAudioFields(form)}
                    label={`${fieldLabel(field, t)} (0-100)`}
                    type={'number'}
                    inputProps={{ min: '0', max: '100', step: '1', maxLength: 200 }}
                    onChange={e => {
                      // replace non numeric chars
                      let value = e.target.value.replace(/\D/g, '');

                      // if not just a bunch of zeros, also strip leading zero chars
                      if (value.length !== (value.match(new RegExp('0', 'g')) || []).length) {
                        value = value.replace(/^0+/, '');
                      } else {
                        value = '0';
                      }

                      // upper limit
                      if (Number(value) > MAX_AUDIO_VOLUME) {
                        value = String(MAX_AUDIO_VOLUME);
                      }

                      // lower limit
                      if (Number(value) < MIN_AUDIO_VOLUME) {
                        value = String(MIN_AUDIO_VOLUME);
                      }

                      form.setFieldValue(field.name, value);
                      e.target.value = value;

                      field.onChange(e);
                    }}
                    onKeyPress={e => {
                      // handle the '.' or ',' since these don't propagate to the onChange handler
                      if (e.charCode < 48 || e.charCode > 57) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    className={classNames([companyClasses.formField])}
                    helperText={
                      form.errors.deviceSettings
                        ? form.errors.deviceSettings.audioVolume
                        : undefined
                    }
                  />
                );
              }}
            />
          </Paper>
          <FastField
            name="deviceSettings.enableNetworkSettingsOverride"
            render={({ field, form }: FieldProps<HasDeviceSettings>) => {
              return (
                <FormControlLabel
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className={classes.networkSettingsOverride}
                  control={
                    <Checkbox
                      onClick={e => e.stopPropagation()}
                      checked={field.value}
                      onChange={(_, checked) => {
                        onChangeNetworkOverride(checked, form);
                        field.onChange(_);
                      }}
                      inputProps={{
                        name: 'deviceSettings.enableNetworkSettingsOverride',
                        id: 'deviceSettings.enableNetworkSettingsOverride',
                      }}
                    />
                  }
                  label={t('newnity.tab.defaultSettings.settingsOverride')}
                />
              );
            }}
          />
          <ExpansionPanel>
            <ExpansionPanelSummary
              className={classes.alignCenter}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.flexGrow}>
                {t('newnity.tab.defaultSettings.configureWifi')}
              </Typography>
              <Typography>
                <Field
                  name="deviceSettings.wifiSettings.networkEnabled"
                  render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                    fieldValue(field, form);
                    return (
                      <FormControlLabel
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        control={
                          <Checkbox
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            className={classes.noPadding}
                            checked={field.value}
                            onChange={(_, checked) => form.setFieldValue(field.name, checked)}
                            disabled={disableNetworkFields(form)}
                          />
                        }
                        label={fieldLabel(field, t)}
                      />
                    );
                  }}
                />
              </Typography>
            </ExpansionPanelSummary>
            {/* </ExpansionPanel> */}
            <ExpansionPanelDetails>
              <div className={companyClasses.fieldsContainer}>
                <div className={companyClasses.horizontalContainer}>
                  <div
                    className={classNames([companyClasses.vertical, companyClasses.marginRight])}
                  >
                    <Field
                      name="deviceSettings.wifiSettings.dhcpEnabled"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <FormControl margin="normal">
                            <InputLabel htmlFor="dhcpEnabled">{fieldLabel(field, t)}</InputLabel>
                            <Select
                              {...field}
                              inputProps={{
                                name: 'deviceSettings.wifiSettings.dhcpEnabled',
                                id: 'deviceSettings.wifiSettings.dhcpEnabled',
                              }}
                              disabled={disableNetworkFields(form)}
                            >
                              {enumItemsFn(NetworkMode)}
                            </Select>
                          </FormControl>
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.ipAddress"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.wifiSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.ipMask"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.wifiSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.ipGateway"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.wifiSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.dnsIp1"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.wifiSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={classNames([companyClasses.vertical, companyClasses.marginLeft])}>
                    <Field
                      name="deviceSettings.wifiSettings.dnsIp2"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.wifiSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.ssId"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.securityKeyType"
                      render={({ field, form }: FieldProps<NCompany>) => {
                        fieldValue(field, form);
                        return (
                          <FormControl margin="normal">
                            <InputLabel htmlFor="securityKeyType">
                              {fieldLabel(field, t)}
                            </InputLabel>
                            <Select
                              {...field}
                              disabled={disableNetworkFields(form)}
                              inputProps={{
                                name: 'deviceSettings.wifiSettings.securityKeyType',
                                id: 'deviceSettings.wifiSettings.securityKeyType',
                              }}
                            >
                              {enumItemsFn(WifiSecurity)}
                            </Select>
                          </FormControl>
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.securityKey"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        const errorStr = i18next.t(
                          'newnity.tab.defaultSettings.deviceSettings.wifiSettings.securityKeyError'
                        );
                        const error =
                          form.values.deviceSettings.enableNetworkSettingsOverride &&
                          field.value !== form.values.deviceSettings.wifiSettings.verifySecurityKey;
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={error}
                            helperText={error && errorStr}
                            type="password"
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.wifiSettings.verifySecurityKey"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        const errorStr = i18next.t(
                          'newnity.tab.defaultSettings.deviceSettings.wifiSettings.securityKeyError'
                        );
                        const error =
                          form.values.deviceSettings.enableNetworkSettingsOverride &&
                          field.value !== form.values.deviceSettings.wifiSettings.securityKey;
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={error}
                            helperText={error && errorStr}
                            type="password"
                            defaultValue={form.values.deviceSettings.wifiSettings.securityKey}
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.flexGrow}>
                {t('newnity.tab.defaultSettings.configureEthernet')}
              </Typography>
              <Typography>
                <Field
                  name="deviceSettings.ethernetSettings.networkEnabled"
                  render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                    fieldValue(field, form);
                    return (
                      <FormControlLabel
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        control={
                          <Checkbox
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            className={classes.noPadding}
                            checked={field.value}
                            onChange={(_, checked) => form.setFieldValue(field.name, checked)}
                            disabled={disableNetworkFields(form)}
                          />
                        }
                        label={fieldLabel(field, t)}
                      />
                    );
                  }}
                />
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={companyClasses.fieldsContainer}>
                <div className={companyClasses.horizontalContainer}>
                  <div
                    className={classNames([companyClasses.vertical, companyClasses.marginRight])}
                  >
                    <Field
                      name="deviceSettings.ethernetSettings.dhcpEnabled"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <FormControl margin="normal">
                            <InputLabel htmlFor="dhcpEnabled">{fieldLabel(field, t)}</InputLabel>
                            <Select
                              {...field}
                              inputProps={{
                                name: 'deviceSettings.ethernetSettings.dhcpEnabled',
                                id: 'deviceSettings.ethernetSettings.dhcpEnabled',
                              }}
                              disabled={disableNetworkFields(form)}
                            >
                              {enumItemsFn(NetworkMode)}
                            </Select>
                          </FormControl>
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.ethernetSettings.ipAddress"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.ethernetSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.ethernetSettings.ipMask"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.ethernetSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={classNames([companyClasses.vertical, companyClasses.marginLeft])}>
                    <Field
                      name="deviceSettings.ethernetSettings.ipGateway"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.ethernetSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.ethernetSettings.dnsIp1"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.ethernetSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.ethernetSettings.dnsIp2"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={
                              disableNetworkFields(form) ||
                              form.values.deviceSettings.ethernetSettings.dhcpEnabled ===
                                NetworkMode.DHCP
                            }
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.flexGrow}>
                {t('newnity.tab.defaultSettings.configureProxy')}
              </Typography>
              <Typography>
                <Field
                  name="deviceSettings.proxySettings.proxyEnabled"
                  render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                    fieldValue(field, form);
                    return (
                      <FormControlLabel
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        control={
                          <Checkbox
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            className={classes.noPadding}
                            checked={field.value}
                            onChange={(_, checked) => form.setFieldValue(field.name, checked)}
                            disabled={disableNetworkFields(form)}
                          />
                        }
                        label={fieldLabel(field, t)}
                      />
                    );
                  }}
                />
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={companyClasses.fieldsContainer}>
                <div className={companyClasses.horizontalContainer}>
                  <div
                    className={classNames([companyClasses.vertical, companyClasses.marginRight])}
                  >
                    <Field
                      name="deviceSettings.proxySettings.proxyAddress"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.proxySettings.proxyPort"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            onChange={e => {
                              const regEx = /^\d*$/;
                              const leadingZeros = /^0+/;

                              // trim leading zeros
                              e.target.value = e.target.value.replace(leadingZeros, '');

                              if (!regEx.test(e.target.value)) {
                                e.preventDefault();
                                e.stopPropagation();
                                return;
                              }

                              field.onChange(e);
                            }}
                            inputProps={{ maxLength: 9 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={classNames([companyClasses.vertical, companyClasses.marginLeft])}>
                    <Field
                      name="deviceSettings.proxySettings.proxyUser"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                    <Field
                      name="deviceSettings.proxySettings.proxyPassword"
                      render={({ field, form }: FieldProps<HasDeviceSettings>) => {
                        fieldValue(field, form);
                        return (
                          <TextField
                            label={fieldLabel(field, t)}
                            {...field}
                            type="password"
                            inputProps={{ maxLength: 200 }}
                            error={validateEmptyField(form, field)}
                            helperText={helperText(form, field)}
                            disabled={disableNetworkFields(form)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      );
    }
  },
  (prevProps: Props, nextProps: Props) =>
    prevProps.parentEntity === nextProps.parentEntity &&
    prevProps.entity === nextProps.entity &&
    prevProps.entityId === nextProps.entityId &&
    prevProps.isSaving === nextProps.isSaving
);
