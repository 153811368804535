import Paper from '@material-ui/core/Paper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';

import { useStyles } from './blade.jss';
import { BladeErrorBoundary } from './bladeErrorBoundary';
import { BladeHeaderContainer } from './bladeHeaderContainer';
import { getBladeComponent, getBladeConfig } from './duck/bladeStore';
import { BladeInstance } from './duck/types';
import { HelpDialog } from './helpDialog';

export interface BladeProps {
  instance: BladeInstance;
}

const Blade: React.FunctionComponent<BladeProps> = props => {
  const config = getBladeConfig(props.instance);

  const classes = useStyles();

  const [isDirty, setIsDirty] = useState(false);
  const [width, setWidth] = useState(config.size.defaultWidth);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const setBladeDirty = useCallback((bladeId: string, dirty: boolean) => {
    setIsDirty(dirty);
  }, []);

  const component = getBladeComponent(props.instance, isDirty, setBladeDirty, width);

  const innerBladeDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (innerBladeDivRef.current) {
      setWidth(innerBladeDivRef.current.offsetWidth);
    }
  }, [innerBladeDivRef]);

  const resizeDragStopped: DraggableEventHandler = (e, data) =>
    setWidth(Math.max(config.size.minWidth, width + data.x));

  const openHelpDialog = useCallback(() => setIsHelpOpen(true), []);
  const closeHelpDialog = useCallback(() => setIsHelpOpen(false), []);

  return (
    <>
      <Paper
        square
        className={classes.paper}
        style={width !== 0 ? { width: `${width}px` } : undefined}
      >
        <div ref={innerBladeDivRef} className={classes.blade}>
          <BladeHeaderContainer
            isDirty={isDirty}
            bladeInstance={props.instance}
            openHelpDialog={openHelpDialog}
          />

          <HelpDialog
            isOpen={isHelpOpen}
            type={props.instance.type}
            closeDialog={closeHelpDialog}
          />

          <BladeErrorBoundary>{component}</BladeErrorBoundary>

          <Draggable
            axis="x"
            defaultClassName={classes.dragHandle}
            defaultClassNameDragging={
              width >= config.size.minWidth
                ? classes.dragHandleActive
                : classes.dragHandleActiveLessThanMin
            }
            position={{ x: 0, y: 0 }}
            onStop={resizeDragStopped}
          >
            <div className={classes.noSelect} />
          </Draggable>
        </div>
      </Paper>
    </>
  );
};

const propsAreEqual = (prevProps: BladeProps, nextProps: BladeProps) =>
  prevProps.instance.id === nextProps.instance.id &&
  prevProps.instance.title === nextProps.instance.title;

const MemoBlade = React.memo(Blade, propsAreEqual);

export { MemoBlade as Blade };
