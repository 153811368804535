import React from 'react';

import { TextFieldProps } from '@material-ui/core/TextField';
import { FastField, Field, FormikErrors, FormikHandlers, FormikProps } from 'formik';
import i18next from 'i18next';

export const validateEmptyField = <T extends {}>(
  form: FormikProps<T>,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  }
): boolean | undefined => {
  return (
    (form.touched as any)[field.name] &&
    !field.value &&
    (form.errors as any)[field.name] !== undefined &&
    (form.errors as any)[field.name].length > 0
  );
};

export const helperText = <T extends {}>(
  form: FormikProps<T>,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  }
) => {
  if ((form.touched as any)[field.name] && !field.value) {
    const path = field.name.split('.');
    let index = 0;
    let error: any = form.errors[path[index++] as keyof FormikErrors<T>];

    while (error && index < path.length) {
      error = error[path[index++]];
    }

    return error;
  }

  return undefined;
};

export const parseFieldToEntity = <T extends {}>(
  field: { name: string; value: any },
  entity: T
) => {
  const path = field.name.split('.');
  let value: any = entity;
  let index = 0;

  while (value && index < path.length) {
    value = value[path[index++]];
  }

  return value !== undefined ? value : field.value;
};

export const replaceFieldInitialValue = <T extends {}>(
  field: { name: string; value: any },
  entity: T
) => {
  const path = field.name.split('.');
  let value: any = entity;
  let index = 0;

  while (value && index < path.length - 1) {
    value = value[path[index++]];
  }

  if (typeof value === 'object') {
    value[path[index]] = field.value;
  }
};

export const isPasswordCompliant = (password: string): boolean => {
  const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*\d)(.){10,}$/;
  return passwordRegEx.test(password);
};

export const getPasswordError = (password: string, verifyPassword: string): string => {
  if (password !== verifyPassword) {
    return i18next.t('user.edit.passwordError');
  }

  if (password !== '' && !isPasswordCompliant(password)) {
    return i18next.t('user.edit.uncomplaint.password');
  }

  return '';
};

export const getFormPasswordError = <T extends {}>(
  form: FormikProps<T>,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  },
  verifyPassword: string
): string => {
  if (validateEmptyField(form, field) && verifyPassword === '') {
    return i18next.t('field.required');
  }

  return getPasswordError(field.value, verifyPassword);
};

export const isEmailValid = (email: string): boolean => {
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailRegEx.test(email);
};

export const getFormEmailError = <T extends {}>(
  form: FormikProps<T>,
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  }
): string => {
  if (validateEmptyField(form, field)) {
    return i18next.t('field.required');
  }
  return getEmailError(field.value);
};

const getEmailError = (email: string): string => {
  if (email !== '' && !isEmailValid(email)) {
    return i18next.t('user.edit.invalid.email');
  }
  return '';
};

export const getFormEmailTemplateError = (
  emailTemplate: string,
  sendWelcomeEmail: boolean
): string => {
  if (sendWelcomeEmail && emailTemplate.length === 0) {
    return i18next.t('field.required');
  }
  return '';
};

export const withTextFastField = (Component: (props: TextFieldProps) => JSX.Element) => {
  return (props: TextFieldProps) => (
    <FastField name={props.name}>{() => <Component {...props} />}</FastField>
  );
};

export const withTextField = (Component: (props: TextFieldProps) => JSX.Element) => {
  return (props: TextFieldProps) => (
    <Field name={props.name}>{() => <Component {...props} />}</Field>
  );
};
