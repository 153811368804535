import i18n from 'i18next';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { BladeActions, bladeConnect, BladeProps, openBlade } from '../../../blade';
import { DataGridSelectionType } from '../../../components/dataGrid';
import { selectedItems, SelectionListBladeType } from '../../../components/selectionBlade';
import { EntityType } from '../../../dataStore';
import {
    cleanupCreateWorkgroup, createWorkgroup, enterPickMode, fetchBrands, fetchTemplateLibraries
} from '../../duck/actions';
import {
    selectPickSelectionPathWorkgroup, selectPickSelectionTreeItemId, selectPickSelectionWorkgroup,
    selectSavingState
} from '../../duck/selectors';
import { WorkgroupCreateObject } from '../../duck/types';
import { WorkgroupListBladeType } from '../workgroupList';
import { WorkgroupCreate, WorkgroupCreateActions, WorkgroupCreateProps } from './workgroupCreate';

const mapStateToProps: MapStateToProps<WorkgroupCreateProps, BladeProps, any> = (
  state,
  ownProps
) => {
  const brandSelection = selectedItems(state, 'create_workgroup_brands');
  const templateLibrariesSelection = selectedItems(state, 'create_workgroup_template_libraries');
  return {
    workgroupParent: selectPickSelectionWorkgroup(state),
    brand: brandSelection && brandSelection.length > 0 ? brandSelection[0] : undefined,
    templateItems: templateLibrariesSelection,
    workgroupParentPath: selectPickSelectionPathWorkgroup(state),
    workgroupParentTreeItemId: selectPickSelectionTreeItemId(state),
    ...selectSavingState(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<WorkgroupCreateActions, BladeProps> = (
  dispatch,
  ownProps
) => {
  return {
    selectTemplateLibraries: () => {
      openBlade(
        WorkgroupCreateBladeType,
        SelectionListBladeType,
        {
          title: i18n.t('select.templateLibraries'),
          entityType: EntityType.TemplateLibrary,
          selectionKey: 'create_workgroup_template_libraries',
          selectionType: DataGridSelectionType.Multiple,
        },
        dispatch
      );
      dispatch<any>(fetchTemplateLibraries());
    },
    selectBrand: () => {
      openBlade(
        WorkgroupCreateBladeType,
        SelectionListBladeType,
        {
          title: i18n.t('select.brands'),
          entityType: EntityType.Brand,
          selectionKey: 'create_workgroup_brands',
          selectionType: DataGridSelectionType.Single,
        },
        dispatch
      );
      dispatch<any>(fetchBrands());
    },
    closeBlade: () => {
      dispatch<any>(cleanupCreateWorkgroup());
      dispatch(BladeActions.closeBlade(WorkgroupCreateBladeType));
    },
    onBladeClose: () => dispatch<any>(cleanupCreateWorkgroup()),
    createWorkgroup: (
      wkCreateObj: WorkgroupCreateObject,
      treeId: string,
      createAnother: boolean
    ) => {
      dispatch<any>(createWorkgroup(wkCreateObj, treeId, createAnother));
    },
    toggleParentSelectionMode: () => dispatch<any>(enterPickMode(WorkgroupListBladeType)),
  };
};

export const WorkgroupCreateBladeType = 'WORKGROUP_CREATE';

const bladeConfig = {
  size: { defaultWidth: 400, minWidth: 400 },
  bladeType: WorkgroupCreateBladeType,
  id: (_: any) => WorkgroupCreateBladeType,
  title: (_: any) => 'Create Workgroup',
  allowMultipleInstances: false,
};

export const WorkgroupCreateContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(WorkgroupCreate);
