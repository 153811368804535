import { bladeConnect, openBlade } from 'app/blade';
import { BladeProps } from 'app/blade/duck/types';
import { DataStoreSelectors, EntityType } from 'app/dataStore';
import { selectCompanyId, selectDeletingStation } from 'app/newnity/duck/selectors';
import { deleteStation, fetchStations } from 'app/newnity/duck/thunks';
import i18next from 'i18next';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { StationEditBladeType } from '../edit/stationEditContainer';
import { StationList, StationListActions, StationListProps } from './stationList';
import { stationStartEdit } from '../../../duck/actions';
import { selectIsEditingStation } from '../../../duck/selectors';

const mapStateToProps: MapStateToProps<StationListProps, BladeProps, any> = (state, _) => {
  return {
    companyId: selectCompanyId(state),
    items: DataStoreSelectors.NStation.selectStationItems(state),
    stationsFetching: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NStation)
      .isFetching,
    stationDeleting: selectDeletingStation(state),
    isEditing: selectIsEditingStation(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<StationListActions, BladeProps> = (dispatch, _) => {
  return {
    fetchStations: () => dispatch<any>(fetchStations()),
    createStation: () => {
      openBlade(StationListBladeType, StationEditBladeType, {}, dispatch)
      dispatch<any>(stationStartEdit())
    },
    editStation: (id: number, name: string) => {
      openBlade(
        StationListBladeType,
        StationEditBladeType,
        { stationId: id, stationName: name },
        dispatch
      )
      dispatch<any>(stationStartEdit())
    },
    deleteStation: (stationId: number, stationName: string, companyId: number) => dispatch<any>(deleteStation(companyId, stationId, stationName, StationListBladeType)),
  };
};

export const StationListBladeType = 'NEWNITY_STATION_LIST';

const bladeConfig = {
  size: { defaultWidth: 600, minWidth: 600 },
  bladeType: StationListBladeType,
  id: (_: any) => StationListBladeType,
  title: (_: any) => i18next.t('newnity.list.station.blade.title'),
  allowMultipleInstances: false,
};

export const StationListContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  StationList
);
