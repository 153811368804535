import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';

import { BladeActions, bladeConnect, BladeProps } from '../../../../blade';
import { defaultSettings } from '../../../../components/deviceSettings/deviceSettingsContainer';
import { DataStoreActions, DataStoreSelectors, EntityType, NCompany } from '../../../../dataStore';
import { cleanupCompanyEdit } from '../../../duck/actions';
import {
    selectCompanyDataIsFetching, selectCompanyId, selectCompanySavingState,
    selectDefaultParentWorkgroupId, selectParentWorkgroupIsFetching
} from '../../../duck/selectors';
import { saveCompanyInfo } from '../../../duck/thunks';
import { CompanyInfo, CompanyInfoActions, CompanyInfoProps } from './company';

interface CompanyContainerProps {
  title: string;
}

const getEmptyCompany = (): NCompany => ({
  id: 0,
  name: '',
  number: '',
  parentId: 1,
  country: '',
  city: '',
  state: '',
  zipCode: '',
  streetAddress: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  deviceSettings: {
    ...defaultSettings,
    wifiSettings: { ...defaultSettings.wifiSettings },
    ethernetSettings: { ...defaultSettings.ethernetSettings },
    proxySettings: { ...defaultSettings.proxySettings },
  },
});

const mapStateToProps: MapStateToProps<CompanyInfoProps, BladeProps, any> = (state, ownProps) => {
  let company: NCompany;
  const companyId = selectCompanyId(state);

  if (companyId) {
    company = DataStoreSelectors.NCompany.selectCompanyById(state, companyId);

    if (!company) {
      company = getEmptyCompany();
    }

    if (!company.deviceSettings) {
      company = { ...company, deviceSettings: getEmptyCompany().deviceSettings };
    }
  } else {
    const parentId = selectDefaultParentWorkgroupId();
    company = getEmptyCompany();
    company.parentId = parentId;
  }
  
  return {
    isFetching: selectCompanyDataIsFetching(state) || selectParentWorkgroupIsFetching(state),
    company,
    savingData: selectCompanySavingState(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<CompanyInfoActions, BladeProps> = (
  dispatch: Dispatch
) => ({
  closeBlade: () => {
    dispatch<any>(BladeActions.closeBlade(CompanyBladeType));
    dispatch(cleanupCompanyEdit());
  },
  saveCompany: (companyData: NCompany) => dispatch<any>(saveCompanyInfo(companyData)),
  upsertEntity: (company: NCompany) =>
    dispatch<any>(DataStoreActions.entityUpsert(EntityType.NCompany, company)),
});

export const CompanyBladeType = 'COMPANY_INFO';

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 800 },
  bladeType: CompanyBladeType,
  id: () => CompanyBladeType,
  title: (ownProps: CompanyContainerProps & BladeProps) => ownProps.title,
  allowMultipleInstances: false,
};

export const CompanyBladeContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  CompanyInfo
);
