import { createSelector } from 'reselect';
import { getDataStore } from './genericSelectors';

export const selectUsers = (state: any) => getDataStore(state).user.items;

export const selectUserItems = createSelector(
  [selectUsers],
  users => {
    return Object.keys(users).map(k => users[Number(k)]);
  }
);

export const selectUserById = (state: any, userId: number) => selectUsers(state)[userId];