import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { collapseItem, expandItem, selectItem, unselectItem } from './duck/actions';
import {
    selectActionState, selectSelectedItems, selectSelectionMode, selectTreeItems, selectVisibleTree
} from './duck/selectors';
import { TreeContainingState } from './duck/types';
import { Tree, TreeActions, TreeProps, TreeStateProps } from './tree';

const mapStateToProps: MapStateToProps<TreeStateProps, TreeProps, TreeContainingState> = (state, ownProps) => {
    return {
        items: selectTreeItems(state, ownProps.areaName, ownProps.treeName),
        visibleTree: selectVisibleTree(state, ownProps.areaName, ownProps.treeName),
        selectionMode: selectSelectionMode(state, ownProps.areaName, ownProps.treeName),
        selectedItems: selectSelectedItems(state, ownProps.areaName, ownProps.treeName),
        actionState: selectActionState(state, ownProps.areaName, ownProps.treeName) 
    };
}

const mapDispatchToProps: MapDispatchToProps<TreeActions, TreeProps> = (dispatch, ownProps) => {
    return {
        collapse: (treeItemId: string) => dispatch(collapseItem(ownProps.areaName, ownProps.treeName, treeItemId)),
        expand: (treeItemId: string) => dispatch(expandItem(ownProps.areaName, ownProps.treeName, treeItemId)),
        select: (treeItemId: string) => dispatch(selectItem(ownProps.areaName, ownProps.treeName, treeItemId)),
        unselect: (treeItemId: string) => dispatch(unselectItem(ownProps.areaName, ownProps.treeName, treeItemId))
    };
}

export const TreeContainer = connect(mapStateToProps, mapDispatchToProps)(Tree);