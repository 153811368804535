import {
  Button,
  Dialog,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogTitle,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { useStyles } from './helpDialog.jss';

import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';

interface HelpDialogProps {
  isOpen: boolean;
  type: string;
  closeDialog: () => void;
}

const markdownNotFound = `
  ### Ooops
  #### Could not find the corresponding MD File.
`;

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export const HelpDialog: React.FunctionComponent<HelpDialogProps> = props => {
  const [markdown, setMarkdown] = useState('');
  const [t] = useTranslation();

  const classes = useStyles();

  const fetchHelpText = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/help/${props.type}.md`);
      if (response.status !== 200) {
        throw new Error('File not Found');
      }

      setMarkdown(await response.text());
    } catch (ex) {
      setMarkdown(markdownNotFound);
    }
  }, [props.type]);

  useEffect(() => {
    if (markdown === '') {
      fetchHelpText();
    }
  }, [props.type, fetchHelpText, markdown]);

  return (
    <Dialog
      open={props.isOpen && !!markdown}
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      maxWidth="md"
      onEscapeKeyDown={props.closeDialog}
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('help')}
      </DialogTitle>
      <DialogContent dividers={true} className={classes.helpDialog}>
        <ReactMarkdown source={markdown} escapeHtml={false} renderers={renderers} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeDialog} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Heading = (props: any) => {
  switch (props.level) {
    case 1:
      return <Typography variant="h4">{props.children}</Typography>;
    case 2:
      return <Typography variant="h5">{props.children}</Typography>;
    case 3:
      return <Typography variant="h6">{props.children}</Typography>;
    default:
      return <Typography variant="subtitle1">{props.children}</Typography>;
  }
};

const renderers = {
  table: (props: any) => <Table>{props.children}</Table>,
  tableHead: (props: any) => <TableHead>{props.children}</TableHead>,
  tableCell: (props: any) => <TableCell>{props.children}</TableCell>,
  tableBody: (props: any) => <TableBody>{props.children}</TableBody>,
  tableRow: (props: any) => <TableRow>{props.children}</TableRow>,
  heading: (props: any) => <Heading {...props} />,
  link: (props: any) => (
    <Typography>
      <Link href={props.href}>{props.children}</Link>
    </Typography>
  ),
};
