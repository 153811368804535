import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    workgroupsContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    headerButton: {
      width: '120px',
      marginRight: theme.spacing(1) / 2
    },
    headerButtonLast: {
      marginRight: 0
    },
    headerButtonText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    headerButtonIconSmall: {
      fontSize: 20,
      marginRight: theme.spacing(1)
    },
    pickSelectionModeContainer: {
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      alignItems: 'center'
    },
    pickSelectionModeContent: {
      flexGrow: 1,
    },
    workgroupsTree: {
      flexGrow: 1,
      minHeight: "1px",
    },
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center'
    }
  });
};

export const useStyles = makeStyles(styles);