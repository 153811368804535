import { createSelector } from 'reselect';

import { EntityType, NLocationSearchResult } from '../types';
import { getDataStore } from './genericSelectors';

export const selectSearchLocations = (state: any) =>
  getDataStore(state)[EntityType.NLocationSearchResult];

export const selectSearchLocationItems = createSelector(
  [selectSearchLocations],
  locations => {
    return Object.keys(locations.items).map<NLocationSearchResult>(
      key => locations.items[Number(key)]
    );
  }
);
