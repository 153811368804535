import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import Help from '@material-ui/icons/HelpOutline';
import WarningRounded from '@material-ui/icons/WarningRounded';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { moodThemeDark } from '../app.jss';
import { useStyles } from './bladeHeader.jss';
import { BladeInstance } from './duck/types';

export interface BladeHeaderProps {
  bladeInstance: BladeInstance;
  isDirty: boolean;
  openHelpDialog: () => void;
}

export interface BladeHeaderActions {
  closeBlade: (bladeId: string) => void;
}

type Props = BladeHeaderActions & BladeHeaderProps;

export const BladeHeader: React.FunctionComponent<Props> = React.memo(
  props => {
    const { closeBlade } = props;

    const closeButtonClick = useCallback(
      (_: React.MouseEvent<HTMLButtonElement>) => {
        closeBlade(props.bladeInstance.id);
      },
      [props.bladeInstance.id, closeBlade]
    );
    const classes = useStyles();
    const [t] = useTranslation();
    return (
      <ThemeProvider theme={moodThemeDark}>
        <div className={classes.header}>
          {props.isDirty && (
            <Tooltip title={t('blade.dirty.warning')}>
              <WarningRounded color="secondary" className={classes.dirtyIcon} />
            </Tooltip>
          )}
          <Typography variant="h5" className={classes.title} color="textPrimary">
            {props.bladeInstance.title}
          </Typography>
          <IconButton className={classes.closeButton} onClick={props.openHelpDialog}>
            <Help />
          </IconButton>
          <IconButton className={classes.closeButton} onClick={closeButtonClick}>
            <Close />
          </IconButton>
        </div>
      </ThemeProvider>
    );
  },
  (prevProps: Props, nextProps: Props) =>
    prevProps.bladeInstance.id === nextProps.bladeInstance.id &&
    prevProps.isDirty === nextProps.isDirty &&
    prevProps.bladeInstance.title === nextProps.bladeInstance.title
);
