import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = () =>
  createStyles({
    tabs: {
      color: "white",
      marginTop: "-12px"
    }
  });

export const useStyles = makeStyles(styles);
