export const resources = {
  en: {
    translation: {
      yes: 'Yes',
      no: 'No',
      all: 'All',
      help: 'Help',
      'Welcome to Admin App': "Admin App is awesome y'all",
      home: 'Home',
      workgroups: 'Workgroups',
      settings: 'Settings',
      users: 'Users',
      players: 'Players',
      sites: 'Sites',
      or: 'or',
      'blade.closeDialog.title': 'Close blade',
      'blade.closeDialog.message': 'Are you sure you want to close these blades?',
      'blade.closeDialog.message.secondLine': 'Some of them have unsaved data:',
      'blade.close.all': 'Close all',
      'blade.dirty.warning': 'This blade has unsaved changes',
      cancel: 'Cancel',
      exit: 'Exit',
      add: 'Add',
      remove: 'Remove',
      create: 'Create',
      import: 'Import',
      delete: 'Delete',
      favorites: 'Favorites',
      search: 'Search',
      upload: 'Upload',
      next: 'Next',
      skip: 'Skip',
      close: 'Close',
      'search.placeholder': 'Type something for search...',
      'workgroups.pickSelectionModeCaption': 'You are now in the Workgroup Pick Mode!',
      'workgroups.expand.tooltip': 'Expand all workgroups',
      'workgroups.collapse.tooltip': 'Collapse all workgroups',
      'workgroups.select.tooltip': 'Select all workgroups',
      'workgroups.unselect.tooltip': 'Deselect all workgroups',
      'workgroups.export.tooltip': 'Export selected workgroups',
      name: 'Name',
      save: 'Save',
      edit: 'Edit',
      logo: 'Logo',
      'field.required': 'Field is required',
      'device.settings.title': 'Device settings',
      'select.templateLibraries': 'Template Libraries',
      'select.brands': 'Brands',
      'workgroups.templateLibraries.brand': 'Brand',
      'workgroups.templateLibraries.logo': 'Logo Path',
      'workgroups.brands.defaultLogo': 'Default Logo',
      'workgroups.brands.brandType': 'Brand Type',
      'create.workgroup.name': 'Workgroup name',
      'create.workgroup.brand_label': 'Brand',
      'create.workgroup.brand_ids': "Brand Id's",
      'create.workgroup.template': 'Template Libraries',
      'create.workgroup.parent_name': 'Parent workgroup',
      'create.workgroup.edit_btn_label': 'EDIT',
      'create.workgroup.select_btn_label': 'SELECT',
      'create.workgroup.error_name_required': 'Field is required',
      'create.workgroup.create_btn_label': 'CREATE',
      'create.workgroup.cancel_btn_label': 'Cancel',
      'create.workgroup.create_another_btn_label': 'Create another',
      'create.workgroup.error.multiple_workgroups':
        'Cannot create workgroup: multiple parents selected',
      'create.workgroup.number_selected': 'selected',
      'create.workgroup.properties_header': 'Properties',
      'workgroup.users': 'Users',
      'workgroup.users.remove': 'Remove Users',
      'workgroup.users.add': 'Add Users',
      'workgroup.users.edit': 'Edit User',
      'workgroup.users.remove.confirm.title': 'Remove User',
      'workgroup.users.remove.confirm.message':
        'Are you sure you want to remove user {{userLogin}}?',
      'workgroup.users.remove.confirm.generic': 'Are you sure you want to remove {{count}} users?',
      'workgroup.users.remove.confirm.editingUser': 'You are currently editing this user. ',
      'workgroup.users.dialog.remove': 'Remove',
      'workgroup.users.isLocked.unlocked': 'Unlocked',
      'workgroup.users.isLocked.locked': 'Locked',
      'workgroup.users.isLocked.masterlocked': 'Locked',
      'workgroup.users.isLocked.inactivity': 'Locked- inactivity',
      'user.columnTitle.onlyWorkgroupAvailable': 'Access Type',
      'user.columnTitle.value.onlyWorkgroupAvailable': 'Exclusive',
      'user.columnTitle.value.multipleWorkgroupsAvailable': 'Non-Exclusive',
      'user.columnTitle.id': 'ID',
      'user.columnTitle.login': 'Login',
      'user.columnTitle.email': 'Email',
      'user.columnTitle.firstName': 'First Name',
      'user.columnTitle.lastName': 'Last Name',
      'user.columnTitle.passwordExpDate': 'Password Expire',
      'user.columnTitle.lockLevel': 'Is Locked',
      'user.columnTitle.lockReason': 'Lock Reason',
      'user.edit.firstName': 'First Name',
      'user.edit.lastName': 'Last Name',
      'user.edit.login': 'Login ID',
      'user.edit.email': 'Email',
      'user.edit.password': 'Password',
      'user.edit.passwordVerify': 'Verify Password',
      'user.edit.changePassword': 'Change Password',
      'user.edit.changeLogin': 'Change Login',
      'user.edit.passwordError': 'Passwords must match',
      'user.edit.login.space.validation': "Login can't contain spaces",
      'user.edit.uncomplaint.password':
        'Password must be at least 10 characters long and contain at least one letter and one digit',
      'user.edit.invalid.email': 'Email must be of format [text]@[domain].[extension]',
      'user.edit.welcomeEmailTemplate': 'Email Template',
      'user.edit.sendWelcomeEmailOnCreate': 'Send welcome email on create',
      'user.edit.sendWelcomeEmailOnSave': 'Send welcome email on save',
      'user.edit.lastWelcomeEmailDate': 'Last welcome email sent on',
      'user.edit.previewEmail': 'Preview',
      'users.company.blade.title': 'Company Users',
      'newnity.startup.anthemAuthenticationFailure': 'Could not authenticate into Anthem!',
      'newnity.company': 'Company',
      'newnity.device': 'Device',
      'newnity.location': 'Location',
      'newnity.stations': 'Playlists',
      'newnity.locations': 'Locations',
      'newnity.devices': 'Devices',
      'newnity.deviceSettings': 'Device settings',
      'newnity.import': 'Import',
      'newnity.import.blade.title': 'Import',
      'newnity.import.step.getTemplate.title': 'Get the template',
      'newnity.import.step.getTemplate.downloadFile': 'Download file',
      'newnity.import.step.getTemplate.openInDrive': 'Open in drive',
      'newnity.import.step.getTemplate.skipThisStep': 'Skip this step',
      'newnity.import.step.uploadFile.title': 'Upload the file',
      'newnity.import.step.checkFileData.progressMessage': 'Checking file data',
      'newnity.import.step.checkFileData.title': 'Check import file data',
      'newnity.import.step.checkFileData.message':
        'Lines containing errors will be skipped at import',
      'newnity.import.summary.entity': 'Entity',
      'newnity.import.summary.total': 'Total',
      'newnity.import.summary.completed': 'Completed',
      'newnity.import.summary.errors': 'Errors',
      'newnity.import.summary.devicesSkippedTitle': 'Skipped devices',
      'newnity.import.summary.locationsSkippedTitle': 'Skipped locations',
      'newnity.import.summary.deviceSettingsSkippedTitle': 'Skipped device settings',
      'newnity.import.step.importData.title': 'Import data',
      'newnity.import.step.importData.duplicatesMessage': 'If an entity already exists, then:',
      'newnity.import.step.importData.progressMessage': 'Importing data',
      'newnity.import.step.importData.radio.update': 'Update values',
      'newnity.import.step.importData.radio.skip': 'Skip',
      'newnity.import.step.importData.completedMessage': 'Import complete, here are the results:',
      'importSkippedRow.columnTitle.name': 'Reason',
      'importSkippedRow.columnTitle.row': 'Row',
      'importSkippedRow.columnTitle.columnName': 'Column',
      'newnity.toolbar.search': 'Search',
      'newnity.toolbar.search.advanced': 'Advanced Search',
      'newnity.toolbar.add': 'New company',
      'newnity.toolbar.info': 'Company Info',
      'newnity.toolbar.close': 'Close company',
      'newnity.toolbar.jump': 'Jump to company',
      'newnity.toolbar.jump.tooltip': 'Quick Search Company',
      'newnity.search': 'Search',
      'newnity.address': 'Address',
      'newnity.phone': 'Phone',
      'newnity.search.address': 'Street Address',
      'newnity.search.city': 'City',
      'newnity.search.state': 'State/Province',
      'newnity.search.zip': 'Postal code',
      'newnity.search.country': 'Country',
      'newnity.search.company.name': 'Company name',
      'newnity.search.company.number': 'Company number',
      'newnity.search.company.firstName': 'Contact first name',
      'newnity.search.company.lastName': 'Contact last name',
      'newnity.search.company.phone': 'Phone',
      'newnity.search.company.email': 'Email',
      'newnity.search.device.deviceNumber': 'Device number',
      'newnity.search.device.deviceDescription': 'Device description',
      'newnity.search.device.serial': 'Serial number',
      'newnity.search.device.locationNumber': 'Location number',
      'newnity.search.device.locationName': 'Location name',
      'newnity.search.device.siteNumber': 'Site number',
      'newnity.search.device.company': 'Company',
      'newnity.search.device.salesOrder': 'Sales order number',
      'newnity.search.location.locationNumber': 'Location number',
      'newnity.search.location.locationName': 'Location name',
      'newnity.search.location.deviceNumber': 'Device number',
      'newnity.search.location.serial': 'Device serial',
      'newnity.search.location.siteNumber': 'Site Number',
      'newnity.search.location.company': 'Company',
      'newnity.search.phone': 'Phone',
      'newnity.search.email': 'Email',
      'newnity.search.resultsPlaceholder': 'Search results',
      'newnity.edit.company.blade.create.title': 'Create company',
      'newnity.edit.company.tab.title': 'Company details',
      'newnity.company.field.error': 'Field is required',
      'newnity.company.name': 'Company name',
      'newnity.company.number': 'Company number',
      'newnity.company.parentWorkgroup': 'Parent workgroup',
      'newnity.company.address': 'Address',
      'newnity.company.address.country': 'Country',
      'newnity.company.address.city': 'City',
      'newnity.company.address.state': 'State/Province',
      'newnity.company.address.zipCode': 'Zip/Postal code',
      'newnity.company.address.streetAddress': 'Street address',
      'newnity.company.contact.firstName': 'First name',
      'newnity.company.contact.lastName': 'Last name',
      'newnity.company.contact.phone': 'Phone',
      'newnity.company.contact.email': 'Email address',
      'newnity.edit.company.toast.create.success': 'Company created successfully',
      'newnity.edit.company.toast.success': 'Company data saved',
      'footer.save': 'Save',
      'footer.create': 'Create',
      'footer.cancel': 'Cancel',
      'footer.clear': 'Clear',
      'newnity.tab.defaultSettings.enableDefaultSettings': 'Enable device default settings',
      'newnity.tab.defaultSettings.deviceSettings.enableSoundVolumeOverride':
        'Enable Volume Override',
      'newnity.tab.defaultSettings.deviceSettings.audioVolume': 'Configure Audio volume',
      'newnity.tab.defaultSettings.networkSettings': 'Network settings',
      'newnity.tab.defaultSettings.networkOverride': 'Enable network settings override',
      'newnity.tab.defaultSettings.settingsOverride': 'Override All Network Settings',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.networkEnabled': 'Enable',
      'newnity.tab.defaultSettings.configureWifi': 'Configure WiFi',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.networkEnabled': 'Enable',
      'newnity.tab.defaultSettings.configureEthernet': 'Configure Ethernet',
      'newnity.tab.defaultSettings.deviceSettings.proxySettings.proxyEnabled': 'Enable',
      'newnity.tab.defaultSettings.deviceSettings.proxySettings.proxyAddress': 'Address',
      'newnity.tab.defaultSettings.deviceSettings.proxySettings.proxyPort': 'Port',
      'newnity.tab.defaultSettings.deviceSettings.proxySettings.proxyUser': 'User',
      'newnity.tab.defaultSettings.deviceSettings.proxySettings.proxyPassword': 'Password',
      'newnity.tab.defaultSettings.configureProxy': 'Configure Proxy',
      'newnity.tab.defaultSettings.title.wifi': 'WiFi Configuration',
      'newnity.tab.defaultSettings.title.ethernet': 'Ethernet Configuration',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.dhcpEnabled': 'Network Mode',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.ipAddress': 'IP Address',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.ipMask': 'Subnet Mask',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.ipGateway': 'Default Gateway',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.dnsIp1': 'Primary DNS',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.dnsIp2': 'Secondary DNS',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.dhcpEnabled': 'Network Mode',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.ipAddress': 'IP Address',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.ipMask': 'Subnet Mask',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.ipGateway': 'Default Gateway',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.dnsIp1': 'Primary DNS',
      'newnity.tab.defaultSettings.deviceSettings.ethernetSettings.dnsIp2': 'Secondary DNS',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.ssId': 'SSID',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.securityKeyType':
        'Please select security type',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.securityKey': 'Password',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.verifySecurityKey':
        'Verify Password',
      'newnity.tab.defaultSettings.deviceSettings.wifiSettings.securityKeyError':
        'Passwords must match',
      'newnity.create.location.tab.details': 'Location details',
      'newnity.create.location.tab.defaultSettings': 'Default settings',
      'newnity.create.location.tab.details.oracleNumber': 'Oracle location number',
      'newnity.create.location.tab.details.name': 'Location name',
      'newnity.create.location.tab.details.siteNumber': 'Site number',
      'newnity.create.location.tab.details.timezone': 'Timezone',
      'newnity.create.location.tab.details.country': 'Country',
      'newnity.create.location.tab.details.city': 'City',
      'newnity.create.location.tab.details.state': 'State/Province',
      'newnity.create.location.tab.details.zipCode': 'Zip/Postal code',
      'newnity.create.location.tab.details.address': 'Address',
      'newnity.create.location.tab.details.devices': 'Devices...',
      'newnity.create.location.tab.details.users': 'Users...',
      'nCompanySearchResult.columnTitle.name': 'Company name',
      'nCompanySearchResult.columnTitle.number': 'Company number',
      'nCompanySearchResult.columnTitle.city': 'City',
      'nCompanySearchResult.columnTitle.country': 'Country',
      'nLocationSearchResult.columnTitle.locationNumber': 'Oracle location number',
      'nLocationSearchResult.columnTitle.locationName': 'Location name',
      'nLocationSearchResult.columnTitle.siteNumber': 'Site number',
      'nLocationSearchResult.columnTitle.companyName': 'Company',
      'nDeviceSearchResult.columnTitle.id': 'Device number',
      'nDeviceSearchResult.columnTitle.serial': 'Serial number',
      'nDeviceSearchResult.columnTitle.salesOrder': 'Sales order number',
      'nDeviceSearchResult.columnTitle.deviceDescription': 'Device description',
      'nDeviceSearchResult.columnTitle.locationName': 'Location name',
      'nDeviceSearchResult.columnTitle.locationNumber': 'Location number',
      'nDeviceSearchResult.columnTitle.siteNumber': 'Site number',
      'nDeviceSearchResult.columnTitle.companyName': 'Company',
      'nProgram.columnTitle.id': 'Program id',
      'nProgram.columnTitle.name': 'Program Name',
      'nProgram.columnTitle.genre': 'Genre',
      'nProgram.columnTitle.description': 'Description',
      'nProgram.columnTitle.albumArt': 'Album Art',
      'nProgram.columnTitle.artists': 'Artists',
      'newnity.search.noresults': 'No results found',
      'newnity.search.locationAddress': 'Location Address',
      'newnity.edit.location.toast.success': 'Location data saved',
      'newnity.edit.location.toast.create.success': 'Location created successfully',
      'newnity.edit.location.blade.create.title': 'Create location',
      'newnity.edit.location.tab.details': 'Location details',
      'newnity.edit.location.tab.details.oracleNumber': 'Oracle location number',
      'newnity.edit.location.tab.details.name': 'Location name',
      'newnity.edit.location.tab.details.clientSiteId': 'Site number',
      'newnity.edit.location.tab.details.timezone': 'Timezone',
      'newnity.edit.location.tab.details.country': 'Country',
      'newnity.edit.location.tab.details.city': 'City',
      'newnity.edit.location.tab.details.state': 'State/Province',
      'newnity.edit.location.tab.details.zipCode': 'Zip/Postal code',
      'newnity.edit.location.tab.details.address': 'Address',
      'newnity.edit.location.tab.details.devices': 'Devices...',
      'newnity.edit.location.users.title': 'Location Users',
      'newnity.list.location.delete.confirm.title': 'Delete location',
      'newnity.list.location.delete.confirm.content':
        'Are you sure you want to delete {{locationName}}?',
      'newnity.list.location.delete.currently.editing': 'You are currently editing this item. ',
      'newnity.list.location.delete.confirm.warning': ' This operation cannot be reverted!',
      'newnity.list.location.delete.table.location': 'Location to be deleted ',
      'newnity.list.location.delete.table.device': 'Devices to be deleted ',
      'newnity.list.location.delete.table.workgroup': 'Workgroups to be deleted ',
      'newnity.list.station.delete.confirm.title': 'Delete playlist',
      'newnity.list.station.delete.confirm.content':
        'Are you sure you want to delete {{stationName}}?',
      'newnity.list.station.delete.currently.editing': 'You are currently editing this item. ',
      'newnity.list.station.blade.title': 'Playlists',
      'newnity.edit.station.blade.isDefault': 'Playlist is set as default',
      'newnity.edit.station.blade.setAsDefault': 'Set this playlist as default',
      'newnity.edit.station.blade.blockRemoteScrolling': 'Block schedule override',
      'newnity.edit.station.blade.header.addProgram': 'Add Program',
      'newnity.edit.station.blade.header.changeProgram': 'Change program',
      'newnity.edit.station.blade.header.addSilence': 'Add Silence',
      'newnity.edit.station.blade.slots.silence': 'Silence',
      'newnity.edit.station.blade.slots.empty': 'Empty slot',
      'newnity.edit.station.blade.scheduleSlots': 'Schedule slots',
      'newnity.edit.station.blade.defaultSlot': 'Default slot',
      'newnity.edit.station.blade.header.removeProgram': 'Remove Program',
      'newnity.edit.station.blade.create.title': 'Create playlist',
      'newnity.edit.station.blade.create.nameError': 'Playlist name cannot be empty',
      'newnity.edit.station.blade.create.name': 'Playlist name',
      'newnity.edit.station.blade.create.header': 'Playlist',
      'newnity.edit.station.toast.success': 'Playlist data saved',
      'newnity.edit.station.toast.create.success': 'Playlist created successfully',
      'newnity.edit.station.slot.columnTitle.name': 'Name',
      'newnity.edit.station.slot.columnTitle.genre': 'Genre',
      'newnity.edit.station.slot.schedule.create.tooltip': 'Create slot schedule',
      'nStation.columnTitle.id': 'Playlist Id',
      'nStation.columnTitle.name': 'Playlist name',
      'nStation.columnTitle.isDefault': 'Default playlist',
      'nSearchCompany.columnTitle.name': 'Company name',
      'nSearchCompany.columnTitle.number': 'Company number',
      'nSearchCompany.columnTitle.city': 'City',
      'nSearchCompany.columnTitle.country': 'Country',
      'nSearchLocation.columnTitle.locationNumber': 'Oracle location number',
      'nSearchLocation.columnTitle.locationName': 'Location name',
      'nSearchLocation.columnTitle.siteNumber': 'Site number',
      'nSearchLocation.columnTitle.companyName': 'Company',
      'nSearchDevice.columnTitle.deviceNumber': 'Device number',
      'nSearchDevice.columnTitle.serial': 'Serial number',
      'nSearchDevice.columnTitle.salesOrder': 'Sales order number',
      'nSearchDevice.columnTitle.deviceDescription': 'Device description',
      'nSearchDevice.columnTitle.locationName': 'Location name',
      'nSearchDevice.columnTitle.locationNumber': 'Location number',
      'nSearchDevice.columnTitle.siteNumber': 'Site number',
      'nSearchDevice.columnTitle.companyName': 'Company',
      'nDevice.columnTitle.serialNumber': 'Serial number',
      'nDevice.columnTitle.name': 'Device name',
      'nDevice.columnTitle.deviceSettings.select.location': 'Please select a location first',
      'nDevice.columnTitle.id': 'Device number',
      'nDevice.columnTitle.deviceTypeName': 'Player type',
      'nDevice.columnTitle.salesOrderNumber': 'Sales order number',
      'nDevice.columnTitle.description': 'Description',
      'nDevice.columnTitle.locationName': 'Location name',
      'nDevice.columnTitle.locationNumber': 'Location number',
      'nDevice.columnTitle.stationName': 'Playlist',
      'nLocationList.columnTitle.name': 'Name',
      'nLocationList.columnTitle.country': 'Country',
      'nLocationList.columnTitle.city': 'City',
      'nLocationList.columnTitle.timezone': 'Timezone',
      'nLocationList.columnTitle.oracleNumber': 'Oracle location Number',
      'nLocationList.columnTitle.clientSiteId': 'Site number',
      'newnity.search.tab.company.address': 'Company Address',
      'newnity.device.properties.tab': 'Properties',
      'newnity.device.edit.number': 'Device number',
      'newnity.device.edit.name': 'Device name',
      'newnity.device.edit.serialNumber': 'Serial number',
      'newnity.device.edit.serialNumber.locked.tooltip': 'Unlock serial number editing',
      'newnity.device.edit.serialNumber.unlocked.tooltip': 'Lock serial number editing',
      'newnity.device.edit.salesOrderNumber': 'Sales order',
      'newnity.device.edit.description': 'Description',
      'newnity.device.edit.deviceTypeId': 'Device type',
      'newnity.device.edit.location': 'Assign to location (Oracle location number | Location name)',
      'newnity.device.edit.station': 'Select playlist',
      'newnity.device.edit.toast.create.success': 'Device created successfully',
      'newnity.device.edit.toast.success': 'Device data saved',
      'newnity.device.delete.confirm.title': 'Delete device',
      'newnity.device.delete.confirm.message':
        'This operation is irreversible! Are you sure you want to delete {{deviceSerial}}?',
      'newnity.device.delete.confirm.serial': 'the selected device',
      'newnity.device.delete.currently.editing': 'You are currently editing this item. ',
      'newnity.details': 'Details',
      'newnity.android': 'Android',
      'newnity.device.edit.title': 'Create device',
      'newnity.device.required.location': 'Location is required',
      'newnity.device.required.name': 'Device name is required',
      'newnity.time': 'Time',
      'newnity.days': 'Days',
      'newnity.schedule.startTime': 'Start time',
      'newnity.schedule.endTime': 'End time',
      'newnity.schedule.startDate': 'Start date',
      'newnity.schedule.endDate': 'End date',
      'newnity.schedule.title': 'Program Schedule',
      'newnity.schedule.noDaysError': 'Please select at least one day',
      'newnity.monday': 'Monday',
      'newnity.tuesday': 'Tuesday',
      'newnity.wednesday': 'Wednesday',
      'newnity.thursday': 'Thursday',
      'newnity.friday': 'Friday',
      'newnity.saturday': 'Saturday',
      'newnity.sunday': 'Sunday',
      'newnity.schedule.startDateEndDate': 'Start date greater than end date',
      'new.user': 'New User',
      'newnity.device.serial.characters':
        'Spaces and characters \\, /, :, *, ?, ", <, >, | are not supported',
      'newnity.add.users.title': 'Add Users',
      'user.details.title': 'User Details',
      'user.details.lockUser': 'Lock User',
      'user.details.unlockUser': 'Unlock User',
      'user.edit.addToWorkgroup': 'Add user to',
    },
  },
  fr: {
    translation: {
      'Welcome to Admin App': 'Omlette du Admin App avec baguette',
      home: 'Home fr',
      workgroups: 'Workgroups fr',
      settings: 'Settings fr',
      users: 'Users fr',
      players: 'Players fr',
      'blade.close.confirmation': 'Are you sure you want to close {{bladeName}} fr?',
      'blade.close.dirty': 'The folowing blades have unsaved data: fr',
      'blade.close.all': 'Close all fr',
      cancel: 'Cancel fr',
      create: 'Create fr',
      delete: 'Delete fr',
      favorites: 'Favorites fr',
      search: 'Search fr',
      'search.placeholder': 'Type something for search fr...',
      'workgroups.expand.tooltip': 'Expand all workgroups fr',
      'workgroups.collapse.tooltip': 'Collapse all workgroups fr',
      'workgroups.select.tooltip': 'Select all workgroups fr',
      'workgroups.unselect.tooltip': 'Deselect all workgroups fr',
      'workgroups.export.tooltip': 'Export selected workgroups fr',
      name: 'Name',
      save: 'Save',
      'field.required': 'Field is required',
      'workgroups.templateLibraries.brand': 'Brand',
      'workgroups.templateLibraries.logoPath': 'Logo',
      'workgroups.brands.defaultLogo': 'Default Logo',
      'workgroups.brands.brandType': 'Brand Type',
      'workgroup.users': 'Users',
      'workgroup.users.remove': 'Remove Users',
      'workgroup.users.add': 'Add Users',
      'workgroup.users.edit': 'Edit User',
      'workgroup.users.remove.confirm.title': 'Remove User',
      'workgroup.users.remove.confirm.message':
        'Are you sure you want to remove user {{userLogin}}?',
      'workgroup.users.remove.confirm.generic': 'Are you sure you want to remove {{count}} users?',
      'user.columnTitle.id': 'ID',
      'user.columnTitle.login': 'Login',
      'user.columnTitle.email': 'Email',
      'user.columnTitle.firstName': 'First Name',
      'user.columnTitle.lastName': 'Last Name',
      'user.columnTitle.passwordExpire': 'Password Expire',
      'user.edit.firstName': 'First Name',
      'user.edit.lastName': 'Last Name',
      'user.edit.login': 'Login ID',
      'user.edit.email': 'Email',
      'user.edit.password': 'Password',
      'user.edit.passwordVerify': 'Verify Password',
      'user.edit.changePassword': 'Change Password',
      'user.edit.changeLogin': 'Change Login',
      'user.edit.passwordError': 'Passwords must match',
      'user.edit.invalid.email': 'Email must be of format [text]@[domain].[extension]',
      'users.company.blade.title': 'Company Users',
      'user.edit.uncomplaint.password':
        'Password must be at least 10 characters long and contain at least one letter and one digit',
      'user.edit.welcomeEmailTemplate': 'Email Template',
      'user.edit.sendWelcomeEmailOnCreate': 'Send welcome email on create',
      'user.edit.sendWelcomeEmailOnSave': 'Send welcome email on save',
      'user.edit.lastWelcomeEmailDate': 'Last welcome email sent on',
      'user.edit.previewEmail': 'Preview',
      'user.edit.addToWorkgroup': 'Add user to',
      'user.details.title': 'User Details',
      'user.details.lockUser': 'Lock User',
      'user.details.unlockUser': 'Unlock User',
    },
  },
  de: {
    translation: {
      'Welcome to Admin App': 'Admin App über alles!!!',
      home: 'Home de',
      workgroups: 'Workgroups de',
      settings: 'Settings de',
      users: 'Users de',
      players: 'Players de',
      'blade.close.confirmation': 'Are you sure you want to close {{bladeName}} de?',
      'blade.close.dirty': 'The folowing blades have unsaved data: de',
      'blade.close.all': 'Close all de',
      cancel: 'Cancel de',
      create: 'Create de',
      delete: 'Delete fr',
      favorites: 'Favorites de',
      search: 'Search de',
      'search.placeholder': 'Type something for search de...',
      'workgroups.expand.tooltip': 'Expand all workgroups de',
      'workgroups.collapse.tooltip': 'Collapse all workgroups de',
      'workgroups.select.tooltip': 'Select all workgroups de',
      'workgroups.unselect.tooltip': 'Deselect all workgroups de',
      'workgroups.export.tooltip': 'Export selected workgroups de',
      name: 'Name',
      save: 'Save',
      'field.required': 'Field is required',
      'workgroups.templateLibraries.brand': 'Brand',
      'workgroups.brands.defaultLogo': 'Default Logo',
      'workgroups.brands.brandType': 'Brand Type',
      'workgroup.users': 'Users',
      'workgroup.users.remove': 'Remove Users',
      'workgroup.users.add': 'Add Users',
      'workgroup.users.edit': 'Edit User',
      'workgroup.users.remove.confirm.title': 'Remove User',
      'workgroup.users.remove.confirm.message':
        'Are you sure you want to remove user {{userLogin}}?',
      'workgroup.users.remove.confirm.generic': 'Are you sure you want to remove {{count}} users?',
      'user.columnTitle.id': 'ID',
      'user.columnTitle.login': 'Login',
      'user.columnTitle.email': 'Email',
      'user.columnTitle.firstName': 'First Name',
      'user.columnTitle.lastName': 'Last Name',
      'user.columnTitle.passwordExpire': 'Password Expire',
      'user.edit.firstName': 'First Name',
      'user.edit.lastName': 'Last Name',
      'user.edit.login': 'Login ID',
      'user.edit.email': 'Email',
      'user.edit.password': 'Password',
      'user.edit.passwordVerify': 'Verify Password',
      'user.edit.changePassword': 'Change Password',
      'user.edit.changeLogin': 'Change Login',
      'user.edit.passwordError': 'Passwords must match',
      'user.edit.invalid.email': 'Email must be of format [text]@[domain].[extension]',
      'users.company.blade.title': 'Company Users',
      'user.edit.uncomplaint.password':
        'Password must be at least 10 characters long and contain at least one letter and one digit',
      'user.edit.welcomeEmailTemplate': 'Email Template',
      'user.edit.sendWelcomeEmailOnCreate': 'Send welcome email on create',
      'user.edit.sendWelcomeEmailOnSave': 'Send welcome email on save',
      'user.edit.lastWelcomeEmailDate': 'Last welcome email sent on',
      'user.edit.previewEmail': 'Preview',
      'user.edit.addToWorkgroup': 'Add user to',
      'user.details.title': 'User Details',
      'user.details.lockUser': 'Lock User',
      'user.details.unlockUser': 'Unlock User',
    },
  },
};
