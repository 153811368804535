import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      //zIndex: 2
    },
    dirtyIcon: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
      marginRight: theme.spacing(2),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    closeButton: {
      padding: '4px',
    },
  });

export const useStyles = makeStyles(styles);
