import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import i18n from '../../../../../localization/i18n';
import { BladeActions, bladeConnect, BladeProps } from '../../../../blade';
import { defaultSettings } from '../../../../components/deviceSettings/deviceSettingsContainer';
import { DataStoreSelectors, EntityType, NDevice, PlayerType } from '../../../../dataStore';
import { deviceSetLocation, setDeviceSaved, deviceFinishEdit } from '../../../duck/actions';
import { selectCompanyId } from '../../../duck/selectors';
import { fetchDevice, fetchLocations, fetchStations, saveDevice } from '../../../duck/thunks';
import { NewnityState } from '../../../duck/types';
import { Device, DeviceActions, DeviceProps } from './device';

export interface DeviceContainerProps extends BladeProps {
  deviceId?: number;
  openedFromLocation?: number;
}

const getEmptyDevice = (): NDevice => ({
  id: 0,
  name: '',
  deviceTypeName: 'Android',
  deviceTypeId: PlayerType.Android,
  serialNumber: '',
  salesOrderNumber: '',
  description: '',
  locationId: 0,
  locationName: '',
  locationNumber: '',
  stationId: 0,
  stationName: '',
  deviceSettings: {
    ...defaultSettings,
    wifiSettings: { ...defaultSettings.wifiSettings },
    ethernetSettings: { ...defaultSettings.ethernetSettings },
    proxySettings: { ...defaultSettings.proxySettings },
  },
});

const getDevice = (state: any, id: number | undefined) => {
  if (!id) {
    return getEmptyDevice();
  }

  let device = DataStoreSelectors.getDataStoreItems(state, EntityType.NDevice)[id] as NDevice;

  if (device === undefined) {
    device = getEmptyDevice();
    device.id = id;
  }

  if (!device.deviceSettings) {
    device = {...device, deviceSettings: getEmptyDevice().deviceSettings};
  }

  if ('stationId' in device === false) {
    device = {...device, stationId: getEmptyDevice().stationId};
  }

  return device;
};

const mapStateToProps: MapStateToProps<DeviceProps, DeviceContainerProps, any> = (
  state,
  ownProps
) => {
  const companyId = selectCompanyId(state);
  const device = getDevice(state, ownProps.deviceId);
  const locations = DataStoreSelectors.NLocationList.selectOrderedLocationItems(state); 
  const stations = DataStoreSelectors.NStation.selectOrderedStationItems(state);
  const isSaving = (state.newnity as NewnityState).currentDevice.isSaving;
  const saved = (state.newnity as NewnityState).currentDevice.saved;
  const isLoading = DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NStation).isFetching
    || DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NLocationList).isFetching
    || DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NDevice).isFetching;

  if (!device.locationId && ownProps.openedFromLocation) {
    device.locationId = ownProps.openedFromLocation;
  }

  return {
    companyId,
    device,
    locations,
    stations,
    isSaving,
    isLoading,
    saved,
    openedFromLocation: ownProps.openedFromLocation,
  };
};

const mapDispatchToProps: MapDispatchToProps<DeviceActions, BladeProps> = (dispatch, _) => {
  return {
    saveDevice: device => dispatch<any>(saveDevice(device)),
    setDeviceSaved: value => dispatch<any>(setDeviceSaved(value)),
    fetchDevice: deviceId => dispatch<any>(fetchDevice(deviceId)),
    fetchLocations: companyId => dispatch<any>(fetchLocations(companyId)),
    fetchStations: () => dispatch<any>(fetchStations()),
    deviceSetLocation: locationId => dispatch<any>(deviceSetLocation(locationId)),
    forceCloseBlade: () => {
      dispatch(BladeActions.forceCloseBlade(DeviceBladeType))
      dispatch<any>(deviceFinishEdit())
    },
    onCloseBlade: () => dispatch<any>(deviceFinishEdit()),
  };
};

export const DeviceBladeType = 'DEVICE';

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 600 },
  bladeType: DeviceBladeType,
  id: (_: any) => DeviceBladeType,
  title: (ownProps: DeviceContainerProps) =>
    ownProps.deviceId ? `Device ${ownProps.deviceId}` : i18n.t('newnity.device.edit.title'),
  allowMultipleInstances: false,
};

export const DeviceContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  Device
);
