import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { BladeActions, bladeConnect } from '../../../blade';
import { BladeProps } from '../../../blade/duck/types';
import { DataStoreSelectors, EntityType } from '../../../dataStore';
import { addSelectedPrograms } from '../../duck/actions';
import { ProgramsList, ProgramsListActions, ProgramsListProps } from './programList';

interface ProgramsListContainerProps {
  stationId: number;
  defaultSlot: boolean;
}

const mapStateToProps: MapStateToProps<
  ProgramsListProps,
  ProgramsListContainerProps & BladeProps,
  any
> = (state, props) => {
   const tempPrograms = DataStoreSelectors.NProgram.selecProgramItems(state);
   const _ = {
    ...props,
    items: tempPrograms,
    itemsFetching: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NProgram).isFetching
  };

  return _;
};

const mapDispatchToProps: MapDispatchToProps<
  ProgramsListActions,
  ProgramsListContainerProps & BladeProps
> = (dispatch, _) => {
  return {
    forceCloseBlade: () =>{  dispatch(BladeActions.forceCloseBlade(ProgramsListBladeType))},
    addPrograms: (items, stationId: number, defaultSlot: boolean) => dispatch(addSelectedPrograms(items, stationId, defaultSlot))
  };
};

export const ProgramsListBladeType = 'NEWNITY_PROGRAM_LIST';

const bladeConfig = {
  size: { defaultWidth: 400, minWidth: 200 },
  bladeType: ProgramsListBladeType,
  id: (_: any) => ProgramsListBladeType,
  title: (_: any) => 'Programs',
  allowMultipleInstances: false,
};

export const ProgramsListContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(ProgramsList);
