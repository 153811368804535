import * as DataStoreActions from './actions';
import * as DataStoreActionTypes from './actionTypes';
import * as GenericSelectors from './selectors/genericSelectors';
import * as NCompanySelectors from './selectors/newnityCompanies';
import * as DeviceSelectors from './selectors/newnityDevices';
import * as NLocationSelectors from './selectors/newnityLocations';
import * as NLocationListSelectors from './selectors/newnityLocationsList';
import * as NParentWorkgroupSelectors from './selectors/newnityParentWorkgroups';
import * as NProgramSelectors from './selectors/newnityPrograms';
import * as NCompanySearchResult from './selectors/newnitySearchCompanies';
import * as NDeviceSearchResult from './selectors/newnitySearchDevices';
import * as NLocationSearchResult from './selectors/newnitySearchLocations';
import * as NStationSelectors from './selectors/newnityStations';
import * as PlayerSelectors from './selectors/playerSelectors';
import * as SiteSelectors from './selectors/siteSelectors';
import * as UserSelectors from './selectors/userSelectors';
import * as WorkgroupSelectors from './selectors/workgroupSelectors';
import {
    Brand, CountryInfo, IdName, ImportResult, ImportSkippedRow, NCompany, NDevice, NLocation,
    NLocationList, NormalizedIdNames, NStation, Player, PlayerType, Site, Stream, TemplateLibrary,
    User, Workgroup
} from './types';

export { areaName, EntityType } from './types';

export type NormalizedIdNames<T extends IdName> = NormalizedIdNames<T>;
export type IdName = IdName;
export type Workgroup = Workgroup;
export type Player = Player;
export type TemplateLibrary = TemplateLibrary;
export type Site = Site;
export type Stream = Stream;
export type Brand = Brand;
export type NCompany = NCompany;
export type NLocation = NLocation;
export type NLocationList = NLocationList;
export type NDevice = NDevice;
export type NStation = NStation;
export type CountryInfo = CountryInfo;
export type User = User;
export type ImportSkippedRow = ImportSkippedRow;
export type ImportResult = ImportResult;
export { PlayerType };
export const DataStoreSelectors = {
  ...GenericSelectors,
  User: { ...UserSelectors },
  Site: { ...SiteSelectors },
  Player: { ...PlayerSelectors },
  Workgroup: { ...WorkgroupSelectors },
  NLocation: { ...NLocationSelectors },
  NLocationList: { ...NLocationListSelectors },
  NCompany: { ...NCompanySelectors },
  NDevice: { ...DeviceSelectors },
  
  NParentWorkgroup: { ...NParentWorkgroupSelectors },
  NStation: { ...NStationSelectors },
  NProgram: { ...NProgramSelectors },
  
  NSearchCompanyResult: { ...NCompanySearchResult },
  NDeviceSearchResult: { ...NDeviceSearchResult },
  NLocationSearchResult: { ...NLocationSearchResult },
};
export { DataStoreActionTypes };
export { dataStoreReducer as reducer, initializeEntityState } from './reducer';
export { DataStoreActions };
