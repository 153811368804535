import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    tabContent: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    selectMargin: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    detailsMargin: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflow: 'auto'
    },
    locationMenuItem: {
      padding: 0,
      lineHeight: 3
    },
    locationSelect: {
      '& > div:first-child': {
        paddingLeft: '0 !important'
      }
    },
    locationNumber: {
      display: 'inline-block', 
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`, 
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      width: '22%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      verticalAlign: 'bottom'
    },
    locationName: {
      display: 'inline-block', 
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`, 
    }
  });
};

export const useStyles = makeStyles(styles);