import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { version } from '../../../package.json';
import { moodThemeDark } from '../app.jss';
import { MVisionLoginToolbarButton } from '../v6/login';
import { useStyles } from './layout.jss';
import { ToolbarContainer } from './toolbar/toolbarContainer';

export interface FromLayoutToolbarProps {
  expandToolbar: () => void;
  toolbarExpanded: boolean;
}

export const Layout: React.FunctionComponent<{}> = React.memo(props => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.app}>
      <CssBaseline />
      <MuiThemeProvider theme={moodThemeDark}>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
              [classes.drawerPaper]: true,
            }),
          }}
          open={drawerOpen}
        >
          <ToolbarContainer expandToolbar={handleDrawerOpen} toolbarExpanded={drawerOpen} />
          {process.env.REACT_APP_MVISION_LOGIN === 'true' && (
            <MVisionLoginToolbarButton showTooltip={!drawerOpen} />
          )}
          <>
            <ListItem
              button
              key={'expand'}
              onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
            >
              <ListItemIcon className={classes.expandCollapseButton}>
                {!drawerOpen ? (
                  <Tooltip title="Expand Toolbar" placement="right">
                    <ChevronRightIcon />
                  </Tooltip>
                ) : (
                  <>
                    <ChevronLeftIcon />
                    <ListItemText className={classes.listItemText} primary="Close Toolbar" />
                  </>
                )}
              </ListItemIcon>
            </ListItem>
            {<div className={classes.version}>v{version}</div>}
          </>
        </Drawer>
      </MuiThemeProvider>
      <main className={classes.content}>{props.children}</main>
    </div>
  );
});
