import { ColumnSettings } from 'app/components/dataGrid/agDataGrid';
import { EntityType } from 'app/dataStore';

export const columnSettings: { [key: string]: ColumnSettings[] } = {
  [EntityType.NCompanySearchResult]: [
    { name: 'number', width: 200 },
    { name: 'name', width: 190, sort: { order: 0, direction: 'asc' }, lockVisibility: true },
    { name: 'city', width: 150 },
    { name: 'country', width: 150 },
  ],
  [EntityType.NLocationSearchResult]: [
    { name: 'locationNumber', width: 220 },
    {
      name: 'locationName',
      width: 190,
      lockVisibility: true,
      sort: { order: 0, direction: 'asc' },
    },
    { name: 'siteNumber', width: 150 },
    { name: 'companyName', width: 150 },
  ],
  [EntityType.NDeviceSearchResult]: [
    { name: 'id', width: 160 },
    { name: 'serial', width: 180, lockVisibility: true, sort: { order: 0, direction: 'asc' } },
    { name: 'salesOrder', width: 190 },
    { name: 'deviceDescription', width: 180 },
    { name: 'locationName', width: 180 },
    { name: 'locationNumber', width: 180 },
    { name: 'siteNumber', width: 140 },
    { name: 'companyName', width: 150 },
  ],
};
