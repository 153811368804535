import { createStyles, Theme } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    treeMeasure: {
      width: "100%",
      height: "100%"
    },
    treeItem: {
      display: "flex",
      alignItems: "center",
      borderLeft: `${theme.spacing(1) / 2}px solid transparent`,
      "&:hover": {
        backgroundColor: theme.palette.grey[200]
      },
      "&:focus-within": {
      }
    },
    selected: {
      backgroundColor: red[50],
      borderLeft: `${theme.spacing(1) / 2}px solid ${
        theme.palette.secondary.main
      }`
    }
  });

export const useStyles = makeStyles(styles);
