import React from 'react';

import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

export const MaxLengthTextField = (props: TextFieldProps) => {
  return (
    <TextField margin="normal" inputProps={{ maxLength: 255 }} autoComplete="new-password" {...props} />
  );
};
