import { action } from 'typesafe-actions';

import { EntityType, IdName, NLocation, NStation } from '../../dataStore';
import {
    ActionTypes, ImportFileParseResult, ImportFileResult, ProgramAsset, Schedule, SearchFields,
    StationDto
} from './types';

export const companyDataFetchRequest = () => action(ActionTypes.COMPANY_DATA_FETCH_REQUEST);

export const companyDataFetchSuccess = () => action(ActionTypes.COMPANY_DATA_FETCH_SUCCESS);

export const companyDataFetchError = (error: string) =>
  action(ActionTypes.COMPANY_DATA_FETCH_ERROR, error);

export const defaultParentWorkgroupRequest = () =>
  action(ActionTypes.DEFAULT_PARENT_WORKGROUP_REQUEST);

export const defaultParentWorkgroupSuccess = (parentId: number) =>
  action(ActionTypes.DEFAULT_PARENT_WORKGROUP_SUCCESS, parentId);

export const defaultParentWorkgroupError = (error: string) =>
  action(ActionTypes.DEFAULT_PARENT_WORKGROUP_ERROR, error);

export const updateCurrentParentWorkgroup = (parentId: number) =>
  action(ActionTypes.UPDATE_PARENT_WORKGROUP_SUCCESS, parentId);

export const companySaveRequest = (id: number) => action(ActionTypes.SAVE_COMPANY_REQUEST, id);

export const companySaveSuccess = (id: number) => action(ActionTypes.SAVE_COMPANY_SUCCESS, id);

export const companySaveError = (err: string) => action(ActionTypes.SAVE_COMPANY_ERROR, err);

export const setCompanyRequest = (companyId: number) =>
  action(ActionTypes.SET_COMPANY_REQUEST, companyId);
export const setCompanySuccess = (companyId: number) =>
  action(ActionTypes.SET_COMPANY_SUCCESS, companyId);
export const setCompanyError = (companyId: number, error: string) =>
  action(ActionTypes.SET_COMPANY_ERROR, { companyId, error });
export const cleanupCompanyEdit = () => action(ActionTypes.CLEANUP_COMPANY_EDIT);
export const searchSetFields = (fields: SearchFields) =>
  action(ActionTypes.SEARCH_SET_FIELDS, fields);

export const searchSetCurrentEntity = (entity: EntityType) =>
  action(ActionTypes.SEARCH_SET_CURRENT_ENTITY, entity);

export const searchSetSearched = (entity: EntityType, fields: SearchFields) =>
  action(ActionTypes.SEARCH_SET_SEARCHED, { entity, fields });

export const searchSetTabIndex = (index: number) => action(ActionTypes.SEARCH_SET_TAB_INDEX, index);

export const locationSaveRequest = (companyId: number, location: NLocation) =>
  action(ActionTypes.SAVE_LOCATION_REQUEST, { companyId, location });

export const locationSaveSuccessful = (companyId: number, location: NLocation) =>
  action(ActionTypes.SAVE_LOCATION_SUCCESS, { companyId, location });

export const locationSaveError = (companyId: number, location: NLocation, error: string) =>
  action(ActionTypes.SAVE_LOCATION_ERROR, { companyId, location, error });

export const setInCompanyMode = (value: boolean) => action(ActionTypes.SET_IN_COMPANY_MODE, value);

export const setSelectedLocations = (items: IdName[]) =>
  action(ActionTypes.SET_SELECTED_LOCATIONS, items);
export const addSelectedPrograms = (items: IdName[], stationId: number, defaultSlot: boolean) =>
  action(ActionTypes.ADD_SELECTED_PROGRAMS, { items, stationId, defaultSlot });
export const setSelectedDevices = (items: IdName[]) =>
  action(ActionTypes.SET_SELECTED_DEVICES, items);
export const locationDeleteRequest = (locationId: number, locationName: string) =>
  action(ActionTypes.DELETE_LOCATION_REQUEST, { locationId, locationName });
export const locationDeleteSuccess = (locationId: number, locationName: string) =>
  action(ActionTypes.DELETE_LOCATION_SUCCESS, { locationId, locationName });
export const locationDeleteError = (locationId: number, locationName: string, error: string) =>
  action(ActionTypes.DELETE_LOCATION_ERROR, {
    locationId,
    locationName,
    error,
  });

export const deviceSaveRequest = () => action(ActionTypes.SAVE_DEVICE_REQUEST);

export const deviceSaveSuccess = () => action(ActionTypes.SAVE_DEVICE_SUCCESS);

export const deviceSaveError = (err: string) => action(ActionTypes.SAVE_DEVICE_ERROR, err);

export const setDeviceSaved = (value: boolean) => action(ActionTypes.SET_DEVICE_SAVED, value);

export const deviceDeleteRequest = (deviceId: number) =>
  action(ActionTypes.DELETE_DEVICE_REQUEST, deviceId);

export const deviceDeleteSuccess = () => action(ActionTypes.DELETE_DEVICE_SUCCESS);

export const deviceDeleteError = (err: string) => action(ActionTypes.DELETE_DEVICE_ERROR, err);

export const deviceSetLocation = (locationId: number) =>
  action(ActionTypes.SET_DEVICE_LOCATION, locationId);

export const startupRequest = action(ActionTypes.STARTUP_REQUEST);
export const startupSuccess = () => action(ActionTypes.STARTUP_SUCCESS);

// stations

// delete
export const stationDeleteRequest = (stationId: number, stationName: string) =>
  action(ActionTypes.DELETE_LOCATION_REQUEST, { stationId, stationName });
export const stationDeleteSuccess = (stationId: number, stationName: string) =>
  action(ActionTypes.DELETE_LOCATION_SUCCESS, { stationId, stationName });
export const stationDeleteError = (stationId: number, stationName: string, error: string) =>
  action(ActionTypes.DELETE_LOCATION_ERROR, {
    stationId,
    stationName,
    error,
  });

// fetch
export const stationFetchRequest = (stationId: number) =>
  action(ActionTypes.STATION_FETCH_REQUEST, stationId);
export const stationFetchSuccess = (stationDto: StationDto, programAssets: ProgramAsset) =>
  action(ActionTypes.STATION_FETCH_SUCCESS, { stationDto, programAssets });
export const stationFetchError = (stationId: number, error: string) =>
  action(ActionTypes.STATION_FETCH_ERROR, {
    stationId,
    error,
  });
// save
export const stationSaveRequest = (station: NStation) =>
  action(ActionTypes.STATION_SAVE_REQUEST, station);
export const stationSaveSuccessful = (station: NStation) =>
  action(ActionTypes.STATION_SAVE_SUCCESS, station);
export const stationSaveError = (station: NStation, error: string) =>
  action(ActionTypes.STATION_SAVE_ERROR, { station, error });
export const setSchedule = (schedule: {
  stationId?: number;
  localId: number;
  schedule: Schedule;
}) => action(ActionTypes.SET_SCHEDULE, schedule);
export const changeStationSlotPosition = (stationId: number, oldIndex: number, newIndex: number) =>
  action(ActionTypes.CHANGE_STATION_SLOT_POSITION, { stationId, oldIndex, newIndex });
export const cleanupStationEdit = (stationId: number) =>
  action(ActionTypes.CLEANUP_STATION_EDIT, stationId);
export const stationAddSilence = (stationId: number) =>
  action(ActionTypes.STATION_ADD_SILENCE, stationId);
export const stationRemoveSlot = (stationId: number, slotLocalId: number) =>
  action(ActionTypes.STATION_REMOVE_SLOT, { stationId, slotLocalId });
export const stationSetEdittingSlot = (stationId: number, slotLocalId: number) =>
  action(ActionTypes.STATION_SET_EDITTING_SLOT, { stationId, slotLocalId });
export const parseImportFileRequest = (filePath: string) =>
  action(ActionTypes.PARSE_IMPORT_FILE_REQUEST, { filePath });
export const parseImportFileSuccess = (filePath: string, parseResult: ImportFileParseResult) =>
  action(ActionTypes.PARSE_IMPORT_FILE_SUCCESS, { filePath, parseResult });
export const parseImportFileError = (filePath: string, err: string) =>
  action(ActionTypes.PARSE_IMPORT_FILE_ERROR, { filePath, err });
export const importFileRequest = (filePath: string) =>
  action(ActionTypes.IMPORT_FILE_REQUEST, { filePath });
export const importFileSuccess = (filePath: string, importResult: ImportFileResult) =>
  action(ActionTypes.IMPORT_FILE_SUCCESS, { filePath, importResult });
export const importFileError = (filePath: string, err: string) =>
  action(ActionTypes.IMPORT_FILE_ERROR, { filePath, err });
export const cleanupImport = () => action(ActionTypes.CLEANUP_IMPORT);


export const locationStartEdit = () => action(ActionTypes.LOCATION_EDITING_STARTED);
export const locationFinishEdit = () => action(ActionTypes.LOCATION_EDITING_COMPLETED);

export const deviceStartEdit = () => action(ActionTypes.DEVICE_EDITING_STARTED);
export const deviceFinishEdit = () => action(ActionTypes.DEVICE_EDITING_COMPLETED);

export const stationStartEdit = () => action(ActionTypes.STATION_EDITING_STARTED);
export const stationFinishEdit = () => action(ActionTypes.STATION_EDITING_COMPLETED);