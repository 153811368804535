import React, { useEffect, useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { uploadChunk } from './api';
import { FileUploadRequest, FileUploadResult, UploadStatus } from './types';

export interface UploadFileProps {
  file: File;
  chunkSize: number;
  canUpload: boolean;
  filesAreContent: boolean;
  onFileComplete?: (result: FileUploadResult) => void;
}

interface UploadFileState {
  chunks: FileUploadRequest;
  error: string;
  status: UploadStatus;
}

export const UploadFile: React.FunctionComponent<UploadFileProps> = React.memo(props => {
  const [fileState, setFileState] = useState<UploadFileState>({
    chunks: {
      flowFilename: props.file.name,
      flowChunkNumber: 1,
      flowTotalChunks: Math.ceil(props.file.size / props.chunkSize),
      flowChunkSize: props.chunkSize,
      flowIdentifier: new Date().getTime().toString(),
      flowRelativePath: props.file.name,
      flowTotalSize: props.file.size,
      isContent: props.filesAreContent,
    },
    error: '',
    status: UploadStatus.NotStarted,
  });

  const { onFileComplete } = props;

  // this effect hook should only run while the file chunks are uploading
  useEffect(() => {
    if (
      !props.canUpload ||
      (fileState.status !== UploadStatus.InProgress && fileState.status !== UploadStatus.NotStarted)
    ) {
      return;
    }

    uploadChunk(fileState.chunks, props.file)
      .then(response => {
        // chunk has been uploaded, so we update the state which will either trigger sending the next chunk or signal the completion of the file upload
        const newState: UploadFileState = {
          chunks: {
            ...fileState.chunks,
            flowChunkNumber: fileState.chunks.flowChunkNumber + 1,
          },
          status:
            fileState.chunks.flowChunkNumber + 1 <= fileState.chunks.flowTotalChunks
              ? UploadStatus.InProgress
              : UploadStatus.Done,
          error: '',
        };
        // extra check here: if the final chunk has been sent but the server did not respond with the assembled file path on the server, then something went wrong
        if (newState.status === UploadStatus.Done) {
          // for the last chunk we expect the response to be Created - 201
          if (response.status !== 201) {
            newState.status = UploadStatus.Failed;
            newState.error = 'Last chunk sent but server did not respond with remote file path';
            if (onFileComplete) {
              onFileComplete({
                error: newState.error,
                file: props.file,
                remotePath: '',
                successful: false,
              });
            }
          } else {
            // finally the file has been uploaded successfully
            response.text().then(remotePath => {
              if (onFileComplete) {
                onFileComplete({
                  error: '',
                  file: props.file,
                  remotePath,
                  successful: true,
                });
              }
            });
          }
        }
        setFileState(newState);
      })
      .catch(err => {
        // chunk upload errored out. there is nothing you can do but show the error and call it a day
        const newState: UploadFileState = {
          ...fileState,
          status: UploadStatus.Failed,
          error: err ? err.toString() : '',
        };
        setFileState(newState);
      });
  }, [
    fileState.status,
    fileState.chunks.flowChunkNumber,
    props.canUpload,
    onFileComplete,
    props.file,
    fileState,
  ]);
  return (
    <div>
      <p>{props.file.name}</p>
      <LinearProgress
        variant="determinate"
        color={fileState.status === UploadStatus.Failed ? 'secondary' : 'primary'}
        value={((fileState.chunks.flowChunkNumber - 1) * 100) / fileState.chunks.flowTotalChunks}
      />
    </div>
  );
});
