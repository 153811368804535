import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },
  titleBox: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  parent: {
    flexGrow: 1,
    display: 'flex'
  },
  row:{
    flexDirection: 'row'
  },
  column:{
    flexDirection: 'column'
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  titleMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexGrow: 1
  },
}));
