import { OptionsObject, WithSnackbarProps } from 'notistack';
import React from 'react';
import { Button } from '@material-ui/core';
import { Notification } from './duck/types';

export interface NotifierProps {
  notifications: Notification[];
}

export interface NotifierActions {
  removeNotification: (notificationKey: number) => void;
}

type Props = WithSnackbarProps & NotifierProps & NotifierActions; 
export class Notifier extends React.Component<Props> {
  displayed: number[] = [];

  storeDisplayed = (id: number) => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate(nextProps: Props) {
    const { notifications: currentSnacks } = this.props;
    const newSnacks = nextProps.notifications;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) continue;
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      const options: OptionsObject = {
        variant: notification.type,
        action: notification.action && <Button color="inherit" size="small">{notification.action.label}</Button>
      };
      this.props.enqueueSnackbar(notification.message, options);
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeNotification(notification.key);
    });
  }

  render() {
    return null;
  }
}
