import { Dispatch } from 'react';
import { Store } from 'redux';

export const SKIP = 'skipInitialCall';
export const EQUALS = 'equals';
export const OBSERVER = '__OBSERVER__';

const primitives = ['string', 'number', 'boolean', 'symbol']

const isPrimitive = (val: any) => {
  if (val === undefined || val === null) {
    return true
  }
  return primitives.some(p => typeof val === p)
}

const hasKey = (obj: any, key: string) => {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

interface Options {
  [SKIP]?: boolean;
  [EQUALS]?: (a: any, b: any) => boolean;
  [key: string]: any;
}

const shallowEquals = (a: any, b: any) => {
  
  if (a === b) {
    return true
  }

  if (isPrimitive(a) || isPrimitive(b)) {
    return a === b
  }

  const [aKeys, bKeys] = [Object.keys(a), Object.keys(b)];
  
  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (let i = 0; i < bKeys.length; i++) {
    let key = bKeys[i]

    if (!hasKey(a, key) || a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

const defaults: Required<Options> = {
  [SKIP]: true,
  [EQUALS]: shallowEquals
}

export interface ObserverInterface {
  (state: any, dispatch: Dispatch<any>, globals: Required<Options>): void;
  [OBSERVER]: boolean;
}

const defaultMapper = (state: any) => state;

type Dispatcher<T> = (dispatch: Dispatch<T>, current: any, previous: any) => void;



/**** EXPORTS START ****/

export const observe = (store: Store, observers: ObserverInterface[], options: Options = {}) => {

  // create globally-applicable options for the given observer set.
  const globals: Required<Options> = [SKIP, EQUALS].reduce((globals, key) => {
    (globals as Required<Options>)[key] = hasKey(options, key) ? options[key] : defaults[key]
    return globals
  }, {...defaults})

  const { dispatch, getState, subscribe } = store;
  const apply = (state: any) => {
    observers.forEach((fn) => { fn(state, dispatch, globals) });
  }
  const listen = () => { apply(getState()) };

  const unsubscribe = subscribe(listen);
  listen();

  return unsubscribe;
}

export const observer = (mapper: (state: any) => any, dispatcher: Dispatcher<any>, locals: Options = {}): ObserverInterface => {
  mapper = mapper || defaultMapper

  let initialized = false;
  let current: any;

  const observer: ObserverInterface = (() => {
    const result: any = (state: any, dispatch: Dispatch<any>, globals: Required<Options>) => {
      const previous: any = current;
      current = mapper(state);
  
      // this branch is run only once, before the Redux reducers
      // return their initial state.
      if (!initialized) {
        initialized = true
        const skip = hasKey(locals, SKIP) ? !!locals[SKIP] : globals[SKIP]
        if (skip) {
          return
        }
      }
  
      const equals = locals[EQUALS] || globals[EQUALS];
  
      if (!equals(current, previous)) {
        dispatcher(dispatch, current, previous)
      }
    }
  
    result[OBSERVER] = true
  
    return result;
  })();

  return observer;
}

/**** EXPORTS END ****/