import React from 'react';

import Switch from '@material-ui/core/Switch';

import { BladeProps } from '../../../blade';
import {
    TabItem, TabsHeaderAndContent
} from '../../../components/bladeLayout/tabsHeaderAndContent';
import { Workgroup } from '../../../dataStore';
import { useStyles } from './workgroupDetails.jss';

export interface WorkgroupDetailsProps {
  workgroup: Workgroup;
}

export interface WorkgroupDetailsActions {
  fetchWorkgroupUsers: () => void;
}

type Props = WorkgroupDetailsProps & WorkgroupDetailsActions & BladeProps;

export const WorkgroupDetails: React.FunctionComponent<Props> = props => {
  const classes = useStyles();

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    props.setDirty(checked);
  };

  const tabs: TabItem[] = ["Properties", "Users", "Sites", "Hardware"].map<
    TabItem
  >(label => ({ label }));
  const content = (selectedTabIndex: number): JSX.Element => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <div className={classes.workgroup}>
            <p>{props.workgroup.name}</p>
            <p>{props.workgroup.id}</p>
            <p>{props.workgroup.parentId}</p>
            <Switch checked={props.isDirty} onChange={handleChange} value="isDirty" />
          </div>
        );

      default:
        return <div>{tabs[selectedTabIndex].label}</div>;
    }
  };
  return <TabsHeaderAndContent tabs={tabs} content={content} />;
};
