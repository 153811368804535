import { denormalize, normalize } from '../../../../utils/extensions';
import { NormalizedTreeItems, TreeItem, TreeState } from '../types';
import { expandItem, getFilteredTree } from './treeExpansion';
import { match } from './treeFilter';

export const addItem = (state: TreeState, id: number, label:string, parentTreeId: string): TreeState => { 
  let newState = {...state};

  const parent = state.items[parentTreeId];
  if (!parent.isExpanded) {
    newState = expandItem(state, parent.treeId);
  }
  
  let childCount = 0;
  let childUnfilteredTreeIndex = 0;
  const parentIndex = newState.unfilteredTree.indexOf(parent.treeId);
  for (var i = parentIndex + 1; i < newState.unfilteredTree.length; i++) {
    const currentTreeId = newState.unfilteredTree[i];
    const currentItem = state.items[currentTreeId];

    if (parent.level === currentItem.level - 1) {
      childCount++;
    }

    if (parent.level >= currentItem.level) {
      break;
    }
  }

  childCount++;
  childUnfilteredTreeIndex = i;
  parent.hasChildren = true;
  
  updateTotalChildCount(state.items, parent);

  const child: TreeItem = {
    modelId: id,
    modelParentId: parent.modelId,
    hasChildren: false,
    isExpanded: false,
    label: label,
    level: parent.level + 1,
    treeId: `${parent.treeId}/${childCount}`,
    treeParentId: parent.treeId,
    flatListIndex: parent.flatListIndex + parent.totalChildCount,
    totalChildCount: 0,
    match: null,
    hasChildrenMatched: false
  };

  match([child], newState.filterValue);

  const items = denormalize(newState.items);
  items
    ._insert(child, child.flatListIndex)
    .filter((_, i) => i > child.flatListIndex)
    .forEach(item => item.flatListIndex++);
  newState.items = normalize(items, "treeId");

  newState.unfilteredTree = 
    [...newState.unfilteredTree._insert(child.treeId, childUnfilteredTreeIndex)];

  newState.filteredTree = getFilteredTree(newState.items, newState.unfilteredTree);

  return newState;
};

const updateTotalChildCount = (items: NormalizedTreeItems, item: TreeItem) => {
  item.totalChildCount++;
  if (item.treeParentId) {
    updateTotalChildCount(items, items[item.treeParentId]);
  }
}