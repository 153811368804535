import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import { Blade } from '../blade';
import { closingCallbackCache } from '../duck/bladeStore';
import { BladeInstance, BladesContextData } from '../duck/types';
import { BladeClosingDialog } from './bladeClosingDialog';
import { useStyles } from './bladeRow.jss';

export interface BladeRowProps {
  blades: BladeInstance[];
  frozenBlades: string[];
  actionAfterClosing: any;
  closingBlades: BladeInstance[];
}

export interface BladeRowActions {
  forceCloseBlades: (actionAfterClosing?: any) => void;
  cancelBladeClose: () => void;
  setBladeNewlyCreateState: (bladeId: string, isNewlyCreated: boolean) => void;
}

type Props = BladeRowProps & BladeRowActions;

const BladesContextValue: BladesContextData = {
  registerBladeClose: (bladeId, canClose, onClose) => {
    closingCallbackCache[bladeId] = { canClose, onClose };
  },
};

const closingBladesCallback = (closingBlades: BladeInstance[]) => {
  closingBlades.forEach(b => {
    const bladeCallbacks = closingCallbackCache[b.id];
    if (bladeCallbacks && bladeCallbacks.onClose) {
      bladeCallbacks.onClose();
    }
  });
};

export const BladesContext = React.createContext<BladesContextData>(BladesContextValue);

const BladeRow = (props: Props) => {
  const bladeContainerRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const anyNewBlade = props.blades.some(b => b.newlyCreated);

  // destructuring actions to set in deps
  const { setBladeNewlyCreateState, forceCloseBlades } = props;

  // effect for scrolling to a newly opened blade
  useEffect(() => {
    if (bladeContainerRef.current) {
      const containerDiv = bladeContainerRef.current;
      if (containerDiv.childElementCount > 1) {
        // last element is always a space buffer div so we need the child before last
        const lastChild = containerDiv.children[
          containerDiv.childElementCount - 2
        ] as HTMLDivElement;
        const isNew = lastChild.getAttribute('data-new') === 'true';
        const newBladeId = lastChild.getAttribute('data-key');
        if (isNew && newBladeId) {
          containerDiv.scrollLeft = lastChild.offsetLeft - containerDiv.offsetLeft;
          setTimeout(() => setBladeNewlyCreateState(newBladeId, false));
        }
      }
    }
  }, [anyNewBlade, setBladeNewlyCreateState]);

  // check every blade to see if it can close
  const closingBladesState = props.closingBlades.map(b => ({
    blade: b,
    canClose: closingCallbackCache[b.id] ? closingCallbackCache[b.id].canClose() : true,
  }));

  // if we have at least one that cannot be closed we will need to show the dialog
  const atLeastOneBladeCantClose = closingBladesState.reduce<boolean>(
    (atLeastOne, blade) => atLeastOne || !blade.canClose,
    false
  );

  const forceCloseAll = useCallback(() => {
    closingBladesCallback(props.closingBlades);
    forceCloseBlades(props.actionAfterClosing);
  }, [forceCloseBlades, props.closingBlades, props.actionAfterClosing]);

  useEffect(() => {
    // if all the blades can be safely closed then close them
    if (closingBladesState.length > 0 && !atLeastOneBladeCantClose) {
      closingBladesCallback(props.closingBlades);
      forceCloseBlades(props.actionAfterClosing);
    }
    // there is a case where some component wishes to close some blades and dispatch an action after, but those blades are not opened.
    // this would mean that closingBlades from Redux state is empty but we still need to dispatch that afterClosingAction
    if (closingBladesState.length === 0 && props.actionAfterClosing) {
      // we call the same forceCloseBades action because this also clears the actionAfterClosing from Redux state
      forceCloseBlades(props.actionAfterClosing);
    }
  }, [
    closingBladesState,
    forceCloseBlades,
    props.actionAfterClosing,
    props.closingBlades,
    atLeastOneBladeCantClose,
  ]);

  const highlights = props.frozenBlades && props.frozenBlades.length > 0;

  const blades = props.blades.map((b, i) => {
    return (
      <div
        className={classNames({
          [classes.faded]: highlights && props.frozenBlades.indexOf(b.id) > -1,
        })}
        data-key={b.id}
        data-new={b.newlyCreated ? 'true' : 'false'}
        key={b.id}
        style={{ zIndex: 100 - i }}
      >
        <Blade instance={b} />
      </div>
    );
  });

  blades.push(
    <div key="BUFFER_SPACE">
      <div className={classes.bufferSpace} />
    </div>
  );

  return (
    <div className={classes.bladeContainer} ref={bladeContainerRef}>
      <BladesContext.Provider value={BladesContextValue}>{blades}</BladesContext.Provider>
      {props.closingBlades.length > 0 && (
        <BladeClosingDialog
          bladesToClose={closingBladesState}
          onCloseAll={forceCloseAll}
          onCancel={props.cancelBladeClose}
          opened={atLeastOneBladeCantClose}
        />
      )}
    </div>
  );
};

const MemoBladeRow = React.memo(BladeRow);

export { MemoBladeRow as BladeRow };
