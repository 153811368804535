export const areaName = 'blade';

export interface BladeState {
  items: NormalizedBlades;
  itemsForClosing: string[];
  postClosingAction: any;
}

export interface NormalizedBlades {
  [id: string]: BladeInstance;
}

export interface BladeInstance {
  parentId: string;
  id: string;
  type: string;
  props: any;
  title: string;
  newlyCreated: boolean;

  frozen: boolean;
}

export interface BladeConfig<TProps> {
  bladeType: string;
  size: { defaultWidth: number; minWidth: number };
  title: (props: TProps) => string;
  id: (props: TProps) => string;
  allowMultipleInstances: boolean;
  numberOfInstances?: number;
}

export interface BladeProps {
  bladeId: string;
  isDirty: boolean;
  setDirty: (dirty: boolean) => void; 
  width: number;
}

export interface BladeClosingCallbackCache {
  [bladeId: string]: {
    canClose: () => boolean;
    onClose?: () => void;
  };
}

export interface BladesContextData {
  registerBladeClose: BladeClosingRegister
}

export type BladeClosingRegister = (bladeId: string, canClose: () => boolean, onClose?: () => void) => void;


export enum ActionTypes {
  MARK_SHOULD_CLOSE = 'MARK_SHOULD_CLOSE',
  MARK_WAIT_CLOSE = 'MARK_WAIT_CLOSE',
  CREATE_BLADE = 'CREATE_BLADE',
  CLOSE_BLADE = 'CLOSE_BLADE',
  CLOSE_CHILDREN_BLADES = 'CLOSE_CHILDREN_BLADES',
  CANCEL_BLADE_CLOSE = 'CANCEL_BLADE_CLOSE',
  FORCE_CLOSE_BLADE = 'FORCE_CLOSE_BLADE',
  SET_BLADE_NEWLY_CREATED_STATE = 'SET_BLADE_NEWLY_CREATED_STATE',
  FREEZE_BLADES = 'HIGHLIGHT_BLADES',
  CLEAR_FROZEN_BLADES = 'CLEAR_HIGHLIGHT_BLADES',
  SET_TITLE = 'SET_TITLE',
  HARD_CLEAR_ALL_BLADES = 'HARD_CLEAR_ALL_BLADES' 
}
