import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';

import { MAD } from '../../..';
import { openBlade } from '../../blade';
import { AdminAppToolbar, AdminAppToolbarActions } from './adminAppToolbar';

const mapDispatchToProps: MapDispatchToProps<AdminAppToolbarActions, any> = (
  dispatch: Dispatch
) => ({
  openBlade: (type: string, props: object) => openBlade('', type, props, dispatch),
  exitApp: () => MAD.homeFn()
});

export const AdminAppToolbarContainer = connect(
  null,
  mapDispatchToProps
)(AdminAppToolbar);