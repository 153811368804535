import React from 'react';

import CheckboxComponent from '@material-ui/core/Checkbox';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';
import IndeterminateCheckBoxOutlined from '@material-ui/icons/IndeterminateCheckBoxOutlined';

import { CheckboxState, SelectedItem } from './duck/types';

interface SelectionCheckboxProps {
  id: string;
  item: SelectedItem;
}

export const SelectionCheckbox = ({ item, id }: SelectionCheckboxProps) => {
  const color = item && item.selected ? 'secondary' : 'primary';

  return (
    <CheckboxComponent
      disableRipple={true}
      color={color}
      checkedIcon={getIcon(item)}
      checked={item !== undefined}
      style={{ paddingLeft: '8px' }}
    />
  );
};

const getIcon = (item: SelectedItem) => {
  if (!item) {
    return <CheckBox />;
  }

  switch (item.state) {
    case CheckboxState.SelectedWithChildren:
      return item.isIndeterminate ? <IndeterminateCheckBox /> : <CheckBox />;
    case CheckboxState.Selected:
      return item.isIndeterminate ? <IndeterminateCheckBoxOutlined /> : <CheckBoxOutlined />;
  }
};
