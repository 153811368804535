import { TextField, Theme } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { useTheme } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { isNumber } from 'util';

export interface VirtualizedSelectProps {
  items: any[];
  itemSize: number;
  noValueLabel?: string;
  maxHeight?: number;
  rowRenderer: (
    items: any[],
    callback?: (value: any) => void
  ) => ({ index, style }: { index: number; style: any }) => any;
}

export const VirtualizedSelect: React.FunctionComponent<SelectProps & VirtualizedSelectProps> = (
  props: SelectProps & VirtualizedSelectProps
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(props.value);
  const [height, setHeight] = useState(0);
  const theme: Theme = useTheme();

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value, value]);

  useEffect(() => {
    const maxHeight = props.maxHeight ? props.maxHeight - theme.spacing(2) : 234;
    const virtualSize = props.items.length * props.itemSize;
    if (virtualSize > maxHeight) {
      setHeight(maxHeight);
    } else {
      setHeight(virtualSize);
    }
  }, [props.items.length, props.itemSize, props.maxHeight, theme]);

  const onClickCallback = useCallback((newValue: any) => {
    setIsOpen(false);
    setValue(newValue);
  }, []);

  return (
    <TextField
      select
      fullWidth
      label={props.placeholder}
      SelectProps={{
        MenuProps: props.MenuProps,
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        renderValue: (selected: any) => {
          let item = null;
          if (isNumber(selected)) {
            item = props.items.find(e => e.id === selected);
          }

          if (!selected) {
            return props.noValueLabel;
          }

          if (props.renderValue && item) {
            return props.renderValue(item);
          }

          if (props.renderValue && !!selected) {
            return props.renderValue(selected);
          }

          return selected;
        },
      }}
      value={value}
    >
      <FixedSizeList
        height={height}
        width={'100%'}
        itemCount={props.items.length}
        itemSize={props.itemSize}
      >
        {props.rowRenderer(props.items, onClickCallback)}
      </FixedSizeList>
      ); }}
    </TextField>
  );
};
