import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    helpDialog: {
      width: '100%',
      overflowX: 'hidden',
      '& img': {
        maxWidth: '100%',
      },
      '& td': {
        textAlign: 'left',
      },
      '& a': {
        color: '#1976d2',
      },
    },
  });

export const useStyles = makeStyles(styles);
