import { FastField, Field, FieldProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { VirtualizedSelect } from 'app/components/forms/virtualizedSelect';
import { GroupBox } from '../../../../components';
import { PlayerType } from '../../../../dataStore';
import { NLocationList, NStation } from '../../../../dataStore/types';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { useStyles } from './device.jss';

export interface DeviceDetailsProps {
  locations: NLocationList[];
  stations: NStation[];
  openedFromLocation?: number;
  deviceSetLocation: (locationId: number) => void;
}

export const DeviceDetails = (props: DeviceDetailsProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [editableSerial, setEditableSerial] = useState(false);
  const [presetStation, setPresetStation] = useState(true);

  const locationSelectRenderValue = useCallback(
    (value: any) => {
      return (
        <>
          <div className={classes.locationNumber}>{value.oracleNumber}</div>
          <div className={classes.locationName}>{value.name}</div>
        </>
      );
    },
    [classes.locationNumber, classes.locationName]
  );

  const locationSelectRowRenderer = useCallback(
    ({ field, form }: FieldProps) => {
      return (items: NLocationList[], callback?: (value: any) => void) => {
        return ({ index, style }: { index: number; style: any }) => {
          const location = items[index];

          return (
            <MenuItem
              style={style}
              key={location.id}
              value={location.id}
              disableGutters={true}
              classes={{ root: classes.locationMenuItem }}
              onClick={e => {
                if (!location) {
                  return;
                }
                form.setFieldValue(field.name, location.id);
                form.setFieldValue('locationName', location.name);
                form.setFieldValue('locationNumber', location.oracleNumber);
                props.deviceSetLocation(location.id);
                if (callback) {
                  callback(location);
                }
              }}
            >
              {locationSelectRenderValue(location)}
            </MenuItem>
          );
        };
      };
    },
    [props, classes.locationMenuItem, locationSelectRenderValue]
  );

  const stationSelectRowRenderer = useCallback(({ field, form }: FieldProps) => {
    return (items: NStation[], callback?: (value: any) => void) => {
      return ({ index, style }: { index: number; style: any }) => {
        const station = items[index];
        if (form.initialValues.stationId === 0 && index === 0) {
          return (
            <MenuItem
              style={style}
              key={0}
              value={0}
              onClick={e => {
                form.setFieldValue(field.name, 0);
                form.setFieldValue("stationName", station.name);
                form.setFieldTouched(field.name, true);

                if (callback) {
                  callback(0);
                }
              }}
            >
              <em>None</em>
            </MenuItem>
          );
        }

        

        return (
          <MenuItem
            style={style}
            key={station.id}
            value={station.id}
            onClick={e => {
              form.setFieldValue(field.name, station.id);
              form.setFieldValue("stationName", station.name);
              form.setFieldTouched(field.name, true);

              if (callback) {
                callback(station);
              }
            }}
          >
            {station.name}
          </MenuItem>
        );
      };
    };
  }, []);

  const stationSelectRowValue = useCallback((value: any) => {
    return <>{value ? value.name : 'None'}</>;
  }, []);

  return (
    <GroupBox title="Device">
      <div className={classes.tabContent}>
        {!props.openedFromLocation && (
          <Field
            name="locationId"
            render={({ field, form }: FieldProps) => (
              <FormControl className={classes.selectMargin} style={{ flexGrow: 1 }}>
                <VirtualizedSelect
                  classes={{ select: classes.locationSelect }}
                  value={field.value}
                  placeholder={t('newnity.device.edit.location')}
                  autoComplete="something"
                  MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                  maxHeight={250}
                  items={props.locations}
                  itemSize={56}
                  renderValue={locationSelectRenderValue}
                  rowRenderer={locationSelectRowRenderer({ field, form })}
                ></VirtualizedSelect>

                <FormHelperText error={validateEmptyField(form, field)}>
                  {helperText(form, field)}
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
        <Field
          name="stationId"
          render={({ field, form }: FieldProps) => {
            const defaultStation = props.stations.filter(x => x.isDefault);

            if (defaultStation.length && form.initialValues.id === 0 && presetStation) {
              form.setFieldValue(field.name, defaultStation[0].id);
              setPresetStation(false);
            }

            return (
              <FormControl className={classes.selectMargin} style={{ flexGrow: 1 }}>
                <VirtualizedSelect
                  value={field.value}
                  placeholder={t('newnity.device.edit.station')}
                  itemSize={48}
                  maxHeight={250}
                  autoComplete="something"
                  MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                  items={[0, ...props.stations]}
                  rowRenderer={stationSelectRowRenderer({ field, form })}
                  renderValue={stationSelectRowValue}
                  noValueLabel="None"
                ></VirtualizedSelect>
              </FormControl>
            );
          }}
        />
        <FastField
          name="id"
          render={({ field }: FieldProps) => (
            <TextField
              {...field}
              label={t('newnity.device.edit.number')}
              margin="normal"
              disabled
            />
          )}
        />
        <FastField
          name="name"
          render={({ field, form }: FieldProps) => (
            <TextField
              {...field}
              label={t('newnity.device.edit.name')}
              margin="normal"
              error={form.errors[field.name] !== undefined}
              helperText={form.errors[field.name]}
            />
          )}
        />
        <FastField
          name="deviceTypeId"
          render={({ field }: FieldProps) => (
            <FormControl className={classes.selectMargin}>
              <InputLabel>{t('newnity.device.edit.deviceTypeId')}</InputLabel>
              <Select {...field}>
                <MenuItem value={PlayerType.Android}>{t('newnity.android')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Field
          name="serialNumber"
          render={({ field, form }: FieldProps) => (
            <TextField
              {...field}
              inputProps={{ maxLength: 180 }}
              label={t('newnity.device.edit.serialNumber')}
              margin="normal"
              disabled={!editableSerial}
              error={form.errors[field.name] !== undefined}
              helperText={form.errors[field.name]}
              onChange={e => {
                form.setFieldValue(field.name, e.currentTarget.value.toUpperCase());
              }}
              // eslint-disable-next-line
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={t(
                        editableSerial
                          ? 'newnity.device.edit.serialNumber.unlocked.tooltip'
                          : 'newnity.device.edit.serialNumber.locked.tooltip'
                      )}
                    >
                      <IconButton
                        onClick={() => {
                          setEditableSerial(!editableSerial);

                          if (editableSerial) {
                            form.setFieldValue(field.name, form.initialValues[field.name]);
                            form.setFieldTouched(field.name, true);
                          }
                        }}
                      >
                        {editableSerial ? <LockOpen /> : <Lock />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <FastField
          name="salesOrderNumber"
          render={({ field }: FieldProps) => (
            <TextField
              {...field}
              inputProps={{ maxLength: 255 }}
              label={t('newnity.device.edit.salesOrderNumber')}
              margin="normal"
            />
          )}
        />
        <FastField
          name="description"
          render={({ field }: FieldProps) => (
            <TextField
              {...field}
              inputProps={{ maxLength: 255 }}
              label={t('newnity.device.edit.description')}
              multiline={true}
              margin="normal"
            />
          )}
        />
      </div>
    </GroupBox>
  );
};
