import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    form: {
      padding: "16px",
      paddingBottom: "0px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowY: "auto"
    },
    fieldsContainer: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto"
    },
    flex1: {
      flexGrow: 1
    },
    formField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formButton: {
      marginLeft: theme.spacing(2)
    }
  });
};

export const useStyles = makeStyles(styles);