import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    list: {
      overflowX: 'hidden',
      '&-mainToolbar': {
        flexGrow: 1,
        overflow: 'hidden',
      },
    },
    companyName: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: { marginLeft: '8px' },
    companyJump: {
      marginLeft: '16px',
      marginRight: 0 - theme.spacing(6),
    },
  });

export const useStyles = makeStyles(styles);
