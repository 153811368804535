import { merge } from 'lodash';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { DataStoreSelectors, EntityType } from '../../dataStore';
import { HasDeviceSettings } from '../../dataStore/types';
import { fetchEntity } from '../../newnity/duck/thunks';
import { WifiSecurity } from '../../newnity/duck/types';
import { DeviceSettings, DeviceSettingsActions, DeviceSettingsProps } from './deviceSettings';

export interface DeviceSettingsContainerProps {
  entityId: number;
  entityType: EntityType;
  parentEntityId: number;
  isSaving: boolean;
}

export const entityTypeMap = {
  [EntityType.NCompany]: EntityType.NParentCompanyWorkgroup,
  [EntityType.NLocation]: EntityType.NCompany,
  [EntityType.NDevice]: EntityType.NLocation,
};

export const defaultSettings = {
  entityId: 0,
  entityType: '' as EntityType,
  enableSoundVolumeOverride: false,
  audioVolume: '100',
  enableNetworkSettingsOverride: false,
  enableWifi: false,
  enableEthernet: false,
  enableProxy: false,
  wifiSettings: {
    id: 0,
    networkEnabled: false,
    dhcpEnabled: 1,
    ipAddress: '',
    ipMask: '',
    ipGateway: '',
    dnsIp1: '',
    dnsIp2: '',
    ssId: '',
    securityKeyType: WifiSecurity.None,
    securityKey: '',
    verifySecurityKey: '',
  },
  ethernetSettings: {
    id: 0,
    networkEnabled: true,
    dhcpEnabled: 1,
    ipAddress: '',
    ipMask: '',
    ipGateway: '',
    dnsIp1: '',
    dnsIp2: '',
  },
  proxySettings: {
    idProxySettings: 0,
    proxyEnabled: false,
    proxyAddress: '',
    proxyPort: 0,
    proxyUser: '',
    proxyPassword: '',
  },
};

const mapStateToProps: MapStateToProps<DeviceSettingsProps, DeviceSettingsContainerProps, any> = (
  state,
  ownProps
) => {
  let entity = DataStoreSelectors.getDataStoreItem(
    state,
    ownProps.entityType,
    ownProps.entityId
  ) as HasDeviceSettings;

  let parentEntity: HasDeviceSettings | undefined;

  parentEntity = DataStoreSelectors.getDataStoreItem(
    state,
    (entityTypeMap as any)[ownProps.entityType],
    ownProps.parentEntityId
  ) as HasDeviceSettings;

  parentEntity = {
    ...parentEntity,
    deviceSettings: merge({}, defaultSettings, parentEntity ? parentEntity.deviceSettings : {})
  }

  entity = {
    ...entity,
    deviceSettings: merge({}, defaultSettings, entity ? entity.deviceSettings : {})
  }

  return {
    entityType: ownProps.entityType,
    entityId: ownProps.entityId,
    isSaving: ownProps.isSaving,
    entity,
    parentEntity,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DeviceSettingsActions,
  DeviceSettingsContainerProps
> = (dispatch, ownProps) => {
  return {
    fetchParentEntity: () => {
      dispatch<any>(
        fetchEntity(
          (entityTypeMap as any)[ownProps.entityType] as EntityType,
          ownProps.parentEntityId
        )
      );
    },
    fetchEntity: () => {
      if (ownProps.entityId > 0) {
        dispatch<any>(fetchEntity(ownProps.entityType, ownProps.entityId));
      }
    },
  };
};

export const DeviceSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSettings);
