import { debounce } from 'debounce';
import { Form, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BladeProps, useBladeClosing } from '../../../../blade';
import { FooterSubmit } from '../../../../components/bladeLayout/footerSubmit';
import { TabsHeaderAndContent } from '../../../../components/bladeLayout/tabsHeaderAndContent';
import { validateDeviceSettings } from '../../../../components/deviceSettings/deviceSettings';
import { DeviceSettingsContainer } from '../../../../components/deviceSettings/deviceSettingsContainer';
import { EntityType, NCompany } from '../../../../dataStore';
import { FetchState } from '../../../duck/types';
import { Loading } from '../../search/results/loading';
import { useStyles } from './company.jss';
import { CompanyDetailsFields } from './companyDetailsFields';

export interface CompanyInfoProps {
  isFetching: boolean;
  company: NCompany;
  savingData: FetchState;
}

export interface CompanyInfoActions {
  saveCompany: (companyData: NCompany) => void;
  closeBlade: () => void;
  upsertEntity: (company: NCompany) => void;
}

type Props = CompanyInfoProps & CompanyInfoActions & BladeProps;

const tabLabels = [
  { label: i18next.t('newnity.edit.company.tab.title') },
  { label: i18next.t('device.settings.title') },
];

export const CompanyInfo: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  const classes = useStyles();

  const { setDirty, closeBlade } = props;

  const conditionalDirty = () => {
    // TODO: Improve dirty logic
    if (props.isDirty) {
      return;
    }

    props.setDirty(true);
  };

  useEffect(() => {
    if (props.savingData.fetchCompleted && props.savingData.fetchError === '') {
      setDirty(false);
    }
  }, [props.savingData.fetchError, props.savingData.fetchCompleted, setDirty]);

  useBladeClosing(props.bladeId, () => !props.isDirty && !props.savingData.fetchError);

  const content = useCallback(
    tabIndex => {
      switch (tabIndex) {
        case 0:
          return <CompanyDetailsFields {...props} />;
        case 1:
          return (
            <DeviceSettingsContainer
              entityId={props.company.id}
              entityType={EntityType.NCompany}
              parentEntityId={props.company.parentId}
              isSaving={props.savingData.isFetching}
            />
          );
        default:
          return <div>Really?</div>;
      }
    },
    [props]
  );

  const footer = useCallback(
    () => (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={closeBlade}
        submitButtonLabel={t(props.company.id ? 'footer.save' : 'footer.create')}
        submit={() => {}}
        submitInProgress={props.savingData.isFetching}
      />
    ),
    [props.company.id, props.savingData.isFetching, closeBlade, t]
  );

  return props.isFetching ? (
    <Loading />
  ) : (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values: NCompany) => {
        const errors: FormikErrors<NCompany> = {};

        if (!values.name) {
          errors.name = t('newnity.company.field.error');
        }

        if (!values.number) {
          errors.number = t('newnity.company.field.error');
        }

        validateDeviceSettings(values, errors);

        return errors;
      }}
      initialValues={props.company}
      onSubmit={(values: NCompany, actions: FormikActions<NCompany>) => {
        props.saveCompany(values);
      }}
      render={(formikBag: FormikProps<NCompany>) => {
        return (
          <Form
            className={classes.form}
            onChange={props.isDirty ? undefined : debounce(conditionalDirty, 250)}
            autoComplete="new-password"
          >
            <TabsHeaderAndContent
              content={content}
              tabs={tabLabels}
              footer={footer}
              isBusy={props.isFetching || props.savingData.isFetching}
            />
          </Form>
        );
      }}
    />
  );
};
