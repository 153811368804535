import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import {
    ItemWithParent, SelectionMode, TreeActions, TreeActionTypes, TreeReducer, TreeState
} from '../../components/tree';
import { CheckboxState } from '../../components/tree/duck/types';
import { DataStoreActions, DataStoreActionTypes, EntityType } from '../../dataStore';
import * as Actions from './actions';
import { ActionTypes, areaName, WorkgroupState, WorkgroupTreeActionNames } from './types';

type WorkgroupActions =
  | ActionType<typeof Actions>
  | ActionType<typeof TreeActions>
  | ActionType<typeof DataStoreActions>;

const initialState: WorkgroupState = {
  favoriteItems: [],
  workgroupsTree: {
    treeName: 'workgroupsTree',
    items: {},
    expandedDefault: [],
    expandedBeforeFilter: [],
    unfilteredTree: [],
    filteredTree: [],
    selectedItems: {},
    selectionMode: SelectionMode.SingleSelection,
    itemActions: {},
    filterValue: '',
  },
  pickSelection: {
    on: false,
    selectedWorkgroupId: 0,
    selectedTreeItemId: '',
    previousTreeSelection: {},
  },
  createWorkgroup: {
    isSaving: false,
    savingError: "",
  },
};

export const workgroupReducer: Reducer<WorkgroupState, WorkgroupActions> = (
  state: WorkgroupState = initialState,
  action: WorkgroupActions
) => {
  switch (action.type) {
    case ActionTypes.CREATE_WORKGROUP_REQUEST: {
      return {
        ...state,
        createWorkgroup: {
          ...state.createWorkgroup,
          isSaving: true,
          savingError: '',
        },
      };
    }
    case ActionTypes.CREATE_WORKGROUP_ERROR: {
      return {
        ...state,
        createWorkgroup: {
          ...state.createWorkgroup,
          isSaving: false,
          savingError: action.payload.err,
        },
      };
    }
    case ActionTypes.CREATE_WORKGROUP_SUCCESS:
      const newState = updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.addItem(
          state.workgroupsTree,
          action.payload.id,
          action.payload.name,
          action.payload.parentTreeId
        )
      );

      return {
        ...newState,
        createWorkgroup: {
          savingError: '',
          isSaving: false,
        },
        pickSelection: action.payload.createAnother
          ? newState.pickSelection
          : {
              on: false,
              selectedWorkgroupId: 0,
              selectedTreeItemId: '',
              previousTreeSelection: {},
            },
      };

    case ActionTypes.CREATE_WORKGROUP_CLEANUP: {
      return {
        ...state,
        createWorkgroup: {
          isSaving: false,
          savingError: '',
          brandItems: [],
          templateItems: [],
          workgroupParent: 0,
        },
        pickSelection: {
          on: false,
          selectedWorkgroupId: 0,
          selectedTreeItemId: '',
          previousTreeSelection: {},
        },
      };
    }

    case DataStoreActionTypes.ENTITY_LIST_SUCCESS: {
      return action.payload.entityType !== EntityType.Workgroup ? state : {
        ...state,
        workgroupsTree: TreeReducer.initTree(
          'workgroupsTree',
          action.payload.items as ItemWithParent[],
          SelectionMode.MultipleWithInheritanceSelection,
          2
        ),
      };
    }
    case ActionTypes.WORKGROUP_FAVORITE_REQUEST: {
      const treeItemId = Object.keys(state.workgroupsTree.items).find(
        k => state.workgroupsTree.items[k].modelId === action.payload.workgroupId
      );
      if (treeItemId) {
        const newWorkgroupTreeState = { ...state.workgroupsTree };
        newWorkgroupTreeState.itemActions = {
          ...newWorkgroupTreeState.itemActions,
          [treeItemId]: {
            ...newWorkgroupTreeState.itemActions[treeItemId],
            [WorkgroupTreeActionNames.ToggleFavorite]: {
              inProgress: true,
              error: null,
            },
          },
        };
        return { ...state, workgroupsTree: newWorkgroupTreeState };
      }
      return state;
    }
    case ActionTypes.WORKGROUP_FAVORITE_SUCCESS: {
      const treeItemId = Object.keys(state.workgroupsTree.items).find(
        k => state.workgroupsTree.items[k].modelId === action.payload.workgroupId
      );
      if (treeItemId) {
        const itemActions = state.workgroupsTree.itemActions[treeItemId];
        itemActions[WorkgroupTreeActionNames.ToggleFavorite].inProgress = false;
      }
      return {
        ...state,
        favoriteItems: [...state.favoriteItems, action.payload.workgroupId],
      };
    }
    case ActionTypes.WORKGROUP_UNFAVORITE_SUCCESS: {
      const index = state.favoriteItems.indexOf(action.payload.workgroupId);
      const favoriteItems = [...state.favoriteItems];
      favoriteItems.splice(index, 1);
      return { ...state, favoriteItems };
    }
    case TreeActionTypes.TREE_EXPAND_ITEM: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.expandItem(state.workgroupsTree, action.payload.treeItemId)
      );
    }
    case TreeActionTypes.TREE_COLLAPSE_ITEM: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.collapseItem(
          state.workgroupsTree,
          action.payload.treeItemId
        )
      );
    }
    case TreeActionTypes.TREE_COLLAPSE_ALL: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.collapseAll(state.workgroupsTree)
      );
    }
    case TreeActionTypes.TREE_EXPAND_ALL: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.expandAll(state.workgroupsTree)
      );
    }
    case TreeActionTypes.TREE_SELECT_ITEM: {
      if (state.pickSelection.on) {
        return state;
      }
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.selectItem(state.workgroupsTree, action.payload.treeItemId)
      );
    }
    case TreeActionTypes.TREE_UNSELECT_ITEM: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.unselectItem(
          state.workgroupsTree,
          action.payload.treeItemId
        )
      );
    }
    case TreeActionTypes.TREE_SELECT_ALL: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.selectAll(state.workgroupsTree)
      );
    }
    case TreeActionTypes.TREE_UNSELECT_ALL: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.unselectAll(state.workgroupsTree)
      );
    }
    case TreeActionTypes.TREE_FILTER: {
      return updateWorkgroupTreeState(state, areaName, () =>
        TreeReducer.filter(state.workgroupsTree, action.payload.filterValue)
      );
    }
    case ActionTypes.WORKGROUP_LIST_ENTER_PICK_MODE: {
      const pickSelection = { ...state.pickSelection };
      const workgroupsTree = { ...state.workgroupsTree };
      pickSelection.on = true;
      pickSelection.previousTreeSelection = workgroupsTree.selectedItems;
      workgroupsTree.selectedItems = pickSelection.selectedTreeItemId
        ? {
            [pickSelection.selectedTreeItemId]: {
              selected: true,
              isIndeterminate: false,
              selectedChildrenCount: 0,
              state: CheckboxState.Selected,
            },
          }
        : {};
      workgroupsTree.selectionMode = SelectionMode.SingleSelection;
      return { ...state, pickSelection, workgroupsTree };
    }
    case ActionTypes.WORKGROUP_LIST_LEAVE_PICK_MODE: {
      const workgroupsTree = { ...state.workgroupsTree };
      workgroupsTree.selectedItems = state.pickSelection.previousTreeSelection;
      workgroupsTree.selectionMode =
        SelectionMode.MultipleWithInheritanceSelection;

      const pickSelection = { ...state.pickSelection };

      //we have a valid selection
      if (action.payload.treeItemId !== '') {
        pickSelection.selectedWorkgroupId = action.payload.workgroupId;
        pickSelection.selectedTreeItemId = action.payload.treeItemId;
      }
      pickSelection.on = false;
      pickSelection.previousTreeSelection = {};
      return { ...state, pickSelection, workgroupsTree };
    }
    default:
      return state;
  }
};

const updateWorkgroupTreeState = (
  state: WorkgroupState,
  area: string,
  fn: () => TreeState
) => {
  if (area !== areaName) {
    return state;
  }
  const newTreeState = fn();
  return { ...state, workgroupsTree: newTreeState };
};
