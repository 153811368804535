import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const styles = (theme: Theme) => {
  return createStyles({
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center',
    },
    form: {
      paddingBottom: "0px",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflow: 'auto'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '50%',
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    verticalContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    horizontalContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    },
    formField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formButton: {
      width: 250,
      justifyContent: 'left',
    },
    iconLeft: {
      marginRight: theme.spacing(2),
    },
  });
};

export const useStyles = makeStyles(styles);
