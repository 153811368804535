import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
  headerButton: {
    marginRight: theme.spacing(1)
  },
  headerButtonIconSmall: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
});

export const useStyles = makeStyles(styles);