import { action } from 'typesafe-actions';

import { ActionTypes, BladeInstance } from './types';

export const createBlade = (blade: BladeInstance) => action(ActionTypes.CREATE_BLADE, blade);
export const closeBlade = (bladeId?: string | string[], actionAfterClosing?: any) =>
  action(ActionTypes.CLOSE_BLADE, { bladeId, actionAfterClosing });
export const closeChildrenBlades = (bladeId: string, actionAfterClosing?: any) =>
  action(ActionTypes.CLOSE_CHILDREN_BLADES, { bladeId, actionAfterClosing });
export const cancelCloseBlade = () => action(ActionTypes.CANCEL_BLADE_CLOSE);
export const forceCloseBlade = (bladeId?: string | string[]) =>
  action(ActionTypes.FORCE_CLOSE_BLADE, bladeId);
export const setBladeNewlyCreateState = (bladeId: string, isNewlyCreated: boolean) =>
  action(ActionTypes.SET_BLADE_NEWLY_CREATED_STATE, {
    bladeId,
    isNewlyCreated,
  });
export const freezeBlades = (bladeIds: string[]) => action(ActionTypes.FREEZE_BLADES, bladeIds);
export const clearFrozenBlades = (bladeIds?: string[]) =>
  action(ActionTypes.CLEAR_FROZEN_BLADES, bladeIds);
export const setTitle = (bladeId: string, value: string) =>
  action(ActionTypes.SET_TITLE, { bladeId, value });
export const hardClearAllBlades = () => action(ActionTypes.HARD_CLEAR_ALL_BLADES);
