import { createSelector } from 'reselect';
import { User } from '../../dataStore';
import { EntityState } from '../../dataStore/types';
import { areaName, UsersState } from './types';

const selectUsersState = (state: any): UsersState =>
  state[areaName] as UsersState;

export const selectCurrentWorkgroupUsers = createSelector(
  [selectUsersState],
  (state: UsersState) => state.currentWorkgroupUsers
);

export const selectWorkgroupUsers = createSelector(
  [selectCurrentWorkgroupUsers],
  (users: EntityState<User>) => {
    return Object.keys(users.items).map<User>(
      key => users.items[Number(key)]
    );
  }
);

export const selectWorkgroupUserRemove = createSelector(
  [selectUsersState],
  (state: UsersState) => state.removeUser
);

export const selectWorkgroupUsersFetching = createSelector(
  [selectUsersState],
  (state: UsersState) => state.currentWorkgroupUsers.itemsFetching.isFetching
);

export const addUserSaving = createSelector(
  [selectUsersState],
  (state: UsersState) => state.addUser.isSaving
);

export const addUserSaved = createSelector(
  [selectUsersState],
  (state: UsersState) => state.addUser.savingComplete
);

export const userSaving = createSelector(
  [selectUsersState],
  (state: UsersState) => state.saveUser.isSaving
);

export const userSaved = createSelector(
  [selectUsersState],
  (state: UsersState) => state.saveUser.savingComplete
);

export const userSaveError = createSelector(
  [selectUsersState],
  (state: UsersState) => state.saveUser.savingError
);

export const userIsEditing = createSelector(
  [selectUsersState],
  (state: UsersState) => state.editUser.isEditing
)

export const getWelcomeEmailTemplates = createSelector(
  [selectUsersState],
  (state: UsersState) => state.welcomeEmailTemplates.welcomeEmailTemplatesList
)