import { connect, MapStateToProps } from 'react-redux';

import { FromLayoutToolbarProps } from '../layout';
import { Toolbar, ToolbarProps } from './toolbar';

const mapStateToProps: MapStateToProps<ToolbarProps, FromLayoutToolbarProps, any> = (
  state,
  ownProps
) => {
  return {
    newnityEnabled: state.newnity.enabled,
    toolbarExpanded: ownProps.toolbarExpanded,
  };
};

export const ToolbarContainer = connect(
  mapStateToProps,
  null
)(Toolbar);
