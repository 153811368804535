import { denormalize } from '../../utils/extensions';
import { areaName, BladeInstance, BladeState, NormalizedBlades } from './types';

export const selectActiveBlades = (state: any): NormalizedBlades =>
  (state[areaName] as BladeState).items;

export const selectActiveBladesDenormalized = (state: any): BladeInstance[] => {
  const items = selectActiveBlades(state);
  return denormalize(items); 
};

export const selectBladeById = (state: any, bladeId: string): BladeInstance => {
  const items = selectActiveBlades(state);
  return items[bladeId];
};

export const selectFrozenBlades = (state: any) => {
  const items = selectActiveBladesDenormalized(state);
  return items.filter(b=>b.frozen).map(b=>b.id);
}

export const selectClosingBlades = (state: any) => {
  const bladeState = state[areaName] as BladeState;
  return bladeState.itemsForClosing.map<BladeInstance>(id => bladeState.items[id]);
};

export const selectPostClosingAction = (state: any) => {
  const bladeState = state[areaName] as BladeState;
  return bladeState.postClosingAction;
}