import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    gridContainer: {
      width: "100%",
      flexGrow: 1
    },
    defaultRow: {
      '& svg': {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    }
  });
};

export const useStyles = makeStyles(styles);
