import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './footerSubmit.jss';

export interface FooterSubmitProps {
  submit?: () => void;
  submitInProgress: boolean;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  hasCancelButton?: boolean;
  cancel?: () => void;
  onSubmitPress?: () => void;
  leftSideContent?: () => JSX.Element;
}

export const FooterSubmit: React.FunctionComponent<FooterSubmitProps> = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.leftsideContent}>{props.children}</div>
      {props.hasCancelButton && (
        <Button className={classes.button} onClick={props.cancel}>
          {props.cancelButtonLabel}
        </Button>
      )}
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        disabled={props.submitInProgress}
        type="submit"
        onClick={props.submit}
        onMouseDown={props.onSubmitPress}
      >
        {props.submitButtonLabel}
        {props.submitInProgress && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </React.Fragment>
  );
};
