import classNames from 'classnames';
import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { Footer } from './footer';
import { Header } from './header';
import { useStyles } from './headerAndContent.jss';

export interface HeaderAndContentProps {
  header?: () => JSX.Element;

  footer?: () => JSX.Element;
  containerClassName?: string;
  disabled?: boolean;
  isBusy?: boolean;
  removePadding?: boolean;
  removeVerticalScroll?: boolean;
}

export const HeaderAndContent: React.FunctionComponent<HeaderAndContentProps> = React.memo(
  (props) => {
    const classes = useStyles();

    return (
      <div
        className={classNames(classes.container, props.containerClassName, {
          [classes.disabled]: props.disabled || props.isBusy,
        })}
      >
        <Header>{props.header && props.header()}</Header>
        <div
          className={classNames(classes.content, {
            [classes.contentWithPadding]: !props.removePadding,
            [classes.contentWithOverflow]: !props.removeVerticalScroll
          })}
        >
          {props.isBusy && <CircularProgress className={classes.loadingProgress} size={60} />}
          {props.children}
        </div>
        {props.footer && <Footer>{props.footer()}</Footer>}
      </div>
    );
  });
