import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl } from '@material-ui/core';

import { Field, FieldProps } from 'formik';
import { Autocomplete } from '../../../../components/autocomplete';
import { countries } from '../../../../dataStore/countries';
import { CountryInfo } from '../../../../dataStore/types';
import { ContentWrapper } from './contentWrapper';
import { TabFields } from './tabFields';
import { useStyles } from './tabs.jss';

export interface AddressTabProps {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  handleChange(e: React.ChangeEvent): void;
  key: string;
  parentName: string;
}

export const AddressFields = (props: AddressTabProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <ContentWrapper>
      <TabFields
        handleChange={props.handleChange}
        dontNestTranslationKey
        fields={{
          address: props.address,
          city: props.city,
          state: props.state,
          zip: props.zip,
          country: props.country,
        }}
        key={props.key}
        parentName={props.parentName}
      />

      <FormControl className={classes.selectMargin}>
        <Field name={`${props.parentName}.country`}>
          {({ form }: FieldProps) => (
            <Autocomplete
              items={countries}
              selectedItem={props.country}
              minCharInput={2}
              textFieldProps={{ name: 'something', label: t('newnity.search.country') }}
              onChange={(item: CountryInfo | undefined) =>
                form.setFieldValue(`${props.parentName}.country`, item ? item.name : '')
              }
              key={`${props.key}-country`}
            />
          )}
        </Field>
      </FormControl>
    </ContentWrapper>
  );
};
