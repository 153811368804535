import React from 'react';
import {
    connect, InferableComponentEnhancerWithProps, MapDispatchToProps, MapStateToProps, MergeProps
} from 'react-redux';
import { Dispatch } from 'redux';

import { createBlade } from './actions';
import { BladeClosingCallbackCache, BladeConfig, BladeInstance, BladeProps } from './types';

interface BladeCache {
  [bladeType: string]: {
    componentFactory: React.ComponentFactory<
      any,
      React.Component<any, any, any>
    >;
    config: BladeConfig<any>;
  };
}

const bladeCache: BladeCache = {};

export const cache = (): BladeCache => bladeCache;
export const closingCallbackCache: BladeClosingCallbackCache = {};

export const registerBladeType = <TProps>(
  config: BladeConfig<TProps>,
  component: any
) => {
  bladeCache[config.bladeType] = {
    componentFactory: React.createFactory(component),
    config,
  };
};

export const openBlade = <T = {}>(
  parentBladeId: string,
  childBladeType: string,
  childBladeInstanceProps: T,
  dispatch?: Dispatch
) => {
  const blade = bladeCache[childBladeType];
  if (!blade) {
    throw new Error(`No blade defined for type ${childBladeType}`);
  }

  const id = blade.config.id(childBladeInstanceProps);
  const title = blade.config.title(childBladeInstanceProps);
  const createBladeAction = createBlade({
    parentId: parentBladeId,
    id,
    type: childBladeType,
    props: childBladeInstanceProps,
    title,
    newlyCreated: true,
    frozen: false,
  });

  if(dispatch){
    dispatch(createBladeAction);
  }
  return createBladeAction;
};

export const getBladeConfig = (
  bladeInfo: BladeInstance,
) => {
  const bladeType = bladeCache[bladeInfo.type];
  if (!bladeType) {
    throw new Error(`No blade defined for type ${bladeInfo.type}`);
  }

  return bladeType.config;
};

export const getBladeComponent = (
  bladeInfo: BladeInstance,
  isDirty: boolean,
  setBladeDirty: (bladeId: string, dirty: boolean) => void,
  width: number,
) => {
  const bladeType = bladeCache[bladeInfo.type];
  if (!bladeType) {
    throw new Error(`No blade defined for type ${bladeInfo.type}`);
  }
  const component = bladeType.componentFactory({
    ...bladeInfo.props,
    isDirty,
    bladeId: bladeInfo.id,
    width,
    setDirty: (dirty: boolean) => setBladeDirty(bladeInfo.id, dirty),
  });
  return component;
};

export const bladeConnect = <TProps, TActions, TOwnProps extends BladeProps>(
  mapStateToProps: MapStateToProps<TProps, TOwnProps, any>,
  mapDispatchToProps: MapDispatchToProps<TActions, TOwnProps>,
  bladeConfig: BladeConfig<TOwnProps>
) => {
  const mergeProps: MergeProps<
    TProps,
    TActions,
    TOwnProps,
    TProps & TActions & BladeProps
  > = (stateProps, dispatchProps, ownProps) => {
    const mergedProps = {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
    };
    return mergedProps;
  };
  const connectWithRegisterBlade: InferableComponentEnhancerWithProps<
    TProps & TActions & BladeProps,
    TOwnProps
  > = component => {
    const containerComponent = connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps,
      { forwardRef: false }
    )(component);
    registerBladeType(bladeConfig, containerComponent);
    return containerComponent;
  };
  return connectWithRegisterBlade;
};
