import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useBladeClosing } from 'app/blade';
import { BladeProps } from 'app/blade/duck/types';
import {
  ButtonsHeaderAndContent,
  createBtn,
} from 'app/components/bladeLayout/buttonsHeaderAndContent';
import { FooterSubmit } from 'app/components/bladeLayout/footerSubmit';
import { DataGridContainer } from 'app/components/dataGrid';
import { DataGridSelectionType } from 'app/components/dataGrid/agDataGrid';
import { EntityType } from 'app/dataStore';
import { IdName, User } from 'app/dataStore/types';
import { useTranslation } from 'react-i18next';
import { useStyles } from './addWorkgroupUsers.jss';
import { columnSettings } from './columnSettings';

export interface AddWorkgroupUsersProps {
  workgroupId: number;
  users: User[];
  workgroupUsers: User[];
  isLoading: boolean;
  isSaving: boolean;
  saved: boolean;
  hideCreateButton?: boolean;
}

export interface AddWorkgroupUsersActions {
  fetchUsers: () => void;
  save: (workgroupId: number, userIds: number[]) => void;
  setSaved: (value: boolean) => void;
  openNewUser: () => void;
  forceCloseBlade: () => void;
  openCreateUserBlade: () => void;
}

type Props = AddWorkgroupUsersProps & AddWorkgroupUsersActions & BladeProps;

export const AddWorkgroupUsers: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<IdName[]>([]);
  const { fetchUsers, setDirty, setSaved } = props;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (props.saved) {
      setDirty(false);
      setSaved(false);
    }
  }, [props.saved, setDirty, setSaved]);

  useBladeClosing(props.bladeId, () => !props.isDirty);

  const handleSelection = useCallback(
    (newSelectedUsers: IdName[]) => {
      const users: User[] = (newSelectedUsers as User[]).filter(
        (user: User) => !(user as any)._default
      );

      setSelectedUsers(users);
      setBladeDirtyStatus(users);
    },
    [props.workgroupUsers, props.isDirty, setDirty]
  );

  const setBladeDirtyStatus = useCallback(
    (users: User[]) => {
      const isDirty =
        users.length !== props.workgroupUsers.length ||
        users.filter(u => props.workgroupUsers.find(e => e.id === u.id)).length !==
        props.workgroupUsers.length;

      if (!props.isDirty && isDirty) {
        setDirty(true);
      }

      if (props.isDirty && !isDirty) {
        setDirty(false);
      }
    },
    [setDirty, props.isDirty]
  );

  const buttons = useMemo(() => props.hideCreateButton ? [] : [createBtn(props.openCreateUserBlade)], [
    props.openCreateUserBlade,
    props.hideCreateButton
  ]);

  const formSubmit = useCallback(
    () =>
      props.save(
        props.workgroupId,
        selectedUsers.filter(u => !props.workgroupUsers.find(e => e.id === u.id)).map(u => u.id)
      ),
    [props.workgroupUsers, props.workgroupId, props.save, selectedUsers]
  );

  const footer = () => {
    return (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={props.forceCloseBlade}
        submitButtonLabel={t('footer.save')}
        submit={formSubmit}
        submitInProgress={props.isSaving}
      />
    );
  };

  return (
    <ButtonsHeaderAndContent
      footer={footer}
      buttons={buttons}
      isBusy={props.isLoading}
      removePadding
      removeVerticalScroll
    >
      <div className={classes.container}>
        <DataGridContainer
          items={props.users}
          highlightNewlyAdded
          keepDefaultSelection={true}
          defaultSelection={props.workgroupUsers}
          onSelect={handleSelection}
          entityType={EntityType.User}
          selectionType={DataGridSelectionType.Multiple}
          columnSettings={columnSettings}
          supressUnselectDefault
        />
      </div>
    </ButtonsHeaderAndContent>
  );
};
