import { SelectionState } from '@devexpress/dx-react-grid';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import { IdName } from '../../dataStore/types';
import { DataGridSelectionType } from './';

interface CustomSelectionStateProps {
  items: IdName[];
  defaultSelection?: IdName[];
  keepDefaultSelection?: boolean;
  selectionType: DataGridSelectionType;
  onSelectionChange: (items: IdName[]) => void;
}

export const CustomSelectionState = (props: CustomSelectionStateProps) => {
  const [ids, setIds] = useState(
    props.defaultSelection ? props.defaultSelection.map(i => i.id) : []
  );

  const containsAllDefault = (values: number[]) => {
    if (props.defaultSelection) {
      return props
        .defaultSelection
        .map(d => d.id)
        .every(d => values.indexOf(d) !== -1);
    } else {
      return true;
    }
  };

  const addDefault = (values: number[]) => {
    if (props.defaultSelection) {
      const defaultIds = props.defaultSelection.map(d => d.id);
      const result = uniq(values.concat(defaultIds));
      return result;
    } else {
      return values;
    }
  }

  const handleSelectionChange = (incomingIds: Array<number | string>) => {
    let newIds: number[] = [];

    switch (props.selectionType) {
      case DataGridSelectionType.SingleOrNone:
      case DataGridSelectionType.Single:
        const notSelectedPreviously = (incomingIds as number[]).find(
          id => ids.indexOf(id) === -1
        );
        if (notSelectedPreviously) {
          newIds = [notSelectedPreviously];
        } else {
          if (props.selectionType === DataGridSelectionType.SingleOrNone) {
            newIds = [];
          }
        }
        break;
      default:
        newIds = incomingIds as number[];
    }
    
    if (props.keepDefaultSelection && !containsAllDefault(newIds)) {
      newIds = addDefault(newIds);
    }

    setIds(newIds);
    
    const selectedItems = newIds
      .map(i => props.items.find(item => item.id === i))
      .filter(i => i !== undefined);

    props.onSelectionChange(selectedItems as IdName[]);
  };

  return (
    <SelectionState 
      selection={ids} 
      onSelectionChange={handleSelectionChange} 
    />
  );
};