import React from 'react';

import { Badge } from '@material-ui/core';
import { useStyles } from './tabs.jss';

interface TabLabelProps {
  label: string;
  numberOfNonEmptyFields: number;
}

export const TabLabel = (props: TabLabelProps) => {
  const classes = useStyles();

  return (
    <>
      {props.numberOfNonEmptyFields ? (
        <Badge
          badgeContent={props.numberOfNonEmptyFields}
          color="secondary"
          classes={{ badge: classes.badge }}
        >
          {props.label}
        </Badge>
      ) : (
        props.label
      )}
    </>
  );
};
