import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    workgroupsToolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[100]
    },
    workgroupsToolbarIconButton: {
      marginRight: theme.spacing(1)
    }
  });
};

export const useStyles = makeStyles(styles);