import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { SelectionBladeState } from './types';

type SelectionActions = ActionType<typeof Actions>;
const initialState: SelectionBladeState = {};

export const reducer: Reducer<any, SelectionActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SELECTION: {
      return { ...state, [action.payload.key]: action.payload.items };
    }
    case ActionTypes.CLEAR_SELECTION: {
      const newState = { ...state };
      delete newState[action.payload.key];
      return newState;
    }
    case ActionTypes.CLEAR_MULTIPLE_SELECTIONS: {
      const newState = { ...state };
      action.payload.keys.forEach(key => delete newState[key]);
      return newState;
    }
    default:
      return state;
  }
};
