import React from 'react';

import { FromLayoutToolbarProps } from '../layout';
import { AdminAppToolbarContainer } from './adminAppToolbarContainer';
import { NewnityToolbarContainer } from './newnityToolbarContainer';

export interface ToolbarProps {
  newnityEnabled: boolean;
}

type Props = ToolbarProps & FromLayoutToolbarProps;

export const Toolbar = (props: Props) => {
  if (props.newnityEnabled) {
    return <NewnityToolbarContainer expandToolbar={props.expandToolbar} toolbarExpanded={props.toolbarExpanded}/>;
  } else {
    return <AdminAppToolbarContainer />;
  }
};