import { TreeItem } from '../../components/tree';

const RANDOM_WORDS = [
  "abstrusity",
  "advertisable",
  "bellwood",
  "benzole",
  "boreum",
  "brenda",
  "cassiopeian",
  "chansonnier",
  "cleric",
  "conclusional",
  "conventicle",
  "copalm",
  "cornopion",
  "crossbar",
  "disputative",
  "djilas",
  "ebracteate",
  "ephemerally",
  "epidemical",
  "evasive",
  "eyeglasses",
  "farragut",
  "fenny",
  "ferryman",
  "fluently",
  "foreigner",
  "genseng",
  "glaiket",
  "haunch",
  "histogeny",
  "illocution",
  "imprescriptible",
  "inapproachable",
  "incisory",
  "intrusiveness",
  "isoceraunic",
  "japygid",
  "juiciest",
  "jump",
  "kananga",
  "leavening",
  "legerdemain",
  "licence",
  "licia",
  "luanda",
  "malaga",
  "mathewson",
  "nonhumus",
  "nonsailor",
  "nummary",
  "nyregyhza",
  "onanist",
  "opis",
  "orphrey",
  "paganising",
  "pebbling",
  "penchi",
  "photopia",
  "pinocle",
  "principally",
  "prosector.",
  "radiosensitive",
  "redbrick",
  "reexposure",
  "revived",
  "subexternal",
  "sukarnapura",
  "supersphenoid",
  "tabularizing",
  "territorialism",
  "tester",
  "thalassography",
  "tuberculise",
  "uncranked",
  "undersawyer",
  "unimpartible",
  "unsubdivided",
  "untwining",
  "unwaived",
  "webfoot",
  "wedeling",
  "wellingborough",
  "whiffet",
  "whipstall",
  "wot",
  "yonkersite",
  "zonary"
];

interface HierarchicalNode {
  name: string;
  depth: number;
  id: string;
  parentId: string;
  modelId: number;
  modelParentId: number;
  children?: HierarchicalNode[];
  hasChildren: boolean;
}
let modelIdCounter = 0;

const createRandomizedData = (levels: number, maxNodesPerLevel: number) => {
  const data = [] as HierarchicalNode[];

  for (let i = 0; i < maxNodesPerLevel; i++) {
    modelIdCounter++;
    data.push(createRandomizedItem(1, `${i + 1}`, "", modelIdCounter, 0, levels, maxNodesPerLevel));
  }

  return data;
};

const createRandomizedItem = (
  depth: number,
  id: string,
  parentId: string,
  modelId: number,
  modelParentId: number,
  levels: number, maxNodesPerLevel: number
) => {
  const numChildren = depth <= levels ? (Math.floor(Math.random() * maxNodesPerLevel)) : 0;
  const item: HierarchicalNode = {
    children: [],
    id,
    depth,
    parentId,
    name: RANDOM_WORDS[Math.floor(Math.random() * RANDOM_WORDS.length)],
    hasChildren: numChildren !== 0,
    modelId,
    modelParentId
  };

  for (let i = 0; i < numChildren; i++) {
    if (item.children) {
      modelIdCounter++;
      item.children.push(
        createRandomizedItem(depth + 1, `${id}/${i + 1}`, id, modelIdCounter, modelId, levels, maxNodesPerLevel)
      );
    }
  }

  return item;
};

const flattenNode = (n: HierarchicalNode) => {
  let tree: TreeItem[] = [
    {
      level: n.depth,
      modelId: n.modelId,
      modelParentId: n.modelParentId,
      treeId: n.id,
      isExpanded: false,
      treeParentId: n.parentId,
      hasChildren: n.hasChildren,
      label: n.name,
      flatListIndex: 0,
      totalChildCount: 0,
      match: null,
      hasChildrenMatched: false
    }
  ];
  if (n.children && n.children.length !== 0) {
    n.children.forEach(c => {
      const childData = flattenNode(c);
      tree = tree.concat(childData);
    });
  }

  return tree;
};

const flattenTreeData = (data: HierarchicalNode[]) => {
  let tree: TreeItem[] = [];

  data.forEach(n => {
    const childData = flattenNode(n);
    tree = tree.concat(childData);
  });
  return tree;
};



export const getMockedTreeItems = (levels: number = 3, maxNodesPerLevel: number = 5) => {
  const treeData = createRandomizedData(levels, maxNodesPerLevel);
  const data = flattenTreeData(treeData);
  return data;
}
