import { SnackbarProvider } from 'notistack';
import React from 'react';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { createGenerateClassName, StylesProvider } from '@material-ui/styles';
import { moodTheme } from './app.jss';
import { BladeRowContainer } from './blade/bladeRow/bladeRowContainer';
import { NotifierContainer } from './components/notifier';
import { Layout } from './layout';

const generateClassName = createGenerateClassName({
  productionPrefix: 'musicsetup',
  disableGlobal: true
});

export const App = () => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={moodTheme} >
        <SnackbarProvider maxSnack={3}>
          <Layout>
            <NotifierContainer />
            <BladeRowContainer />
          </Layout>
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
