import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      
      minHeight: "48px",
      boxSizing: 'content-box',
      zIndex: 1
    },
    containerExtendMargins: {
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
    stickyHelper: {
      height: "1px",
      bottom: 0
    },
  });
export const useStyles = makeStyles(styles);
