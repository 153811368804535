import { connect } from 'react-redux';

import { IdName } from '../../dataStore';
import { DataGrid, DataGridProps, DataGridStateProps } from './agDataGrid';

export interface DataGridContainerProps extends DataGridProps {
  items: IdName[];
}

const mapStateToProps: (
  state: any,
  ownProps: DataGridContainerProps
) => DataGridStateProps & DataGridProps = (state, ownProps) => ownProps;

export const DataGridContainer = connect(mapStateToProps)(DataGrid);
