import { WorkgroupProfileType } from 'app/newnity/duck/types';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { Action, Dispatch } from 'redux';
import { BladeActions, bladeConnect, BladeProps, openBlade } from '../../../../blade';
import { UserSelectors } from '../../../../users';
import {
  fetchWelcomeEmailTemplates,
  fetchWorkgroupUsers,
  removeUsersFromWorkgroup,
  userEditingStarting,
} from '../../../duck/actions';
import { AddWorkgroupUsersBladeType } from '../../addWorkgroupUsers/addWorkgroupUsersContainer';
import { EditUserBladeType } from '../../createUser';
import {
  WorkgroupUserList,
  WorkgroupUsersListActions,
  WorkgroupUsersListProps,
} from './workgroupUsersList';

interface WorkgroupUserListContainerProps extends BladeProps {
  workgroupId: number;
  title: string;
  workgroupName: string;
  profileType?: WorkgroupProfileType;
  hideCreateButton?: boolean;
}

const mapStateToProps: MapStateToProps<
  WorkgroupUsersListProps,
  WorkgroupUserListContainerProps,
  any
> = (state, ownProps) => {
  const removingUser = UserSelectors.selectWorkgroupUserRemove(state);
  const isFetching = UserSelectors.selectWorkgroupUsersFetching(state);
  const isEditing = UserSelectors.userIsEditing(state);
  return {
    workgroupId: ownProps.workgroupId,
    profileType: ownProps.profileType ? ownProps.profileType : WorkgroupProfileType.NewnityDefault,
    users: UserSelectors.selectWorkgroupUsers(state),
    removingUser: removingUser.isRemoving && !removingUser.removingComplete,
    isFetching,
    isEditing,
    hideCreateButton: ownProps.hideCreateButton
  };
};

const openEditOrCreateUserBlade = (props: any, dispatch: Dispatch<Action<any>>) => {
  dispatch<any>(
    BladeActions.closeBlade(AddWorkgroupUsersBladeType, () =>
      dispatch<any>(
        BladeActions.closeBlade(EditUserBladeType, () =>
          openBlade(WorkgroupUserListListBladeType, EditUserBladeType, props, dispatch)
        )
      )
    )
  );
};

const mapDispatchToProps: MapDispatchToProps<
  WorkgroupUsersListActions,
  WorkgroupUserListContainerProps
> = (dispatch, ownProps) => {
  return {
    fetchUsers: () => dispatch<any>(fetchWorkgroupUsers(ownProps.workgroupId)),
    removeUsers: (userIds: number[]) =>
      dispatch<any>(
        removeUsersFromWorkgroup(ownProps.workgroupId, userIds, WorkgroupUserListListBladeType)
      ),
    openEditUser: (userId: number, firstName: string, lastName) => {
      openEditOrCreateUserBlade(
        {
          userId, firstName, lastName, workgroupId: ownProps.workgroupId,
          profileType: ownProps.profileType ? ownProps.profileType : WorkgroupProfileType.NewnityDefault
        },
        dispatch
      );

      dispatch<any>(userEditingStarting());
      dispatch<any>(fetchWelcomeEmailTemplates());
    },
    openUsersList: () => {
      const openAction = () =>
        openBlade(
          WorkgroupUserListListBladeType,
          AddWorkgroupUsersBladeType,
          {
            workgroupId: ownProps.workgroupId,
            workgroupName: ownProps.workgroupName,
            profileType: ownProps.profileType ? ownProps.profileType : WorkgroupProfileType.NewnityDefault,
            hideCreateButton: ownProps.hideCreateButton
          },
          dispatch
        );

      dispatch<any>(BladeActions.closeBlade(EditUserBladeType, openAction));
    },
    openNewUserBlade: () => {
      openEditOrCreateUserBlade(
        {
          userId: 0,
          workgroupId: ownProps.workgroupId,
          workgroupName: ownProps.workgroupName,
          automaticallyAdd: true,
          profileType: ownProps.profileType ? ownProps.profileType : WorkgroupProfileType.NewnityDefault,
          hideCreateButton: ownProps.hideCreateButton
        },
        dispatch
      );
    },
  };
};

export const WorkgroupUserListListBladeType = 'WORKGROUP_USER_LIST';

const bladeConfig = {
  size: { defaultWidth: 840, minWidth: 840 },
  bladeType: WorkgroupUserListListBladeType,
  id: (_: any) => WorkgroupUserListListBladeType,
  title: (ownProps: WorkgroupUserListContainerProps) => ownProps.title,
  allowMultipleInstances: false,
};

export const WorkgroupUserListContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(WorkgroupUserList);
