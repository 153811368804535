import { createSelector } from 'reselect';

import { EntityType, NParentCompanyWorkgroup } from '../types';
import { getDataStore } from './genericSelectors';

export const selectParentWorkgroups = (state: any) =>
  getDataStore(state)[EntityType.NParentCompanyWorkgroup];

export const selectParentWorkgroupItems = createSelector(
  [selectParentWorkgroups],
  parentWorkgroups => {
    return Object.keys(parentWorkgroups.items).map<NParentCompanyWorkgroup>(
      key => parentWorkgroups.items[Number(key)]
    );
  }
);

export const selectParentWorkgroupById = (state: any, workgroupId: number) =>
  selectParentWorkgroups(state).items[workgroupId];
