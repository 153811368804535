import React from 'react';

import { CompanySearchFields } from 'app/newnity/duck/types';
import { AddressFields } from '../addressFields';
import { TabFieldsWithContentWrapper } from '../tabFields';
import { getCompanyInfoFields, getCompanyPhoneFields } from '../utils';

interface CompanyFieldsProps {
  tabIndex: number;
  company: CompanySearchFields;
  handleChange(e: React.ChangeEvent): void;
}

export const CompanyFields = (props: CompanyFieldsProps) => {
  return (
    <>
      {props.tabIndex === 0 && (
        <TabFieldsWithContentWrapper
          handleChange={props.handleChange}
          fields={getCompanyInfoFields(props.company)}
          key="company"
          parentName="company"
        />
      )}
      {props.tabIndex === 1 && (
        <AddressFields
          parentName="company"
          {...props.company}
          handleChange={props.handleChange}
          key="company.address"
        />
      )}
      {props.tabIndex === 2 && (
        <TabFieldsWithContentWrapper
          handleChange={props.handleChange}
          fields={getCompanyPhoneFields(props.company)}
          key="company"
          parentName="company"
        />
      )}
    </>
  );
};
