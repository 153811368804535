import {
  CompanySearchFields,
  DeviceSearchFields,
  LocationSearchFields,
} from 'app/newnity/duck/types';

const countNonEmptyValues = (args: string[]): number => args.filter(a => a !== '').length;

export const getCompanyInfoFields = (company: CompanySearchFields) => ({
  number: company.number,
  name: company.name,
});

export const countNonEmptyCompanyInfoFields = (company: CompanySearchFields) =>
  countNonEmptyValues(Object.values(getCompanyInfoFields(company)));

export const getCompanyPhoneFields = (company: CompanySearchFields) => ({
  firstName: company.firstName,
  lastName: company.lastName,
  phone: company.phone,
  email: company.email,
});

export const countNonEmptyPhoneFields = (company: CompanySearchFields) =>
  countNonEmptyValues(Object.values(getCompanyPhoneFields(company)));

export const getAddressFields = (entity: CompanySearchFields | LocationSearchFields) => ({
  address: entity.address,
  city: entity.city,
  state: entity.state,
  zip: entity.zip,
  country: entity.country,
});

export const countNonEmptyAddressFields = (entity: CompanySearchFields | LocationSearchFields) =>
  countNonEmptyValues(Object.values(getAddressFields(entity)));

export const getLocationInfoFields = (location: LocationSearchFields) => ({
  locationNumber: location.locationNumber,
  locationName: location.locationName,
  deviceNumber: location.deviceNumber,
  serial: location.serial,
  siteNumber: location.siteNumber,
  company: location.company,
});

export const countNonEmptyLocationInfoFields = (location: LocationSearchFields) =>
  countNonEmptyValues(Object.values(getLocationInfoFields(location)));

export const getDeviceInfoFields = (device: DeviceSearchFields) => ({
  deviceNumber: device.deviceNumber,
  deviceDescription: device.deviceDescription,
  serial: device.serial,
  locationNumber: device.locationNumber,
  locationName: device.locationName,
  siteNumber: device.siteNumber,
  company: device.company,
  salesOrder: device.salesOrder,
});

export const countNonEmptyDeviceInfoFields = (device: DeviceSearchFields) =>
  countNonEmptyValues(Object.values(getDeviceInfoFields(device)));
