import { createSelector } from 'reselect';

import { EntityType, NLocationList } from '../types';
import { getDataStore } from './genericSelectors';

export const selectLocations = (state: any) =>
  getDataStore(state)[EntityType.NLocationList];

export const selectLocationItems = createSelector(
  [selectLocations],
  locations => {
    return Object.keys(locations.items).map<NLocationList>(
      key => locations.items[Number(key)]
    );
  }
);

export const selectOrderedLocationItems = createSelector(
  [selectLocationItems],
  locations => {
    return locations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
);

export const selectLocationById = (state: any, locationId: number) => selectLocations(state).items[locationId];
