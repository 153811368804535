import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { useStyles } from './results.jss';

export const Loading = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.centerContainer}>
      <CircularProgress size={60} className={classes.progress} />
    </div>
  );
};