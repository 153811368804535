import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropZone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      height: '300px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      cursor: 'pointer'
      // '&:focused': {
      //   borderColor: theme.palette.secondary
      // }
    },
    icon: {
      fontSize: 128
    }
  })
);
