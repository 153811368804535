import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  });

export const useStyles = makeStyles(styles);
