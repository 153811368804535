import { Checkbox, InputAdornment, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { Field, FieldProps, Form, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BladeProps, useBladeClosing } from '../../../blade';
import { FieldButton } from '../../../components';
import { Footer } from '../../../components/bladeLayout/footer';
import { FooterSubmit } from '../../../components/bladeLayout/footerSubmit';
import { HeaderAndContent } from '../../../components/bladeLayout/headerAndContent';
import { IdName, Workgroup } from '../../../dataStore';
import { WorkgroupCreateObject } from '../../duck/types';
import { useStyles } from './workgroupCreate.jss';

export interface WorkgroupCreateProps {
  isSaving: boolean;
  savingError: string;
  brand?: IdName;
  templateItems?: IdName[];
  workgroupParent: Workgroup;
  workgroupParentPath: string;
  workgroupParentTreeItemId: string;
}

export interface WorkgroupCreateActions {
  createWorkgroup: (
    wkCreateObj: WorkgroupCreateObject,
    treeId: string,
    createAnother: boolean
  ) => void;
  selectTemplateLibraries: () => void;
  selectBrand: () => void;
  closeBlade: () => void;
  onBladeClose: () => void;
  toggleParentSelectionMode: (selectionModeOn: boolean) => void;
}

type Props = WorkgroupCreateProps & WorkgroupCreateActions & BladeProps;

export const WORKGROUP_CREATE = 'createWorkgroup';

interface WorkgroupCreateFormValues {
  workgroupName: string;
  logoPath: string;
  parentName: string;
  brandName: string;
}

let debounceDirty: any;
export const WorkgroupCreate: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [createPressed, setCreatePressed] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const workgroupNameInnerRef = useRef<any>(null);

  useBladeClosing(props.bladeId, () => props.isDirty, props.onBladeClose);

  return (
    <HeaderAndContent removePadding>
      <Formik
        validate={(values: WorkgroupCreateFormValues) => {
          const errors: FormikErrors<WorkgroupCreateFormValues> = {};

          // we only validate if the user pressed the Create button and not before!
          if (!values.workgroupName && createPressed) {
            errors.workgroupName = t('create.workgroup.error_name_required');
          }
          if (!props.workgroupParent && createPressed) {
            errors.parentName = t('create.workgroup.error_name_required');
          }
          if (!props.brand && createPressed) {
            errors.brandName = t('create.workgroup.error_name_required');
          }
          return errors;
        }}
        initialValues={{
          workgroupName: '',
          logoPath: 'mood_media.png',
          parentName: props.workgroupParent ? props.workgroupParent.name : '',
          createAnother: false,
          brandName: '',
        }}
        onSubmit={(
          values: WorkgroupCreateFormValues,
          actions: FormikActions<WorkgroupCreateFormValues>
        ) => {
          // Todo
          const payload: WorkgroupCreateObject = {
            Name: values.workgroupName,
            ParentId: props.workgroupParent.id,
            BrandId: props.brand ? props.brand.id : 0,
            TemplateLibraries: props.templateItems ? props.templateItems.map(tl => tl.id) : [],
            LogoPath: values.logoPath,
          };
          props.createWorkgroup(payload, props.workgroupParentTreeItemId, createAnother);
          setCreatePressed(false);
          actions.setFieldValue('workgroupName', '');
          workgroupNameInnerRef.current.focus();
        }}
        render={() => (
          <Form className={classes.form} autoComplete="off">
            <div className={classes.fieldsContainer}>
              <Typography variant="h6">{t('create.workgroup.properties_header')}</Typography>
              <Divider />
              <Field
                name="workgroupName"
                render={({ field, form }: FieldProps<WorkgroupCreateFormValues>) => {
                  const f = field.onChange;

                  field.onChange = (e: any) => {
                    f(e);
                    debounceDirty(e.target.value);
                  };
                  return (
                    <TextField
                      inputRef={workgroupNameInnerRef}
                      autoFocus={true}
                      error={validateEmptyField(form, field.name)}
                      label={t('create.workgroup.name')}
                      inputProps={{ maxLength: 255 }}
                      helperText={form.errors.workgroupName}
                      {...field}
                      className={classes.formField}
                      margin="normal"
                    />
                  );
                }}
              />
              <Field
                name="brandId"
                render={({ field, form }: FieldProps<WorkgroupCreateFormValues>) => (
                  <TextField
                    label={t('create.workgroup.brand_label')}
                    placeholder={t('create.workgroup.brand_ids')}
                    {...field}
                    error={!props.brand && createPressed}
                    value={props.brand ? props.brand.name : ''}
                    className={classes.formField}
                    helperText={!props.brand && form.errors.brandName}
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <FieldButton
                            title={t('create.workgroup.edit_btn_label')}
                            onClick={() => props.selectBrand()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Field
                name="templateIds"
                render={({ field }: FieldProps<WorkgroupCreateFormValues>) => (
                  <TextField
                    label={t('create.workgroup.template')}
                    {...field}
                    value={
                      props.templateItems
                        ? `${props.templateItems.length} ${t('create.workgroup.number_selected')}`
                        : ''
                    }
                    className={classes.formField}
                    margin="normal"
                    InputProps={{
                      readOnly: true,

                      endAdornment: (
                        <InputAdornment position="end">
                          <FieldButton
                            title={t('create.workgroup.edit_btn_label')}
                            onClick={() => props.selectTemplateLibraries()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Field
                name="logoPath"
                render={({ field }: FieldProps<WorkgroupCreateFormValues>) => (
                  <TextField
                    label={t('logo')}
                    inputProps={{ maxLength: 50 }}
                    {...field}
                    className={classes.formField}
                  />
                )}
              />
              <Field
                name="parentName"
                render={({ field, form }: FieldProps<WorkgroupCreateFormValues>) => {
                  return (
                    <TextField
                      label={t('create.workgroup.parent_name')}
                      error={validateEmptyField(form, field.name)}
                      helperText={props.workgroupParentPath || form.errors.parentName}
                      value={props.workgroupParent ? props.workgroupParent.name : ''}
                      className={classes.formField}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FieldButton
                              title={t('create.workgroup.select_btn_label')}
                              onClick={() => {
                                props.toggleParentSelectionMode(true);
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />
            </div>
            <Footer>
              <FooterSubmit
                hasCancelButton={true}
                cancelButtonLabel={t('create.workgroup.cancel_btn_label')}
                cancel={() => {
                  props.closeBlade();
                }}
                submitButtonLabel={t('create.workgroup.create_btn_label')}
                submit={() => {
                  setCreatePressed(true);
                }}
                submitInProgress={props.isSaving}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createAnother}
                      onChange={(_, checked) => setCreateAnother(checked)}
                    />
                  }
                  label={t('create.workgroup.create_another_btn_label')}
                />
              </FooterSubmit>
            </Footer>
          </Form>
        )}
      />
    </HeaderAndContent>
  );
};

function validateEmptyField(
  form: FormikProps<WorkgroupCreateFormValues>,
  x: string
): boolean | undefined {
  return (
    (form.touched as any)[x] &&
    (form.errors as any)[x] !== undefined &&
    (form.errors as any)[x].length > 0
  );
}
