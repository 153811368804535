import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Measure, { ContentRect } from 'react-measure';

import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  Sorting,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import { EntityType, IdName } from '../../dataStore';
import { CustomSelectionState } from './customSelectionState';
import { useStyles } from './dataGrid.jss';

export interface DataGridStateProps {
  items: IdName[];
}

export enum DataGridSelectionType {
  None,
  Single,
  SingleOrNone,
  Multiple,
  MultipleOrNone,
}

export interface ColumnSettings {
  name: string;
  width: number;
  sort?: { order: number; direction: 'asc' | 'desc' };
  formatter?: (item: IdName) => JSX.Element;
}

export interface DataGridProps {
  entityType: EntityType;
  selectionType: DataGridSelectionType;
  onSelect?: (items: IdName[]) => void;
  defaultSelection?: IdName[];
  keepDefaultSelection?: boolean;
  columnSettings: ColumnSettings[];
  showSelectionColumn?: boolean;
}

export type Props = DataGridProps &
  DataGridStateProps & {
    children?: React.ReactNode;
  };

const columnsBuilder = (
  columnSettings: ColumnSettings[],
  entityType: EntityType,
  t: i18next.TFunction
) => {
  const columns: Column[] = [];
  const columnFiltering: FilteringState.ColumnExtension[] = [];
  const columnWidths: TableColumnWidthInfo[] = [];
  const sortOrder = new Array<Sorting>(columnSettings.length);
  const sortingExtensions: IntegratedSorting.ColumnExtension[] = [];
  let customSorting = false;
  columnSettings.forEach(cs => {
    columns.push({
      name: cs.name,
      title: t(`${entityType}.columnTitle.${cs.name}`),
    });
    columnFiltering.push({
      columnName: cs.name,
      filteringEnabled: true,
    });
    columnWidths.push({
      columnName: cs.name,
      width: cs.width,
    });
    if (cs.sort) {
      customSorting = true;
      sortOrder[cs.sort.order] = {
        columnName: cs.name,
        direction: cs.sort.direction,
      };
    }
    sortingExtensions.push({
      columnName: cs.name,
      compare: customCompare,
    });
  });
  const sorting: Sorting[] = customSorting
    ? sortOrder.filter(s => s !== undefined)
    : [{ columnName: 'name', direction: 'asc' }];
  return { columns, columnFiltering, columnWidths, sorting, sortingExtensions };
};

const getUniqueRowId = (row: IdName) => row.id;

const appendDefaultToItems = (arr: IdName[], defaultItems?: IdName[], keepDefault?: boolean) => {
  if (!defaultItems) {
    defaultItems = [];
  }

  const defaultIds = defaultItems.map(i => i.id);
  return arr.map(i => {
    if (defaultIds.indexOf(i.id) !== -1) {
      return { ...i, _default: true, _keepDefault: keepDefault };
    } else {
      return { ...i, _default: false, _keepDefault: keepDefault };
    }
  });
};

const customCompare = (a: any, b: any) => {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
  }

  return a === b ? 0 : a < b ? -1 : 1;
};

const CustomSelectionCell = React.memo((props: any) => {
  const classes = useStyles();
  return (
    <TableSelection.Cell
      {...props}
      className={props.row._keepDefault && props.row._default ? classes.defaultRow : ''}
    />
  );
});

export const DataGrid: React.FunctionComponent<Props> = React.memo(
  (props: Props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [items, setItems] = useState(
      appendDefaultToItems(props.items, props.defaultSelection, props.keepDefaultSelection)
    );
    const [height, setHeight] = useState(0);
    const [columns, setColumns] = useState([] as Column[]);
    const [columnFiltering, setColumnFiltering] = useState<FilteringState.ColumnExtension[]>([]);
    const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([]);
    const [columnSorting, setColumnSorting] = useState<Sorting[]>([]);
    const [sortingExtensions, setSortingExtensions] = useState<IntegratedSorting.ColumnExtension[]>(
      []
    );

    const selectionEnabled = props.selectionType !== DataGridSelectionType.None;
    const multipleSelection =
      props.selectionType === DataGridSelectionType.Multiple ||
      props.selectionType === DataGridSelectionType.MultipleOrNone;

    useEffect(() => {
      const config = columnsBuilder(props.columnSettings, props.entityType, t);

      setColumns(config.columns);
      setColumnFiltering(config.columnFiltering);
      setColumnWidths(config.columnWidths);
      setColumnSorting(config.sorting);
      setSortingExtensions(config.sortingExtensions);
    }, [props.columnSettings.length, props.columnSettings, props.entityType, t]);

    useEffect(() => {
      const newItems = appendDefaultToItems(
        props.items,
        props.defaultSelection,
        props.keepDefaultSelection
      );
      setItems(newItems);
    }, [props.items, props.defaultSelection, props.keepDefaultSelection]);

    const handleResize = (contentRect: ContentRect) => {
      const h = contentRect.bounds ? contentRect.bounds.height : 0;
      setHeight(Math.floor(h));
    };

    return (
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => {
          return (
            <div ref={measureRef} className={classes.gridContainer}>
              <Grid getRowId={getUniqueRowId} rows={items} columns={columns}>
                {props.columnSettings.map(c => {
                  if (c.formatter) {
                    return (
                      <DataTypeProvider
                        formatterComponent={p => {
                          if (c.formatter) {
                            return c.formatter(p.row);
                          } else {
                            return null;
                          }
                        }}
                        for={[c.name]}
                        key={c.name}
                      />
                    );
                  } else {
                    return undefined;
                  }
                })}
                <FilteringState defaultFilters={[]} columnExtensions={columnFiltering} />
                <IntegratedFiltering />
                {selectionEnabled && props.onSelect && (
                  <CustomSelectionState
                    items={items}
                    defaultSelection={props.defaultSelection}
                    keepDefaultSelection={props.keepDefaultSelection}
                    selectionType={props.selectionType}
                    onSelectionChange={props.onSelect}
                  />
                )}
                {selectionEnabled && <IntegratedSelection />}
                <SortingState sorting={columnSorting} onSortingChange={setColumnSorting} />
                <IntegratedSorting columnExtensions={sortingExtensions} />
                <VirtualTable height={height} estimatedRowHeight={48} />
                <TableColumnResizing
                  columnWidths={columnWidths}
                  onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow showSortingControls />
                {selectionEnabled && (
                  <TableSelection
                    showSelectAll={multipleSelection}
                    showSelectionColumn={
                      items &&
                      items.length && // If having no data, hiding the selection column, will not show the 'No data' message
                      props.showSelectionColumn !== undefined
                        ? props.showSelectionColumn
                        : true
                    }
                    selectByRowClick={true}
                    highlightRow={true}
                    cellComponent={CustomSelectionCell}
                  ></TableSelection>
                )}
                <TableFilterRow />
              </Grid>
            </div>
          );
        }}
      </Measure>
    );
  },
  (p, n) => {
    let areEqual = true;
    Object.keys(p)
      .filter(k => k !== 'defaultSelection')
      .forEach(k => {
        areEqual = areEqual && (p as any)[k] === (n as any)[k];
      });
    return areEqual;
  }
);
