import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, List,
    ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import Autorenew from '@material-ui/icons/Autorenew';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { BladeInstance } from '../duck/types';

export interface BladeToClose {
  blade: BladeInstance;
  canClose: boolean | undefined;
};

export interface BladeClosingProps {
  opened: boolean;
  bladesToClose: BladeToClose[];
  onCloseAll: () => void;
  onCancel: () => void;
}

const BladeIcon: React.FunctionComponent<{ canClose: boolean | undefined }> = React.memo(props => (
  <Icon>
    {props.canClose === undefined ? (
      <Autorenew />
    ) : props.canClose ? (
      <CheckCircle />
    ) : (
      <Cancel color="secondary" />
    )}
  </Icon>
));

export const BladeClosingDialog: React.FunctionComponent<BladeClosingProps> = props => {
  const [t] = useTranslation();

  return (
    <Dialog open={props.opened}>
      <DialogTitle>{t('blade.closeDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('blade.closeDialog.message')}<br/>{t('blade.closeDialog.message.secondLine')}</DialogContentText>
        <List>
          {props.bladesToClose.map(b => (
            <ListItem key={b.blade.id}>
              <ListItemIcon>
                <BladeIcon canClose={b.canClose} />
              </ListItemIcon>
              <ListItemText primary={b.blade.title} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary" autoFocus>
          {t('cancel')}
        </Button>
        <Button onClick={props.onCloseAll} color="primary">
          {t('blade.close.all')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
