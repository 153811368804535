import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  stationFieldLeft: {
    flexGrow: 1,
    marginRight: theme.spacing(1)
  },
  stationFieldRight: {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
});

export const useStyles = makeStyles(styles);

