import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
  TextField,
  Tooltip,
} from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { moodTheme } from '../app.jss';
import { useStyles as useFooterStyles } from '../components/bladeLayout/footerSubmit.jss';
import { useStyles as useToolbarStyles } from '../layout/toolbar/toolbar.jss';

export interface MVisionLoginProps {
  open: boolean;
  isLoggingIn: boolean;
  onCancel: () => void;
  onLogin: (username: string, password: string) => void;
}

interface LoginState {
  username: string;
  password: string;
}

export const useMVisionLogin = (
  username?: string,
  password?: string,
  afterLogin?: () => Promise<any>
): {
  loginState: LoginState;
  loggingIn: boolean;
  dialogOpen: boolean;
  loginPressed: boolean;
  onLogin: (username: string, password: string) => void;
  onCancel: () => void;
  setDialogState: (dialogOpen: boolean) => void;
} => {
  const [loginState, setLoginState] = useState<LoginState>({
    username: username ? username : '',
    password: password ? password : '',
  });

  const [loggingIn, setLoggingIn] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loginPressed, setLoginPressed] = useState(false);

  const onLogin = useCallback((userName: string, pass: string) => {
    setLoginState({ username: userName, password: pass });
    setLoggingIn(true);
    setLoginPressed(true);
  }, []);

  const onCancel = useCallback(() => {
    setLoggingIn(false);
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    if (!loginState.username || !loginState.password) {
      return;
    }
    api
      .login(loginState.username, loginState.password)
      .then(result => {
        result.text().then((status: string) => {
          if (afterLogin) {
            return afterLogin();
          }
          setLoggingIn(false);
          setDialogOpen(false);
        });
      })
      .catch(err => alert(err));
  }, [loginState, afterLogin]);

  const setDialogState = useCallback((isDialogOpen: boolean) => setDialogOpen(isDialogOpen), []);
  return { loginState, onLogin, onCancel, setDialogState, loginPressed, loggingIn, dialogOpen };
};

interface MVisionLoginToolbarButtonProps {
  showTooltip: boolean;
}

export const MVisionLoginToolbarButton = React.memo(
  (props: MVisionLoginToolbarButtonProps) => {
    const toolbarClasses = useToolbarStyles();
    const {
      onLogin,
      onCancel,
      setDialogState,
      dialogOpen,
      loggingIn,
    } = useMVisionLogin();

    return (
      <>
        <ListItem button key={'home'} onClick={() => setDialogState(true)}>
          <ListItemIcon className={toolbarClasses.icon}>
            {!props.showTooltip ? (
              <AccountBox />
            ) : (
              <Tooltip title="MVision Login" placement="right">
                <AccountBox />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary="MVision Login" />
        </ListItem>
        <MVisionLoginDialog
          open={dialogOpen}
          isLoggingIn={loggingIn}
          onLogin={onLogin}
          onCancel={onCancel}
        />
      </>
    );
  },
  (prevProps: MVisionLoginToolbarButtonProps, nextProps: MVisionLoginToolbarButtonProps) =>
    prevProps.showTooltip === nextProps.showTooltip
);

export const MVisionLoginDialog = React.memo(
  (props: MVisionLoginProps) => {
    const footerClasses = useFooterStyles();
    const [showPassword, setShowPassword] = useState(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    return (
      <MuiThemeProvider theme={moodTheme}>
        <Dialog open={props.open}>
          <DialogTitle>Mvision login</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                inputRef={usernameRef}
                disabled={props.isLoggingIn}
                label="Username"
                value="r.fifiita"
              />
              <TextField
                value="Moodmedia2019"
                style={{ marginTop: moodTheme.spacing(4) }}
                inputRef={passwordRef}
                label="Password"
                disabled={props.isLoggingIn}
                id="adornment-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        {props.isLoggingIn && (
                          <CircularProgress size={24} className={footerClasses.buttonProgress} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginTop: moodTheme.spacing(2) }}
              onClick={props.onCancel}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ marginTop: moodTheme.spacing(2) }}
              onClick={() => {
                if (!usernameRef.current || !passwordRef.current) return;
                props.onLogin(usernameRef.current.value, passwordRef.current.value);
              }}
              color="secondary"
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  },
  (prevProps, nextProps) =>
    prevProps.open === nextProps.open && prevProps.isLoggingIn === nextProps.isLoggingIn
);

const api = {
  login: async (username: string, password: string): Promise<any> =>
    fetch('http://localhost/v6/auth/login', {
      credentials: 'include',
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ username, password, checkExpired: false }),
    }),
};
