import i18next from 'i18next';
import React, { useCallback } from 'react';

import { Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { useStyles } from './buttonsHeaderAndContent.jss';
import { HeaderAndContent, HeaderAndContentProps } from './headerAndContent';

export interface ButtonItem {
  label: string;
  onClick?: () => void;
  contained?: boolean;
  icon?: string;
  disabled?: boolean;
}

export interface ButtonsHeaderAndContentProps
  extends Pick<HeaderAndContentProps, Exclude<keyof HeaderAndContentProps, 'header'>> {
  buttons: ButtonItem[];
}

export const ButtonsHeaderAndContent: React.FunctionComponent<ButtonsHeaderAndContentProps> = React.memo(
  props => {
    const classes = useStyles({});

    const header = useCallback(
      () => (
        <>
          {props.buttons.map((button, i) => (
            <Button
              key={`Button_${button.label}`}
              className={classes.headerButton}
              variant={button.contained ? 'contained' : 'text'}
              color={button.contained ? 'secondary' : 'default'}
              onClick={button.onClick}
              disabled={button.disabled}
            >
              {button.icon && <Icon className={classes.headerButtonIconSmall}>{button.icon}</Icon>}
              {button.label}
            </Button>
          ))}
        </>
      ),
      [props.buttons, classes.headerButtonIconSmall, classes.headerButton]
    );

    return (
      <HeaderAndContent header={header} {...props}>
        {props.children}
      </HeaderAndContent>
    );
  }
);

export const customBtn = (
  label: string,
  onClick?: () => void,
  icon?: string,
  contained?: boolean
) => ({
  icon,
  onClick,
  label,
  contained,
});

export const createBtn = (onClick?: () => void, label?: string): ButtonItem => ({
  icon: 'add',
  label: label ? label : i18next.t('create'),
  onClick,
  contained: true,
});

export const editBtn = (onClick?: () => void, label?: string, disabled?: boolean): ButtonItem => ({
  icon: 'create',
  label: label ? label : i18next.t('edit'),
  onClick,
  disabled: disabled ? disabled : false,
});

export const deleteBtn = (onClick?: () => void, label?: string): ButtonItem => ({
  icon: 'delete',
  label: label ? label : i18next.t('delete'),
  onClick,
});

export const importBtn = (onClick?: () => void, label?: string): ButtonItem => ({
  icon: 'cloud_upload',
  label: label ? label : i18next.t('import'),
  onClick,
});

export const createEditDelete = (
  createFn: () => void,
  editFn: () => void,
  deleteFn: () => void
) => [createBtn(createFn), editBtn(editFn), deleteBtn(deleteFn)];
