import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1",
      minHeight: "1px",
    },
    contentWithOverflow: {
      overflowY: "auto"
    },
    contentWithPadding: {
      padding: theme.spacing(2),
      // paddingLeft: theme.spacing(2
    },
    '@keyframes fade': {
      from: { opacity: 1 },
      to: { opacity: 0.4 }
    },
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center'
    },
    disabled: {
      animationName: '$fade',
      animationDuration: '0.5s',
      animationFillMode: 'forwards',
      pointerEvents: "none"
    },
  });

export const useStyles = makeStyles(styles);
