import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center'
    },
    container: {
      background: 'white'
    }
  });
};

export const useStyles = makeStyles(styles);