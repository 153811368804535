import React, { useEffect, useRef } from 'react';

import { Input } from '@material-ui/core';

import { useStyles } from './programSchedule.jss';
import { SelectionRange } from './timeInput';

interface InputAwareOfSelectionProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  selectionRange?: SelectionRange;
}

export const InputAwareOfSelection = React.memo((props: InputAwareOfSelectionProps) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.selectionRange) {
      (inputRef.current as any).setSelectionRange(
        props.selectionRange.start,
        props.selectionRange.end
      );
    }
  }, [props.selectionRange]);

  return (
    <Input
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onKeyDown={props.onKeyDown}
      classes={{
        input: classes.myInput,
      }}
      inputProps={{
        ref: inputRef,
      }}
    />
  );
});