import React, { useEffect, useState } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import { InputAwareOfSelection } from './inputAwareOfSelection';
import { useStyles } from './programSchedule.jss';

const MinMax = {
  hour: { min: 0, max: 23 },
  minute: { min: 0, max: 59 },
  second: { min: 0, max: 59 },
};

interface TimeSelectionRanges {
  hour?: SelectionRange;
  minute?: SelectionRange;
  second?: SelectionRange;
}

export interface SelectionRange {
  start: number;
  end: number;
}

export interface HMS {
  hour: number;
  minute: number;
  second: number;
}

const Separator = React.memo(() => {
  const classes = useStyles();

  return <span className={classes.separator}>:</span>;
});

const TimeLabel = React.memo((props: { label: string }) => {
  const classes = useStyles();

  return <InputLabel classes={{ root: classes.timeLabel }}>{props.label}</InputLabel>;
});

export interface TimeInputProps {
  label?: string;
  defaultValue: HMS;
  disabled?: boolean;
  onChange?: (value: HMS) => void;
}

export const TimeInput = React.memo(
  (props: TimeInputProps) => {
    const [value, setValue] = useState(props.defaultValue);
    const [ranges, setRanges] = useState({} as TimeSelectionRanges);
    const [focus, setFocus] = useState('none');
    const classes = useStyles();

    useEffect(() => {
      if (props.defaultValue !== value) {
        setValue(props.defaultValue);
      }
    }, [props.defaultValue, value]);

    const getNumberFromInput = (input: string, selectionStart: number, min: number): number => {
      if (!input) {
        return min;
      }

      if (input.length === 1) {
        return Number(input[0]) * 10;
      }

      if (input.length === 2) {
        return Number(input);
      }

      if (input.length === 3) {
        if (selectionStart === 1) {
          return Number(input[0] + input[2]);
        } else if (selectionStart === 2) {
          return Number(input[0] + input[1]);
        }
      }

      return Number(input[0] + input[1]);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.currentTarget.value;
      const name = e.currentTarget.name;
      const previous = (value as any)[name];
      const { min, max } = (MinMax as any)[name];
      const i: number = e.target.selectionStart || 3;
      const j: number = e.target.selectionEnd || 3;

      if (/^\d*$/.test(v)) {
        const next = getNumberFromInput(v, i, min);

        if (next >= min && next <= max && previous !== next) {
          const nextValue = { ...value, [name]: next };
          setValue(nextValue);
          setRanges({
            ...ranges,
            [name]: {
              start: i,
              end: j,
            },
          });
          props.onChange && props.onChange(nextValue);
          return;
        }
      }

      setRanges({
        ...ranges,
        [name]: {
          start: i,
          end: j,
        },
      });
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocus(e.currentTarget.name);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 38) {
        e.preventDefault();
        add(1);
      } else if (e.keyCode === 40) {
        e.preventDefault();
        add(-1);
      }
    };

    const add = (a: number) => {
      const previous = (value as any)[focus];
      if (previous === undefined) {
        return;
      }

      const { min, max } = (MinMax as any)[focus];
      let next = previous + a;

      if (next > max) {
        next = min;
      }

      if (next < min) {
        next = max;
      }

      const nextValue = { ...value, [focus]: next };
      setValue(nextValue);
      props.onChange && props.onChange(nextValue);
    };

    return (
      <div>
        {props.label && <TimeLabel label={props.label} />}
        <div>
          <InputAwareOfSelection
            name="hour"
            value={value.hour._padLeft2()}
            disabled={props.disabled}
            onChange={handleInput}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            selectionRange={ranges.hour}
          />
          <Separator />
          <InputAwareOfSelection
            name="minute"
            value={value.minute._padLeft2()}
            disabled={props.disabled}
            onChange={handleInput}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            selectionRange={ranges.minute}
          />
          <Separator />
          <InputAwareOfSelection
            name="second"
            value={value.second._padLeft2()}
            disabled={props.disabled}
            onChange={handleInput}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            selectionRange={ranges.second}
          />
          <div className={classes.arrows}>
            <ArrowDropUp onClick={() => !props.disabled && add(1)} className={classes.arrow} />
            <ArrowDropDown onClick={() => !props.disabled && add(-1)} className={classes.arrow} />
          </div>
        </div>
      </div>
    );
  },
  (p, n) => p.defaultValue === n.defaultValue && p.label === n.label && p.disabled === n.disabled
);
