export const timezones = [
  {id: 1, name: 'Africa/Johannesburg'},
{id: 2, name: 'Africa/Abidjan'},
{id: 3, name: 'Africa/Accra'},
{id: 4, name: 'Africa/Addis_Ababa'},
{id: 5, name: 'Africa/Algiers'},
{id: 6, name: 'Africa/Asmara'},
{id: 7, name: 'Africa/Asmera'},
{id: 8, name: 'Africa/Bamako'},
{id: 9, name: 'Africa/Bangui'},
{id: 10, name: 'Africa/Banjul'},
{id: 11, name: 'Africa/Bissau'},
{id: 12, name: 'Africa/Blantyre'},
{id: 13, name: 'Africa/Brazzaville'},
{id: 14, name: 'Africa/Bujumbura'},
{id: 15, name: 'Africa/Cairo'},
{id: 16, name: 'Africa/Casablanca'},
{id: 17, name: 'Africa/Ceuta'},
{id: 18, name: 'Africa/Conakry'},
{id: 19, name: 'Africa/Dakar'},
{id: 20, name: 'Africa/Dar_es_Salaam'},
{id: 21, name: 'Africa/Djibouti'},
{id: 22, name: 'Africa/Douala'},
{id: 23, name: 'Africa/El_Aaiun'},
{id: 24, name: 'Africa/Freetown'},
{id: 25, name: 'Africa/Gaborone'},
{id: 26, name: 'Africa/Harare'},
{id: 27, name: 'Africa/Johannesburg'},
{id: 28, name: 'Africa/Juba'},
{id: 29, name: 'Africa/Kampala'},
{id: 30, name: 'Africa/Khartoum'},
{id: 31, name: 'Africa/Kigali'},
{id: 32, name: 'Africa/Kinshasa'},
{id: 33, name: 'Africa/Lagos'},
{id: 34, name: 'Africa/Libreville'},
{id: 35, name: 'Africa/Lome'},
{id: 36, name: 'Africa/Luanda'},
{id: 37, name: 'Africa/Lubumbashi'},
{id: 38, name: 'Africa/Lusaka'},
{id: 39, name: 'Africa/Malabo'},
{id: 40, name: 'Africa/Maputo'},
{id: 41, name: 'Africa/Maseru'},
{id: 42, name: 'Africa/Mbabane'},
{id: 43, name: 'Africa/Mogadishu'},
{id: 44, name: 'Africa/Monrovia'},
{id: 45, name: 'Africa/Nairobi'},
{id: 46, name: 'Africa/Ndjamena'},
{id: 47, name: 'Africa/Niamey'},
{id: 48, name: 'Africa/Nouakchott'},
{id: 49, name: 'Africa/Ouagadougou'},
{id: 50, name: 'Africa/Porto-Novo'},
{id: 51, name: 'Africa/Sao_Tome'},
{id: 52, name: 'Africa/Timbuktu'},
{id: 53, name: 'Africa/Tripoli'},
{id: 54, name: 'Africa/Tunis'},
{id: 55, name: 'Africa/Windhoek'},
{id: 56, name: 'America/Adak'},
{id: 57, name: 'America/Anchorage'},
{id: 58, name: 'America/Anguilla'},
{id: 59, name: 'America/Antigua'},
{id: 60, name: 'America/Araguaina'},
{id: 61, name: 'America/Argentina/Buenos_Aires'},
{id: 62, name: 'America/Argentina/Catamarca'},
{id: 63, name: 'America/Argentina/ComodRivadavia'},
{id: 64, name: 'America/Argentina/Cordoba'},
{id: 65, name: 'America/Argentina/Jujuy'},
{id: 66, name: 'America/Argentina/La_Rioja'},
{id: 67, name: 'America/Argentina/Mendoza'},
{id: 68, name: 'America/Argentina/Rio_Gallegos'},
{id: 69, name: 'America/Argentina/Salta'},
{id: 70, name: 'America/Argentina/San_Juan'},
{id: 71, name: 'America/Argentina/San_Luis'},
{id: 72, name: 'America/Argentina/Tucuman'},
{id: 73, name: 'America/Argentina/Ushuaia'},
{id: 74, name: 'America/Aruba'},
{id: 75, name: 'America/Asuncion'},
{id: 76, name: 'America/Atikokan'},
{id: 77, name: 'America/Atka'},
{id: 78, name: 'America/Bahia'},
{id: 79, name: 'America/Bahia_Banderas'},
{id: 80, name: 'America/Barbados'},
{id: 81, name: 'America/Belem'},
{id: 82, name: 'America/Belize'},
{id: 83, name: 'America/Blanc-Sablon'},
{id: 84, name: 'America/Boa_Vista'},
{id: 85, name: 'America/Bogota'},
{id: 86, name: 'America/Boise'},
{id: 87, name: 'America/Buenos_Aires'},
{id: 88, name: 'America/Cambridge_Bay'},
{id: 89, name: 'America/Campo_Grande'},
{id: 90, name: 'America/Cancun'},
{id: 91, name: 'America/Caracas'},
{id: 92, name: 'America/Catamarca'},
{id: 93, name: 'America/Cayenne'},
{id: 94, name: 'America/Cayman'},
{id: 95, name: 'America/Chicago'},
{id: 96, name: 'America/Chihuahua'},
{id: 97, name: 'America/Coral_Harbour'},
{id: 98, name: 'America/Cordoba'},
{id: 99, name: 'America/Costa_Rica'},
{id: 100, name: 'America/Creston'},
{id: 101, name: 'America/Cuiaba'},
{id: 102, name: 'America/Curacao'},
{id: 103, name: 'America/Danmarkshavn'},
{id: 104, name: 'America/Dawson'},
{id: 105, name: 'America/Dawson_Creek'},
{id: 106, name: 'America/Denver'},
{id: 107, name: 'America/Detroit'},
{id: 108, name: 'America/Dominica'},
{id: 109, name: 'America/Edmonton'},
{id: 110, name: 'America/Eirunepe'},
{id: 111, name: 'America/El_Salvador'},
{id: 112, name: 'America/Ensenada'},
{id: 113, name: 'America/Fort_Wayne'},
{id: 114, name: 'America/Fortaleza'},
{id: 115, name: 'America/Glace_Bay'},
{id: 116, name: 'America/Godthab'},
{id: 117, name: 'America/Goose_Bay'},
{id: 118, name: 'America/Grand_Turk'},
{id: 119, name: 'America/Grenada'},
{id: 120, name: 'America/Guadeloupe'},
{id: 121, name: 'America/Guatemala'},
{id: 122, name: 'America/Guayaquil'},
{id: 123, name: 'America/Guyana'},
{id: 124, name: 'America/Halifax'},
{id: 125, name: 'America/Havana'},
{id: 126, name: 'America/Hermosillo'},
{id: 127, name: 'America/Indiana/Indianapolis'},
{id: 128, name: 'America/Indiana/Knox'},
{id: 129, name: 'America/Indiana/Marengo'},
{id: 130, name: 'America/Indiana/Petersburg'},
{id: 131, name: 'America/Indiana/Tell_City'},
{id: 132, name: 'America/Indiana/Vevay'},
{id: 133, name: 'America/Indiana/Vincennes'},
{id: 134, name: 'America/Indiana/Winamac'},
{id: 135, name: 'America/Indianapolis'},
{id: 136, name: 'America/Inuvik'},
{id: 137, name: 'America/Iqaluit'},
{id: 138, name: 'America/Jamaica'},
{id: 139, name: 'America/Jujuy'},
{id: 140, name: 'America/Juneau'},
{id: 141, name: 'America/Kentucky/Louisville'},
{id: 142, name: 'America/Kentucky/Monticello'},
{id: 143, name: 'America/Knox_IN'},
{id: 144, name: 'America/Kralendijk'},
{id: 145, name: 'America/La_Paz'},
{id: 146, name: 'America/Lima'},
{id: 147, name: 'America/Los_Angeles'},
{id: 148, name: 'America/Louisville'},
{id: 149, name: 'America/Lower_Princes'},
{id: 150, name: 'America/Maceio'},
{id: 151, name: 'America/Managua'},
{id: 152, name: 'America/Manaus'},
{id: 153, name: 'America/Marigot'},
{id: 154, name: 'America/Martinique'},
{id: 155, name: 'America/Matamoros'},
{id: 156, name: 'America/Mazatlan'},
{id: 157, name: 'America/Mendoza'},
{id: 158, name: 'America/Menominee'},
{id: 159, name: 'America/Merida'},
{id: 160, name: 'America/Metlakatla'},
{id: 161, name: 'America/Mexico_City'},
{id: 162, name: 'America/Miquelon'},
{id: 163, name: 'America/Moncton'},
{id: 164, name: 'America/Monterrey'},
{id: 165, name: 'America/Montevideo'},
{id: 166, name: 'America/Montreal'},
{id: 167, name: 'America/Montserrat'},
{id: 168, name: 'America/Nassau'},
{id: 169, name: 'America/New_York'},
{id: 170, name: 'America/Nipigon'},
{id: 171, name: 'America/Nome'},
{id: 172, name: 'America/Noronha'},
{id: 173, name: 'America/North_Dakota/Beulah'},
{id: 174, name: 'America/North_Dakota/Center'},
{id: 175, name: 'America/North_Dakota/New_Salem'},
{id: 176, name: 'America/Ojinaga'},
{id: 177, name: 'America/Panama'},
{id: 178, name: 'America/Pangnirtung'},
{id: 179, name: 'America/Paramaribo'},
{id: 180, name: 'America/Phoenix'},
{id: 181, name: 'America/Port-au-Prince'},
{id: 182, name: 'America/Port_of_Spain'},
{id: 183, name: 'America/Porto_Acre'},
{id: 184, name: 'America/Porto_Velho'},
{id: 185, name: 'America/Puerto_Rico'},
{id: 186, name: 'America/Rainy_River'},
{id: 187, name: 'America/Rankin_Inlet'},
{id: 188, name: 'America/Recife'},
{id: 189, name: 'America/Regina'},
{id: 190, name: 'America/Resolute'},
{id: 191, name: 'America/Rio_Branco'},
{id: 192, name: 'America/Rosario'},
{id: 193, name: 'America/Santa_Isabel'},
{id: 194, name: 'America/Santarem'},
{id: 195, name: 'America/Santiago'},
{id: 196, name: 'America/Santo_Domingo'},
{id: 197, name: 'America/Sao_Paulo'},
{id: 198, name: 'America/Scoresbysund'},
{id: 199, name: 'America/Shiprock'},
{id: 200, name: 'America/Sitka'},
{id: 201, name: 'America/St_Barthelemy'},
{id: 202, name: 'America/St_Johns'},
{id: 203, name: 'America/St_Kitts'},
{id: 204, name: 'America/St_Lucia'},
{id: 205, name: 'America/St_Thomas'},
{id: 206, name: 'America/St_Vincent'},
{id: 207, name: 'America/Swift_Current'},
{id: 208, name: 'America/Tegucigalpa'},
{id: 209, name: 'America/Thule'},
{id: 210, name: 'America/Thunder_Bay'},
{id: 211, name: 'America/Tijuana'},
{id: 212, name: 'America/Toronto'},
{id: 213, name: 'America/Tortola'},
{id: 214, name: 'America/Vancouver'},
{id: 215, name: 'America/Virgin'},
{id: 216, name: 'America/Whitehorse'},
{id: 217, name: 'America/Winnipeg'},
{id: 218, name: 'America/Yakutat'},
{id: 219, name: 'America/Yellowknife'},
{id: 220, name: 'Antarctica/Casey'},
{id: 221, name: 'Antarctica/Davis'},
{id: 222, name: 'Antarctica/DumontDUrville'},
{id: 223, name: 'Antarctica/Macquarie'},
{id: 224, name: 'Antarctica/Mawson'},
{id: 225, name: 'Antarctica/McMurdo'},
{id: 226, name: 'Antarctica/Palmer'},
{id: 227, name: 'Antarctica/Rothera'},
{id: 228, name: 'Antarctica/South_Pole'},
{id: 229, name: 'Antarctica/Syowa'},
{id: 230, name: 'Antarctica/Vostok'},
{id: 231, name: 'Arctic/Longyearbyen'},
{id: 232, name: 'Asia/Aden'},
{id: 233, name: 'Asia/Almaty'},
{id: 234, name: 'Asia/Amman'},
{id: 235, name: 'Asia/Anadyr'},
{id: 236, name: 'Asia/Aqtau'},
{id: 237, name: 'Asia/Aqtobe'},
{id: 238, name: 'Asia/Ashgabat'},
{id: 239, name: 'Asia/Ashkhabad'},
{id: 240, name: 'Asia/Baghdad'},
{id: 241, name: 'Asia/Bahrain'},
{id: 242, name: 'Asia/Baku'},
{id: 243, name: 'Asia/Bangkok'},
{id: 244, name: 'Asia/Beirut'},
{id: 245, name: 'Asia/Bishkek'},
{id: 246, name: 'Asia/Brunei'},
{id: 247, name: 'Asia/Calcutta'},
{id: 248, name: 'Asia/Choibalsan'},
{id: 249, name: 'Asia/Chongqing'},
{id: 250, name: 'Asia/Chungking'},
{id: 251, name: 'Asia/Colombo'},
{id: 252, name: 'Asia/Dacca'},
{id: 253, name: 'Asia/Damascus'},
{id: 254, name: 'Asia/Dhaka'},
{id: 255, name: 'Asia/Dili'},
{id: 256, name: 'Asia/Dubai'},
{id: 257, name: 'Asia/Dushanbe'},
{id: 258, name: 'Asia/Gaza'},
{id: 259, name: 'Asia/Harbin'},
{id: 260, name: 'Asia/Hebron'},
{id: 261, name: 'Asia/Ho_Chi_Minh'},
{id: 262, name: 'Asia/Hong_Kong'},
{id: 263, name: 'Asia/Hovd'},
{id: 264, name: 'Asia/Irkutsk'},
{id: 265, name: 'Asia/Istanbul'},
{id: 266, name: 'Asia/Jakarta'},
{id: 267, name: 'Asia/Jayapura'},
{id: 268, name: 'Asia/Jerusalem'},
{id: 269, name: 'Asia/Kabul'},
{id: 270, name: 'Asia/Kamchatka'},
{id: 271, name: 'Asia/Karachi'},
{id: 272, name: 'Asia/Kashgar'},
{id: 273, name: 'Asia/Kathmandu'},
{id: 274, name: 'Asia/Katmandu'},
{id: 275, name: 'Asia/Kolkata'},
{id: 276, name: 'Asia/Krasnoyarsk'},
{id: 277, name: 'Asia/Kuala_Lumpur'},
{id: 278, name: 'Asia/Kuching'},
{id: 279, name: 'Asia/Kuwait'},
{id: 280, name: 'Asia/Macao'},
{id: 281, name: 'Asia/Macau'},
{id: 282, name: 'Asia/Magadan'},
{id: 283, name: 'Asia/Makassar'},
{id: 284, name: 'Asia/Manila'},
{id: 285, name: 'Asia/Muscat'},
{id: 286, name: 'Asia/Nicosia'},
{id: 287, name: 'Asia/Novokuznetsk'},
{id: 288, name: 'Asia/Novosibirsk'},
{id: 289, name: 'Asia/Omsk'},
{id: 290, name: 'Asia/Oral'},
{id: 291, name: 'Asia/Phnom_Penh'},
{id: 292, name: 'Asia/Pontianak'},
{id: 293, name: 'Asia/Pyongyang'},
{id: 294, name: 'Asia/Qatar'},
{id: 295, name: 'Asia/Qyzylorda'},
{id: 296, name: 'Asia/Rangoon'},
{id: 297, name: 'Asia/Riyadh'},
{id: 298, name: 'Asia/Saigon'},
{id: 299, name: 'Asia/Sakhalin'},
{id: 300, name: 'Asia/Samarkand'},
{id: 301, name: 'Asia/Seoul'},
{id: 302, name: 'Asia/Shanghai'},
{id: 303, name: 'Asia/Singapore'},
{id: 304, name: 'Asia/Taipei'},
{id: 305, name: 'Asia/Tashkent'},
{id: 306, name: 'Asia/Tbilisi'},
{id: 307, name: 'Asia/Tehran'},
{id: 308, name: 'Asia/Tel_Aviv'},
{id: 309, name: 'Asia/Thimbu'},
{id: 310, name: 'Asia/Thimphu'},
{id: 311, name: 'Asia/Tokyo'},
{id: 312, name: 'Asia/Ujung_Pandang'},
{id: 313, name: 'Asia/Ulaanbaatar'},
{id: 314, name: 'Asia/Ulan_Bator'},
{id: 315, name: 'Asia/Urumqi'},
{id: 316, name: 'Asia/Vientiane'},
{id: 317, name: 'Asia/Vladivostok'},
{id: 318, name: 'Asia/Yakutsk'},
{id: 319, name: 'Asia/Yekaterinburg'},
{id: 320, name: 'Asia/Yerevan'},
{id: 321, name: 'Atlantic/Azores'},
{id: 322, name: 'Atlantic/Bermuda'},
{id: 323, name: 'Atlantic/Canary'},
{id: 324, name: 'Atlantic/Cape_Verde'},
{id: 325, name: 'Atlantic/Faeroe'},
{id: 326, name: 'Atlantic/Faroe'},
{id: 327, name: 'Atlantic/Jan_Mayen'},
{id: 328, name: 'Atlantic/Madeira'},
{id: 329, name: 'Atlantic/Reykjavik'},
{id: 330, name: 'Atlantic/South_Georgia'},
{id: 331, name: 'Atlantic/St_Helena'},
{id: 332, name: 'Atlantic/Stanley'},
{id: 333, name: 'Australia/ACT'},
{id: 334, name: 'Australia/Adelaide'},
{id: 335, name: 'Australia/Brisbane'},
{id: 336, name: 'Australia/Broken_Hill'},
{id: 337, name: 'Australia/Canberra'},
{id: 338, name: 'Australia/Currie'},
{id: 339, name: 'Australia/Darwin'},
{id: 340, name: 'Australia/Eucla'},
{id: 341, name: 'Australia/Hobart'},
{id: 342, name: 'Australia/LHI'},
{id: 343, name: 'Australia/Lindeman'},
{id: 344, name: 'Australia/Lord_Howe'},
{id: 345, name: 'Australia/Melbourne'},
{id: 346, name: 'Australia/NSW'},
{id: 347, name: 'Australia/North'},
{id: 348, name: 'Australia/Perth'},
{id: 349, name: 'Australia/Queensland'},
{id: 350, name: 'Australia/South'},
{id: 351, name: 'Australia/Sydney'},
{id: 352, name: 'Australia/Tasmania'},
{id: 353, name: 'Australia/Victoria'},
{id: 354, name: 'Australia/West'},
{id: 355, name: 'Australia/Yancowinna'},
{id: 356, name: 'Brazil/Acre'},
{id: 357, name: 'Brazil/DeNoronha'},
{id: 358, name: 'Brazil/East'},
{id: 359, name: 'Brazil/West'},
{id: 360, name: 'CET'},
{id: 361, name: 'CST6CDT'},
{id: 362, name: 'Canada/Atlantic'},
{id: 363, name: 'Canada/Central'},
{id: 364, name: 'Canada/East-Saskatchewan'},
{id: 365, name: 'Canada/Eastern'},
{id: 366, name: 'Canada/Mountain'},
{id: 367, name: 'Canada/Newfoundland'},
{id: 368, name: 'Canada/Pacific'},
{id: 369, name: 'Canada/Saskatchewan'},
{id: 370, name: 'Canada/Yukon'},
{id: 371, name: 'Chile/Continental'},
{id: 372, name: 'Chile/EasterIsland'},
{id: 373, name: 'Cuba'},
{id: 374, name: 'EET'},
{id: 375, name: 'EST'},
{id: 376, name: 'EST5EDT'},
{id: 377, name: 'Egypt'},
{id: 378, name: 'Eire'},
{id: 379, name: 'Etc/GMT'},
{id: 380, name: 'Etc/GMT+0'},
{id: 381, name: 'Etc/GMT+1'},
{id: 382, name: 'Etc/GMT+10'},
{id: 383, name: 'Etc/GMT+11'},
{id: 384, name: 'Etc/GMT+12'},
{id: 385, name: 'Etc/GMT+2'},
{id: 386, name: 'Etc/GMT+3'},
{id: 387, name: 'Etc/GMT+4'},
{id: 388, name: 'Etc/GMT+5'},
{id: 389, name: 'Etc/GMT+6'},
{id: 390, name: 'Etc/GMT+7'},
{id: 391, name: 'Etc/GMT+8'},
{id: 392, name: 'Etc/GMT+9'},
{id: 393, name: 'Etc/GMT-0'},
{id: 394, name: 'Etc/GMT-1'},
{id: 395, name: 'Etc/GMT-10'},
{id: 396, name: 'Etc/GMT-11'},
{id: 397, name: 'Etc/GMT-12'},
{id: 398, name: 'Etc/GMT-13'},
{id: 399, name: 'Etc/GMT-14'},
{id: 400, name: 'Etc/GMT-2'},
{id: 401, name: 'Etc/GMT-3'},
{id: 402, name: 'Etc/GMT-4'},
{id: 403, name: 'Etc/GMT-5'},
{id: 404, name: 'Etc/GMT-6'},
{id: 405, name: 'Etc/GMT-7'},
{id: 406, name: 'Etc/GMT-8'},
{id: 407, name: 'Etc/GMT-9'},
{id: 408, name: 'Etc/GMT0'},
{id: 409, name: 'Etc/Greenwich'},
{id: 410, name: 'Etc/UCT'},
{id: 411, name: 'Etc/UTC'},
{id: 412, name: 'Etc/Universal'},
{id: 413, name: 'Etc/Zulu'},
{id: 414, name: 'Europe/Amsterdam'},
{id: 415, name: 'Europe/Andorra'},
{id: 416, name: 'Europe/Athens'},
{id: 417, name: 'Europe/Belfast'},
{id: 418, name: 'Europe/Belgrade'},
{id: 419, name: 'Europe/Berlin'},
{id: 420, name: 'Europe/Bratislava'},
{id: 421, name: 'Europe/Brussels'},
{id: 422, name: 'Europe/Bucharest'},
{id: 423, name: 'Europe/Budapest'},
{id: 424, name: 'Europe/Chisinau'},
{id: 425, name: 'Europe/Copenhagen'},
{id: 426, name: 'Europe/Dublin'},
{id: 427, name: 'Europe/Gibraltar'},
{id: 428, name: 'Europe/Guernsey'},
{id: 429, name: 'Europe/Helsinki'},
{id: 430, name: 'Europe/Isle_of_Man'},
{id: 431, name: 'Europe/Istanbul'},
{id: 432, name: 'Europe/Jersey'},
{id: 433, name: 'Europe/Kaliningrad'},
{id: 434, name: 'Europe/Kiev'},
{id: 435, name: 'Europe/Lisbon'},
{id: 436, name: 'Europe/Ljubljana'},
{id: 437, name: 'Europe/London'},
{id: 438, name: 'Europe/Luxembourg'},
{id: 439, name: 'Europe/Madrid'},
{id: 440, name: 'Europe/Malta'},
{id: 441, name: 'Europe/Mariehamn'},
{id: 442, name: 'Europe/Minsk'},
{id: 443, name: 'Europe/Monaco'},
{id: 444, name: 'Europe/Moscow'},
{id: 445, name: 'Europe/Nicosia'},
{id: 446, name: 'Europe/Oslo'},
{id: 447, name: 'Europe/Paris'},
{id: 448, name: 'Europe/Podgorica'},
{id: 449, name: 'Europe/Prague'},
{id: 450, name: 'Europe/Riga'},
{id: 451, name: 'Europe/Rome'},
{id: 452, name: 'Europe/Samara'},
{id: 453, name: 'Europe/San_Marino'},
{id: 454, name: 'Europe/Sarajevo'},
{id: 455, name: 'Europe/Simferopol'},
{id: 456, name: 'Europe/Skopje'},
{id: 457, name: 'Europe/Sofia'},
{id: 458, name: 'Europe/Stockholm'},
{id: 459, name: 'Europe/Tallinn'},
{id: 460, name: 'Europe/Tirane'},
{id: 461, name: 'Europe/Tiraspol'},
{id: 462, name: 'Europe/Uzhgorod'},
{id: 463, name: 'Europe/Vaduz'},
{id: 464, name: 'Europe/Vatican'},
{id: 465, name: 'Europe/Vienna'},
{id: 466, name: 'Europe/Vilnius'},
{id: 467, name: 'Europe/Volgograd'},
{id: 468, name: 'Europe/Warsaw'},
{id: 469, name: 'Europe/Zagreb'},
{id: 470, name: 'Europe/Zaporozhye'},
{id: 471, name: 'Europe/Zurich'},
{id: 472, name: 'GB'},
{id: 473, name: 'GB-Eire'},
{id: 474, name: 'GMT'},
{id: 475, name: 'GMT+0'},
{id: 476, name: 'GMT-0'},
{id: 477, name: 'GMT0'},
{id: 478, name: 'Greenwich'},
{id: 479, name: 'HST'},
{id: 480, name: 'Hongkong'},
{id: 481, name: 'Iceland'},
{id: 482, name: 'Indian/Antananarivo'},
{id: 483, name: 'Indian/Chagos'},
{id: 484, name: 'Indian/Christmas'},
{id: 485, name: 'Indian/Cocos'},
{id: 486, name: 'Indian/Comoro'},
{id: 487, name: 'Indian/Kerguelen'},
{id: 488, name: 'Indian/Mahe'},
{id: 489, name: 'Indian/Maldives'},
{id: 490, name: 'Indian/Mauritius'},
{id: 491, name: 'Indian/Mayotte'},
{id: 492, name: 'Indian/Reunion'},
{id: 493, name: 'Iran'},
{id: 494, name: 'Israel'},
{id: 495, name: 'Jamaica'},
{id: 496, name: 'Japan'},
{id: 497, name: 'Kwajalein'},
{id: 498, name: 'Libya'},
{id: 499, name: 'MET'},
{id: 500, name: 'MST'},
{id: 501, name: 'MST7MDT'},
{id: 502, name: 'Mexico/BajaNorte'},
{id: 503, name: 'Mexico/BajaSur'},
{id: 504, name: 'Mexico/General'},
{id: 505, name: 'NZ'},
{id: 506, name: 'NZ-CHAT'},
{id: 507, name: 'Navajo'},
{id: 508, name: 'PRC'},
{id: 509, name: 'PST8PDT'},
{id: 510, name: 'Pacific/Apia'},
{id: 511, name: 'Pacific/Auckland'},
{id: 512, name: 'Pacific/Chatham'},
{id: 513, name: 'Pacific/Chuuk'},
{id: 514, name: 'Pacific/Easter'},
{id: 515, name: 'Pacific/Efate'},
{id: 516, name: 'Pacific/Enderbury'},
{id: 517, name: 'Pacific/Fakaofo'},
{id: 518, name: 'Pacific/Fiji'},
{id: 519, name: 'Pacific/Funafuti'},
{id: 520, name: 'Pacific/Galapagos'},
{id: 521, name: 'Pacific/Gambier'},
{id: 522, name: 'Pacific/Guadalcanal'},
{id: 523, name: 'Pacific/Guam'},
{id: 524, name: 'Pacific/Honolulu'},
{id: 525, name: 'Pacific/Johnston'},
{id: 526, name: 'Pacific/Kiritimati'},
{id: 527, name: 'Pacific/Kosrae'},
{id: 528, name: 'Pacific/Kwajalein'},
{id: 529, name: 'Pacific/Majuro'},
{id: 530, name: 'Pacific/Marquesas'},
{id: 531, name: 'Pacific/Midway'},
{id: 532, name: 'Pacific/Nauru'},
{id: 533, name: 'Pacific/Niue'},
{id: 534, name: 'Pacific/Norfolk'},
{id: 535, name: 'Pacific/Noumea'},
{id: 536, name: 'Pacific/Pago_Pago'},
{id: 537, name: 'Pacific/Palau'},
{id: 538, name: 'Pacific/Pitcairn'},
{id: 539, name: 'Pacific/Pohnpei'},
{id: 540, name: 'Pacific/Ponape'},
{id: 541, name: 'Pacific/Port_Moresby'},
{id: 542, name: 'Pacific/Rarotonga'},
{id: 543, name: 'Pacific/Saipan'},
{id: 544, name: 'Pacific/Samoa'},
{id: 545, name: 'Pacific/Tahiti'},
{id: 546, name: 'Pacific/Tarawa'},
{id: 547, name: 'Pacific/Tongatapu'},
{id: 548, name: 'Pacific/Truk'},
{id: 549, name: 'Pacific/Wake'},
{id: 550, name: 'Pacific/Wallis'},
{id: 551, name: 'Pacific/Yap'},
{id: 552, name: 'Poland'},
{id: 553, name: 'Portugal'},
{id: 554, name: 'ROC'},
{id: 555, name: 'ROK'},
{id: 556, name: 'Singapore'},
{id: 557, name: 'Turkey'},
{id: 558, name: 'UCT'},
{id: 559, name: 'US/Alaska'},
{id: 560, name: 'US/Aleutian'},
{id: 561, name: 'US/Arizona'},
{id: 562, name: 'US/Central'},
{id: 563, name: 'US/East-Indiana'},
{id: 564, name: 'US/Eastern'},
{id: 565, name: 'US/Hawaii'},
{id: 566, name: 'US/Indiana-Starke'},
{id: 567, name: 'US/Michigan'},
{id: 568, name: 'US/Mountain'},
{id: 569, name: 'US/Pacific'},
{id: 570, name: 'US/Samoa'},
{id: 571, name: 'UTC'},
{id: 572, name: 'Universal'},
{id: 573, name: 'W-SU'},
{id: 574, name: 'WET'},
{id: 575, name: 'Zulu '},
];
