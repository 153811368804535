import React from 'react';

import { MenuItem } from '@material-ui/core';

export const enumItemsFn = <T extends any>(enumType: T) => {
  return Object.keys(enumType)
    .filter(key => Number.isNaN(Number(key)))
    .map(key => (
      <MenuItem key={key} value={enumType[key]}>
        {key}
      </MenuItem>
    ));
};
