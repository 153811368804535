import React from 'react';

import { FileUploadResult } from './types';
import { UploadFile } from './uploadFile';

export interface UploadFileListProps {
  files: File[];
  chunkSize: number;
  canUpload: boolean;
  filesAreContent: boolean;
  onFileComplete?: (result: FileUploadResult) => void;
}

export const UploadFileList: React.FunctionComponent<UploadFileListProps> = React.memo(props => {
  return (
    <div>
      {props.files.map(f => (
        <UploadFile
          key={f.name}
          file={f}
          filesAreContent={props.filesAreContent}
          chunkSize={props.chunkSize}
          canUpload={props.canUpload}
          onFileComplete={props.onFileComplete}
        />
      ))}
    </div>
  );
});
