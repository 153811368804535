import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    dateInput: {
      '& ::-webkit-clear-button': {
        display: 'none',
      },
    },
    myInput: {
      width: theme.spacing(3),
      textAlign: 'center',
    },
    separator: {
      padding: theme.spacing(1) / 2,
    },
    timeLabel: {
      fontSize: '0.8rem',
    },
    arrows: {
      display: 'inline-flex',
      flexDirection: 'column',
      marginLeft: '8px',
    },
    arrow: {
      cursor: 'pointer',
    },
    form: {
      flex: 1,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    weekdaysButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    weekendButton: {
      marginRight: theme.spacing(1),
    },
    calendar: {
      boxShadow: `0px 1px 5px 0px rgba(0,0,0,0.2), 
                  0px 2px 2px 0px rgba(0,0,0,0.14), 
                  0px 3px 1px -2px rgba(0,0,0,0.12)`,
      '& .react-datepicker__day--selected': {
        background: theme.palette.secondary.main,
      },
      '& .react-datepicker__header': {
        background: theme.palette.grey[100],
      },
    },
  });
};

export const useStyles = makeStyles(styles);
