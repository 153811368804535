import {
  ButtonsHeaderAndContent,
  createBtn,
  customBtn,
  deleteBtn,
  editBtn,
} from 'app/components/bladeLayout/buttonsHeaderAndContent';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../../localization/i18n';
import { DataGridContainer, DataGridSelectionType } from '../../../../components/dataGrid';
import { DeleteConfirmDialog, useDeleteConfirm } from '../../../../components/deleteConfirmDialog';
import { EntityType, IdName, User } from '../../../../dataStore';
import { Loading } from '../../../../newnity/blades/search/results/loading';
import { columnSettings } from './columnSettings';
import { useStyles } from './workgroupUsersList.jss';

export interface WorkgroupUsersListProps {
  workgroupId: number;
  users: User[];
  removingUser: boolean;
  isFetching: boolean;
  isEditing: boolean;
}

export interface WorkgroupUsersListActions {
  fetchUsers: () => void;
  removeUsers: (userIds: number[]) => void;
  openNewUserBlade: () => void;
  openUsersList: () => void;
  openEditUser: (userId: number, firstName: string, lastName: string) => void;
}

type Props = WorkgroupUsersListActions & WorkgroupUsersListProps;

const userRemoveMessage = (selected: User[], propsUsers: User[], isEditing: boolean) => {
  if (selected.length > 1) {
    return i18n.t('workgroup.users.remove.confirm.generic', { count: selected.length });
  } else {
    const userLogin = propsUsers.find(user => selected[0].id === user.id);
    let removeMessage = '';
    if (isEditing) {
      removeMessage = removeMessage + i18n.t('workgroup.users.remove.confirm.editingUser');
    }
    if (userLogin) {
      removeMessage =
        removeMessage +
        i18n.t('workgroup.users.remove.confirm.message', { userLogin: userLogin.login });
    }
    return removeMessage;
  }
};

export const WorkgroupUserList: React.FunctionComponent<Props> = props => {
  const [removeConfirmUsers, setRemoveConfirmUsers] = useDeleteConfirm();
  const [selected, setSelected] = useState<User[]>([]);
  const classes = useStyles();
  const [t] = useTranslation();

  useEffect(() => {
    props.fetchUsers();
  }, [props.fetchUsers]);

  const onSelect = useCallback((users: IdName[]) => setSelected(users as User[]), []);

  const onUserRemove = useCallback(() => {
    props.removeUsers(removeConfirmUsers.id as number[]);
    setRemoveConfirmUsers({
      id: selected.map((user: User) => user.id),
      name: '',
      confirmNeeded: false,
    });
  }, [removeConfirmUsers, selected, props.removeUsers]);

  const onEditUserClick = useCallback(() => {
    if (selected.length === 0) {
      return;
    }

    const userId = selected[0].id;
    const userToEdit = props.users.filter((user: User) => user.id === userId)[0];

    const firstName = userToEdit.firstName;
    const lastName = userToEdit.lastName;

    props.openEditUser(userId, firstName, lastName);
  }, [selected, props.users, props.openEditUser]);

  const onRemoveUsersClick = useCallback(() => {
    if (selected.length > 0) {
      setRemoveConfirmUsers({
        id: selected.map((user: User) => user.id),
        name: selected.map((user: User) => user.email).join(','),
        confirmNeeded: true,
      });
    }
  }, [selected]);

  const handleDeleteCancelled = useCallback(
    () =>
      setRemoveConfirmUsers({
        id: 0,
        name: '',
        confirmNeeded: false,
      }),
    []
  );

  const deleteConfirmMessage = useMemo(
    () => (selected.length > 0 ? userRemoveMessage(selected, props.users, props.isEditing) : ''),
    [selected, props.users, props.isEditing]
  );

  const disableEdit = useMemo(() => selected.length > 1, [selected]);

  const buttons = useMemo(
    () => [
      createBtn(props.openNewUserBlade, t('create')),
      customBtn(t('add'), props.openUsersList, 'add'),
      editBtn(onEditUserClick, t('edit'), disableEdit),
      deleteBtn(onRemoveUsersClick, t('remove')),
    ],
    [disableEdit, onEditUserClick, onRemoveUsersClick, props.openNewUserBlade, props.openUsersList]
  );

  return (
    <ButtonsHeaderAndContent
      isBusy={props.removingUser}
      buttons={buttons}
      removePadding
      removeVerticalScroll
    >
      {props.isFetching ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <DataGridContainer
            entityType={EntityType.User}
            onSelect={onSelect}
            defaultSelection={selected}
            items={props.users}
            selectionType={DataGridSelectionType.Multiple}
            columnSettings={columnSettings}
          />
          <DeleteConfirmDialog
            important={false}
            title={t('workgroup.users.remove.confirm.title')}
            message={deleteConfirmMessage}
            open={removeConfirmUsers.confirmNeeded}
            onDelete={onUserRemove}
            onCancel={handleDeleteCancelled}
            deleteLabel={t('workgroup.users.dialog.remove')}
          />
        </div>
      )}
    </ButtonsHeaderAndContent>
  );
};
