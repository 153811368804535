import produce from 'immer';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { EntityType } from '../../dataStore';
import { parseDate, parseTimeSpan } from '../../utils/dateTime';
import * as Actions from './actions';
import { getDefaultSchedule } from './api/stationApi';
import {
    ActionTypes, AnthemMediaType, ImportState, NewnityState, SearchFields, StationScheduleSlot
} from './types';

export const emptySearchFields: SearchFields = {
  company: {
    number: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
  },
  device: {
    deviceNumber: '',
    deviceDescription: '',
    serial: '',
    locationNumber: '',
    locationName: '',
    siteNumber: '',
    company: '',
    salesOrder: '',
  },
  location: {
    locationNumber: '',
    locationName: '',
    deviceNumber: '',
    serial: '',
    siteNumber: '',
    company: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
};

const initialImportState: ImportState = {
  importFetchState: {
    fetchCompleted: false,
    isFetching: false,
    fetchError: '',
    id: '',
  },parseFileFetchState: {
    fetchCompleted: false,
    isFetching: false,
    fetchError: '',
    id: '',
  },
  importResult: {
    devices: {
      completed: 0,
      skipped: [],
      skippedRowCount: 0,
      total: 0,
    },
    locations: {
      completed: 0,
      skipped: [],
      skippedRowCount: 0,
      total: 0,
    },
  },
  
  parseFileResult: {
    devices: {
      completed: 0,
      skipped: [],
      skippedRowCount: 0,
      total: 0,
    },
    locations: {
      completed: 0,
      skipped: [],
      skippedRowCount: 0,
      total: 0,
    },
    deviceSettings: {
      completed: 0,
      skipped: [],
      skippedRowCount: 0,
      total: 0,
    },
  }
};

let initialNewnityState: NewnityState = {
  enabled: false,
  loadingCompanyMode: false,
  inCompanyMode: false,
  selectedLocations: [],
  selectedDevices: [],
  programAsset: {},
  import: initialImportState,
  currentCompany: {
    fetchingData: false,
    companyId: 0,
    fetchError: '',
    savingState: {
      fetchCompleted: false,
      isFetching: false,
      fetchError: '',
      id: 0,
    },
  },
  currentLocation: {
    savingData: false,
    deletingLocation: false,
    isEditing: false,
  },
  currentDevice: {
    saved: false,
    isSaving: false,
    locationId: 0,
    stationId: 0,
    stationName: '',
    deletingDevice: false,
    isEditing: false,
  },
  currentStation: {
    fetchingData: {
      fetchCompleted: false,
      fetchError: '',
      id: 0,
      isFetching: false,      
    },
    deletingStation: false,
    createSavingState: {
      fetchCompleted: false,
      isFetching: false,
      fetchError: '',
      id: 0,
    },
    editSavingState: {
      fetchCompleted: false,
      isFetching: false,
      fetchError: '',
      id: 0,
    },
    scheduleEdittingSlotId: -2,
    blockRemoteScrolling: false,
    editSchedule: { id: 0, name: '', scheduleSlots: [] },
    createSchedule: { id: 0, name: '', scheduleSlots: [] },
    isEditing: false,
  },
  search: {
    currentEntity: EntityType.NCompanySearchResult,
    tabIndex: 0,
    currentFields: emptySearchFields,
  },
  parentWorkgroup: {
    fetchingData: false,
    defaultWorkgroupParent: 0,
    fetchError: '',
    currentWorkgroupParent: 0,
  },
};

type NewnityActions = ActionType<typeof Actions>;

export const newnityReducer: Reducer<NewnityState, NewnityActions> = (
  state: NewnityState = initialNewnityState,
  action: NewnityActions
) => {
  switch (action.type) {
    case ActionTypes.ADD_SELECTED_PROGRAMS: {
      return produce(state, newState => {
        if (action.payload.items.length === 0) {
          return;
        }
        const stationState = action.payload.stationId
          ? newState.currentStation.editSchedule
          : newState.currentStation.createSchedule;
        if (action.payload.defaultSlot) {
          // create the slot if it doesn't exist
          if (!stationState.defaultSlot) {
            stationState.defaultSlot = {
              localId: -1,
              name: '',
              assetId: action.payload.items[0].id,
              programId: 0,
              id: 0,
            };
          } else {
            // if the slot existss then just change its asset
            stationState.defaultSlot.assetId = action.payload.items[0].id;
            const programId = Object.keys(newState.programAsset).find(
              k => newState.programAsset[Number(k)] === action.payload.items[0].id
            );
            stationState.defaultSlot.programId = programId ? Number(programId) : 0;
          }
        } else {
          const nextLocalId = stationState.scheduleSlots.length
            ? Math.max(...stationState.scheduleSlots.map(s => s.localId)) + 1
            : 1;

          const newSlots = action.payload.items.map<StationScheduleSlot>((item, i) => ({
            localId: nextLocalId + i,
            id: 0,
            name: '',
            type: AnthemMediaType.MusicProgram,
            isSilence: false,
            isDefault: false,
            programId: 0,
            assetId: item.id,
            schedule: getDefaultSchedule(),
          }));
          // if the station has no default slot set up, then copy the first program and set it as the default slot
          if (!stationState.defaultSlot) {
            stationState.defaultSlot = {
              localId: -1,
              name: '',
              assetId: newSlots[0].assetId,
              programId: 0,
              id: 0,
            };
          }
          stationState.scheduleSlots.unshift(...newSlots);
        }
      });
    }

    case ActionTypes.SET_COMPANY_REQUEST: {
      return produce(state, draftState => {
        draftState.loadingCompanyMode = true;
      });
    }
    case ActionTypes.SET_COMPANY_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadingCompanyMode = false;
        draftState.currentCompany.companyId = action.payload;
      });
    }
    case ActionTypes.SET_COMPANY_ERROR: {
      return produce(state, draftState => {
        draftState.loadingCompanyMode = false;
        draftState.currentCompany.companyId = 0;
      });
    }
    case ActionTypes.COMPANY_DATA_FETCH_REQUEST: {
      return produce(state, draftState => {
        draftState.currentCompany.fetchingData = true;
        draftState.currentCompany.fetchError = '';
      });
    }
    case ActionTypes.CLEANUP_COMPANY_EDIT: {
      return produce(state, draftState => {
        draftState.currentCompany.fetchError = '';
        draftState.currentCompany.fetchingData = false;
        draftState.currentCompany.savingState = {
          fetchCompleted: false,
          fetchError: '',
          isFetching: false,
          id: 0,
        };
      });
    }
    case ActionTypes.COMPANY_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          fetchingData: false,
          fetchComplete: true,
          fetchError: '',
        },
      };
    }

    case ActionTypes.COMPANY_DATA_FETCH_ERROR: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          fetchingData: false,
          fetchComplete: false,
          fetchError: action.payload,
        },
      };
    }
    case ActionTypes.SAVE_COMPANY_REQUEST: {
      return produce(state, draftState => {
        draftState.currentCompany.savingState = {
          isFetching: true,
          fetchError: '',
          fetchCompleted: false,
          id: action.payload,
        };
      });
    }
    case ActionTypes.SAVE_COMPANY_SUCCESS: {
      return produce(state, draftState => {
        draftState.currentCompany.savingState = {
          isFetching: false,
          fetchError: '',
          fetchCompleted: true,
          id: draftState.currentCompany.savingState.id,
        };
        draftState.currentCompany.companyId = action.payload;
      });
    }
    case ActionTypes.SAVE_COMPANY_ERROR: {
      return produce(state, draftState => {
        draftState.currentCompany.savingState = {
          isFetching: false,
          fetchError: action.payload,
          fetchCompleted: false,
          id: draftState.currentCompany.savingState.id,
        };
      });
    }
    case ActionTypes.SEARCH_SET_FIELDS: {
      return {
        ...state,
        search: {
          ...state.search,
          currentFields: action.payload,
        },
      };
    }
    case ActionTypes.SEARCH_SET_CURRENT_ENTITY: {
      return {
        ...state,
        search: {
          ...state.search,
          currentEntity: action.payload,
        },
      };
    }
    case ActionTypes.SEARCH_SET_SEARCHED: {
      return {
        ...state,
        search: {
          ...state.search,
          searchedEntity: action.payload.entity,
          searchedFields: action.payload.fields,
        },
      };
    }
    case ActionTypes.SEARCH_SET_TAB_INDEX: {
      return {
        ...state,
        search: {
          ...state.search,
          tabIndex: action.payload,
        },
      };
    }
    case ActionTypes.SAVE_LOCATION_REQUEST: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          savingData: true,
        },
      };
    }
    case ActionTypes.SAVE_LOCATION_ERROR:
    case ActionTypes.SAVE_LOCATION_SUCCESS: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          savingData: false,
        },
      };
    }
    case ActionTypes.DELETE_LOCATION_REQUEST: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          deletingLocation: true,
        },
      };
    }
    case ActionTypes.DELETE_LOCATION_SUCCESS:
    case ActionTypes.DELETE_LOCATION_ERROR: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          deletingLocation: false,
        },
      };
    }
    case ActionTypes.LOCATION_EDITING_STARTED: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          isEditing: true,
        }
      };
    }
    case ActionTypes.LOCATION_EDITING_COMPLETED: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          isEditing: false,
        }
      };
    }
    case ActionTypes.SET_IN_COMPANY_MODE: {
      return produce(state, draftState => {
        draftState.inCompanyMode = action.payload;
        if (!action.payload) {
          draftState.currentCompany.companyId = 0;
        }
      });
    }
    case ActionTypes.SET_SELECTED_LOCATIONS: {
      return {
        ...state,
        selectedLocations: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_DEVICES: {
      return {
        ...state,
        selectedDevices: action.payload,
      };
    }
    case ActionTypes.SAVE_DEVICE_REQUEST: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          isSaving: true,
        },
      };
    }
    case ActionTypes.SAVE_DEVICE_ERROR: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          isSaving: false,
          saved: false,
        },
      };
    }
    case ActionTypes.SAVE_DEVICE_SUCCESS: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          isSaving: false,
          saved: true,
        },
      };
    }
    case ActionTypes.DELETE_DEVICE_REQUEST: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          deletingDevice: true,
        },
      };
    }
    case ActionTypes.DELETE_DEVICE_ERROR: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          deletingDevice: false,
        },
      };
    }
    case ActionTypes.DELETE_DEVICE_SUCCESS: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          deletingDevice: false,
        },
      };
    }
    case ActionTypes.SET_DEVICE_SAVED: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          saved: action.payload,
        },
      };
    }
    case ActionTypes.SET_DEVICE_LOCATION: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          locationId: action.payload,
        },
      };
    }
    case ActionTypes.DEVICE_EDITING_STARTED: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          isEditing: true,
        },
      };
    }
    case ActionTypes.DEVICE_EDITING_COMPLETED: {
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          isEditing: false,
        },
      };
    }
    case ActionTypes.DEFAULT_PARENT_WORKGROUP_REQUEST: {
      return {
        ...state,
        parentWorkgroup: {
          ...state.parentWorkgroup,
          fetchingData: true,
        },
      };
    }
    case ActionTypes.DEFAULT_PARENT_WORKGROUP_SUCCESS: {
      return {
        ...state,
        parentWorkgroup: {
          ...state.parentWorkgroup,
          fetchingData: false,
          defaultWorkgroupParent: action.payload,
          currentWorkgroupParent: action.payload,
        },
      };
    }
    case ActionTypes.DEFAULT_PARENT_WORKGROUP_ERROR: {
      return {
        ...state,
        parentWorkgroup: {
          ...state.parentWorkgroup,
          fetchingData: false,
          fetchError: action.payload,
        },
      };
    }
    case ActionTypes.UPDATE_PARENT_WORKGROUP_SUCCESS: {
      return {
        ...state,
        parentWorkgroup: {
          ...state.parentWorkgroup,
          fetchingData: false,
          currentWorkgroupParent: action.payload,
        },
      };
    }
    case ActionTypes.SET_SCHEDULE: {
      const { localId, schedule } = action.payload;
      return produce(state, draftState => {
        const stationState = action.payload.stationId
          ? draftState.currentStation.editSchedule
          : draftState.currentStation.createSchedule;
        const index = stationState.scheduleSlots.findIndex(s => s.localId === localId);

        if (index !== -1) {
          stationState.scheduleSlots[index].schedule = schedule;
        }
      });
    }
    case ActionTypes.STATION_FETCH_REQUEST: {
      return produce(state, draftState => {
        draftState.currentStation.fetchingData.id = action.payload;
        draftState.currentStation.fetchingData.fetchCompleted = false;
        draftState.currentStation.fetchingData.isFetching = true;
        draftState.currentStation.fetchingData.fetchError = '';
      });
    }
    case ActionTypes.STATION_FETCH_SUCCESS: {
      return produce(state, draftState => {
        draftState.programAsset = action.payload.programAssets;
        const scheduleSlots = action.payload.stationDto.schedule.slots.map<StationScheduleSlot>(
          (ss, i) => ({
            assetId: ss.contentIdMedia ? draftState.programAsset[ss.contentIdMedia] : undefined,
            programId: ss.contentIdMedia,
            localId: i,
            type: ss.type,
            id: ss.id,
            name: ss.name,
            isSilence: ss.type === AnthemMediaType.Silence,
            schedule: {
              id: ss.id,
              name: ss.name,
              diffusionDays: ss.diffusionDays,
              startDate: parseDate(ss.startDate),
              startTime: parseTimeSpan(ss.startTime),
              endDate: parseDate(ss.endDate),
              endTime: parseTimeSpan(ss.endTime),
            },
          })
        );

        draftState.currentStation.fetchingData.fetchCompleted = true;
        draftState.currentStation.fetchingData.isFetching = false;
        draftState.currentStation.fetchingData.fetchError = '';

        draftState.currentStation.blockRemoteScrolling = !!action.payload.stationDto.schedule
          .blockRemoteScrolling;
        draftState.currentStation.editSchedule = {
          scheduleSlots,
          id: action.payload.stationDto.schedule.id,
          name: action.payload.stationDto.schedule.name,
          defaultSlot: {
            localId: -1,
            name: '',
            id: action.payload.stationDto.schedule.defaultSlot.id,
            assetId: action.payload.stationDto.schedule.defaultSlot.contentIdMedia
              ? draftState.programAsset[
                  action.payload.stationDto.schedule.defaultSlot.contentIdMedia
                ]
              : undefined,
            programId: action.payload.stationDto.schedule.defaultSlot.contentIdMedia,
          },
        };
      });
    }
    case ActionTypes.STATION_FETCH_ERROR: {
      return produce(state, draftState => {
        draftState.currentStation.fetchingData.fetchCompleted = false;
        draftState.currentStation.fetchingData.isFetching = false;
        draftState.currentStation.fetchingData.fetchError = action.payload.error;
      });
    }
    case ActionTypes.STATION_SAVE_REQUEST: {
      return produce(state, draftState => {
        if (action.payload.id) {
          draftState.currentStation.editSavingState.fetchCompleted = false;
          draftState.currentStation.editSavingState.isFetching = true;
          draftState.currentStation.editSavingState.fetchError = '';
          draftState.currentStation.editSavingState.id = action.payload.id;
        } else {
          draftState.currentStation.createSavingState.fetchCompleted = false;
          draftState.currentStation.createSavingState.isFetching = true;
          draftState.currentStation.createSavingState.fetchError = '';
          draftState.currentStation.editSavingState.id = action.payload.id;
        }
      });
    }
    case ActionTypes.STATION_SAVE_SUCCESS: {
      return produce(state, draftState => {
        const savingState = action.payload.id
          ? draftState.currentStation.editSavingState
          : draftState.currentStation.createSavingState;
        savingState.fetchCompleted = true;
        savingState.fetchError = '';
        savingState.isFetching = false;
      });
    }
    case ActionTypes.STATION_SAVE_ERROR: {
      return produce(state, draftState => {
        if (action.payload.station.id) {
          draftState.currentStation.editSavingState = {
            fetchCompleted: false,
            fetchError: action.payload.error,
            isFetching: false,
            id: draftState.currentStation.editSavingState.id,
          };
        } else {
          draftState.currentStation.createSavingState = {
            fetchCompleted: false,
            fetchError: action.payload.error,
            isFetching: false,
            id: draftState.currentStation.editSavingState.id,
          };
        }
      });
    }
    case ActionTypes.CHANGE_STATION_SLOT_POSITION: {
      return produce(state, draftState => {
        const stationState = action.payload.stationId
          ? draftState.currentStation.editSchedule
          : draftState.currentStation.createSchedule;
        stationState.scheduleSlots.splice(
          action.payload.newIndex,
          0,
          stationState.scheduleSlots.splice(action.payload.oldIndex, 1)[0]
        );
      });
    }
    case ActionTypes.STATION_ADD_SILENCE: {
      return produce(state, draftState => {
        const stationState = action.payload
          ? draftState.currentStation.editSchedule
          : draftState.currentStation.createSchedule;

        stationState.scheduleSlots.push({
          assetId: undefined,
          programId: undefined,
          name: 'Silence',
          isSilence: true,
          id: 0,
          type: AnthemMediaType.Silence,
          localId: stationState.scheduleSlots.length
            ? Math.max(...stationState.scheduleSlots.map(s => s.localId)) + 1
            : 1,
          schedule: getDefaultSchedule(),
        });
      });
    }
    case ActionTypes.STATION_REMOVE_SLOT: {
      return produce(state, draftState => {
        const stationState = action.payload.stationId
          ? draftState.currentStation.editSchedule
          : draftState.currentStation.createSchedule;
        const index = stationState.scheduleSlots.findIndex(
          slot => slot.localId === action.payload.slotLocalId
        );
        stationState.scheduleSlots.splice(index, 1);
      });
    }
    case ActionTypes.STATION_SET_EDITTING_SLOT: {
      return produce(state, draftState => {
        draftState.currentStation.scheduleEdittingSlotId = action.payload.slotLocalId;
      });
    }
    case ActionTypes.CLEANUP_STATION_EDIT: {
      return produce(state, draftState => {
        if (action.payload) {
          draftState.currentStation.editSchedule = {
            id: 0,
            name: '',
            scheduleSlots: [],
          };
          draftState.currentStation.editSavingState = {
            fetchCompleted: false,
            fetchError: '',
            isFetching: false,
            id: 0,
          };
        } else {
          draftState.currentStation.createSchedule = {
            id: 0,
            name: '',
            scheduleSlots: [],
          };
          draftState.currentStation.createSavingState = {
            fetchCompleted: false,
            fetchError: '',
            isFetching: false,
            id: 0,
          };
        }
      });
    }
    case ActionTypes.STATION_EDITING_STARTED: {
      return {
        ...state,
        currentStation: {
          ...state.currentStation,
          isEditing: true,
        }
      };
    }
    case ActionTypes.STATION_EDITING_COMPLETED: {
      return {
        ...state,
        currentStation: {
          ...state.currentStation,
          isEditing: false,
        }
      };
    }
    case ActionTypes.PARSE_IMPORT_FILE_REQUEST: {
      return produce(state, draftState => {
        draftState.import.parseFileFetchState.fetchCompleted = false;
        draftState.import.parseFileFetchState.fetchError = '';
        draftState.import.parseFileFetchState.id = action.payload.filePath;
        draftState.import.parseFileFetchState.isFetching = true;
      });
    }
    case ActionTypes.PARSE_IMPORT_FILE_SUCCESS: {
      return produce(state, draftState => {
        draftState.import.parseFileFetchState.fetchCompleted = true;
        draftState.import.parseFileFetchState.fetchError = '';
        draftState.import.parseFileFetchState.id = '';
        draftState.import.parseFileFetchState.isFetching = false;
        draftState.import.parseFileResult = action.payload.parseResult;
      });
    }
    case ActionTypes.PARSE_IMPORT_FILE_ERROR: {
      return produce(state, draftState => {
        draftState.import.parseFileFetchState.fetchCompleted = false;
        draftState.import.parseFileFetchState.fetchError = action.payload.err;
        draftState.import.parseFileFetchState.id = '';
        draftState.import.parseFileFetchState.isFetching = false;
      });
    }
    case ActionTypes.IMPORT_FILE_REQUEST: {
      return produce(state, draftState => {
        draftState.import.importFetchState.fetchCompleted = false;
        draftState.import.importFetchState.fetchError = '';
        draftState.import.importFetchState.id = action.payload.filePath;
        draftState.import.importFetchState.isFetching = true;
      });
    }
    case ActionTypes.IMPORT_FILE_SUCCESS: {
      return produce(state, draftState => {
        draftState.import.importFetchState.fetchCompleted = true;
        draftState.import.importFetchState.fetchError = '';
        draftState.import.importFetchState.id = '';
        draftState.import.importFetchState.isFetching = false;
        draftState.import.importResult = action.payload.importResult;
      });
    }
    case ActionTypes.IMPORT_FILE_ERROR: {
      return produce(state, draftState => {
        draftState.import.importFetchState.fetchCompleted = false;
        draftState.import.importFetchState.fetchError = action.payload.err;
        draftState.import.importFetchState.id = '';
        draftState.import.importFetchState.isFetching = false;
      });
    }
    case ActionTypes.CLEANUP_IMPORT: {
      return produce(state, draftState => {
        draftState.import = initialImportState;
      });
    }
    default:
      return state;
  }
};

export const setInitialState = (enabled: boolean) => {
  initialNewnityState = {
    ...initialNewnityState,
    enabled,
  };
};
