import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    listMeasure: {
      flexGrow: 1,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
    emptySlot: {
      fontStyle: 'italic',
      color: theme.palette.grey[400],
    },
    silenceIcon: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1.5),
    },
    moveHandle: {
      color: theme.palette.grey[600],
    },
    item: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      zIndex: 9999,
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      '&:hover $hiddenButton': {
        visibility: 'visible',
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    itemDay: {
      width: 20,
      float: 'left',
      textAlign: 'center',
      color: theme.palette.grey[400],
      marginRight: theme.spacing(1),
    },
    itemSelectedDay: {
      color: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    hiddenButton: {
      visibility: 'collapse',
    },
    itemLabel: {
      marginLeft: theme.spacing(1),
      flexGrow: 1,
    },
    itemLabelSilenceIconCompensation: {
      marginLeft: 40
    },
    lastItem: {
      backgroundColor: 'red',
    },
  });

export const useStyles = makeStyles(styles);
