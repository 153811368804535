import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    audioSettingsPaper: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    alignCenter: {
      alignItems: 'center'
    },
    flexGrow: {
      flexGrow: 1
    },
    noPadding: {
      padding: 0
    },
    networkSettingsOverride: {
      marginTop: theme.spacing(3)
    }
  });
};

export const useStyles = makeStyles(styles);
