import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import { bladeConnect, BladeProps, openBlade } from '../../../../blade';
import { DataStoreSelectors, EntityType } from '../../../../dataStore';
import { locationStartEdit, setSelectedLocations} from '../../../duck/actions';
import { selectCompanyId, selectDeletingLocation, selectIsEditingLocation } from '../../../duck/selectors';
import { deleteLocation, fetchLocations } from '../../../duck/thunks';
import { NewnityState } from '../../../duck/types';
import { LocationEditBladeType } from '../edit/locationEditContainer';
import { LocationList, LocationListActions, LocationListProps } from './locationList';

const mapStateToProps: MapStateToProps<LocationListProps, BladeProps, any> = (state, _) => {
  return {
    companyId: selectCompanyId(state),
    items: DataStoreSelectors.NLocationList.selectLocationItems(state),
    locationsFetching: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NLocationList)
      .isFetching,
    selected: (state.newnity as NewnityState).selectedLocations,
    locationDeleting: selectDeletingLocation(state),
    isEditing: selectIsEditingLocation(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<LocationListActions, BladeProps> = (dispatch, _) => {
  return {
    fetchLocations: (companyId: number) => dispatch<any>(fetchLocations(companyId)),
    createLocation: () => {
      openBlade(LocationListBladeType, LocationEditBladeType, {}, dispatch)
      dispatch<any>(locationStartEdit())
    },
    editLocation: (id: number, name: string) => {
      openBlade(
        LocationListBladeType,
        LocationEditBladeType,
        { locationSiteId: id, locationName: name },
        dispatch
      )
      dispatch<any>(locationStartEdit())
    },
    setSelected: items => dispatch(setSelectedLocations(items)),
    deleteLocation: (id: number, name: string) => dispatch<any>(deleteLocation(id, name, LocationListBladeType)),    
  };
};

export const LocationListBladeType = 'NEWNITY_LOCATION_LIST';

const bladeConfig = {
  size: { defaultWidth: 700, minWidth: 600 },
  bladeType: LocationListBladeType,
  id: (_: any) => LocationListBladeType,
  title: (_: any) => 'Locations',
  allowMultipleInstances: false,
};

export const LocationListContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  LocationList
);
