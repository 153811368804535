import { IdName, NCompany, NDevice, NLocation } from '../../dataStore';
import { DeviceSettings, EntityType } from '../../dataStore/types';
import { apiFetch, currentWorkgroupKey, fetchHelper, getCookieValue } from '../../v6/ajax';
import { anthemApi } from './api/anthemApi';
import { stationApi } from './api/stationApi';
import { ImportFileParseResult, ImportFileResult, SearchEntity, SearchFields} from './types';

type CompanyDataWithoutDeviceSettings = Pick<NCompany, Exclude<keyof NCompany, 'deviceSettings'>>;
export type CompanyDto = {
  company: CompanyDataWithoutDeviceSettings;
  deviceSettings: DeviceSettings;
};

type LocationDataWithoutDeviceSettings = Pick<
  NLocation,
  Exclude<keyof NLocation, 'deviceSettings'>
>;

export type LocationDto = {
  site: LocationDataWithoutDeviceSettings;
  deviceSettings: DeviceSettings;
};

export const NewnityApi = {
  ...anthemApi,
  ...stationApi,
  dtoToCompany: (dto: CompanyDto): NCompany => ({
    ...dto.company,
    deviceSettings: {
      ...dto.deviceSettings,
      wifiSettings: {
        ...dto.deviceSettings.wifiSettings,
        verifySecurityKey: dto.deviceSettings.wifiSettings.securityKey,
      },
    },
  }),
  companyToDto: (company: NCompany) => {
    const justCompany = { ...company };
    delete justCompany.deviceSettings;
    return { company: justCompany, deviceSettings: company.deviceSettings };
  },
  dtoToLocation: (dto: LocationDto): NLocation => ({
    ...dto.site,
    deviceSettings: {
      ...dto.deviceSettings,
      wifiSettings: {
        ...dto.deviceSettings.wifiSettings,
        verifySecurityKey: dto.deviceSettings.wifiSettings.securityKey,
      },
    },
  }),
  locationToDto: (location: NLocation) => {
    const justLocation = { ...location };
    delete justLocation.deviceSettings;
    return { site: justLocation, deviceSettings: location.deviceSettings };
  },
  getCompanyInfo: async (
    companyId: number
  ): Promise<{
    company: Pick<NCompany, Exclude<keyof NCompany, 'deviceSettings'>>;
    deviceSettings: DeviceSettings;
  }> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company/${companyId}`
    );

    return response.json();
  },

  saveCompanyInfo: async (company: NCompany, createMode: boolean): Promise<CompanyDto> => {
    let response;
    const url = process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company`;
    const saveUrl = createMode ? url : `${url}/${company.id}`;
    response = await fetchHelper(
      saveUrl,
      createMode ? 'POST' : 'PUT',
      NewnityApi.companyToDto(company)
    );
    return response.json();
  },

  getComanpies: async (): Promise<IdName[]> => {
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company`);
    return response.json();
  },

  search: async (fields: SearchFields, entity: SearchEntity): Promise<IdName[]> => {
    const workgroupId =
      (process.env.REACT_APP_HEADER_01 as string) === 'MVision-Current-WorkgroupId'
        ? Number(getCookieValue(currentWorkgroupKey))
        : 1;
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/search`,
      'POST',
      {
        workgroupId,
        entity,
        fields,
      }
    );

    return response.json();
  },

  LocationToDto: (location: NLocation) => {
    const justLocation = { ...location };
    delete justLocation.deviceSettings;
    return { location: justLocation, deviceSettings: justLocation.deviceSettings };
  },

  getLocations: async (companyId: number): Promise<NLocation[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/list/${companyId}`
    );

    return response.json();
  },

  getLocation: async (
    id: number
  ): Promise<{
    site: Pick<NLocation, Exclude<keyof NLocation, 'deviceSettings'>>;
    deviceSettings: DeviceSettings;
  }> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/${id}`
    );

    return response.json();
  },
  getDeleteLocations: async (locationIds: number[]) => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/getDeleteLocations`,
      'POST',
      {siteIds: locationIds}
    );
    return response.json();
  },
  
  saveLocation: async (
    companyId: number,
    location: NLocation,
    create?: boolean
  ): Promise<LocationDto> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/${companyId}`,
      create ? 'POST' : 'PUT',
      NewnityApi.locationToDto(location)
    );
    return response.json();
  },

  deleteLocations: async (locationIds: number[]) => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/location/deleteLocations`,
      'POST',
      {siteIds: locationIds}
    );
    return response.status;
  },
  saveDevice: async (
    device: NDevice,
    create?: boolean
  ): Promise<{ error: string; device: NDevice }> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices`,
      create ? 'POST' : 'PUT',
      device
    );

    return response.json();
  },
  deleteDevice: async (deviceId: number): Promise<{ error: string; device: NDevice }> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices/${deviceId}`,
      'DELETE'
    );

    return response.json();
  },
  dtoToDevice: (dto: NDevice): NDevice => ({
    ...dto,
    deviceSettings: {
      ...dto.deviceSettings,
      wifiSettings: {
        ...dto.deviceSettings.wifiSettings,
        verifySecurityKey: dto.deviceSettings.wifiSettings.securityKey,
      },
    },
  }),
  getDevice: async (deviceId: number): Promise<NDevice> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices/${deviceId}`
    );

    return response.json();
  },

  getDevices: async (companyId: number): Promise<NDevice[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/devices/byCompany/${companyId}`
    );

    return response.json();
  },
  getDefaultParentWorkgroup: async (
    companyId?: number
  ): Promise<{
    company: Pick<NCompany, Exclude<keyof NCompany, 'deviceSettings'>>;
    deviceSettings: DeviceSettings;
  }> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        'v6/admin/newnity/company/parent' +
        (companyId ? `/${companyId}` : '')
    );

    return response.json();
  },
  getParentEntity: async (
    entityType: EntityType,
    entityId: number
  ): Promise<{
    [keyof: string]:
      | Pick<NCompany, Exclude<keyof NCompany, 'deviceSettings'>>
      | Pick<NLocation, Exclude<keyof NLocation, 'deviceSettings'>>
      | DeviceSettings;
    deviceSettings: DeviceSettings;
  }> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/entity/${entityType}/${entityId}`
    );

    return response.json();
  },
  getImportTemplateUrl: () => process.env.REACT_APP_ROOT_DOMAIN + 'v6/admin/newnity/import/template',
  getImportTemplateDriveUrl: () => process.env.REACT_APP_ROOT_DOMAIN + 'v6/admin/newnity/import/driveTemplate',
  checkImportFile: async (remotePath: string): Promise<ImportFileParseResult> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/import/parse/${remotePath}`
    );
    return response.json();
  },
  importFile: async (remotePath: string, companyId: number): Promise<ImportFileResult> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/import/${remotePath}?companyId=${companyId}`,
      { method: 'POST' }
    );
    return response.json();
  },
};
