import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    centerContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        alignSelf: 'center',
      },
    },
    resultsContainer: {
      height: '100%',
      marginLeft: "2px",
    },
    gridContainer: {
      display: 'flex',
      height: '100%',
    },
    progress: {
      alignSelf: 'center',
      margin: '0 auto',
    },
  });
};

export const useStyles = makeStyles(styles);
