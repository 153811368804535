import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityType, IdName } from '../../dataStore';
import { FooterSubmit } from '../bladeLayout/footerSubmit';
import { HeaderAndContent } from '../bladeLayout/headerAndContent';
import { DataGridContainer } from '../dataGrid';
import { ColumnSettings, DataGridSelectionType } from '../dataGrid/agDataGrid';
import { useStyles } from './selectionBlade.jss';

export interface SelectionBladeProps {
  itemsFetching: boolean;
  entityType: EntityType;
  selectedIds?: IdName[];
  selectionType?: DataGridSelectionType;
  items: IdName[];
  columnSettings: ColumnSettings[];
}

export interface SelectionBladeActions {
  selectionSubmitted: (items: IdName[]) => void;
  cancel: () => void;
}

type Props = SelectionBladeProps & SelectionBladeActions;

export const SelectionBlade: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selectedItems, setSelectedItems] = useState<IdName[]>(
    props.selectedIds ? props.selectedIds : []
  );

  const { selectionSubmitted } = props;

  const onSelect = useCallback((items: IdName[]) => setSelectedItems(items), []);
  const onSubmit = useCallback(() => selectionSubmitted(selectedItems), [
    selectionSubmitted,
    selectedItems,
  ]);
  const showFooter = props.selectionType !== DataGridSelectionType.None;
  const footer = useCallback(
    () =>
      showFooter ? (
        <FooterSubmit
          hasCancelButton={true}
          cancel={props.cancel}
          cancelButtonLabel={t('cancel')}
          submitButtonLabel={t('save')}
          submit={onSubmit}
          submitInProgress={false}
        />
      ) : (
        <React.Fragment />
      ),
    [props.cancel, showFooter, onSubmit, t]
  );
  return (
    <HeaderAndContent
      isBusy={props.itemsFetching}
      removePadding
      footer={footer}
      containerClassName={classes.container}
    >
      <DataGridContainer
        entityType={props.entityType}
        onSelect={onSelect}
        defaultSelection={props.selectedIds}
        items={props.items}
        columnSettings={props.columnSettings}
        selectionType={props.selectionType ? props.selectionType : DataGridSelectionType.None}
      />
    </HeaderAndContent>
  );
};
