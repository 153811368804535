import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';

import { BladeActions } from '../../blade';
import { NormalizedBlades } from '../../blade/duck/types';
import { pushNotification } from '../../components/notifier';
import { SelectionBladeActions } from '../../components/selectionBlade';
import { DataStoreActions, DataStoreSelectors, EntityType, Workgroup } from '../../dataStore';
import { WorkgroupCreateBladeType } from '../blades/workgroupCreate/workgroupCreateContainer';
import { WorkgroupApi } from './api';
import { ActionTypes, WorkgroupCreateObject } from './types';

export function fetchWorkgroups() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.Workgroup));
    try {
      const workgroups = await WorkgroupApi.getWorkgroups();
      dispatch(DataStoreActions.entityListSuccess(EntityType.Workgroup, workgroups));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Workgroup, err));
      dispatch(pushNotification(`Could not fetch workgroups: ${err}`, 'error'));
    }
  };
}

export function fetchBrands() {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const brandsFetching = DataStoreSelectors.getDataStoreItemsFetching(
      getState(),
      EntityType.Brand
    );
    if (brandsFetching.fetchComplete || brandsFetching.isFetching) {
      return;
    }
    dispatch(DataStoreActions.entityListRequest(EntityType.Brand));
    try {
      const brands = await WorkgroupApi.getBrands();
      dispatch(DataStoreActions.entityListSuccess(EntityType.Brand, brands));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Brand, err));
      dispatch(pushNotification(`Could not fetch brand: ${err}`, 'error'));
    }
  };
}

export function fetchTemplateLibraries(workgroupId?: number) {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const templatesFetching = DataStoreSelectors.getDataStoreItemsFetching(
      getState(),
      EntityType.TemplateLibrary
    );
    if (templatesFetching.fetchComplete || templatesFetching.isFetching) { return };

    dispatch(DataStoreActions.entityListRequest(EntityType.TemplateLibrary));
    try {
      const templateLibraries = await WorkgroupApi.getTemplateLibraries(workgroupId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.TemplateLibrary, templateLibraries));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Brand, err));
      dispatch(pushNotification(`Could not fetch brand: ${err}`, 'error'));
    }
  };
}

export function createWorkgroup(
  wkCreateObj: WorkgroupCreateObject,
  parentTreeId: string,
  createAnother: boolean
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(workgroupCreateRequest());
      const id = await WorkgroupApi.createWorkgroup(wkCreateObj);
      dispatch(
        workgroupCreateSuccess(
          id,
          wkCreateObj.Name,
          wkCreateObj.ParentId,
          parentTreeId,
          createAnother
        )
      );
      const newWorkgroup: Workgroup = {
        id,
        name: wkCreateObj.Name,
        parentId: wkCreateObj.ParentId,
      };
      dispatch(DataStoreActions.entityUpsert(EntityType.Workgroup, newWorkgroup));
      dispatch(
        pushNotification(`Created workgroup ${wkCreateObj.Name}`, 'success', {
          label: 'OPEN',
          actionName: 'OPEN_WORKGROUP',
          actionParams: {},
        })
      );
      if (!createAnother) {
        dispatch(BladeActions.forceCloseBlade(WorkgroupCreateBladeType));
      }
    } catch (error) {
      dispatch(workgroupCreateError(error));
      dispatch(pushNotification(`Could not create workgroup: ${error}`, 'error'));
    }
  };
}

export const cleanupCreate = createAction(ActionTypes.CREATE_WORKGROUP_CLEANUP, action => () =>
  action()
);

export const cleanupCreateWorkgroup = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(
      SelectionBladeActions.clearMultipleSelections([
        'create_workgroup_template_libraries',
        'create_workgroup_brands',
      ])
    );
    dispatch(cleanupCreate());
  };
};

export function setWorkgroupAsFavorite(workgroupId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(workgroupFavoriteRequest(workgroupId));
    await WorkgroupApi.setWorkgroupAsFavorite(workgroupId);
    dispatch(workgroupFavoriteSuccess(workgroupId));
  };
}

export function removeWorkgroupAsFavorite(workgroupId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(workgroupUnfavoriteRequest(workgroupId));
    await WorkgroupApi.removeWorkgroupAsFavorite(workgroupId);
    dispatch(workgroupUnfavoriteSuccess(workgroupId));
  };
}

export const workgroupCreateSuccess = createAction(
  ActionTypes.CREATE_WORKGROUP_SUCCESS,
  action => (
    id: number,
    name: string,
    parentId: number,
    parentTreeId: string,
    createAnother: boolean
  ) =>
    action({
      id,
      name,
      parentId,
      parentTreeId,
      createAnother,
    })
);

export const workgroupCreateError = createAction(
  ActionTypes.CREATE_WORKGROUP_ERROR,
  action => (err: string) => action({ err })
);
export const workgroupCreateRequest = createAction(
  ActionTypes.CREATE_WORKGROUP_REQUEST,
  action => () => action()
);

export const workgroupFavoriteRequest = createAction(
  ActionTypes.WORKGROUP_FAVORITE_REQUEST,
  action => (workgroupId: number) => action({ workgroupId })
);
export const workgroupFavoriteSuccess = createAction(
  ActionTypes.WORKGROUP_FAVORITE_SUCCESS,
  action => (workgroupId: number) => action({ workgroupId })
);
export const workgroupFavoriteError = createAction(
  ActionTypes.WORKGROUP_FAVORITE_ERROR,
  action => (error: string) => action({ error })
);

export const workgroupUnfavoriteRequest = createAction(
  ActionTypes.WORKGROUP_UNFAVORITE_REQUEST,
  action => (workgroupId: number) => action({ workgroupId })
);
export const workgroupUnfavoriteSuccess = createAction(
  ActionTypes.WORKGROUP_UNFAVORITE_SUCCESS,
  action => (workgroupId: number) => action({ workgroupId })
);
export const workgroupUnfavoriteError = createAction(
  ActionTypes.WORKGROUP_UNFAVORITE_ERROR,
  action => (error: string) => action({ error })
);

export const workgroupListEnterPickMode = createAction(
  ActionTypes.WORKGROUP_LIST_ENTER_PICK_MODE,
  action => () => action()
);

export function enterPickMode(item: string) {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState();

    const bladeItems: NormalizedBlades = state.blade.items;
    const bladeKeys = Object.keys(bladeItems);
    const blades = [];

    for (var a = 0; a < bladeKeys.length; a++) {
      if (bladeKeys[a] !== item) blades.push(bladeKeys[a]);
    }

    dispatch(workgroupListEnterPickMode());
    dispatch(BladeActions.freezeBlades(blades));
  };
}

export const workgroupListLeavePickMode = createAction(
  ActionTypes.WORKGROUP_LIST_LEAVE_PICK_MODE,
  action => (workgroupId: number, treeItemId: string) =>
    action({
      workgroupId,
      treeItemId,
    })
);

export const leavePickMode = (workgroupId: number, treeItemId: string) => (
  dispatch: Dispatch<any>
) => {
  dispatch(workgroupListLeavePickMode(workgroupId, treeItemId));
  dispatch(BladeActions.clearFrozenBlades());
};
