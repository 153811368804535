import { debounce } from 'debounce';
import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { SortEnd } from 'react-sortable-hoc';

import {
    Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography
} from '@material-ui/core';
import StarBorderRounded from '@material-ui/icons/StarBorderRounded';
import StarRounded from '@material-ui/icons/StarRounded';

import { BladeProps, useBladeClosing } from '../../../../blade';
import {
    ButtonsHeaderAndContent, customBtn
} from '../../../../components/bladeLayout/buttonsHeaderAndContent';
import { FooterSubmit } from '../../../../components/bladeLayout/footerSubmit';
import { GroupBox } from '../../../../components/groupBox/groupBox';
import { NStation } from '../../../../dataStore';
import { NProgram } from '../../../../dataStore/types';
import { FetchState, StationDefaultSlot, StationScheduleSlot } from '../../../duck/types';
import { useStyles } from './stationEdit.jss';
import { StationProgramList } from './stationProgramList';

export interface SlotAssetMapping {
  [slotLocalId: number]: NProgram | undefined;
}

export interface StationEditProps {
  station: NStation;
  blockRemoteScrolling: boolean;
  companyId: number;
  savingData: FetchState;
  fetchingData: FetchState;
  scheduleSlots: StationScheduleSlot[];
  defaultSlot?: StationDefaultSlot;
  assets: SlotAssetMapping;
  scheduleEditingSlotId: number;
  canChangeDefault: boolean;
}

export interface StationEditActions {
  createStation: (station: NStation, blockRemoteScrolling: boolean) => void;
  fetchStation: (stationId: number) => void;
  scheduleSlot: (stationId: number, slotProgramName: string, slotLocalId: number) => void;
  addSlots: (stationId: number, defaultSlot: boolean) => void;
  addSilence: (stationId: number) => void;
  deleteSlot: (stationId: number, slotLocalId: number) => void;
  changeSlotPosition: (stationId: number, oldIndex: number, newIndex: number) => void;
  closeBlade: () => void;
  onBladeClose: (stationId: number) => void;
}

type Props = StationEditProps & StationEditActions & BladeProps;

export const StationEdit: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [defaultStation, setDefaultStation] = useState(props.station.isDefault);
  const [stationName, setStationName] = useState(props.station.name);
  const [scheduleSlots, setScheduleSlots] = useState(props.scheduleSlots);
  const createMode = props.station.id === 0;
  const [submitPressed, setSubmitPressed] = useState(false);
  const [blockRemoteScrolling, setBlockRemoteScrolling] = useState(props.blockRemoteScrolling);
  const [defaultSlot, setDefaultSlot] = useState(props.defaultSlot);

  const { fetchStation, setDirty, changeSlotPosition, savingData } = props;

  // load station data when blade opens
  useEffect(() => {
    if (props.station.id) {
      fetchStation(props.station.id);
    }
  }, [props.station.id]);

  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => {
      props.onBladeClose(props.station.id);
    }
  );

  // store initial station data into state
  useEffect(() => {
    if (!props.fetchingData.isFetching) {
      setScheduleSlots(props.scheduleSlots);
      setBlockRemoteScrolling(props.blockRemoteScrolling);
      setDefaultSlot(props.defaultSlot);
    }
  }, [
    props.fetchingData.isFetching,
    props.scheduleSlots,
    props.defaultSlot,
    props.blockRemoteScrolling,
  ]);

  useEffect(() => {
    if (savingData.fetchCompleted && savingData.fetchError === '') {
      setDirty(false);
    }
  }, [savingData.fetchError, savingData.fetchCompleted]);

  // compare current station data with initial data to determine if the blade is dirty
  useEffect(() => {
    if (props.fetchingData.isFetching || props.savingData.isFetching || props.savingData.fetchCompleted) {
      return;
    }

    const nextDirty = !isEqual(
      {
        stationName,
        blockRemoteScrolling,
        scheduleSlots,
        defaultSlot,
        defaultStation,
      },
      {
        stationName: props.station.name,
        blockRemoteScrolling: props.blockRemoteScrolling,
        scheduleSlots: props.scheduleSlots,
        defaultSlot: props.defaultSlot,
        defaultStation: props.station.isDefault,
      }
    );

    if (nextDirty !== props.isDirty) {
      setDirty(nextDirty);
    }
  }, [
    stationName,
    blockRemoteScrolling,
    defaultSlot,
    scheduleSlots,
    props.scheduleSlots,
    props.defaultSlot,
    props.fetchingData.isFetching,
    props.blockRemoteScrolling,
    props.station,
    props.isDirty,
    defaultStation,
    props.savingData.isFetching,
    props.savingData.fetchCompleted,
    setDirty,
  ]);

  const onSubmit = () => {
    setSubmitPressed(true);
    if (stationName) {
      props.createStation(
        { ...props.station, name: stationName, isDefault: defaultStation },
        blockRemoteScrolling
      );
    }
  };
  const headerButtons = [
    customBtn(
      t('newnity.edit.station.blade.header.addProgram'),
      () => props.addSlots(props.station.id, false),
      'add',
      true
    ),
    customBtn(
      t('newnity.edit.station.blade.header.addSilence'),
      () => props.addSilence(props.station.id),
      'add',
      false
    ),
  ];
  const onBlockRemoteScrollingCheckChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setBlockRemoteScrolling(checked);
    },
    []
  );

  const onSetDefaultStation = useCallback(() => setDefaultStation(!defaultStation), [
    defaultStation,
  ]);

  const onScheduleEdit = (slotLocalId: number, slotName: string) => {
    props.scheduleSlot(props.station.id, slotName, slotLocalId);
  };
  const onDelete = (slotLocalId: number) => {
    props.deleteSlot(props.station.id, slotLocalId);
  };
  const onSortEnd = useCallback(
    (sort: SortEnd) => {
      changeSlotPosition(props.station.id, sort.oldIndex, sort.newIndex);
    },
    [changeSlotPosition, props.station.id]
  );
  const defaultSlotAsset = props.defaultSlot ? props.assets[props.defaultSlot.localId] : undefined;

  const onStationNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStationName(e.currentTarget.value);
  }, []);

  const footer = () => (
    <FooterSubmit
      hasCancelButton={true}
      cancelButtonLabel={t('footer.cancel')}
      cancel={props.closeBlade}
      submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
      submit={onSubmit}
      submitInProgress={props.savingData.isFetching}
    />
  );
  return (
    <ButtonsHeaderAndContent
      isBusy={props.savingData.isFetching || props.fetchingData.isFetching}
      buttons={headerButtons}
      footer={footer}
    >
      {' '}
      <>
        <GroupBox
          title={t('newnity.edit.station.blade.create.header')}
          direction="row"
          headerContent={
            <Tooltip
              title={
                defaultStation
                  ? t('newnity.edit.station.blade.isDefault')
                  : t('newnity.edit.station.blade.setAsDefault')
              }
            >
              <IconButton disabled={!props.canChangeDefault} onClick={onSetDefaultStation}>
                {defaultStation ? <StarRounded color="secondary" /> : <StarBorderRounded />}
              </IconButton>
            </Tooltip>
          }
        >
          <TextField
            className={classes.stationFieldLeft}
            label={t('newnity.edit.station.blade.create.name')}
            value={stationName}
            onChange={onStationNameChange}
            margin="normal"
            error={submitPressed && !stationName}
            helperText={
              submitPressed && !stationName && t('newnity.edit.station.blade.create.nameError')
            }
            inputProps={{ maxLength: 190 }}
          />
          <FormControlLabel
            className={classes.stationFieldRight}
            style={{ marginTop: '24px' }}
            control={
              <Checkbox
                checked={blockRemoteScrolling}
                onChange={debounce(onBlockRemoteScrollingCheckChange, 250)}
              />
            }
            label={t('newnity.edit.station.blade.blockRemoteScrolling')}
          />
        </GroupBox>
        <GroupBox title={t('newnity.edit.station.blade.scheduleSlots')} grow>
          {!props.fetchingData.isFetching && (
            <StationProgramList
              slots={props.scheduleSlots}
              assets={props.assets}
              onScheduleEdit={onScheduleEdit}
              onDelete={onDelete}
              onSortEnd={onSortEnd}
              schedulingSlotId={props.scheduleEditingSlotId}
              useDragHandle
              lockAxis="y"
              lockToContainerEdges={true}
            />
          )}
        </GroupBox>
        <GroupBox
          title={t('newnity.edit.station.blade.defaultSlot')}
          noMargin
          headerContent={
            <Button variant="text" onClick={() => props.addSlots(props.station.id, true)}>
              {t('newnity.edit.station.blade.header.changeProgram')}
            </Button>
          }
        >
          <Typography variant="body1" noWrap>
            {defaultSlotAsset && !props.fetchingData.isFetching
              ? defaultSlotAsset.name
              : t('newnity.edit.station.blade.slots.empty')}
          </Typography>
        </GroupBox>
      </>
    </ButtonsHeaderAndContent>
  );
};
