import { NormalizedIdNames } from '../';
import { areaName, DataStoreState, EntityType, IdName } from '../types';

export const getDataStore = (state: any) => state[areaName] as DataStoreState;

export const getDataStoreItems = (state: any, entityType: EntityType) => {
  return (state[areaName] as DataStoreState)[entityType as keyof DataStoreState]
    .items as NormalizedIdNames<IdName>;
};

export const getDataStoreItem = (state: any, entityType: EntityType, entityId: number) => {
  return getDataStoreItems(state, entityType)[entityId];
};

export const getDataStoreItemsFetching = (state: any, entityType: EntityType) => {
  const key = entityType as keyof DataStoreState;
  if ((state[areaName] as DataStoreState)[key]) {
    return (state[areaName] as DataStoreState)[key].itemsFetching;
  } else {
    return {
      isFetching: false,
      error: '',
      fetchComplete: true,
    };
  }
};
