import { createSelector } from 'reselect';

import { EntityState, EntityType, NStation } from '../types';
import { getDataStore } from './genericSelectors';

export const selectStations = (state: any) =>
  getDataStore(state)[EntityType.NStation];

export const selectStationItems = createSelector(
  [selectStations],
  stations => {
    return Object.keys(stations.items).map<NStation>(
      key => stations.items[Number(key)]
    );
  }
);

export const selectOrderedStationItems = createSelector(
  [selectStationItems],
  stations => {
    return stations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
);

export const selectStationById = (state: any, stationId: number) => selectStations(state).items[stationId];
export const selectDefaultStation = createSelector([selectStations], (stations: EntityState<NStation>) => {
  const stationId = Object.keys(stations.items).find(k => stations.items[Number(k)].isDefault);
  return stations.items[Number(stationId)];
});