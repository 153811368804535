import produce from 'immer';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, BladeState, NormalizedBlades } from './types';

type BladeActions = ActionType<typeof Actions>;

const initialState: BladeState = {
  items: {},
  itemsForClosing: [],
  postClosingAction: undefined,
};

const getRelatedBladeIds = (blades: NormalizedBlades, parentBladeIds: string | string[], includeParents: boolean = true) => {
  const parentIds = Array.isArray(parentBladeIds) ? parentBladeIds : [parentBladeIds];
  const childrenIds = new Set<string>(parentIds.filter(id => blades[id] !== undefined));
  Object.keys(blades).forEach(id => {
    if (childrenIds.has(blades[id].parentId)) {
      childrenIds.add(blades[id].id);
    }
  });
  if(!includeParents) {
    parentIds.forEach(p => childrenIds.delete(p));
  }
  return childrenIds;
};

export const bladeReducer: Reducer<BladeState, BladeActions> = (
  state: BladeState = initialState,
  action: BladeActions
) => {
  return produce(state, draftState => {
    switch (action.type) {
      case ActionTypes.CREATE_BLADE: {
        if (draftState.items[action.payload.id]) {
          draftState.items[action.payload.id].newlyCreated = true;
        } else {
          draftState.items[action.payload.id] = action.payload;
        }
        break;
      }
      case ActionTypes.CLOSE_BLADE: {
        // no bladeId in payload means close all blades
        draftState.itemsForClosing = action.payload.bladeId
          ? Array.from(getRelatedBladeIds(draftState.items, action.payload.bladeId))
          : Object.keys(draftState.items);

        draftState.postClosingAction = action.payload.actionAfterClosing;
        break;
      }
      case ActionTypes.CLOSE_CHILDREN_BLADES: {
        draftState.itemsForClosing = Array.from(getRelatedBladeIds(draftState.items, action.payload.bladeId, false));
        draftState.postClosingAction = action.payload.actionAfterClosing;
        break;
      }
      case ActionTypes.FORCE_CLOSE_BLADE: {
        draftState.postClosingAction = undefined;
        if (action.payload) {
          const bladeIds = Array.isArray(action.payload) ? action.payload : [action.payload];
          bladeIds.forEach(id => {
            delete draftState.items[id];
          });

          draftState.itemsForClosing = draftState.itemsForClosing.filter(
            bladeId => !bladeIds.includes(bladeId)
          );
        } else {
          draftState.itemsForClosing.forEach(id => {
            delete draftState.items[id];
          });
          draftState.itemsForClosing = [];
        }
        break;
      }
      case ActionTypes.CANCEL_BLADE_CLOSE: {
        draftState.postClosingAction = undefined;
        draftState.itemsForClosing = [];
        break;
      }
      case ActionTypes.SET_BLADE_NEWLY_CREATED_STATE: {
        draftState.items[action.payload.bladeId].newlyCreated = action.payload.isNewlyCreated;
        break;
      }
      case ActionTypes.FREEZE_BLADES: {
        action.payload.forEach(id => (draftState.items[id].frozen = true));
        break;
      }
      case ActionTypes.CLEAR_FROZEN_BLADES: {
        if (action.payload) {
          action.payload.forEach(bladeId => (draftState.items[bladeId].frozen = false));
        } else {
          Object.keys(draftState.items).forEach(
            bladeId => (draftState.items[bladeId].frozen = false)
          );
        }
        break;
      }
      case ActionTypes.SET_TITLE: {
        draftState.items[action.payload.bladeId].title = action.payload.value;
        break;
      }
      case ActionTypes.HARD_CLEAR_ALL_BLADES: {
        draftState.items = {};
        break;
      }
      default:
        break;
    }
  });
};
