import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { initializeEntityState, User } from '../../dataStore';
import { normalize } from '../../utils/extensions';
import * as Actions from './actions';
import { ActionTypes, UsersState } from './types';

type UserActions = ActionType<typeof Actions>;

const initialState: UsersState = {
  currentWorkgroupUsers: initializeEntityState(),
  removeUser: {
    isRemoving: false,
    removingError: '',
    removingComplete: false,
  },
  addUser: {
    isSaving: false,
    savingError: '',
    savingComplete: false,
  },
  saveUser: {
    isSaving: false,
    savingError: '',
    savingComplete: false,
  },
  fetchUser: {
    isFetching: false,
    fetchError: '',
    fetchComplete: false,
  },
  editUser: {
    isEditing: false,
  },
  fetchWelcomeEmailTemplates: {
    isFetching: false,
    fetchError: '',
    fetchComplete: false,
  },
  welcomeEmailTemplates: 
  {
    welcomeEmailTemplatesList: []
  }
};

export const usersReducer: Reducer<UsersState, UserActions> = (
  state: UsersState = initialState,
  action: UserActions
) => {
  switch (action.type) {
    case ActionTypes.WORKGROUP_USER_LIST_REQUEST: {
      return {
        ...state,
        currentWorkgroupUsers: {
          ...state.currentWorkgroupUsers,
          itemsFetching: {
            ...state.currentWorkgroupUsers.itemsFetching,
            isFetching: true,
          },
        },
      };
    }
    case ActionTypes.WORKGROUP_USER_LIST_ERROR: {
      return {
        ...state,
        currentWorkgroupUsers: {
          ...state.currentWorkgroupUsers,
          itemsFetching: {
            ...state.currentWorkgroupUsers.itemsFetching,
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }
    case ActionTypes.WORKGROUP_USER_LIST_SUCCESS: {
      return {
        ...state,
        currentWorkgroupUsers: {
          ...state.currentWorkgroupUsers,
          items: normalize<User>(action.payload.users, 'id'),
          itemsFetching: {
            ...state.currentWorkgroupUsers.itemsFetching,
            isFetching: false,
            error: '',
            fetchComplete: true,
          },
        },
      };
    }
    case ActionTypes.WORKGROUP_REMOVE_USERS_REQUEST: {
      return {
        ...state,
        removeUser: {
          ...state.removeUser,
          isRemoving: true,
        },
      };
    }
    case ActionTypes.WORKGROUP_REMOVE_USERS_ERROR: {
      return {
        ...state,
        removeUser: {
          ...state.removeUser,
          isRemoving: false,
          removingComplete: false,
          removingError: action.payload.error,
        },
      };
    }
    case ActionTypes.WORKGROUP_ADD_USERS_REQUEST: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          isSaving: true,
        },
      };
    }
    case ActionTypes.WORKGROUP_ADD_USERS_ERROR: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          isSaving: false,
          savingError: action.payload.error,
        },
      };
    }
    case ActionTypes.WORKGROUP_ADD_USERS_SUCCESS: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          isSaving: false,
          savingComplete: true,
        },
      };
    }
    case ActionTypes.WORKGROUP_ADD_USERS_SET_SAVED: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          savingComplete: action.payload,
        },
      };
    }
    case ActionTypes.WORKGROUP_REMOVE_USERS_SUCCESS: {
      const removedIds = action.payload.userIds;
      const newUserIds = { ...state.currentWorkgroupUsers.items };

      removedIds.forEach((userId: number) => {
        delete newUserIds[userId];
      });

      return {
        ...state,
        currentWorkgroupUsers: {
          ...state.currentWorkgroupUsers,
          items: newUserIds,
        },
        removeUser: {
          ...state.removeUser,
          isRemoving: false,
          removingComplete: true,
          removingError: '',
        },
      };
    }
    case ActionTypes.USER_SAVE_REQUEST: {
      return {
        ...state,
        saveUser: {
          ...state.saveUser,
          isSaving: true,
          savingComplete: false,
        },
      };
    }
    case ActionTypes.USER_SAVE_ERROR: {
      return {
        ...state,
        saveUser: {
          ...state.saveUser,
          isSaving: false,
          savingComplete: true,
          savingError: action.payload.error,
        },
      };
    }
    case ActionTypes.USER_SAVE_SUCCESS: {
      return {
        ...state,
        saveUser: {
          ...state.saveUser,
          isSaving: false,
          savingComplete: true,
          savingError: '',
        },
      };
    }
    case ActionTypes.USER_FETCH_REQUEST: {
      return {
        ...state,
        fetchUser: {
          ...state.fetchUser,
          isFetching: true,
          fetchError: '',
          fetchComplete: false,
        },
      };
    }
    case ActionTypes.USER_FETCH_ERROR: {
      return {
        ...state,
        fetchUser: {
          ...state.fetchUser,
          isFetching: false,
          fetchError: action.payload.error,
          fetchComplete: false,
        },
      };
    }
    case ActionTypes.USER_FETCH_SUCCESS: {
      return {
        ...state,
        fetchUser: {
          ...state.fetchUser,
          isFetching: false,
          fetchError: '',
          fetchComplete: true,
        },
      };
    }
    case ActionTypes.USER_EDIT_REQUEST: {
      return {
        ...state,
        editUser: {
          ...state.editUser,
          isEditing: true,
        },
      };
    }
    case ActionTypes.USER_EDIT_COMPLETED: {
      return {
        ...state,
        editUser: {
          ...state.editUser,
          isEditing: false,
        },
      };
    }
    case ActionTypes.WELCOME_EMAIL_TEMPLATES_FETCH_REQUESTED: {
      return {
        ...state,
        fetchWelcomeEmailTemplates: {
          ...state.fetchWelcomeEmailTemplates, 
            isFetching: true,
            fetchError: '',
            fetchComplete: false
          },
        };
    }
    case ActionTypes.WELCOME_EMAIL_TEMPLATES_FETCH_FAILED: {
      return {
        ...state,
        fetchWelcomeEmailTemplates: {
          ...state.fetchWelcomeEmailTemplates, 
            isFetching: false,
            fetchError: action.payload,
            fetchComplete: false
          },
        };
    }
    case ActionTypes.WELCOME_EMAIL_TEMPLATES_FETCH_SUCCESS: {
      return {
        ...state,
        welcomeEmailTemplates: {
          ...state.welcomeEmailTemplates,
          welcomeEmailTemplatesList: action.payload.welcomeEmailTemplatesList,
        },
        fetchWelcomeEmailTemplates: {
          ...state.fetchWelcomeEmailTemplates, 
            isFetching: false,
            fetchError: '',
            fetchComplete: true
          },
      };
    }
    default:
      return state;
  }
};
