import { createSelector } from 'reselect';

import { EntityType, NDevice } from '../types';
import { getDataStore } from './genericSelectors';

export const selectDevices = (state: any) =>
  getDataStore(state)[EntityType.NDevice];

export const selectDeviceItems = createSelector(
  [selectDevices],
  devices => {
    return Object.keys(devices.items).map<NDevice>(
      key => devices.items[Number(key)]
    );
  }
);

export const selectDeviceById = (state: any, deviceId: number) => selectDevices(state).items[deviceId];
