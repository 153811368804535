import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  format,
  getDay,
  parseISO,
  startOfDay,
  startOfWeek,
} from 'date-fns';

export const parseTimeSpan = (timeSpan: string) => {
  const timeSpanParts = timeSpan.split(':');
  if (timeSpanParts.length !== 3) {
    throw new Error('TimeSpan format incorrect. The desired format is HH:mm:ss');
  }
  const hours = Number(timeSpanParts[0]);
  const minutes = Number(timeSpanParts[1]);
  const seconds = Number(timeSpanParts[2]);

  const startOfToday = startOfDay(new Date());

  let date = addHours(startOfToday, hours);
  date = addMinutes(date, minutes);
  date = addSeconds(date, seconds);
  return date;
};

export const parseDate = (date: string) => parseISO(date);

type DayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const daysOfTheWeek = (
  formatToUse: string = 'dd',
  sundayIsFirstDay: boolean = true
): Array<{
  label: string;
  day: DayNumber;
}> => {
  const weekStartsOn = sundayIsFirstDay ? 0 : 1;
  const today = new Date();
  const startOfWeekVar = startOfWeek(today, { weekStartsOn });
  const days = [];

  for (let i = 0; i < 7; i++) {
    const dayDate = addDays(startOfWeekVar, i);
    //{ locale: i18next.language,}
    const label = format(dayDate, formatToUse);
    const day = getDay(dayDate) as DayNumber;
    days.push({ label, day });
  }

  return days;
};

const dayToPowerMap = {
  0: 1,
  1: 64,
  2: 32,
  3: 16,
  4: 8,
  5: 4,
  6: 2,
};

export const selectedDaysFromDiffusion = (
  diffusionDays: number,
  formatToUse: string = 'EEEEEE',
  sundayIsFirstDay: boolean = true
) => {
  return daysOfTheWeek(formatToUse, sundayIsFirstDay).map(d => ({
    label: d.label,
    day: d.day,
    selected: (diffusionDays & dayToPowerMap[d.day]) !== 0,
  }));
};
