import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FooterSubmit } from '../../../components/bladeLayout/footerSubmit';
import { HeaderAndContent } from '../../../components/bladeLayout/headerAndContent';
import { DataGridContainer } from '../../../components/dataGrid';
import { ColumnSettings, DataGridSelectionType } from '../../../components/dataGrid/agDataGrid';
import { EntityType, IdName } from '../../../dataStore';
import { NProgram } from '../../../dataStore/types';
import { useStyles } from './programsList.jss';

export interface ProgramsListProps {
  stationId: number;
  defaultSlot: boolean;
  items: NProgram[];
  itemsFetching: boolean;
}

export interface ProgramsListActions {
  forceCloseBlade: () => void;
  addPrograms: (items: IdName[], stationId: number, defaultSlot: boolean) => void;
}

type Props = ProgramsListActions & ProgramsListProps;

const columnSettings: ColumnSettings[] = [
  {
    name: 'name',
    width: 200,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true
  },
  {
    name: 'genre',
    width: 150,
  },
];

export const ProgramsList: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const selectedRef = React.useRef<IdName[]>([]);
  const onSelect = useCallback((selectedIds: IdName[]) => (selectedRef.current = selectedIds), []);
  const footer = () => (
    <FooterSubmit
      hasCancelButton={true}
      cancelButtonLabel={t('footer.cancel')}
      cancel={() => {
        props.forceCloseBlade();
      }}
      submitButtonLabel={t('footer.save')}
      submit={() => {
        props.addPrograms(selectedRef.current, props.stationId, props.defaultSlot);
        props.forceCloseBlade();
      }}
      submitInProgress={false}
    />
  );

  return (
    <HeaderAndContent footer={footer} isBusy={props.itemsFetching} removePadding>
      <div className={classes.container}>
        <DataGridContainer
          onSelect={onSelect}
          entityType={EntityType.NProgram}
          items={props.items}
          showSelectionColumn={true}
          selectionType={
            props.defaultSlot ? DataGridSelectionType.Single : DataGridSelectionType.MultipleOrNone
          }
          columnSettings={columnSettings}
        />
      </div>
    </HeaderAndContent>
  );
};
